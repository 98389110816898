import React from "react";
import { useNavigate } from "react-router-dom";
import { TitleH1, TitleH2 } from "../../../../shared/native/typography.styles";
import { PrimaryButton } from "../../../../shared/native/button.styles";
import {
  InfoPage,
  InfoPageContent,
  InfoPageTextInfo,
} from "../../../../shared/infoPage/infoPage.styles";

const CompleteProfileForm: React.FC = () => {
  const navigate = useNavigate();

  return (
    <InfoPage>
      <InfoPageContent>
        <InfoPageTextInfo>
          <TitleH1>Поздравляем!</TitleH1>
          <TitleH2>Профиль успешно создан.</TitleH2>
          <p>
            Теперь вы можете перейти к подаче заявления на программы высшего и дополнительного
            образования.
          </p>
        </InfoPageTextInfo>
        <PrimaryButton onClick={(): void => navigate("/applications")}>Перейти</PrimaryButton>
      </InfoPageContent>
    </InfoPage>
  );
};

export default CompleteProfileForm;
