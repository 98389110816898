import { AxiosResponse } from "axios/index";
import ResponseBody from "../../models/ResponseBody";
import { $baseApi } from "../../index";
import RequestBody from "../../models/RequestBody";
import { AdditionalDataForm } from "../../../ui/modules/applications/components/highEducation/additionalDataForm/interfaces/additionalDataForm";

export const getAdditionalDataFormRequest = (): Promise<
  AxiosResponse<ResponseBody<AdditionalDataForm>>
> => {
  return $baseApi.post<ResponseBody<AdditionalDataForm>>(
    "",
    new RequestBody("draft.read", {
      type: "additionalVO",
    })
  );
};

export const saveAdditionalDataFormRequest = (
  data: AdditionalDataForm
): Promise<AxiosResponse<ResponseBody<null>>> => {
  const resultBody = {
    type: "additionalVO",
    data: {
      ...data,
    },
  };
  return $baseApi.post<ResponseBody<null>>("", new RequestBody("draft.save", resultBody));
};

export const submitAdditionalDataFormRequest = (): Promise<AxiosResponse<ResponseBody<null>>> => {
  return $baseApi.post<ResponseBody<null>>("", new RequestBody("profile.save", null));
};
