import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PreviewProfileForm from "../../ui/modules/profile/components/peviewProfileForm/PreviewProfileForm";
import DocumentsSubmission from "../../ui/modules/profile/components/documentsSubmission/DocumentsSubmission";
import FillingOutProfile from "../../ui/modules/profile/components/fillingOutProfile/FillingOutProfile";
import ProfileForm from "../../ui/modules/profile/ProfileForm";
import CompleteProfileForm from "../../ui/modules/profile/components/completeProfileForm/CompleteProfileForm";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { fetchGetProfileForm } from "../../store/modules/profile/profileForm/profileThunk";
import Loader from "../../ui/shared/loaders/Loader";
import SignatureRoutes from "./signatureRoutes";

const ProfileRoutes: React.FC = () => {
  const dispatch = useAppDispatch();
  const { accountState } = useAppSelector((state) => state.coreState);
  const { profileFormState } = useAppSelector((state) => state.modulesState);

  React.useEffect(() => {
    dispatch(fetchGetProfileForm());
  }, []);

  if (profileFormState.dataLoadingStatus === "LOADING") {
    return (
      <Routes>
        <Route path="*" element={<Loader type="blank" />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {accountState?.user?.hasProfile ? (
        <>
          <Route path="" element={<Navigate to="/profile/preview" />} />
          <Route path="preview" element={<PreviewProfileForm mode="view" />} />
          <Route path="complete-profile-form" element={<CompleteProfileForm />} />
          <Route path="signature/*" element={<SignatureRoutes />} />
        </>
      ) : (
        <>
          <Route path="" element={<DocumentsSubmission />} />
          <Route path="filling-out-profile" element={<FillingOutProfile />} />
          <Route path="profile-form" element={<ProfileForm />} />
          <Route path="check-profile-form" element={<PreviewProfileForm mode="finallyCheck" />} />
        </>
      )}
    </Routes>
  );
};

export default ProfileRoutes;
