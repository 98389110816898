import React from "react";
import { Route, Routes } from "react-router-dom";
import AddingDataInfo from "../../../../ui/modules/applications/components/highEducation/additionalDataForm/components/addingDataInfo/AddingDataInfo";
import WhatNeedInfo from "../../../../ui/modules/applications/components/highEducation/additionalDataForm/components/whatNeedInfo/whatNeedInfo";
import AdditionalDataForm from "../../../../ui/modules/applications/components/highEducation/additionalDataForm/AdditionalDataForm";
import PreviewAdditionalDataForm from "../../../../ui/modules/applications/components/highEducation/additionalDataForm/components/previewAdditionalDataForm/PreviewAdditionalDataForm";
import CompleteAdditionalDataForm from "../../../../ui/modules/applications/components/highEducation/additionalDataForm/components/completeAdditionalDataForm/CompleteAdditionalDataForm";
import { useAppSelector } from "../../../hooks/redux";

const AdditionalHighDataRoutes: React.FC = () => {
  const { accountState } = useAppSelector((state) => state.coreState);

  if (accountState?.user?.hasVO) {
    return (
      <Routes>
        <Route path="" element={<PreviewAdditionalDataForm mode="view" />} />
        <Route path="complete" element={<CompleteAdditionalDataForm />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="" element={<AddingDataInfo />} />
      <Route path="what-need" element={<WhatNeedInfo />} />
      <Route path="form" element={<AdditionalDataForm />} />
      <Route path="check" element={<PreviewAdditionalDataForm mode="finallyCheck" />} />
    </Routes>
  );
};

export default AdditionalHighDataRoutes;
