import React from "react";
import { Control, RegisterOptions, useController } from "react-hook-form";
import { Input } from "../native/input.styles";
import { DropdownContainer, StyledSelectContainer, StyledSelectItem } from "./select.styles";
import SelectItem from "./SelectItem";
import UseOutsideClick from "../../../core/hooks/useOutsideClick";
import colors from "../../../core/styles/colors";

const Select: React.FC<
  {
    items: SelectItem[];
    placeholder?: string;
    control: Control<any>;
    name: string;
    options?: RegisterOptions;
    readOnly?: boolean;
    disabled?: boolean;
    hideArrow?: boolean;
    whiteBackground?: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  items,
  placeholder,
  control,
  name,
  options = {},
  readOnly,
  disabled,
  hideArrow,
  whiteBackground,
  ...attrs
}) => {
  // REFS
  const containerRef = React.useRef(null);
  const dropdownRef = React.useRef(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  // CONTROLLER
  const controller = useController({ control, name, rules: options });

  const getItemByControlValue = (): SelectItem => {
    return items.find((item) => item.value === controller.field.value);
  };

  // STATES
  const [showOptionsDropdown, setShowOptionsDropdown] = React.useState(false);
  const [inputValue, setInputValue] = React.useState<string>(getItemByControlValue()?.label ?? "");
  const [viewedItems, setViewedItems] = React.useState<SelectItem[]>(items);
  const [dropdownCoords, setDropdownCoords] = React.useState<DOMRect>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  } as DOMRect);

  // FUNCTIONS
  const setInputValueHandler = (eventValue: string): void => {
    setShowOptionsDropdown(true);
    setInputValue(eventValue);

    const newItems = items.filter((item) =>
      item.label?.toLowerCase().includes(eventValue.toLowerCase())
    );
    setViewedItems(newItems);

    const equalItem = items.find((item) => item.label?.toLowerCase() === eventValue?.toLowerCase());
    if (equalItem) {
      if (controller.field.value !== equalItem.value) {
        controller.field.onChange(equalItem.value);
      }
      return;
    }

    controller.field.onChange("");
  };

  const setDialogValueHandler = (value: any): void => {
    const selectedValue = items.find((item) => item.value === value);

    if (selectedValue.value !== controller.field.value) {
      setShowOptionsDropdown(false);
      controller.field.onChange(value);
    }
  };

  const setControlValueHandler = (): void => {
    const selectedItem = getItemByControlValue();

    if (!selectedItem) {
      setInputValue("");
      return;
    }

    if (selectedItem.label !== inputValue) {
      setInputValue(selectedItem.label);
    }
  };

  // EFFECTS
  UseOutsideClick(containerRef, () => setShowOptionsDropdown(false), showOptionsDropdown);

  React.useEffect(() => {
    setViewedItems(items);
    setControlValueHandler();
  }, [items]);

  React.useEffect(() => {
    setControlValueHandler();
  }, [controller.field.value]);

  React.useEffect(() => {
    const update = (): void => {
      const elementPositions = inputRef?.current?.getBoundingClientRect();

      if (!elementPositions) {
        return;
      }

      setDropdownCoords({
        x: elementPositions.left + document.documentElement.scrollLeft,
        y: elementPositions.top + elementPositions.height + document.documentElement.scrollTop,
        width: elementPositions.width,
        height: elementPositions.height,
      } as DOMRect);
    };

    if (inputRef) {
      update();
      document.addEventListener("scroll", update, true);
    }

    return () => {
      document.removeEventListener("scroll", update, true);
    };
  }, [inputRef]);

  return (
    <StyledSelectContainer
      ref={containerRef}
      open={showOptionsDropdown}
      hideArrow={hideArrow}
      {...attrs}
    >
      <Input
        ref={inputRef}
        className="selectInput"
        placeholder={placeholder}
        value={inputValue}
        onClick={(): void => {
          if (disabled || readOnly) {
            return;
          }

          setShowOptionsDropdown(true);
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          setInputValueHandler(e.target.value)
        }
        readOnly={readOnly}
        disabled={disabled}
        hasErrors={!!controller.fieldState.error}
        style={whiteBackground ? { background: colors.white.general } : {}}
      />
      {showOptionsDropdown && (
        <DropdownContainer
          ref={dropdownRef}
          style={{
            left: dropdownCoords.x,
            top: dropdownCoords.y,
          }}
          width={inputRef.current.offsetWidth}
          whiteBackground={whiteBackground}
        >
          {viewedItems?.length ? (
            viewedItems.map((item) => (
              <StyledSelectItem
                key={item.value}
                $active={item.value === controller.field.value}
                onClick={(): void => setDialogValueHandler(item.value)}
                whiteBackground={whiteBackground}
              >
                <span>{item.label}</span>
              </StyledSelectItem>
            ))
          ) : (
            <p className="noContent">Варианты отсутствуют</p>
          )}
        </DropdownContainer>
      )}
    </StyledSelectContainer>
  );
};

export default Select;
