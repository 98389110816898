import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import NotificationCard from "../notificationCard/NotificationCard";
import Notification from "../../../../interfaces/Notification";
import { OutlinedButton } from "../../../../../../shared/native/button.styles";
import FieldsGroup from "../../../../../../shared/fieldsGroup/fieldsGroup.styles";
import AddFileButton from "../../../../../../shared/addFileButton/AddFileButton";
import colors from "../../../../../../../core/styles/colors";
import { useAppDispatch } from "../../../../../../../core/hooks/redux";
import { submitNotificationRequest } from "../../../../../../../http/requests/notifications.http";
import { removeNotification } from "../../../../../../../store/modules/notifications/notificationsSlice";
import AddFileInfo from "../../../../../../shared/addFileInfo/AddFileInfo";

const PreviewAndUploadType: React.FC<{ notification: Notification }> = ({ notification }) => {
  const dispatch = useAppDispatch();
  const [disableResponseButton, setDisableResponseButton] = React.useState(false);
  const { control, setValue, handleSubmit } = useForm<FieldValues>({
    defaultValues: {
      files: [],
    },
  });

  const submitHandler = (data: FieldValues): void => {
    setDisableResponseButton(true);
    submitNotificationRequest({
      notify_id: notification.uid,
      type: notification.notification_type,
      files: data.files,
      answer: "",
    }).then((res) => {
      setDisableResponseButton(false);
      if (res.data.error) {
        return;
      }

      dispatch(removeNotification(notification.uid));
    });
  };

  const openLinkHandler = (): void => {
    window.open(notification.file_url, "_blank").focus();
  };

  return (
    <NotificationCard
      title={notification?.document_name}
      comment={notification.comment}
      handleSubmit={handleSubmit(submitHandler)}
      disableResponseButton={disableResponseButton}
    >
      <FieldsGroup>
        <OutlinedButton
          type="button"
          onClick={openLinkHandler}
          initialBackground={colors.white.general}
        >
          Открыть
        </OutlinedButton>
        <AddFileButton
          control={control}
          setValue={setValue}
          multiple
          name="files"
          options={{ required: "Обязательное поле" }}
          accept="image/*"
          previewProps={{
            whiteBackground: true,
            style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
          }}
          buttonProps={{
            whiteBackground: true,
          }}
        >
          Загрузить
        </AddFileButton>
      </FieldsGroup>
      <AddFileInfo style={{ marginTop: "16px" }} />
    </NotificationCard>
  );
};

export default PreviewAndUploadType;
