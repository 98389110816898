import styled from "styled-components";
import colors from "../../../core/styles/colors";
import sizes from "../../../core/styles/sizes";
import zIndex from "../../../core/styles/z-index";

export const ModalContainer = styled.div`
  position: fixed;
  z-index: ${zIndex.modal};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  @media all and (${sizes.mobile}) {
    z-index: ${zIndex.topElements - 1};
  }
`;

export const ModalWindow = styled.div`
  position: relative;
  width: 495px;
  padding: 16px 20px;
  background: ${colors.white.hoverWhite3};
  border-radius: 24px;

  @media all and (${sizes.mobile}) {
    width: calc(100% - 36px);
    height: calc(100% - 130px);
    padding: 80px 18px 50px 18px;
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    &:hover {
      filter: brightness(95%);
    }
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
`;
