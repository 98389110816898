import styled from "styled-components";
import colors from "../../../../../../../../core/styles/colors";

export const SignatureModalWindow = styled.div`
  width: 100%;
`;

export const SignatureModalWindowContent = styled.div`
  width: 100%;

  h2 {
    color: ${colors.black.lightBlack};
  }

  p {
    font-size: 14px;
    font-weight: 300;
    color: ${colors.gray.gray300};
    margin: 0;
    span {
      font-weight: 600;
      color: ${colors.red.general};
      margin-right: 4px;
    }
  }

  input {
    margin: 16px 0;
    background-color: ${colors.white.general};
  }
`;

export const SignatureModalWindowActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    width: 100%;
    padding: 0 16px;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
