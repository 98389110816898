import styled from "styled-components";
import colors from "../../../../core/styles/colors";

export const LinkCard = styled.div<{ $dashed: boolean; color: string }>`
  position: relative;
  width: ${(props): string => (props.$dashed ? "220px" : "224px")};
  min-height: ${(props): string => (props.$dashed ? "100px" : "104px")};
  height: fit-content;
  padding: 8px 16px;
  background: ${colors.white.hoverWhite3};
  border-radius: 12.5px;
  border: ${(props): string => (props.$dashed ? `2px dashed ${colors.gray.gray300}` : "none")};
  cursor: pointer;
  transition: ease-in-out 0.3s;
  &:hover {
    background: ${colors.red.general};
    border-color: ${colors.white.general};
    b {
      color: ${colors.white.general};
    }
    p {
      color: ${colors.white.general};
    }
    .cardPriority {
      color: ${colors.white.general};
      border: 1px solid ${colors.white.general};
    }
  }
  .chip {
    position: absolute;
    top: -8px;
    right: -8px;
    min-width: 14px;
    width: fit-content;
    height: 14px;
    padding: 5px;
    background: ${(props): string => props.color};
    border-radius: 24px;
    font-size: 14px;
    font-weight: 700;
    color: ${colors.white.general};
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 10px;
      height: 10px;
    }
  }

  @media all and (max-width: 570px) {
    width: calc(100% - 32px);
  }
`;
