import React from "react";
import { Control, Controller, FieldValues, FormState, UseFormRegister } from "react-hook-form";
import InputMask from "react-input-mask";
import {
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../../../shared/form/fieldInfo.styles";
import { FieldData } from "../../../../../../../../../shared/form/fieldData.styles";
import { Input } from "../../../../../../../../../shared/native/input.styles";
import emailRegexp from "../../../../../../../../../../core/regexp/emailRegexp";
import noUnderlines from "../../../../../../../../../../core/regexp/noUnderlines";
import { TitleH2 } from "../../../../../../../../../shared/native/typography.styles";

const OrganizationForm: React.FC<{
  register: UseFormRegister<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control;
}> = ({ register, formState: { errors }, control }) => {
  return (
    <>
      <FieldInfo>
        <TitleH2 style={{ textAlign: "left" }}>Данные организации</TitleH2>
      </FieldInfo>
      <FieldData />
      <FieldInfo leftContent>
        <FieldName leftContent>Наименование организации</FieldName>
        {errors.organizationName && (
          <FieldError leftContent>{errors.organizationName?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.organizationName}
          {...register("organizationName", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>ИНН</FieldName>
        {errors.inn && <FieldError leftContent>{errors.inn?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.inn}
          {...register("inn", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>КПП</FieldName>
        {errors.kpp && <FieldError leftContent>{errors.kpp?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.kpp}
          {...register("kpp", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>ОГРН</FieldName>
        {errors.ogrn && <FieldError leftContent>{errors.ogrn?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.ogrn}
          {...register("ogrn", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>Наименование банка</FieldName>
        {errors.bankName && (
          <FieldError leftContent>{errors.bankName?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.bankName}
          {...register("bankName", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>Рассчетный счет</FieldName>
        {errors.paymentAccount && (
          <FieldError leftContent>{errors.paymentAccount?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.paymentAccount}
          {...register("paymentAccount", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>БИК</FieldName>
        {errors.bik && <FieldError leftContent>{errors.bik?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.bik}
          {...register("bik", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>Корреспондентский счет</FieldName>
        {errors.correspondentAccount && (
          <FieldError leftContent>{errors.correspondentAccount?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.correspondentAccount}
          {...register("correspondentAccount", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>Телефон организации</FieldName>
        {errors.organizationPhone && (
          <FieldError leftContent>{errors.organizationPhone?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Controller
          name="organizationPhone"
          control={control}
          rules={{
            required: "Обязательное поле",
            pattern: {
              value: noUnderlines,
              message: "Неполное значение",
            },
          }}
          render={({ field: { value, onChange } }): React.ReactElement => (
            <InputMask mask="+9 (999) 999-99-99" value={value} onChange={onChange}>
              <Input
                className="defaultInput"
                placeholder="+X (XXX) XXX-XX-XX"
                hasErrors={!!errors.organizationPhone}
              />
            </InputMask>
          )}
        />
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>Email организации</FieldName>
        {errors.organizationEmail && (
          <FieldError leftContent>{errors.organizationEmail?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.organizationEmail}
          {...register("organizationEmail", {
            required: "Обязательное поле",
            pattern: {
              value: emailRegexp,
              message: "Неверный формат",
            },
          })}
        />
      </FieldData>
    </>
  );
};

export default OrganizationForm;
