import styled from "styled-components";
import colors from "../../../../core/styles/colors";
import sizes from "../../../../core/styles/sizes";

export const Container = styled.div`
  width: 100%;
  min-height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  @media all and (${sizes.mobile}) {
    height: 100%;
  }
`;

export const Form = styled.form`
  flex-grow: 1;
  max-width: 476px;
  .fieldRow {
    width: 100%;
    margin-top: 16px;
    .fieldHeader {
      width: calc(100% - 32px);
      padding: 0 16px;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0;
      }
      a {
        text-decoration: none;
        color: ${colors.red.general};
        font-weight: 300;
        &:hover {
          text-decoration: underline 1px solid ${colors.red.general};
        }
      }
    }
    input {
      width: calc(100% - 30px);
    }
  }
  .error {
    display: block;
    width: 100%;
    padding: 16px 0;
    text-align: center;
  }
  .submitButton {
    width: 100%;
    margin-top: 49px;
  }
  &:has(.error) {
    .submitButton {
      margin-top: 8px;
    }
  }

  @media all and (${sizes.mobile}) {
    width: calc(100% - 32px);
    margin: 0 16px;
    padding: 16px;
    border-radius: 10px;
    background: ${colors.white.general};
  }
`;

export const LoginFormOther = styled.div`
  width: 100%;
  p {
    width: 100%;
    text-align: center;
    font-weight: 300;
    a {
      margin-left: 4px;
      text-decoration: none;
      color: ${colors.red.general};
      &:hover {
        text-decoration: underline 1px solid ${colors.red.general};
      }
    }
  }
`;
