import React from "react";
import { Route, Routes } from "react-router-dom";
import SubmissionInfo from "../../../../ui/modules/applications/components/highEducation/highEducationForm/components/submissionInfo/SubmissionInfo";
import HighEducationForm from "../../../../ui/modules/applications/components/highEducation/highEducationForm/HighEducationForm";
import PreviewHighEducationForm from "../../../../ui/modules/applications/components/highEducation/highEducationForm/components/previewHighEducationForm/PreviewHighEducationForm";
import CompleteHighEducationForm from "../../../../ui/modules/applications/components/highEducation/highEducationForm/components/completeHighEducationForm/CompleteHighEducationForm";
import RatingApplicationsList from "../../../../ui/modules/applications/components/applicationsList/ratingApplicationsList/RatingApplicationsList";

const HighEducationRoutes: React.FC = () => {
  return (
    <Routes>
      {/* PREVIEW */}
      <Route path="" element={<RatingApplicationsList degree="high" />} />
      <Route path=":id" element={<PreviewHighEducationForm mode="view" />} />
      {/* FORM */}
      <Route path="submission-info" element={<SubmissionInfo />} />
      <Route path="form" element={<HighEducationForm />} />
      <Route path="check" element={<PreviewHighEducationForm mode="finallyCheck" />} />
      <Route path="complete" element={<CompleteHighEducationForm />} />
    </Routes>
  );
};

export default HighEducationRoutes;
