import convertAddressObject from "../../../../../../../profile/utils/convertAddressObject";
import AdditionalEducationLegalPersonForm from "../interfaces/AdditionalEducationLegalPersonForm";

const convertFormData = (
  data: AdditionalEducationLegalPersonForm
): AdditionalEducationLegalPersonForm => {
  const formData = data;

  if (formData.residingCoincidesRegistration) {
    formData.residingAddress = formData.registerAddress;
  }

  formData.registerAddress = convertAddressObject("to", formData.registerAddress);
  formData.residingAddress = convertAddressObject("to", formData.residingAddress);

  return formData;
};

export default convertFormData;
