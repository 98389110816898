import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import NotificationCard from "../notificationCard/NotificationCard";
import Notification from "../../../../interfaces/Notification";
import { submitNotificationRequest } from "../../../../../../../http/requests/notifications.http";
import { removeNotification } from "../../../../../../../store/modules/notifications/notificationsSlice";
import { useAppDispatch } from "../../../../../../../core/hooks/redux";

const TextType: React.FC<{ notification: Notification }> = ({ notification }) => {
  const dispatch = useAppDispatch();
  const { handleSubmit } = useForm<FieldValues>();
  const [disableResponseButton, setDisableResponseButton] = React.useState(false);

  const submitHandler = (): void => {
    setDisableResponseButton(true);
    submitNotificationRequest({
      notify_id: notification.uid,
      type: notification.notification_type,
      files: [],
      answer: "",
    }).then((res) => {
      setDisableResponseButton(false);
      if (res.data.error) {
        return;
      }

      dispatch(removeNotification(notification.uid));
    });
  };

  return (
    <NotificationCard
      title={notification?.document_name}
      comment={notification.comment}
      handleSubmit={handleSubmit(submitHandler)}
      responseButtonText="Отметить прочитанным"
      disableResponseButton={disableResponseButton}
    />
  );
};

export default TextType;
