import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import NotificationsInfo from "../../../ui/modules/notifications/interfaces/NotificationsInfo";
import loading from "../../../core/types/loading";
import { fetchGetNotificationsInfo } from "./notificationsThunk";
import Notification from "../../../ui/modules/notifications/interfaces/Notification";

interface NotificationsState {
  info: NotificationsInfo;
  notifications: Notification[];
  countLoadingStatus: loading;
}

const initialState: NotificationsState = {
  info: null,
  notifications: [],
  countLoadingStatus: null,
};

const notificationsAdapter = createEntityAdapter<NotificationsState>();

const NotificationsSlice = createSlice({
  name: "notifications",
  initialState: notificationsAdapter.getInitialState(initialState),
  reducers: {
    setNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.notifications = action.payload;
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      const notificationId = action.payload;
      const newNotifications = [...state.notifications];

      newNotifications.splice(
        newNotifications.findIndex((notification) => notification.uid === notificationId),
        1
      );

      state.notifications = newNotifications;
    },
  },
  extraReducers: (builder) => {
    // GET COUNT
    builder.addCase(fetchGetNotificationsInfo.pending, (state) => {
      state.countLoadingStatus = "LOADING";
    });
    builder.addCase(fetchGetNotificationsInfo.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.countLoadingStatus = "ERROR";
        return;
      }
      state.countLoadingStatus = "COMPLETE";

      state.info = action.payload.payload;
    });
  },
});

export const { setNotifications, removeNotification } = NotificationsSlice.actions;

export default NotificationsSlice.reducer;
