import React from "react";
import {
  Control,
  FieldValues,
  FormState,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import {
  FieldDescription,
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import {
  AddFileContainer,
  FieldData,
  RowFieldsContainer,
} from "../../../../../../../shared/form/fieldData.styles";
import Select from "../../../../../../../shared/select/Select";
import { useAppDispatch, useAppSelector } from "../../../../../../../../core/hooks/redux";
import DictionaryItem from "../../../../../../../../core/interfaces/dictionaries/DictionaryItem";
import { Input } from "../../../../../../../shared/native/input.styles";
import { fetchGetProfileForm } from "../../../../../../../../store/modules/profile/profileForm/profileThunk";
import ResponseBody from "../../../../../../../../http/models/ResponseBody";
import SavedProfileData from "../../../../../../profile/interfaces/savedProfileData";
import Calendar from "../../../../../../../shared/calendar/Calendar";
import DateRegexp from "../../../../../../../../core/regexp/DateRegexp";
import AddFileButton from "../../../../../../../shared/addFileButton/AddFileButton";
import { SplitAdditionalAeDataForm } from "../../interfaces/additionalAEDataForm";
import AddFileInfo from "../../../../../../../shared/addFileInfo/AddFileInfo";

const EducationForm: React.FC<{
  register: UseFormRegister<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<SplitAdditionalAeDataForm>;
}> = ({ register, formState: { errors }, control, setValue }) => {
  const dispatch = useAppDispatch();
  const { dictionariesState } = useAppSelector((state) => state.coreState);
  const [userCitizenship, setUserCitizenship] = React.useState<string>(null);
  const [currentDocumentsType, setCurrentDocumentsType] = React.useState<DictionaryItem[]>([]);
  const educationLevelWatch = useWatch({ control, name: "educationLevel" }) as string;

  React.useEffect(() => {
    if (dictionariesState.dictionaries && educationLevelWatch) {
      setCurrentDocumentsType(
        dictionariesState.dictionaries.edu_type_dpo.find((type) => educationLevelWatch === type.id)
          .docyments_type
      );
      return;
    }

    setCurrentDocumentsType([]);
    setValue("educationDocumentType", "");
  }, [dictionariesState.dictionaries, educationLevelWatch]);

  React.useEffect(() => {
    dispatch(fetchGetProfileForm()).then((res) => {
      const data = res.payload as ResponseBody<SavedProfileData>;
      setUserCitizenship(data.payload.passportData.citizenship);
    });
  }, []);

  return (
    <FormContainer>
      <FieldInfo>
        <FieldName>Уровень образования</FieldName>
        {errors.educationLevel && (
          <FieldError>{errors.educationLevel?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        {dictionariesState?.dictionaries && (
          <Select
            className="defaultSelect"
            placeholder="Выберите уровень"
            items={dictionariesState.dictionaries.edu_type_dpo.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            control={control}
            options={{
              required: "Обязательное поле",
            }}
            name="educationLevel"
          />
        )}
      </FieldData>
      <FieldInfo>
        <FieldName>Документ об образовании</FieldName>
        {errors.educationDocumentType && (
          <FieldError>{errors.educationDocumentType?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите документ"
          items={
            currentDocumentsType?.length
              ? currentDocumentsType.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))
              : []
          }
          control={control}
          options={{
            required: "Обязательное поле",
          }}
          name="educationDocumentType"
          disabled={!currentDocumentsType?.length}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Серия и номер</FieldName>
        {(errors.serial || errors.number) && (
          <FieldError>
            {(errors.serial?.message as string) ?? (errors.number?.message as string)}
          </FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <RowFieldsContainer>
          <Input
            placeholder="Серия"
            hasErrors={!!errors.serial}
            style={{
              marginRight: 8,
            }}
            {...register("serial", {
              required: {
                message: "Серия является обязательным",
                value: userCitizenship === "643",
              },
              maxLength: {
                message: "Максимальная длина серии 20 символов",
                value: 20,
              },
            })}
          />
          <Input
            placeholder="Номер"
            hasErrors={!!errors.number}
            {...register("number", {
              required: "Номер является обязательным",
              maxLength: {
                message: "Максимальная длина номера 100 символов",
                value: 100,
              },
            })}
          />
        </RowFieldsContainer>
      </FieldData>
      <FieldInfo>
        <FieldName>Кем выдан</FieldName>
        {errors.issuedByWhom && <FieldError>{errors.issuedByWhom?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.issuedByWhom}
          {...register("issuedByWhom", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Дата выдачи</FieldName>
        {errors.dateOfIssue && <FieldError>{errors.dateOfIssue?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Calendar
          className="defaultCalendar"
          placeholder="ДД.ММ.ГГГГ"
          control={control}
          name="dateOfIssue"
          maxDate={new Date()}
          options={{
            required: "Обязательное поле",
            pattern: {
              value: DateRegexp,
              message: "Неверная дата",
            },
          }}
          defaultActiveStartDate={new Date()}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Загрузка документов</FieldName>
        <FieldDescription>Возможно прикрепить несколько документов</FieldDescription>
        {(errors.educationDocument || errors.educationDocumentAppendix) && (
          <FieldError>
            {(errors.educationDocument?.message as string) ??
              (errors.educationDocumentAppendix?.message as string)}
          </FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <AddFileContainer>
          <AddFileButton
            name="educationDocument"
            control={control}
            options={{ required: "Обязательные поля" }}
            setValue={setValue}
            accept={"image/*"}
            multiple
          >
            Документ об образовании
          </AddFileButton>
          <AddFileButton
            name="educationDocumentAppendix"
            control={control}
            options={{ required: "Обязательные поля" }}
            setValue={setValue}
            accept={"image/*"}
            multiple
            description="(Все страницы)"
          >
            Приложение к документу
          </AddFileButton>
        </AddFileContainer>
      </FieldData>
      <FieldInfo />
      <FieldData>
        <AddFileInfo />
      </FieldData>
    </FormContainer>
  );
};

export default EducationForm;
