import React from "react";
import { FieldValues, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Notification from "../../../../../interfaces/Notification";
import NotificationCard from "../../notificationCard/NotificationCard";
import { OutlinedButton, PrimaryButton } from "../../../../../../../shared/native/button.styles";
import colors from "../../../../../../../../core/styles/colors";
import { useAppDispatch, useAppSelector } from "../../../../../../../../core/hooks/redux";
import { submitNotificationRequest } from "../../../../../../../../http/requests/notifications.http";
import { removeNotification } from "../../../../../../../../store/modules/notifications/notificationsSlice";
import FieldsGroup from "../../../../../../../shared/fieldsGroup/fieldsGroup.styles";
import AddFileButton from "../../../../../../../shared/addFileButton/AddFileButton";
import degreeType from "../../../../../../../../core/types/degreeType";
import Modal from "../../../../../../../shared/modal/Modal";
import {
  SignatureModalWindow,
  SignatureModalWindowActions,
  SignatureModalWindowContent,
} from "./signatureType.styled";
import File from "../../../../../../../../core/interfaces/File";
import { TitleH2 } from "../../../../../../../shared/native/typography.styles";
import { Input } from "../../../../../../../shared/native/input.styles";
import { sendVerificationCodeRequest } from "../../../../../../../../http/requests/signature.http";
import UseAlert from "../../../../../../../../core/hooks/useAlert";
import AddFileInfo from "../../../../../../../shared/addFileInfo/AddFileInfo";

const SignatureType: React.FC<{
  notification: Notification;
  degree: degreeType;
}> = ({ notification, degree }) => {
  const alertHandler = UseAlert();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { accountState } = useAppSelector((state) => state.coreState);
  const { register, control, setValue, handleSubmit } = useForm<{
    files?: File[];
    answer?: string;
  }>();
  const [disableResponseButton, setDisableResponseButton] = React.useState(false);
  const [showConfirmWindow, setShowConfirmWindow] = React.useState(false);
  const answerWatch = useWatch({ control, name: "answer" });

  const submitHandler = (data?: FieldValues): void => {
    let resultFiles: File[] = [];

    if (degree !== "additional") {
      resultFiles = accountState?.user?.hasEcp ? [] : data?.files;
    }

    setDisableResponseButton(true);
    submitNotificationRequest({
      notify_id: notification.uid,
      type: notification.notification_type,
      files: resultFiles,
      answer: degree !== "additional" && notification.ident ? data?.answer : "",
      email: accountState.user?.email,
    }).then((res) => {
      setDisableResponseButton(false);
      if (res.data.error) {
        return;
      }

      dispatch(removeNotification(notification.uid));
    });
  };

  const submitConditions = (data: FieldValues): void => {
    if (!accountState?.user?.hasEcp && !data.files?.length && degree !== "additional") {
      navigate("/profile/signature");
      return;
    }

    if (degree === "additional" || notification.ident) {
      if (degree !== "additional" && notification.ident) {
        sendVerificationCodeRequest("email").then((res) => {
          if (res.data.error) {
            alertHandler({ message: res.data.text, type: "error" });
            return;
          }

          setShowConfirmWindow(true);
        });
        return;
      }

      setShowConfirmWindow(true);
      return;
    }

    submitHandler(data);
  };

  const openLinkHandler = (): void => {
    window.open(notification.file_url, "_blank").focus();
  };

  return (
    <NotificationCard
      title={notification?.document_name}
      comment={notification.comment}
      handleSubmit={handleSubmit(submitConditions)}
      responseButtonText="Подписать"
      disableResponseButton={disableResponseButton}
    >
      {accountState?.user?.hasEcp || degree === "additional" ? (
        <OutlinedButton
          type="button"
          onClick={openLinkHandler}
          initialBackground={colors.white.general}
          style={{ fontWeight: 300, textTransform: "none", padding: "0 16px" }}
        >
          Открыть
        </OutlinedButton>
      ) : (
        <>
          <FieldsGroup>
            <OutlinedButton
              type="button"
              onClick={openLinkHandler}
              initialBackground={colors.white.general}
            >
              Открыть
            </OutlinedButton>
            <AddFileButton
              control={control}
              setValue={setValue}
              multiple
              name="files"
              accept="image/*"
              previewProps={{
                whiteBackground: true,
                style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
              }}
              buttonProps={{
                whiteBackground: true,
              }}
            >
              Загрузить
            </AddFileButton>
          </FieldsGroup>
          <AddFileInfo style={{ marginTop: "16px" }} />
        </>
      )}
      {showConfirmWindow && (
        <Modal closeModal={(): void => setShowConfirmWindow(false)}>
          <SignatureModalWindow>
            <SignatureModalWindowContent>
              <TitleH2 style={{ marginBottom: 16 }}>
                {degree === "additional"
                  ? "Подписать документ электронной подписью?"
                  : "Подтверждение"}
              </TitleH2>
              {degree !== "additional" && (
                <>
                  <p>
                    На вашу электронную почту отправлен код подтверждения, который необходимо
                    вставить в поле для ввода и подписать документ.
                  </p>
                  <Input
                    {...register("answer", { required: true })}
                    placeholder="Код подтверждения"
                  />
                </>
              )}
            </SignatureModalWindowContent>
            <SignatureModalWindowActions>
              {degree === "additional" && (
                <OutlinedButton onClick={(): void => setShowConfirmWindow(false)}>
                  Нет
                </OutlinedButton>
              )}
              <PrimaryButton
                disabled={
                  (degree !== "additional" && notification.ident && !answerWatch?.length) ||
                  disableResponseButton
                }
                onClick={handleSubmit(submitHandler)}
              >
                {degree === "additional" ? "Да" : "Подтвердить"}
              </PrimaryButton>
            </SignatureModalWindowActions>
          </SignatureModalWindow>
        </Modal>
      )}
    </NotificationCard>
  );
};

export default SignatureType;
