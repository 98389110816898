import React from "react";
import { Flag, Container } from "./photoRequirementsModal.styles";
import requirementsInfo from "./constants/requirementsInfo";
import { ReactComponent as CheckMarkIcon } from "../../../../../../core/static/icons/check-mark.svg";
import { ReactComponent as XMarkIcon } from "../../../../../../core/static/icons/x-mark.svg";
import { TitleH2 } from "../../../../../shared/native/typography.styles";

const PhotoRequirementsModal: React.FC = () => {
  return (
    <Container>
      <div className="header">
        <TitleH2>Требования к фото</TitleH2>
        <p>Ровный фон, хорошее освещение, фото головы по линию плеч, взгляд в камеру.</p>
      </div>
      <div className="content">
        {requirementsInfo.map((requirement) => (
          <div key={requirement.label} className="content__item">
            <div className="image">{requirement.img}</div>
            <div className="info">
              <Flag checked={requirement.check}>
                {requirement.check ? <CheckMarkIcon /> : <XMarkIcon />}
              </Flag>
              <p>{requirement.label}</p>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default PhotoRequirementsModal;
