import SubmitFormCase from "../../../shared/form/interfaces/submitFormCase";
import {
  convertContactsForm,
  convertPassportForm,
  convertPersonalForm,
  convertResidingPlaceForm,
} from "../utils/convertToSaveRequest";

export default [
  {
    name: "personal",
    convertFunc: convertPersonalForm,
  },
  {
    name: "contacts",
    convertFunc: convertContactsForm,
  },
  {
    name: "passport",
    convertFunc: convertPassportForm,
  },
  {
    name: "address",
    convertFunc: convertResidingPlaceForm,
  },
] as SubmitFormCase[];
