import Step from "../../../../../../shared/stepper/interfaces/Step";
import ApplicationForm from "../forms/applicationForm/ApplicationForm";
import AchievementsForm from "../forms/achievementsForm/achievementsForm";
import DiscountsAndBenefitsForm from "../forms/discountsAndBenefitsForm/DiscountsAndBenefitsForm";
import LetterForm from "../forms/letterForm/letterForm";

const steps: Step[] = [
  {
    title: "Заявление о приеме на обучение",
    element: (props: any) => <ApplicationForm {...props} />,
  },
  {
    title: "Индивидуальные достижения",
    element: (props: any) => <AchievementsForm {...props} />,
  },
  {
    title: "Мотивационное письмо",
    element: (props: any) => <LetterForm {...props} />,
  },
  {
    title: "Скидки и льготы",
    element: (props: any) => <DiscountsAndBenefitsForm {...props} />,
  },
];

export default steps;
