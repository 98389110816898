import React from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import { Container, Flag } from "./checkbox.styles";

const Checkbox: React.FC<
  {
    label?: string;
    value?: boolean;
    control?: Control;
    name?: string;
    options?: RegisterOptions;
    disabled?: boolean;
  } & React.HTMLAttributes<HTMLInputElement>
> = ({ label, value, control, name, options = {}, disabled, ...attrs }) => {
  const changeFlagHandler = (currentValue: boolean, onChange: (...event: any[]) => void): void => {
    if (disabled) {
      return;
    }

    onChange(!currentValue);
  };

  if (control) {
    return (
      <Controller
        control={control}
        name={name}
        rules={options}
        render={({ field }): React.ReactElement => (
          <Container
            className="checkbox"
            disabled={disabled}
            onClick={(): void => changeFlagHandler(field.value, field.onChange)}
            {...attrs}
          >
            <Flag checked={field.value} disabled={disabled} />
            {!!label && <p className="label">{label}</p>}
          </Container>
        )}
      />
    );
  }

  return (
    <Container className="checkbox" {...attrs}>
      <Flag checked={value} />
      {!!label && <p className="label">{label}</p>}
    </Container>
  );
};

export default Checkbox;
