import styled from "styled-components";
import { HTMLAttributes } from "react";
import colors from "../../../../core/styles/colors";

export const ImageCardContainer = styled.div<
  { activeByDefault?: boolean; hasHoverImage: boolean } & HTMLAttributes<HTMLDivElement>
>`
  box-shadow: 0 0 8px ${colors.gray.gray100};
  width: 100%;
  background: ${colors.white.general};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: ${(props): string => (props.hasHoverImage ? "pointer" : "auto")};
  .content {
    position: relative;
    width: calc(100% - 20px);
    margin: 20px 10px 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      border-radius: 10px 10px 0 0;
    }
    .hoverImg {
      position: absolute;
      opacity: ${(props): number => (props.activeByDefault ? 1 : 0)};
      transition: ease-in-out 0.3s;
    }
  }
  .footer {
    width: calc(100% - 32px);
    padding: 0 16px;
    background: ${(props): string =>
      props.activeByDefault ? colors.red.dark : colors.black.general};
    border-radius: 0 0 10px 10px;
    transition: ease-in-out 0.3s;
    h3 {
      padding: 10px 0;
      color: ${colors.white.general};
      text-transform: uppercase;
    }
  }
  ${(props): string =>
    props.hasHoverImage &&
    `&:hover {
    .content {
      .hoverImg {
        opacity: 1;
      }
    }
    .footer {
      background: ${colors.red.dark};
    }
  }`}
`;
