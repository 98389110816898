import React, { InputHTMLAttributes } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import { Container, RadioItemContainer } from "./radioButtons.styles";
import RadioButtonItem from "./RadioButtonItem";

const RadioButtons: React.FC<
  {
    items: RadioButtonItem[];
    control?: Control<any>;
    name?: string;
    options?: RegisterOptions;
    disabledAll?: boolean;
    value?: string;
  } & InputHTMLAttributes<HTMLInputElement>
> = ({ items, control, name = "", options = {}, disabledAll, value, ...attrs }) => {
  return (
    <Container>
      {control && name ? (
        <Controller
          control={control}
          name={name}
          rules={options}
          render={({ field }): React.ReactElement => (
            <>
              {items.map((item) => (
                <RadioItemContainer
                  key={item.label}
                  onClick={(): void => field.onChange(item.value)}
                >
                  <input
                    type="radio"
                    value={item.value}
                    checked={field.value === item.value}
                    disabled={item.disabled ?? disabledAll}
                    readOnly
                    {...attrs}
                  />
                  <span>{item.label}</span>
                </RadioItemContainer>
              ))}
            </>
          )}
        />
      ) : (
        items.map((item) => (
          <RadioItemContainer key={item.label}>
            <input
              type="radio"
              value={item.value}
              checked={value === item.value}
              disabled={item.disabled ?? disabledAll}
              {...attrs}
            />
            <span>{item.label}</span>
          </RadioItemContainer>
        ))
      )}
    </Container>
  );
};

export default RadioButtons;
