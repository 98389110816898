import React from "react";
import {
  Control,
  FieldErrors,
  FieldValues,
  FormState,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { Input } from "../../../../shared/native/input.styles";
import { FormContainer } from "../../../../shared/form/form.styles";
import { FieldData } from "../../../../shared/form/fieldData.styles";
import { FieldError, FieldInfo, FieldName } from "../../../../shared/form/fieldInfo.styles";
import Address from "./components/address/Address";
import AddFileButton from "../../../../shared/addFileButton/AddFileButton";
import Checkbox from "../../../../shared/checkbox/Checkbox";
import ProfileForm from "../../interfaces/ProfileForm";

const ResidingPlaceForm: React.FC<{
  register: UseFormRegister<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<ProfileForm>;
}> = ({ register, formState, control, setValue }) => {
  const { errors } = formState;
  const residingCoincidesRegistrationWatch = useWatch({
    control,
    name: "residingCoincidesRegistration",
  });

  React.useEffect(() => {
    if (residingCoincidesRegistrationWatch) {
      setValue("residingAddress", {
        country: "",
        region: "",
        city: "",
        street: "",
        house: "",
        flat: "",
        index: "",
      });
    }
  }, [residingCoincidesRegistrationWatch]);

  return (
    <FormContainer>
      <FieldInfo byRowStart>
        <FieldName>Адрес регистрации</FieldName>
        {errors.registerAddress &&
          Object.keys(errors.registerAddress as FieldErrors).some(
            (field) => (errors.registerAddress as FieldErrors)[field].type === "required"
          ) && <FieldError>Обязательные поля</FieldError>}
      </FieldInfo>
      <FieldData>
        <Address
          control={control}
          name="registerAddress"
          options={{
            required: "Обязательное поле",
          }}
          setValue={setValue}
        />
      </FieldData>
      <FieldInfo byRowStart>
        <FieldName>Адрес проживания</FieldName>
        <Checkbox
          label="Совпадает с регистрацией"
          control={control}
          name="residingCoincidesRegistration"
        />
        {errors.residingAddress &&
          Object.keys(errors.residingAddress as FieldErrors).some(
            (field) => (errors.residingAddress as FieldErrors)[field].type === "required"
          ) && <FieldError>Обязательные поля</FieldError>}
      </FieldInfo>
      <FieldData>
        <Address
          control={control}
          name="residingAddress"
          options={{
            required: !residingCoincidesRegistrationWatch ? "Обязательное поле" : false,
          }}
          disabled={residingCoincidesRegistrationWatch}
          setValue={setValue}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Место рождения</FieldName>
        {errors.placeOfBirth && <FieldError>{errors.placeOfBirth?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          {...register("placeOfBirth", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Загрузка документов</FieldName>
        {(errors.mainPageOfPassport || errors.registrationPageOfPassport) && (
          <FieldError>
            {(errors.mainPageOfPassport?.message as string) ??
              (errors.registrationPageOfPassport?.message as string)}
          </FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <AddFileButton
          accept={"image/*"}
          name="mainPageOfPassport"
          control={control}
          options={{ required: "Обязательное поле" }}
          setValue={setValue}
          style={{ marginRight: 8 }}
        >
          Основная страница
        </AddFileButton>
        <AddFileButton
          accept={"image/*"}
          name="registrationPageOfPassport"
          control={control}
          options={{ required: "Обязательное поле" }}
          setValue={setValue}
        >
          Страница с пропиской
        </AddFileButton>
      </FieldData>
    </FormContainer>
  );
};

export default ResidingPlaceForm;
