import styled from "styled-components";
import sizes from "../../../core/styles/sizes";

export default styled.div`
  width: 100%;
  min-height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  @media all and (${sizes.mobile}) {
    width: 100%;
    height: 100%;
  }
`;
