import React from "react";
import { useAppDispatch, useAppSelector } from "../../../core/hooks/redux";
import { setHasCompletedApplications, setToken } from "../../../store/core/account/accountSlice";
import { fetchGetNotificationsInfo } from "../../../store/modules/notifications/notificationsThunk";
import ResponseBody from "../../../http/models/ResponseBody";
import NotificationsInfo from "../../modules/notifications/interfaces/NotificationsInfo";
import { fetchGetDictionaries } from "../../../store/core/dictionaries/dictionariesThunk";
import { fetchGetAccount } from "../../../store/core/account/accountThunk";
import User from "../../../core/interfaces/User";
import { getApplicationsRequest } from "../../../http/requests/applications/applications.http";
import setCompletedApplications from "../../modules/applications/utils/setCompletedApplications";
import degreeType from "../../../core/types/degreeType";
import ApplicationInfo from "../../../core/interfaces/applications/ApplicationInfo";
import ApplicationsLists from "../../../core/interfaces/applications/ApplicationsLists";

const BackgroundProcesses: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { accountState } = useAppSelector((state) => state.coreState);

  const getNotificationsHandler = (callbackFunc?: () => void): void => {
    dispatch(fetchGetNotificationsInfo()).then((res) => {
      const body = res.payload as ResponseBody<NotificationsInfo>;

      if (body.error || res.meta.requestStatus === "rejected") {
        return;
      }

      if (callbackFunc) {
        callbackFunc();
      }
    });
  };

  const getNotificationsPolling = (): void => {
    setTimeout(() => {
      getNotificationsHandler(getNotificationsPolling);
    }, 180000);
  };

  React.useEffect(() => {
    const lsToken = localStorage.getItem("token");

    if (lsToken) {
      dispatch(setToken(lsToken));
    }
  }, []);

  React.useEffect(() => {
    if (accountState.token) {
      dispatch(fetchGetDictionaries());
      dispatch(fetchGetAccount()).then((accountRes) => {
        const accountData = accountRes.payload as ResponseBody<User>;

        if (accountData?.payload?.hasProfile) {
          // SET HIGH EDUCATION
          getApplicationsRequest("high").then((notificationsRes) => {
            const applications = notificationsRes.data.payload as ApplicationsLists;
            const hasCompletedApplications = [
              ...applications.paid,
              ...applications.budget,
              ...applications.draft,
            ].some((application) => !!+application.completed);

            dispatch(
              setHasCompletedApplications({ degree: "high", value: hasCompletedApplications })
            );
          });

          // SET OTHER EDUCATION
          (["additional", "postgraduate"] as degreeType[]).forEach((degree) => {
            getApplicationsRequest(degree).then((notificationsRes) => {
              const applications = notificationsRes.data.payload as ApplicationInfo[];

              setCompletedApplications(applications, degree);
            });
          });

          getNotificationsHandler(getNotificationsPolling);
        }
      });
    }
  }, [accountState.token]);

  return <>{children}</>;
};

export default BackgroundProcesses;
