import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Form } from "./rememberPasswordForm.styles";
import { OutlinedButton, PrimaryButton } from "../../../shared/native/button.styles";
import { Input } from "../../../shared/native/input.styles";
import { rememberPasswordRequest } from "../../../../http/requests/account.http";
import RememberPasswordFormInterface from "../interfaces/rememberPasswordForm";
import { ErrorText, TitleH1 } from "../../../shared/native/typography.styles";
import isEmail from "../../../../core/validators/isEmail";

const RememberPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const [sentPassword, setSentPassword] = React.useState(false);
  const [errorText, setErrorText] = React.useState(null);
  const [rememberPasswordForm, setRememberPasswordForm] =
    React.useState<RememberPasswordFormInterface>({
      email: "",
    });

  const submitRememberPassword = (): void => {
    rememberPasswordRequest(rememberPasswordForm).then((res) => {
      if (res.data.error) {
        setErrorText(res.data.text);
        return;
      }

      setSentPassword(true);
    });
  };

  return (
    <Container>
      <Form>
        <TitleH1>Восстановление пароля</TitleH1>
        {!sentPassword ? (
          <div className="fieldRow">
            <div className="fieldHeader">
              <p>Email</p>
            </div>
            <Input
              placeholder="Введите email"
              value={rememberPasswordForm.email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                setRememberPasswordForm({
                  ...rememberPasswordForm,
                  email: e.target.value,
                })
              }
            />
          </div>
        ) : (
          <p style={{ textAlign: "center" }}>Текущий пароль выслан на указанную почту</p>
        )}
        {!!errorText && <ErrorText>{errorText}</ErrorText>}
        <div className="actions">
          <OutlinedButton onClick={(): void => navigate("/")}>Назад</OutlinedButton>
          {!sentPassword && (
            <PrimaryButton
              style={{ marginLeft: 24 }}
              onClick={submitRememberPassword}
              disabled={!isEmail(rememberPasswordForm.email)}
            >
              Отправить
            </PrimaryButton>
          )}
        </div>
      </Form>
    </Container>
  );
};

export default RememberPasswordForm;
