import { AxiosResponse } from "axios";
import { $baseApi } from "../index";
import ResponseBody from "../models/ResponseBody";
import RequestBody from "../models/RequestBody";
import PersonalData from "../../ui/modules/profile/interfaces/requests/PersonalData";
import ContactsData from "../../ui/modules/profile/interfaces/requests/ContactsData";
import PassportData from "../../ui/modules/profile/interfaces/requests/PassportData";
import ResidingPlaceData from "../../ui/modules/profile/interfaces/requests/ResidingPlaceData";
import SavedProfileData from "../../ui/modules/profile/interfaces/savedProfileData";

export const getProfileFormRequest = (): Promise<AxiosResponse<ResponseBody<SavedProfileData>>> => {
  return $baseApi.post<ResponseBody<SavedProfileData>>(
    "",
    new RequestBody("draft.read", {
      type: "profile",
    })
  );
};

export const saveProfileFormRequest = (
  type: string,
  data: PersonalData | ContactsData | PassportData | ResidingPlaceData
): Promise<AxiosResponse<ResponseBody<null>>> => {
  const resultBody = {
    type,
    data: {
      ...data,
    },
  };
  return $baseApi.post<ResponseBody<null>>("", new RequestBody("draft.save", resultBody));
};

export const submitProfileFormRequest = (): Promise<AxiosResponse<ResponseBody<null>>> => {
  return $baseApi.post<ResponseBody<null>>("", new RequestBody("profile.save", null));
};
