import File from "../interfaces/File";

const convertFile = (fromTo: "from" | "to", files: File[]): File[] => {
  if (!files?.length || !Array.isArray(files)) {
    return [];
  }

  if (fromTo === "from") {
    return files.map((file) => ({
      file: file.path,
      info: {
        name: file.name,
        lastModified: null,
        size: null,
        type: null,
      },
    }));
  }

  return files.map((file) =>
    file.info.type
      ? file
      : ({
          path: file.file,
          name: file.info.name,
        } as File)
  );
};

export default convertFile;
