import RadioButtonItem from "../../ui/shared/radioButtons/RadioButtonItem";

const genderRadioButtonItems: RadioButtonItem[] = [
  {
    label: "Мужской",
    value: "male",
  },
  {
    label: "Женский",
    value: "female",
  },
];

export default genderRadioButtonItems;
