import React from "react";
import { useNavigate } from "react-router-dom";
import { TitleH1 } from "../../../../shared/native/typography.styles";
import { PrimaryButton } from "../../../../shared/native/button.styles";
import { setViewedStep } from "../../../../../store/modules/profile/profileForm/profileFormSlice";
import { useAppDispatch, useAppSelector } from "../../../../../core/hooks/redux";
import {
  InfoPage,
  InfoPageContent,
  InfoPageTextInfo,
} from "../../../../shared/infoPage/infoPage.styles";

const DocumentsSubmission: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { profileFormState } = useAppSelector((state) => state.modulesState);

  React.useEffect(() => {
    if (profileFormState.residingPlaceData.placeOfBirth?.length) {
      navigate("/profile/check-profile-form");
      return;
    }
    if (profileFormState.passportData.dateOfIssue?.length) {
      dispatch(setViewedStep(3));
      navigate("/profile/profile-form");
      return;
    }
    if (profileFormState.contactsData.email?.length) {
      dispatch(setViewedStep(2));
      navigate("/profile/profile-form");
      return;
    }
    if (profileFormState.personalData.firstname?.length) {
      dispatch(setViewedStep(1));
      navigate("/profile/profile-form");
    }
  }, [profileFormState]);

  return (
    <InfoPage>
      <InfoPageContent>
        <InfoPageTextInfo>
          <TitleH1>Подача документов в Московский институт психоанализа</TitleH1>
          <p>
            Сервис поможет подать документы на любые программы высшего и дополнительного образования
            без личного посещения института, бумажных заявлений и очередей.
          </p>
          <p>
            Единый личный кабинет для всех уровней образования избавит вас от необходимости
            многократно заполнять информацию о себе и заново прикладывать документы.
          </p>
        </InfoPageTextInfo>
        <PrimaryButton onClick={(): void => navigate("/profile/filling-out-profile")}>
          Начать
        </PrimaryButton>
      </InfoPageContent>
    </InfoPage>
  );
};

export default DocumentsSubmission;
