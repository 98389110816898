import { combineReducers, configureStore } from "@reduxjs/toolkit";
import coreState from "./core/coreStore";
import modulesState from "./modules/modulesStore";

const rootReducer = combineReducers({
  coreState,
  modulesState,
});

const setupStore = configureStore({
  reducer: rootReducer,
});

export default setupStore;

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore.getState>;
export type AppDispatch = (typeof setupStore)["dispatch"];
