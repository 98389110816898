import {
  PostgraduateEducationForm,
  SplitPostgraduateEducationForm,
} from "../interfaces/postgraduateEducationForm";
import convertFile from "../../../../../../../core/utils/convertFile";
import store from "../../../../../../../store/store";
import getSelectedProgram from "./getSelectedProgram";

const convertSplitPostgraduateEducationForm = (
  data: SplitPostgraduateEducationForm
): PostgraduateEducationForm => {
  const state = store.getState();
  const { dictionaries } = state.coreState.dictionariesState;

  const program = getSelectedProgram(
    data.educationLevel,
    data.directionAndSpeciality,
    data.form,
    data.financing
  );

  return {
    application: {
      educationLevel: program.level_id,
      educationLevelName: program.level,
      directionAndSpeciality: program.specialty_id,
      directionAndSpecialityName: program.specialty,
      form: program.form_id,
      formName: program.form,
      financing: program.type_budget_id,
      financingName: program.type_budget,
      program_uid: program.program_uid,
      vi: program.vi,
    },
    achievements: Object.values(data.achievements)
      .filter((item) => !!item.achievement && item.achievement !== "0")
      .map((item) => ({
        achievement: item.achievement,
        document: convertFile("to", item.document),
      })),
    letter: convertFile("to", data.letter),
    discountsAndBenefits: {
      discountsAndBenefits: data.discountsAndBenefits,
      discountsPercentage:
        dictionaries.discounts.find((discount) => discount.id === data.discountsAndBenefits)?.val ||
        null,
      discountsConfirm: convertFile("to", data.discountsConfirm),
    },
  };
};

export default convertSplitPostgraduateEducationForm;
