import { DaDataAddress, DaDataSuggestion } from "react-dadata/dist/types";
import Address from "../../../../core/interfaces/Address";

const convertAddressObject = (type: "from" | "to", address: Address): Address => {
  if (type === "from") {
    return {
      ...address,
      region: {
        value: address.region as string,
      } as DaDataSuggestion<DaDataAddress>,
      city: {
        value: address.city as string,
      } as DaDataSuggestion<DaDataAddress>,
      street: {
        value: address.street as string,
      } as DaDataSuggestion<DaDataAddress>,
      house: {
        value: address.house as string,
      } as DaDataSuggestion<DaDataAddress>,
    };
  }

  return {
    ...address,
    region: (address.region as DaDataSuggestion<DaDataAddress>).value,
    city: (address.city as DaDataSuggestion<DaDataAddress>).value,
    street: (address.street as DaDataSuggestion<DaDataAddress>).value,
    house: (address.house as DaDataSuggestion<DaDataAddress>).value,
  };
};

export default convertAddressObject;
