import React from "react";
import { Route, Routes } from "react-router-dom";
import BaseApplicationsList from "../../../../ui/modules/applications/components/applicationsList/baseApplicationsList/BaseApplicationsList";
import SubmissionInfo from "../../../../ui/modules/applications/components/additionalEducation/additionalEducationForm/components/submissionInfo/SubmissionInfo";
import AdditionalEducationForm from "../../../../ui/modules/applications/components/additionalEducation/additionalEducationForm/AdditionalEducationForm";
import PreviewAdditionalEducationForm from "../../../../ui/modules/applications/components/additionalEducation/additionalEducationForm/components/previewAdditionalEducationForm/PreviewAdditionalEducationForm";
import CompleteAdditionalEducationForm from "../../../../ui/modules/applications/components/additionalEducation/additionalEducationForm/components/completeAdditionalEducationForm/CompleteAdditionalEducationForm";

const AdditionalEducationRoutes: React.FC = () => {
  return (
    <Routes>
      {/* PREVIEW */}
      <Route path="" element={<BaseApplicationsList degree="additional" />} />
      <Route path=":id" element={<PreviewAdditionalEducationForm mode="view" />} />
      {/* FORM */}
      <Route path="submission-info" element={<SubmissionInfo />} />
      <Route path="form" element={<AdditionalEducationForm />} />
      <Route path="check" element={<PreviewAdditionalEducationForm mode="finallyCheck" />} />
      <Route path="complete" element={<CompleteAdditionalEducationForm />} />
    </Routes>
  );
};

export default AdditionalEducationRoutes;
