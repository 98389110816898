import { DaDataAddressBounds } from "react-dadata/dist/types";

interface AddressField {
  placeholder: string;
  name: string;
  dadataBound: DaDataAddressBounds;
  fiasIdKey: "region_fias_id" | "city_fias_id" | "street_fias_id" | "house_fias_id";
}

export default [
  {
    name: "country",
    placeholder: "Страна",
    dadataBound: "country",
    fiasIdKey: null,
  },
  {
    name: "region",
    placeholder: "Регион",
    dadataBound: "region",
    fiasIdKey: "region_fias_id",
  },
  {
    name: "city",
    placeholder: "Город",
    dadataBound: "city",
    fiasIdKey: "city_fias_id",
  },
  {
    name: "street",
    placeholder: "Улица",
    dadataBound: "street",
    fiasIdKey: "street_fias_id",
  },
  {
    name: "house",
    placeholder: "Дом/Корпус/Строение",
    dadataBound: "houses",
    fiasIdKey: "house_fias_id",
  },
  {
    name: "flat",
    placeholder: "Кв.",
    dadataBound: "flat",
    fiasIdKey: null,
  },
] as AddressField[];
