import styled from "styled-components";
import colors from "../../../core/styles/colors";

export const Li = styled.li`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  &::marker {
    color: ${colors.red.general};
    font-size: 10px;
  }
`;
