import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Stepper from "../../../../../shared/stepper/Stepper";
import { OutlinedButton, PrimaryButton } from "../../../../../shared/native/button.styles";
import { useAppDispatch, useAppSelector } from "../../../../../../core/hooks/redux";
import UseAlert from "../../../../../../core/hooks/useAlert";
import getCookie from "../../../../../../core/utils/getCookie";
import {
  AdditionalEducationForm as AdditionalEducationFormInterface,
  SplitAdditionalEducationForm,
} from "./interfaces/additionalEducationForm";
import steps from "./constants/steps";
import { ReactComponent as LineArrowIcon } from "../../../../../../core/static/icons/line-arrow.svg";
import { Form, FormActions, FormContent } from "../../../../../shared/form/form.styles";
import { setViewedStep } from "../../../../../../store/modules/applications/additionalEducation/additionalEducationSlice";
import ResponseBody from "../../../../../../http/models/ResponseBody";
import loading from "../../../../../../core/types/loading";
import { fetchGetAdditionalEducation } from "../../../../../../store/modules/applications/additionalEducation/additionalEducationThunk";
import { saveAdditionalEducationFormRequest } from "../../../../../../http/requests/applications/additionalEducation.http";
import convertSplitAdditionalEducationForm from "./utils/convertSplitAdditionalEducationForm";

const AdditionalEducationForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const alertHandler = UseAlert();
  const navigate = useNavigate();
  const [submitLoadingStatus, setSubmitLoadingStatus] = React.useState<loading>(null);
  const { additionalEducationState } = useAppSelector(
    (state) => state.modulesState.applicationsState
  );
  const { register, control, formState, handleSubmit, reset, setValue } =
    useForm<SplitAdditionalEducationForm>({
      values: {
        ...additionalEducationState.application,
        ...additionalEducationState.paymentForm,
      },
      mode: "onBlur",
    });

  React.useEffect(() => {
    const cookie = getCookie();
    if (cookie?.lastModifiedAdditionalEducationStep) {
      dispatch(setViewedStep(Number(cookie.lastModifiedAdditionalEducationStep)));
    }
    if (cookie?.lastModifiedAdditionalEducationApplicationId) {
      dispatch(
        fetchGetAdditionalEducation(Number(cookie.lastModifiedAdditionalEducationApplicationId))
      );
    }
  }, []);

  const setLastModifiedStepCookie = (step: number): void => {
    document.cookie = `lastModifiedAdditionalEducationStep=${step}; path=/`;
  };

  const setLastModifiedApplicationCookie = (applicationId: number): void => {
    document.cookie = `lastModifiedAdditionalEducationApplicationId=${applicationId}; path=/`;
  };

  const getCurrentFormComponent = (): ((props?: any) => React.ReactNode) => {
    return steps.find((step, key) => additionalEducationState.viewedStep === key)?.element;
  };

  const goToPreviousStep = (): void => {
    const previousStepIndex = additionalEducationState.viewedStep - 1;
    dispatch(setViewedStep(previousStepIndex));
    setLastModifiedStepCookie(previousStepIndex);
  };

  const submitForm = (stepId: number, formData: SplitAdditionalEducationForm): void => {
    if (stepId !== 0 && !additionalEducationState.id) {
      return;
    }

    setSubmitLoadingStatus("LOADING");
    saveAdditionalEducationFormRequest(
      additionalEducationState.id,
      convertSplitAdditionalEducationForm(formData)
    ).then((saveRes) => {
      if (saveRes.data.error) {
        setSubmitLoadingStatus("ERROR");
        alertHandler({ message: saveRes.data.text, type: "error" });
        return;
      }
      setSubmitLoadingStatus("COMPLETE");

      setLastModifiedApplicationCookie(saveRes.data.payload?.id);
      alertHandler({ message: "Информация сохранена", type: "success" });

      dispatch(fetchGetAdditionalEducation(saveRes.data.payload.id)).then((action) => {
        const getRes = action.payload as ResponseBody<AdditionalEducationFormInterface>;
        if (getRes.error) {
          alertHandler({ message: getRes.text, type: "error" });
        }

        if (stepId === steps.length - 1) {
          navigate("/applications/additional-education/check");
          return;
        }
        dispatch(setViewedStep(stepId + 1));
        setLastModifiedStepCookie(stepId + 1);
      });
    });
  };

  return (
    <Form onSubmit={handleSubmit((data) => submitForm(additionalEducationState.viewedStep, data))}>
      <Stepper
        items={steps}
        currentStep={additionalEducationState.viewedStep}
        className="stepper"
      />
      <FormContent>
        {getCurrentFormComponent()({
          register,
          control,
          formState,
          reset,
          setValue,
        })}
      </FormContent>
      <FormActions lastStep={additionalEducationState.viewedStep === steps.length - 1}>
        {additionalEducationState.viewedStep !== 0 && (
          <OutlinedButton className="leftButton" type="button" onClick={goToPreviousStep}>
            <LineArrowIcon />
          </OutlinedButton>
        )}
        <PrimaryButton
          disabled={submitLoadingStatus === "LOADING"}
          className="rightButton"
          type="submit"
        >
          {additionalEducationState.viewedStep !== steps.length - 1 ? (
            <LineArrowIcon />
          ) : (
            "Сохранить"
          )}
        </PrimaryButton>
      </FormActions>
    </Form>
  );
};

export default AdditionalEducationForm;
