import styled from "styled-components";
import colors from "../../../core/styles/colors";

export const AddFileButton = styled.button<{ hasErrors: boolean; whiteBackground: boolean }>`
  outline: none;
  width: fit-content;
  min-height: 48px;
  height: 100%;
  padding: 0 13px;
  border: 2px dashed
    ${(props): string => (props.hasErrors ? colors.red.general : colors.gray.gray300)};
  background: ${(props): string =>
    props.whiteBackground ? colors.white.general : colors.white.hoverWhite3};
  border-radius: 10px;
  font-family: "Gilroy", serif;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  color: ${colors.gray.gray300};
  cursor: pointer;
  transition: ease-in-out 0.3s;
  &:hover {
    border: 2px solid ${colors.red.general};
    background: ${colors.red.general};
    color: ${colors.white.general};

    span {
      color: ${colors.white.general};
    }
  }
`;

export const AddFileButtonDescription = styled.span`
  display: block;
  color: ${colors.red.general};
  font-size: 16px;
  text-align: center;
`;

export const FilesInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FileInfoItem = styled.div<{ whiteBackground: boolean }>`
  position: relative;
  width: fit-content;
  background: ${(props): string =>
    props.whiteBackground ? colors.white.general : colors.white.hoverWhite};
  border-radius: 8px;
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .img {
    position: absolute;
    width: 36px;
    height: 36px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    border-radius: 3px;
    font-size: 0;
  }
  svg {
    margin-left: 8px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    &:hover {
      filter: brightness(90%);
    }
  }
`;
