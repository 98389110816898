import styled from "styled-components";
import colors from "../../../core/styles/colors";

export const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  .label {
    margin: 0 0 0 8px;
    font-size: 18px;
    font-weight: 300;
    color: ${(props): string => (props.disabled ? colors.gray.gray300 : colors.black.lightBlack)};
  }
`;

export const Flag = styled.div<{ checked: boolean; disabled?: boolean }>`
  position: relative;
  min-height: 14px;
  min-width: 14px;
  margin: 0;
  appearance: none;
  border: 1px solid ${(props): string => (props.disabled ? colors.red.light : colors.red.general)};
  border-radius: 3px;
  &::before {
    position: absolute;
    display: ${(props): string => (props.checked ? "block" : "none")};
    content: " ";
    width: 6px;
    height: 1.7px;
    border-radius: 2px;
    top: 7px;
    left: 2px;
    background: ${(props): string => (props.disabled ? colors.red.light : colors.red.general)};
    transform: rotate(55deg);
  }
  &::after {
    position: absolute;
    display: ${(props): string => (props.checked ? "block" : "none")};
    content: " ";
    width: 8px;
    height: 1.7px;
    border-radius: 2px;
    top: 6px;
    left: 4.5px;
    background: ${(props): string => (props.disabled ? colors.red.light : colors.red.general)};
    transform: rotate(120deg);
  }
`;
