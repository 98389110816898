import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAdditionalEducationDictionariesRequest,
  getDictionariesRequest,
  getPostgraduateEducationDictionariesRequest,
  getProgramDictionariesRequest,
} from "../../../http/requests/dictionaries.http";

export const fetchGetDictionaries = createAsyncThunk("dictionaries/getAll", async () => {
  const response = await getDictionariesRequest();
  return response.data;
});

export const fetchGetProgramDictionaries = createAsyncThunk(
  "dictionaries/getProgramDictionaries",
  async () => {
    const response = await getProgramDictionariesRequest();
    return response.data;
  }
);

export const fetchGetAdditionalEducationDictionaries = createAsyncThunk(
  "dictionaries/getAdditionalEducationDictionaries",
  async () => {
    const response = await getAdditionalEducationDictionariesRequest();
    return response.data;
  }
);

export const fetchGetPostgraduateEducationDictionaries = createAsyncThunk(
  "dictionaries/getPostgraduateEducationDictionaries",
  async () => {
    const response = await getPostgraduateEducationDictionariesRequest();
    return response.data;
  }
);
