import { AdditionalEducationForm } from "../interfaces/additionalEducationForm";
import convertFile from "../../../../../../../core/utils/convertFile";

const convertAdditionalEducationFromSavedData = (
  data: AdditionalEducationForm
): AdditionalEducationForm => ({
  id: data.id,
  application: data?.application ?? null,
  paymentForm: data?.paymentForm
    ? {
        paymentMethod: data.paymentForm.paymentMethod,
        discountsAndBenefits: data.paymentForm.discountsAndBenefits,
        discountsPercentage: data.paymentForm.discountsPercentage,
        discountConfirmation: convertFile("from", data.paymentForm.discountConfirmation),
      }
    : null,
});

export default convertAdditionalEducationFromSavedData;
