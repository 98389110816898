import styled from "styled-components";
import sizes from "../../../core/styles/sizes";
import colors from "../../../core/styles/colors";

export const FormPreview = styled.div<{ mode: "finallyCheck" | "view" }>`
  width: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: ${(props): string => (props.mode === "view" ? "flex-start" : "space-between")};
  .form {
    max-height: ${(props): string => (props.mode === "finallyCheck" ? "50vh" : "60vh")};
    overflow-y: auto;
  }

  @media all and (${sizes.mobile}) {
    height: calc(100% - 110px);
    padding: 80px 0 30px 0;
    background: ${colors.white.general};
    .form {
      max-height: 100%;
      height: auto;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    width: 220px;
    color: ${colors.red.general};
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    margin: 8px 0 0 0;
  }

  @media all and (${sizes.mobile}) {
    h2 {
      text-transform: uppercase;
    }
    p {
      width: calc(100% - 32px);
      margin: 8px 16px;
    }
  }
`;

export const Actions = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    padding: 0 28px;
    &:first-child {
      border-color: ${colors.black.general};
      color: ${colors.black.general};
      margin-right: 32px;
      &:hover {
        background: ${colors.black.general};
        color: ${colors.white.general};
      }
    }
  }

  @media all and (${sizes.mobile}) {
    width: calc(100% - 32px);
    margin: 0 16px;
    button {
      width: 100%;
      &:first-child {
        margin-right: 10px;
      }
    }
  }
`;

export const PreviewColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const PreviewRowContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media all and (${sizes.mobile}) {
    margin-top: 8px;
  }
`;
