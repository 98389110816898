import React from "react";
import { useNavigate } from "react-router-dom";
import { TitleH1, TitleH3 } from "../../../../../../../shared/native/typography.styles";
import { PrimaryButton } from "../../../../../../../shared/native/button.styles";
import { Ul } from "../../../../../../../shared/native/ul.styles";
import { Li } from "../../../../../../../shared/native/li.styles";
import {
  InfoPage,
  InfoPageContent,
  InfoPageTextInfo,
} from "../../../../../../../shared/infoPage/infoPage.styles";
import { useAppDispatch, useAppSelector } from "../../../../../../../../core/hooks/redux";
import { setViewedStep } from "../../../../../../../../store/modules/applications/highEducation/highEducationSlice";

const AddingDataInfo: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { additionalAEDataState } = useAppSelector((state) => state.modulesState.applicationsState);

  React.useEffect(() => {
    if (additionalAEDataState.employment?.position) {
      navigate("/applications/additional-ae-data/check");
      return;
    }

    if (additionalAEDataState.education?.educationLevel) {
      dispatch(setViewedStep(1));
      navigate("/applications/additional-ae-data/form");
    }
  }, []);

  return (
    <InfoPage>
      <InfoPageContent>
        <InfoPageTextInfo>
          <TitleH1>Внесение дополнительных сведений</TitleH1>
          <p>
            Для подачи заявления на программу дополнительного образования, пожалуйста, внесите
            данные о предыдущем образовании и сведения о трудоустройстве.
          </p>
          <TitleH3>Что нужно для заполнения:</TitleH3>
          <Ul>
            <Li>Документ о ранее полученном образовании</Li>
          </Ul>
        </InfoPageTextInfo>
        <PrimaryButton onClick={(): void => navigate("/applications/additional-ae-data/form")}>
          Начать
        </PrimaryButton>
      </InfoPageContent>
    </InfoPage>
  );
};

export default AddingDataInfo;
