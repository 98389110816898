import React from "react";
import { Control, FieldValues, FormState, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import {
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import { AddFileContainer, FieldData } from "../../../../../../../shared/form/fieldData.styles";
import { Input } from "../../../../../../../shared/native/input.styles";
import Calendar from "../../../../../../../shared/calendar/Calendar";
import DateRegexp from "../../../../../../../../core/regexp/DateRegexp";
import AddFileButton from "../../../../../../../shared/addFileButton/AddFileButton";
import { SplitAdditionalDataForm } from "../../interfaces/additionalDataForm";
import AddFileInfo from "../../../../../../../shared/addFileInfo/AddFileInfo";

const TrainingCertificateForm: React.FC<{
  register: UseFormRegister<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<SplitAdditionalDataForm>;
}> = ({ register, formState: { errors }, control, setValue }) => {
  return (
    <FormContainer>
      <FieldInfo>
        <FieldName>Справка об обучении</FieldName>
        {errors.trainingCertificate && (
          <FieldError>{errors.trainingCertificate?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <AddFileButton
          name="trainingCertificate"
          control={control}
          options={{ required: "Обязательное поле" }}
          setValue={setValue}
          accept={"image/*"}
          multiple
        >
          Справка об обучении
        </AddFileButton>
      </FieldData>
      <FieldInfo>
        <FieldName>Наименование вуза</FieldName>
        {errors.nameOfUniversity && (
          <FieldError>{errors.nameOfUniversity?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.nameOfUniversity}
          {...register("nameOfUniversity", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Регистрационный номер справки</FieldName>
        {errors.certificateRegistrationNumber && (
          <FieldError>{errors.certificateRegistrationNumber?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.certificateRegistrationNumber}
          {...register("certificateRegistrationNumber", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Дата выдачи справки</FieldName>
        {errors.certificateDateIssue && (
          <FieldError>{errors.certificateDateIssue?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Calendar
          className="defaultCalendar"
          placeholder="ДД.ММ.ГГГГ"
          control={control}
          name="certificateDateIssue"
          maxDate={new Date()}
          options={{
            required: "Обязательное поле",
            pattern: {
              value: DateRegexp,
              message: "Неверная дата",
            },
          }}
          defaultActiveStartDate={new Date()}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Загрузка справок</FieldName>
        {(errors.certificateAboutOfStudentStatus || errors.certificateAboutOfUniversityLicense) && (
          <FieldError>
            {(errors.certificateAboutOfStudentStatus?.message as string) ??
              (errors.certificateAboutOfUniversityLicense?.message as string)}
          </FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <AddFileContainer>
          <AddFileButton
            name="certificateAboutOfStudentStatus"
            control={control}
            options={{ required: "Обязательные поля" }}
            setValue={setValue}
            accept={"image/*"}
            multiple
          >
            О статусе студента
          </AddFileButton>
          <AddFileButton
            name="certificateAboutOfUniversityLicense"
            control={control}
            options={{ required: "Обязательные поля" }}
            setValue={setValue}
            accept={"image/*"}
            multiple
          >
            Лицензия вуза
          </AddFileButton>
        </AddFileContainer>
      </FieldData>
      <FieldInfo />
      <FieldData>
        <AddFileInfo />
      </FieldData>
    </FormContainer>
  );
};

export default TrainingCertificateForm;
