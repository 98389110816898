import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "../core/routes/appRoutes";
import BackgroundProcesses from "./shared/backgroundProcesses/BackgroundProcesses";
import AlertService from "./shared/alertService/AlertService";
import GlobalStyles from "./global.styles";
import "./global.css";

const App: React.FC = () => {
  return (
    <BackgroundProcesses>
      <BrowserRouter>
        <AlertService />
        <GlobalStyles />
        <AppRoutes />
      </BrowserRouter>
    </BackgroundProcesses>
  );
};

export default App;
