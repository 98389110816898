import styled from "styled-components";
import sizes from "../../../core/styles/sizes";
import colors from "../../../core/styles/colors";

export const ServicePage = styled.div`
  width: 100%;
  min-height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  @media all and (${sizes.mobile}) {
    height: 100%;
    .content {
      width: calc(100% - 24px);
      margin: 0 12px;
      padding: 8px 8px 32px 8px;
      background: ${colors.white.general};
      border-radius: 10px;
      button {
        width: 320px;
      }
    }
  }
`;

export const ServicePageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ServicePageActions = styled.div`
  display: flex;
  button {
    padding: 0 16px;
  }
`;
