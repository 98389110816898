import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, FormActions, FormContent } from "../../../../../shared/form/form.styles";
import { useAppDispatch, useAppSelector } from "../../../../../../core/hooks/redux";
import UseAlert from "../../../../../../core/hooks/useAlert";
import getCookie from "../../../../../../core/utils/getCookie";
import {
  AdditionalPostgraduateDataForm as AdditionalPostgraduateDataFormInterface,
  SplitAdditionalPostgraduateDataForm,
} from "./interfaces/additionalPostgraduateDataForm";
import Stepper from "../../../../../shared/stepper/Stepper";
import { OutlinedButton, PrimaryButton } from "../../../../../shared/native/button.styles";
import { ReactComponent as LineArrowIcon } from "../../../../../../core/static/icons/line-arrow.svg";
import steps from "./constants/steps";
import convertSplitAdditionalPostgraduateDataForm from "./utils/convertSplitAdditionalPosgraduateDataForm";
import ResponseBody from "../../../../../../http/models/ResponseBody";
import loading from "../../../../../../core/types/loading";
import { setViewedStep } from "../../../../../../store/modules/applications/additionalPostgraduateData/additionalPostgraduateDataSlice";
import { saveAdditionalPostgraduateDataFormRequest } from "../../../../../../http/requests/applications/additionalPosgraduateData.http";
import { fetchGetAdditionalPostgraduateData } from "../../../../../../store/modules/applications/additionalPostgraduateData/additionalPostgraduateDataThunk";

const AdditionalPostgraduateDataForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const alertHandler = UseAlert();
  const navigate = useNavigate();
  const [submitLoadingStatus, setSubmitLoadingStatus] = React.useState<loading>(null);
  const { additionalPostgraduateDataState } = useAppSelector(
    (state) => state.modulesState.applicationsState
  );
  const { register, control, formState, handleSubmit, reset, setValue } =
    useForm<SplitAdditionalPostgraduateDataForm>({
      values: {
        ...additionalPostgraduateDataState.educationInfo,
        ...additionalPostgraduateDataState.educationDocument,
        ...additionalPostgraduateDataState.healthLimitations,
      },
      mode: "onBlur",
    });

  React.useEffect(() => {
    const cookie = getCookie();
    if (cookie?.lastModifiedAdditionalPostgraduateDataStep) {
      dispatch(setViewedStep(Number(cookie.lastModifiedAdditionalPostgraduateDataStep)));
    }
  }, []);

  const setLastModifiedStepCookie = (step: number): void => {
    document.cookie = `lastModifiedAdditionalPostgraduateDataStep=${step}; path=/`;
  };

  const getCurrentFormComponent = (): ((props?: any) => React.ReactNode) => {
    return steps.find((step, key) => additionalPostgraduateDataState.viewedStep === key)?.element;
  };

  const goToPreviousStep = (): void => {
    const previousStepIndex = additionalPostgraduateDataState.viewedStep - 1;
    dispatch(setViewedStep(previousStepIndex));
    setLastModifiedStepCookie(previousStepIndex);
  };

  const submitForm = (stepId: number, formData: SplitAdditionalPostgraduateDataForm): void => {
    setSubmitLoadingStatus("LOADING");

    saveAdditionalPostgraduateDataFormRequest(
      convertSplitAdditionalPostgraduateDataForm(formData)
    ).then((saveRes) => {
      if (saveRes.data.error) {
        setSubmitLoadingStatus("ERROR");
        alertHandler({ message: saveRes.data.text, type: "error" });
        return;
      }
      setSubmitLoadingStatus("COMPLETE");

      alertHandler({ message: "Информация сохранена", type: "success" });

      dispatch(fetchGetAdditionalPostgraduateData()).then((action) => {
        const getRes = action.payload as ResponseBody<AdditionalPostgraduateDataFormInterface>;
        if (getRes.error) {
          alertHandler({ message: getRes.text, type: "error" });
        }

        if (stepId === steps.length - 1) {
          navigate("/applications/additional-postgraduate-data/check");
          return;
        }
        dispatch(setViewedStep(stepId + 1));
        setLastModifiedStepCookie(stepId + 1);
      });
    });
  };

  return (
    <Form
      onSubmit={handleSubmit((data) =>
        submitForm(additionalPostgraduateDataState.viewedStep, data)
      )}
    >
      <Stepper
        items={steps}
        currentStep={additionalPostgraduateDataState.viewedStep}
        className="stepper"
      />
      <FormContent>
        {getCurrentFormComponent()({
          register,
          control,
          formState,
          reset,
          setValue,
        })}
      </FormContent>
      <FormActions lastStep={additionalPostgraduateDataState.viewedStep === steps.length - 1}>
        {additionalPostgraduateDataState.viewedStep !== 0 && (
          <OutlinedButton
            disabled={additionalPostgraduateDataState.dataLoadingStatus === "LOADING"}
            className="leftButton"
            type="button"
            onClick={goToPreviousStep}
          >
            <LineArrowIcon />
          </OutlinedButton>
        )}
        <PrimaryButton
          disabled={
            additionalPostgraduateDataState.dataLoadingStatus === "LOADING" ||
            submitLoadingStatus === "LOADING"
          }
          className="rightButton"
          type="submit"
        >
          {additionalPostgraduateDataState.viewedStep !== steps.length - 1 ? (
            <LineArrowIcon />
          ) : (
            "Сохранить"
          )}
        </PrimaryButton>
      </FormActions>
    </Form>
  );
};

export default AdditionalPostgraduateDataForm;
