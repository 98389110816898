const colors = {
  white: {
    general: "#ffffff",
    card: "#fafafa",
    disabled: "#f8f9f9",
    disabledText: "#c5c5c5",
    hoverWhite: "#f4f4f6",
    hoverWhite2: "#f0efef",
    hoverWhite3: "#f1f3f4",
    hoverWhite4: "#e7e9ea",
    white700: "#dfdfdf",
  },
  gray: {
    xMark: "#c4c4c4",
    gray100: "#bac1c9",
    gray200: "#b3b1b0",
    gray300: "#939290",
  },
  black: {
    general: "#000000",
    hoverBlack: "#0b0709",
    lightBlack: "#42403E",
  },
  red: {
    general: "#fb002e",
    dark: "#e63239",
    hover: "#ec002a",
    light: "#fd8096",
  },
  blue: {
    general: "#3366FF",
    darkBlue: "#485066",
  },
  green: {
    general: "#30A000",
  },
  transparent: "rgba(0, 0, 0, 0)",
};

export default colors;
