import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAdditionalPostgraduateDataFormRequest } from "../../../../http/requests/applications/additionalPosgraduateData.http";

export const fetchGetAdditionalPostgraduateData = createAsyncThunk(
  "additionalPostgraduateData/get",
  async () => {
    const response = await getAdditionalPostgraduateDataFormRequest();
    return response.data;
  }
);
