import React from "react";

const UseScrollPosition = (
  element: React.RefObject<HTMLElement>,
  handler: React.Dispatch<number>
): void => {
  const submitScrollData = (): void => {
    handler(
      (element.current.scrollTop / (element.current.scrollHeight - element.current.clientHeight)) *
        100
    );
  };

  React.useEffect(() => {
    if (!element) {
      return;
    }

    element.current?.addEventListener("scroll", submitScrollData);

    return () => {
      element.current?.removeEventListener("scroll", submitScrollData);
    };
  }, [element?.current]);
};

export default UseScrollPosition;
