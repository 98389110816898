import paymentType from "../types/paymentType";
import ApplicationInfo from "../../../../../../../core/interfaces/applications/ApplicationInfo";

class PaymentInfo {
  applications: ApplicationInfo[];

  name: paymentType;

  viewedName: string;

  disabled: boolean;

  constructor(name: paymentType, applications: ApplicationInfo[] = [], disabled = false) {
    const info = {
      paid: "Платное",
      budget: "Бюджет",
      draft: "Черновики",
    } as Record<paymentType, string>;

    this.applications = applications.sort(
      (firstItem, secondItem) => firstItem.priority - secondItem.priority
    );
    this.name = name;
    this.viewedName = info[name];
    this.disabled = disabled;
  }
}

export default PaymentInfo;
