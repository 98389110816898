import React from "react";
import { Control, FieldValues, FormState, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { Input } from "../../../../../../../../../shared/native/input.styles";
import {
  FieldDescription,
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../../../shared/form/fieldInfo.styles";
import {
  AddFileContainer,
  FieldData,
  RowFieldsContainer,
} from "../../../../../../../../../shared/form/fieldData.styles";
import Calendar from "../../../../../../../../../shared/calendar/Calendar";
import DateRegexp from "../../../../../../../../../../core/regexp/DateRegexp";
import AddFileButton from "../../../../../../../../../shared/addFileButton/AddFileButton";
import { TitleH2 } from "../../../../../../../../../shared/native/typography.styles";
import AddFileInfo from "../../../../../../../../../shared/addFileInfo/AddFileInfo";

const CertificateInfo: React.FC<{
  register: UseFormRegister<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<FieldValues>;
}> = ({ register, formState: { errors }, control, setValue }) => {
  return (
    <>
      <FieldInfo>
        <TitleH2 style={{ textAlign: "left" }}>Сведения о сертификате</TitleH2>
      </FieldInfo>
      <FieldData />
      <FieldInfo leftContent>
        <FieldName leftContent>Серия и номер</FieldName>
        {(errors.certificateSerial || errors.certificateNumber) && (
          <FieldError leftContent>
            {(errors.certificateSerial?.message as string) ??
              (errors.certificateNumber?.message as string)}
          </FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <RowFieldsContainer>
          <Input
            className="defaultInput"
            hasErrors={!!errors.certificateSerial}
            placeholder="Начните вводить"
            {...register("certificateSerial", {
              required: "Обязательное поле",
            })}
            style={{ marginRight: 8 }}
          />
          <Input
            className="defaultInput"
            hasErrors={!!errors.certificateNumber}
            placeholder="Начните вводить"
            {...register("certificateNumber", {
              required: "Обязательное поле",
            })}
          />
        </RowFieldsContainer>
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>Дата выдачи</FieldName>
        {errors.certificateDateOfIssue && (
          <FieldError leftContent>{errors.certificateDateOfIssue?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Calendar
          className="defaultCalendar"
          placeholder="ДД.ММ.ГГГГ"
          control={control}
          name="certificateDateOfIssue"
          maxDate={new Date()}
          options={{
            required: "Обязательное поле",
            pattern: {
              value: DateRegexp,
              message: "Неверная дата",
            },
          }}
          defaultActiveStartDate={new Date()}
        />
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>
          Скан документа удостоверяющего
          <br /> личность законного представителя
        </FieldName>
        <FieldDescription leftContent>держателя сертификата материнского капитала</FieldDescription>
        {errors.identityDocument && (
          <FieldError leftContent>{errors.identityDocument?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <AddFileContainer>
          <AddFileButton
            multiple
            accept={"image/*"}
            name="identityDocument"
            control={control}
            options={{ required: "Обязательное поле" }}
            setValue={setValue}
          >
            Прикрепить
          </AddFileButton>
        </AddFileContainer>
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>Скан сертификата на материнский капитал</FieldName>
        {errors.maternityCertificate && (
          <FieldError leftContent>{errors.maternityCertificate?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <AddFileContainer>
          <AddFileButton
            multiple
            accept={"image/*"}
            name="maternityCertificate"
            control={control}
            options={{ required: "Обязательное поле" }}
            setValue={setValue}
          >
            Прикрепить
          </AddFileButton>
        </AddFileContainer>
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>Справка из ПФР</FieldName>
        <FieldDescription leftContent>о размере денежных средств на сертификате</FieldDescription>
        {errors.pfrCertificate && (
          <FieldError leftContent>{errors.pfrCertificate?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <AddFileContainer>
          <AddFileButton
            multiple
            accept={"image/*"}
            name="pfrCertificate"
            control={control}
            options={{ required: "Обязательное поле" }}
            setValue={setValue}
          >
            Прикрепить
          </AddFileButton>
        </AddFileContainer>
      </FieldData>
      <FieldInfo />
      <FieldData>
        <AddFileInfo />
      </FieldData>
    </>
  );
};

export default CertificateInfo;
