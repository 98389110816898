import styled from "styled-components";
import colors from "../../../../../core/styles/colors";
import sizes from "../../../../../core/styles/sizes";

export const ApplicationInfoContainer = styled.div`
  width: calc(100% - 110px);
  height: inherit;
  padding: 0 55px;

  @media all and (${sizes.mobile}) {
    width: calc(100% - 32px);
    height: calc(100% - 122px);
    padding: 82px 16px 40px 16px;
    background: ${colors.white.general};
  }
`;

export const GoBackButton = styled.button`
  background: none;
  border: none;
  outline: none;
  position: absolute;
  top: -32px;
  left: 88px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    transform: rotate(180deg);
    path {
      fill: ${colors.black.general};
      transition: ease-in-out 0.3s;
    }
  }
  span {
    font-family: Gilroy, serif;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    margin-left: 8px;
    transition: ease-in-out 0.3s;
  }
  &:hover {
    svg {
      path {
        fill: ${colors.red.general};
      }
    }
    span {
      color: ${colors.red.general};
    }
  }

  @media all and (${sizes.mobile}) {
    display: none;
  }
`;

export const Header = styled.div`
  width: 100%;
  .date {
    margin-bottom: 4px;
    color: ${colors.black.general};
    text-align: left;
  }
  .program {
    text-align: left;
    font-weight: 300;
    font-size: 20px;
    color: ${colors.black.general};
  }

  @media all and (${sizes.mobile}) {
    .date {
      font-size: 18px;
      text-transform: uppercase;
      margin: 8px 0 0 0;
    }
    .program {
      font-size: 14px;
    }
  }
`;

export const StatusesContainer = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media all and (${sizes.mobile}) {
    flex-direction: column;
  }
`;

export const NotificationsContainer = styled.div<{ isGeneral: boolean }>`
  width: 100%;
  max-height: ${(props): string => (props.isGeneral ? "50vh" : "37vh")};
  overflow-y: auto;
  margin-top: 32px;

  @media all and (${sizes.mobile}) {
    max-height: 72vh;
    margin-top: 16px;
  }
`;
