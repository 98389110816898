import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import NotificationCard from "../notificationCard/NotificationCard";
import Notification from "../../../../interfaces/Notification";

const InsideSignatureType: React.FC<{ notification: Notification }> = ({ notification }) => {
  const { handleSubmit } = useForm<FieldValues>();
  const navigate = useNavigate();

  const submitHandler = (): void => {
    navigate("/profile/signature");
  };

  return (
    <NotificationCard
      title={notification?.title}
      comment={notification.comment}
      handleSubmit={handleSubmit(submitHandler)}
      responseButtonText="Получить"
    />
  );
};

export default InsideSignatureType;
