const graduateTypes = {
  high: "vpo",
  additional: "dpo",
  postgraduate: "asp",
  vpo: "high",
  dpo: "additional",
  asp: "postgraduate",
};

export default graduateTypes;
