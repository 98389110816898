import { AxiosResponse } from "axios/index";
import ResponseBody from "../../models/ResponseBody";
import RequestBody from "../../models/RequestBody";
import { $baseApi } from "../../index";
import { PostgraduateEducationForm } from "../../../ui/modules/applications/components/postgraduateEducation/postgraduateEducationForm/interfaces/postgraduateEducationForm";

export const getPostgraduateEducationFormRequest = (
  applicationId: number
): Promise<AxiosResponse<ResponseBody<PostgraduateEducationForm>>> => {
  return $baseApi.post<ResponseBody<PostgraduateEducationForm>>(
    "",
    new RequestBody("draft.read", {
      type: "applicationASP",
      id: applicationId,
    })
  );
};

export const savePostgraduateEducationFormRequest = (
  applicationId: number,
  data: PostgraduateEducationForm
): Promise<AxiosResponse<ResponseBody<{ id: number }>>> => {
  const resultBody = {
    type: "applicationASP",
    data: {
      ...data,
      id: applicationId,
    },
  };
  return $baseApi.post<ResponseBody<{ id: number }>>("", new RequestBody("draft.save", resultBody));
};

export const submitPostgraduateEducationFormRequest = (
  applicationId: number
): Promise<AxiosResponse<ResponseBody<null>>> => {
  return $baseApi.post<ResponseBody<null>>(
    "",
    new RequestBody("application.save", { id: applicationId })
  );
};
