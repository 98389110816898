import React from "react";
import BlankLoader from "./blankLoader.styles";
import SpinnerLoader from "./spinnerLoader.styles";
import LoaderContainer from "./loaderContainer.styles";

const Loader: React.FC<{ type: "blank" | "spinner" }> = ({ type }) => {
  return (
    <LoaderContainer>
      {type === "spinner" && <SpinnerLoader />}
      {type === "blank" && <BlankLoader />}
    </LoaderContainer>
  );
};

export default Loader;
