import Step from "../../../shared/stepper/interfaces/Step";
import PersonalDataForm from "../forms/personalDataForm/PersonalDataForm";
import ContactsDataForm from "../forms/contactsDataForm/ContactsDataForm";
import PassportDataForm from "../forms/passportDataForm/PassportDataForm";
import ResidingPlaceForm from "../forms/residingPlaceForm/ResidingPlaceForm";

const steps: Step[] = [
  {
    title: "Личные данные",
    element: (props: any) => <PersonalDataForm {...props} />,
  },
  {
    title: "Контактные данные",
    element: (props: any) => <ContactsDataForm {...props} />,
  },
  {
    title: "Паспортные данные",
    element: (props: any) => <PassportDataForm {...props} />,
  },
  {
    title: "Место проживания",
    element: (props: any) => <ResidingPlaceForm {...props} />,
  },
];

export default steps;
