interface GraduateInfo {
  [key: string]: { text: string };
}

const graduateInfo: GraduateInfo = {
  high: {
    text: "высшее образование",
  },
  additional: {
    text: "дополнительное образование",
  },
  postgraduate: {
    text: "аспирантуру",
  },
};

export default graduateInfo;
