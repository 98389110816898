import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../../../core/hooks/redux";
import UseAlert from "../../../../../../../../core/hooks/useAlert";
import { fetchGetAccount } from "../../../../../../../../store/core/account/accountThunk";
import {
  Actions,
  FormPreview,
  Header,
  PreviewColumnContainer,
} from "../../../../../../../shared/form/formPreview.styles";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import { OutlinedButton, PrimaryButton } from "../../../../../../../shared/native/button.styles";
import {
  FieldData,
  FieldPhoto,
  FieldText,
} from "../../../../../../../shared/form/fieldData.styles";
import {
  FieldDescription,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import { TitleH2 } from "../../../../../../../shared/native/typography.styles";
import File from "../../../../../../../../core/interfaces/File";
import getPostgraduateEducationFields from "./utils/getPostgraduateEducationFields";
import { setViewedStep } from "../../../../../../../../store/modules/applications/highEducation/highEducationSlice";
import PreviewField from "../../../../../../../shared/form/interfaces/previewField";
import getCookie from "../../../../../../../../core/utils/getCookie";
import Loader from "../../../../../../../shared/loaders/Loader";
import loading from "../../../../../../../../core/types/loading";
import { fetchGetPostgraduateEducation } from "../../../../../../../../store/modules/applications/postgraduateEducation/postgraduateEducationThunk";
import { submitPostgraduateEducationFormRequest } from "../../../../../../../../http/requests/applications/postgraduateEducation.http";

const PreviewPostgraduateEducationForm: React.FC<{ mode: "finallyCheck" | "view" }> = ({
  mode,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const alertHandler = UseAlert();
  const previewRef = React.useRef<HTMLDivElement>(null);
  const [viewedFields, setViewedFields] = React.useState<PreviewField[]>([]);
  const [submitLoadingStatus, setSubmitLoadingStatus] = React.useState<loading>(null);
  const { postgraduateEducationState } = useAppSelector(
    (state) => state.modulesState.applicationsState
  );
  const { dictionariesState } = useAppSelector((state) => state.coreState);

  React.useEffect(() => {
    const cookie = getCookie();
    if (cookie?.lastModifiedPostgraduateEducationApplicationId) {
      dispatch(
        fetchGetPostgraduateEducation(Number(cookie.lastModifiedPostgraduateEducationApplicationId))
      );
    }
  }, []);

  React.useEffect(() => {
    if (!dictionariesState.postgraduateEducationDictionaries) {
      return;
    }

    setViewedFields(
      getPostgraduateEducationFields({
        ...postgraduateEducationState.application,
        achievements: postgraduateEducationState.achievements,
        letter: postgraduateEducationState.letter,
        ...postgraduateEducationState.discountsAndBenefits,
      })
    );
  }, [postgraduateEducationState, dictionariesState]);

  const backToEdit = (): void => {
    dispatch(setViewedStep(0));
    document.cookie = `lastModifiedPostgraduateEducationStep=0; path=/`;
    navigate("/applications/postgraduate-education/form");
  };

  const submitData = (): void => {
    if (!postgraduateEducationState.id) {
      return;
    }

    setSubmitLoadingStatus("LOADING");
    submitPostgraduateEducationFormRequest(postgraduateEducationState.id).then((res) => {
      if (res.data.error) {
        setSubmitLoadingStatus("ERROR");
        alertHandler({ message: res.data.text, type: "error" });
        return;
      }

      setSubmitLoadingStatus("COMPLETE");
      alertHandler({ message: "Заявление добавлено", type: "success" });
      dispatch(fetchGetAccount());
      navigate("/applications/postgraduate-education/complete");
    });
  };

  return (
    <FormPreview mode={mode}>
      <Header>
        <TitleH2>
          {mode === "finallyCheck" ? "Проверка внесенных данных" : "Данные заявления"}
        </TitleH2>
        {mode === "finallyCheck" && (
          <p>Если вы нашли ошибку в данных, то внесите необходимые правки.</p>
        )}
      </Header>
      <FormContainer ref={previewRef} className="form">
        {postgraduateEducationState.dataLoadingStatus === "LOADING" ? (
          <Loader type="blank" />
        ) : (
          viewedFields.map(
            (field) =>
              !field?.hideField && (
                <React.Fragment key={field.name}>
                  <FieldInfo>
                    <FieldName>{field.name}</FieldName>
                    {!!field?.description && (
                      <FieldDescription>{field.description}</FieldDescription>
                    )}
                  </FieldInfo>
                  <FieldData>
                    {field.type === "text" && (
                      <FieldText $gray={!(field.value as string)?.length}>
                        {(field.value as string)?.length ? (field.value as string) : "Отсутствует"}
                      </FieldText>
                    )}
                    {field.type === "files" &&
                      ((field.value as string[])?.every((item) => !item) ? (
                        <FieldText $gray>Отсутствует</FieldText>
                      ) : (
                        (field.value as File[])?.map(
                          (file, index) =>
                            file?.info?.name && (
                              <FieldPhoto
                                key={file.info.name + index.toString()}
                                src={file.file as string}
                                alt={file.info.name}
                              />
                            )
                        )
                      ))}
                    {field.type === "achievements" && (
                      <PreviewColumnContainer>
                        {(field.value as { achievement: string; document: File }[]).length ? (
                          (field.value as { achievement: string; document: File }[]).map(
                            (achievement, index) => (
                              <React.Fragment key={achievement.achievement}>
                                <FieldText
                                  style={{ marginTop: index === 0 ? 0 : 8, marginBottom: 4 }}
                                >
                                  {
                                    field.selectedProgram.achievements.find(
                                      (item) => item.achievement_id === achievement.achievement
                                    )?.achievement_name
                                  }
                                </FieldText>
                                {achievement.document?.info?.name && (
                                  <FieldPhoto
                                    key={achievement.document.name + index.toString()}
                                    src={achievement.document.file as string}
                                    alt={achievement.document.info.name}
                                  />
                                )}
                              </React.Fragment>
                            )
                          )
                        ) : (
                          <FieldText $gray>Отсутствуют</FieldText>
                        )}
                      </PreviewColumnContainer>
                    )}
                  </FieldData>
                </React.Fragment>
              )
          )
        )}
      </FormContainer>
      {mode === "finallyCheck" && (
        <Actions>
          <OutlinedButton onClick={backToEdit}>Редактировать</OutlinedButton>
          <PrimaryButton disabled={submitLoadingStatus === "LOADING"} onClick={submitData}>
            Подтвердить
          </PrimaryButton>
        </Actions>
      )}
    </FormPreview>
  );
};

export default PreviewPostgraduateEducationForm;
