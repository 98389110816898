import styled from "styled-components";
import colors from "../../../core/styles/colors";

export const TitleH1 = styled.h1`
  width: 100%;
  text-align: center;
  font-family: Gilroy, serif;
  font-size: 29px;
  line-height: 30px;
  color: ${colors.red.general};
`;

export const TitleH2 = styled.h2`
  width: 100%;
  text-align: center;
  font-family: Gilroy, serif;
  font-size: 18px;
  margin: 0;
  line-height: 17px;
  color: ${colors.red.general};
`;

export const TitleH3 = styled.h3`
  width: 100%;
  text-align: center;
  font-family: Gilroy, serif;
  font-size: 14px;
  margin: 0;
  line-height: 17px;
  color: ${colors.red.general};
`;

export const ErrorText = styled.span`
  display: block;
  width: 100%;
  text-align: center;
  font-family: Gilroy, serif;
  color: ${colors.red.general};
`;

export const NoContentText = styled(TitleH1)`
  padding: 64px 0;
  color: ${colors.white.white700};
`;
