import ApplicationInfo from "../../../../core/interfaces/applications/ApplicationInfo";
import { setHasCompletedApplications } from "../../../../store/core/account/accountSlice";
import store from "../../../../store/store";
import degreeType from "../../../../core/types/degreeType";

const setCompletedApplications = (applications: ApplicationInfo[], degree: degreeType): void => {
  store.dispatch(
    setHasCompletedApplications({
      degree,
      value: applications?.some((application) => application.completed),
    })
  );
};

export default setCompletedApplications;
