import React from "react";
import alertType from "../../../../core/constants/alertType";
import colors from "../../../../core/styles/colors";

const alertTypes: { type: alertType; color: string; icon: React.ReactNode }[] = [
  {
    type: "success",
    color: colors.green.general,
    icon: <span>✓</span>,
  },
  {
    type: "error",
    color: colors.red.general,
    icon: <span>✘</span>,
  },
  {
    type: "info",
    color: colors.blue.general,
    icon: <span>ℹ</span>,
  },
];

export default alertTypes;
