import styled from "styled-components";
import colors from "../../../../../core/styles/colors";

export const Subgroup = styled.div`
  width: 100%;
`;

export const SubgroupTitle = styled.h4`
  width: 100%;
  margin: 8px 0 0 0;
  color: ${colors.white.general};
  font-size: 14px;
`;

export const SubgroupLink = styled.div<{
  $active?: boolean;
  disabled?: boolean;
}>`
  position: relative;
  width: calc(100% - 8px);
  margin: 4px 0 0 8px;
  display: flex;
  align-items: center;
  a,
  span {
    width: fit-content;
    font-size: 14px;
    font-weight: 300;
    color: ${(props): string => {
      if (props.disabled) {
        return colors.gray.gray300;
      }
      if (props.$active) {
        return colors.white.general;
      }

      return colors.gray.gray100;
    }};
    transition: ease-in-out 0.3s;
    &:hover {
      color: ${(props): string => (props.disabled ? colors.gray.gray300 : colors.white.general)};
    }
  }
  p {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.red.general};
    margin: 0 0 0 4px;
  }
  ${(props): string =>
    props.$active &&
    `&:before {
    position: absolute;
    display: block;
    content: "";
    height: 4px;
    width: 4px;
    border-radius: 4px;
    background: ${colors.red.general};
    top: 6px;
    left: -8px;
  }`}
`;
