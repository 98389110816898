import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AddNewBaseApplicationButton,
  BaseApplicationsList as StyledBaseApplicationsList,
  BaseApplicationsListContainer,
  BaseApplicationsListContent,
} from "./baseApplicationsList.styles";
import { TitleH1 } from "../../../../../shared/native/typography.styles";
import LinkCard from "../../../../../shared/cards/linkCard/LinkCard";
import { getApplicationsRequest } from "../../../../../../http/requests/applications/applications.http";
import ApplicationInfo from "../../../../../../core/interfaces/applications/ApplicationInfo";
import useIsMobile from "../../../../../../core/hooks/UseIsMobile";
import { useAppDispatch } from "../../../../../../core/hooks/redux";
import { resetHighEducationState } from "../../../../../../store/modules/applications/highEducation/highEducationSlice";
import Loader from "../../../../../shared/loaders/Loader";
import { resetAdditionalEducationState } from "../../../../../../store/modules/applications/additionalEducation/additionalEducationSlice";
import formatDate from "../../../../../../core/utils/formatDate";
import capitalize from "../../../../../../core/utils/capitalize";
import graduateInfo from "../../selectApplicationType/constants/graduateInfo";
import { resetPostgraduateEducationState } from "../../../../../../store/modules/applications/postgraduateEducation/postgraduateEducationSlice";
import setCompletedApplications from "../../../utils/setCompletedApplications";
import degreeType from "../../../../../../core/types/degreeType";
import {
  ApplicationCardContent,
  CardBody,
  CardFooter,
  CardHeader,
} from "../applicationsListCard.styles";

const BaseApplicationsList: React.FC<{ degree: degreeType }> = ({ degree }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [applications, setApplications] = React.useState<ApplicationInfo[]>([]);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1040);

  useIsMobile(setIsMobile);

  React.useEffect(() => {
    getApplicationsRequest(degree).then((res) => {
      const resApplications = (res.data.payload as ApplicationInfo[]).map((application) => ({
        ...application,
        completed: !!+application.completed,
      }));

      setApplications(resApplications);
      setCompletedApplications(resApplications, degree);

      if (!resApplications?.length) {
        navigate(`/applications/${degree}-education/submission-info`);
      }
    });
  }, []);

  const applicationClickHandler = (
    isNew: boolean,
    applicationId?: string,
    completed?: boolean
  ): void => {
    document.cookie = `lastModified${capitalize(degree)}EducationApplicationId=${
      isNew ? "null" : applicationId
    }; path=/`;
    document.cookie = `lastModified${capitalize(degree)}EducationStep=0; path=/`;
    if (isNew) {
      dispatch(resetHighEducationState());
      dispatch(resetAdditionalEducationState());
      dispatch(resetPostgraduateEducationState());
      navigate(`/applications/${degree}-education/submission-info`);
      return;
    }
    navigate(`/applications/${degree}-education/${completed ? applicationId : "submission-info"}`);
  };

  if (!applications.length) {
    return (
      <BaseApplicationsListContainer>
        <Loader type="spinner" />
      </BaseApplicationsListContainer>
    );
  }

  return (
    <BaseApplicationsListContainer>
      <BaseApplicationsListContent>
        <TitleH1>Заявления на {graduateInfo[degree].text}</TitleH1>
        <StyledBaseApplicationsList>
          {!isMobile && (
            <LinkCard $dashed onClick={(): void => applicationClickHandler(true)}>
              <ApplicationCardContent isnew>
                <p>Новое заявление</p>
              </ApplicationCardContent>
            </LinkCard>
          )}
          {applications.map((application) => (
            <LinkCard
              key={application.id}
              onClick={(): void =>
                applicationClickHandler(false, application.id, application.completed as boolean)
              }
            >
              <ApplicationCardContent>
                <CardHeader>
                  <b>{degree === "high" ? application.level : ""}</b>
                  <p>{formatDate(application.datetime.split(" ")?.[0])}</p>
                </CardHeader>
                <CardBody>
                  <b>{degree === "high" ? application.speciality : application.programm}</b>
                  <p>{application.form}</p>
                  <p>{degree === "high" ? application.financing : ""}</p>
                </CardBody>
                <CardFooter />
              </ApplicationCardContent>
            </LinkCard>
          ))}
        </StyledBaseApplicationsList>
      </BaseApplicationsListContent>
      {isMobile && (
        <AddNewBaseApplicationButton onClick={(): void => applicationClickHandler(true)}>
          Новое заявление
        </AddNewBaseApplicationButton>
      )}
    </BaseApplicationsListContainer>
  );
};

export default BaseApplicationsList;
