import React from "react";
import { Control, FieldValues, FormState, UseFormSetValue, useWatch } from "react-hook-form";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import {
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import { FieldData } from "../../../../../../../shared/form/fieldData.styles";
import Select from "../../../../../../../shared/select/Select";
import { useAppSelector } from "../../../../../../../../core/hooks/redux";
import ProgramDictionary from "../../../../../../../../core/interfaces/dictionaries/programDictionaries/ProgramDictionary";
import SelectItem from "../../../../../../../shared/select/SelectItem";
import { SplitPostgraduateEducationForm } from "../../interfaces/postgraduateEducationForm";
import getSelectedProgram from "../../utils/getSelectedProgram";

const ApplicationForm: React.FC<{
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<SplitPostgraduateEducationForm>;
}> = ({ formState: { errors }, control, setValue }) => {
  const { dictionariesState } = useAppSelector((state) => state.coreState);

  // STATES
  const [modifiedFormByUser, setModifiedFormByUser] = React.useState(false);

  // WATCHES
  const educationLevelWatch = useWatch({ control, name: "educationLevel" });
  const directionAndSpecialityWatch = useWatch({ control, name: "directionAndSpeciality" });
  const formWatch = useWatch({ control, name: "form" });
  const financingWatch = useWatch({ control, name: "financing" });

  // FUNCTIONS
  const getFilteredItems = (
    type: "level" | "specialty" | "form" | "type_budget",
    filter: (program: ProgramDictionary) => boolean
  ): SelectItem[] => {
    if (!dictionariesState.postgraduateEducationDictionaries) {
      return [];
    }

    const filteredPrograms = dictionariesState.postgraduateEducationDictionaries.filter(filter);
    const alreadyHasItems: ProgramDictionary[] = [];
    const uniquePrograms = filteredPrograms.filter((program) => {
      const alreadyHasItem = alreadyHasItems.find(
        (item) => item[`${type}_id`] === program[`${type}_id`]
      );
      if (alreadyHasItem) {
        return false;
      }

      alreadyHasItems.push(program);
      return true;
    });

    return uniquePrograms.map((program) => {
      return {
        label: program[type],
        value: program[`${type}_id`],
      };
    });
  };

  const setModifiedFormByUserHandler = (): void => {
    setModifiedFormByUser(true);
  };

  // EFFECTS
  React.useEffect(() => {
    if (!modifiedFormByUser) {
      return;
    }

    setValue("directionAndSpeciality", "");
  }, [educationLevelWatch]);

  React.useEffect(() => {
    if (!modifiedFormByUser) {
      return;
    }

    setValue("form", "");
  }, [directionAndSpecialityWatch]);

  React.useEffect(() => {
    if (!modifiedFormByUser) {
      return;
    }

    setValue("financing", "");
  }, [formWatch]);

  React.useEffect(() => {
    if (!modifiedFormByUser) {
      return;
    }
    const selectedProgram = getSelectedProgram(
      educationLevelWatch,
      directionAndSpecialityWatch,
      formWatch,
      financingWatch
    );

    if (!selectedProgram) {
      return;
    }

    setValue("program_uid", selectedProgram.program_uid);
    setValue("vi", selectedProgram.vi);
  }, [educationLevelWatch, directionAndSpecialityWatch, formWatch, financingWatch]);

  return (
    <FormContainer>
      <FieldInfo>
        <FieldName>Уровень образования для поступления</FieldName>
        {errors.level && <FieldError>{errors.level?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите вариант ответа"
          items={getFilteredItems("level", () => true)}
          control={control}
          options={{
            required: "Обязательное поле",
          }}
          name="educationLevel"
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Научная специальность</FieldName>
        {errors.speciality && <FieldError>{errors.speciality?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите вариант ответа"
          items={getFilteredItems(
            "specialty",
            (program) => program.level_id === educationLevelWatch
          )}
          control={control}
          options={{
            required: "Обязательное поле",
          }}
          name="directionAndSpeciality"
          disabled={!educationLevelWatch}
          onClick={setModifiedFormByUserHandler}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Форма</FieldName>
        {errors.form && <FieldError>{errors.form?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите вариант ответа"
          items={getFilteredItems(
            "form",
            (program) =>
              program.level_id === educationLevelWatch &&
              program.specialty_id === directionAndSpecialityWatch
          )}
          control={control}
          options={{
            required: "Обязательное поле",
          }}
          name="form"
          disabled={!directionAndSpecialityWatch}
          onClick={setModifiedFormByUserHandler}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Источник финансирования</FieldName>
        {errors.financing && <FieldError>{errors.financing?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите вариант ответа"
          items={getFilteredItems(
            "type_budget",
            (program) =>
              program.level_id === educationLevelWatch &&
              program.specialty_id === directionAndSpecialityWatch &&
              program.form_id === formWatch
          )}
          control={control}
          options={{
            required: "Обязательное поле",
          }}
          name="financing"
          disabled={!formWatch}
          onClick={setModifiedFormByUserHandler}
        />
      </FieldData>
    </FormContainer>
  );
};

export default ApplicationForm;
