import SelectItem from "../../../../../../../../shared/select/SelectItem";

export default [
  {
    label: "Нет",
    value: "1",
  },
  {
    label: "Да",
    value: "2",
  },
] as SelectItem[];
