import React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import InputMask from "react-input-mask";
import NotificationCard from "../../notificationCard/NotificationCard";
import Notification from "../../../../../interfaces/Notification";
import Select from "../../../../../../../shared/select/Select";
import SelectItem from "../../../../../../../shared/select/SelectItem";
import { Input } from "../../../../../../../shared/native/input.styles";
import documentsInfo from "../../../constants/documentsInfo";
import noUnderlines from "../../../../../../../../core/regexp/noUnderlines";
import colors from "../../../../../../../../core/styles/colors";
import { useAppDispatch } from "../../../../../../../../core/hooks/redux";
import { submitNotificationRequest } from "../../../../../../../../http/requests/notifications.http";
import { removeNotification } from "../../../../../../../../store/modules/notifications/notificationsSlice";
import getFormComponentByAnswer from "./constants/getFormComponentByAnswer";
import defaultFormValues from "./constants/defaultFormValues";
import NotificationSubmitForm from "../../../../../interfaces/NotificationSubmitForm";
import convertFormData from "./utils/convertFormData";
import degreeType from "../../../../../../../../core/types/degreeType";

const FormType: React.FC<{ notification: Notification; degree: degreeType }> = ({
  notification,
  degree,
}) => {
  const dispatch = useAppDispatch();
  const [disableResponseButton, setDisableResponseButton] = React.useState(false);
  const { handleSubmit, control, register, setValue, formState } = useForm<
    NotificationSubmitForm & { answer: number }
  >({
    defaultValues: defaultFormValues,
  });
  const answerWatch = useWatch({ control, name: "answer" });

  const submitHandler = (data: NotificationSubmitForm & { answer: number }): void => {
    setDisableResponseButton(true);

    submitNotificationRequest({
      notify_id: notification.uid,
      type: notification.notification_type,
      files: [],
      answer: data.answer,
      form: convertFormData(data),
    }).then((res) => {
      setDisableResponseButton(false);
      if (res.data.error) {
        return;
      }

      dispatch(removeNotification(notification.uid));
    });
  };

  return (
    <NotificationCard
      title={notification?.document_name}
      comment={notification.comment}
      handleSubmit={handleSubmit(submitHandler)}
      disableResponseButton={disableResponseButton}
    >
      {notification?.dict ? (
        <>
          <Select
            items={notification.dict.map(
              (dict) =>
                ({
                  label: dict.name,
                  value: dict.id,
                } as SelectItem)
            )}
            control={control}
            options={{ required: "Обязательное поле" }}
            name="answer"
            placeholder="Выберите ответ"
            whiteBackground
          />
          {getFormComponentByAnswer(degree)[answerWatch]?.element?.({
            control,
            register,
            setValue,
            formState,
          })}
        </>
      ) : (
        <>
          {documentsInfo[notification.document_id] ? (
            <Controller
              control={control}
              name="answer"
              rules={{
                required: "Обязательное поле",
                pattern: {
                  value: noUnderlines,
                  message: "Неполное значение",
                },
                validate: (value) =>
                  documentsInfo[notification.document_id]?.validate
                    ? documentsInfo[notification.document_id]?.validate(value)
                    : true,
              }}
              render={({ field: { value, onChange } }): React.ReactElement => (
                <InputMask
                  mask={documentsInfo[notification.document_id].mask}
                  value={value}
                  onChange={onChange}
                >
                  <Input
                    placeholder={
                      documentsInfo[notification.document_id].placeholder ?? "Введите ответ"
                    }
                    hasErrors={!!formState?.errors?.answer}
                    style={{ background: colors.white.general }}
                  />
                </InputMask>
              )}
            />
          ) : (
            <Input
              placeholder="Введите ответ"
              {...register("answer", { required: "Обязательное поле" })}
              hasErrors={!!formState?.errors?.answer}
              style={{ background: colors.white.general }}
            />
          )}
        </>
      )}
    </NotificationCard>
  );
};

export default FormType;
