import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import NotificationCard from "../notificationCard/NotificationCard";
import Notification from "../../../../interfaces/Notification";
import AddFileButton from "../../../../../../shared/addFileButton/AddFileButton";
import { submitNotificationRequest } from "../../../../../../../http/requests/notifications.http";
import { removeNotification } from "../../../../../../../store/modules/notifications/notificationsSlice";
import { useAppDispatch } from "../../../../../../../core/hooks/redux";
import { FieldInfo } from "../../../../../../shared/form/fieldInfo.styles";
import { FieldData } from "../../../../../../shared/form/fieldData.styles";
import AddFileInfo from "../../../../../../shared/addFileInfo/AddFileInfo";

const FileUploadType: React.FC<{ notification: Notification }> = ({ notification }) => {
  const dispatch = useAppDispatch();
  const [disableResponseButton, setDisableResponseButton] = React.useState(false);
  const { control, setValue, handleSubmit } = useForm<FieldValues>({
    defaultValues: {
      files: [],
    },
  });

  const submitHandler = (data: FieldValues): void => {
    setDisableResponseButton(true);
    submitNotificationRequest({
      notify_id: notification.uid,
      type: notification.notification_type,
      files: data.files,
      answer: "",
    }).then((res) => {
      setDisableResponseButton(false);
      if (res.data.error) {
        return;
      }

      dispatch(removeNotification(notification.uid));
    });
  };

  return (
    <NotificationCard
      title={notification?.document_name}
      comment={notification.comment}
      handleSubmit={handleSubmit(submitHandler)}
      disableResponseButton={disableResponseButton}
    >
      <AddFileButton
        control={control}
        setValue={setValue}
        name="files"
        options={{ required: "Обязательное поле" }}
        accept="image/*"
        multiple
        previewProps={{
          whiteBackground: true,
        }}
        buttonProps={{
          whiteBackground: true,
        }}
      >
        Загрузить
      </AddFileButton>
      <FieldInfo />
      <FieldData>
        <AddFileInfo />
      </FieldData>
    </NotificationCard>
  );
};

export default FileUploadType;
