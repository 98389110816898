import React from "react";
import { useNavigate } from "react-router-dom";
import {
  InfoPage,
  InfoPageContent,
  InfoPageTextInfo,
} from "../../../../../../../shared/infoPage/infoPage.styles";
import { TitleH1 } from "../../../../../../../shared/native/typography.styles";
import { PrimaryButton } from "../../../../../../../shared/native/button.styles";
import { useAppDispatch, useAppSelector } from "../../../../../../../../core/hooks/redux";
import { setViewedStep } from "../../../../../../../../store/modules/applications/additionalData/additionalDataSlice";

const AddingDataInfo: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { additionalDataState } = useAppSelector((state) => state.modulesState.applicationsState);

  React.useEffect(() => {
    if (additionalDataState.educationInfo.transferredFromAnotherUniversity) {
      if (additionalDataState.trainingCertificate.certificateDateIssue) {
        dispatch(setViewedStep(3));
        navigate("/applications/additional-high-data/form");
        return;
      }
    }
    if (additionalDataState.educationDocument.dateOfIssue) {
      dispatch(setViewedStep(2));
      navigate("/applications/additional-high-data/form");
      return;
    }
    if (additionalDataState.educationInfo.educationLevel) {
      dispatch(setViewedStep(1));
      navigate("/applications/additional-high-data/form");
    }
  }, []);

  return (
    <InfoPage>
      <InfoPageContent>
        <InfoPageTextInfo>
          <TitleH1>Внесение дополнительных сведений</TitleH1>
          <p>
            Внесите данные об образовании и специальных условиях
            <br />
            (при необходимости).
          </p>
        </InfoPageTextInfo>
        <PrimaryButton
          onClick={(): void => navigate(`/applications/additional-high-data/what-need`)}
        >
          Продолжить
        </PrimaryButton>
      </InfoPageContent>
    </InfoPage>
  );
};

export default AddingDataInfo;
