import styled from "styled-components";
import colors from "../../../core/styles/colors";
import sizes from "../../../core/styles/sizes";

export const SignatureForm = styled.div`
  width: calc(100% - 110px);
  min-height: inherit;
  padding: 0 55px;
  .stepper {
    width: 100%;
    margin: 10px 0 16px 0;
  }

  @media all and (${sizes.mobile}) {
    width: 100%;
    height: calc(100% - 144px);
    padding: 72px 0;
    background: ${colors.white.general};
    .stepper {
      width: 100%;
      margin: 0 0 16px 0;
    }
  }
`;

export const SignatureFormContent = styled.form<{ stepIndex: number }>`
  width: 100%;
  display: grid;
  grid-template-areas:
    "file info"
    "file actions";
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 0.9fr;
  grid-gap: 32px;

  @media all and (${sizes.mobile}) {
    width: calc(100% - 32px);
    padding: 0 16px;
    display: ${(props): string => (props.stepIndex === 2 ? "flex" : "grid")};
    flex-direction: column;
    grid-template-areas:
      "info"
      "file"
      "actions";
    grid-template-rows: none;
    grid-template-columns: none;
  }
`;

export const SignatureFormFile = styled.div<{ stepIndex: number }>`
  grid-area: file;
  width: calc(100% - 32px);
  padding: 16px;
  background: ${colors.white.hoverWhite4};
  border-radius: 12.5px;
  .react-pdf {
    width: 100%;
    &__Document {
      max-height: 55vh;
      overflow-y: auto;
    }
    &__Page {
      width: 100%;
      &__canvas {
        width: 100% !important;
        height: 100% !important;
      }
      &__textContent {
        display: none;
      }
      .annotationLayer {
        display: none;
      }
    }
  }

  @media all and (${sizes.mobile}) {
    display: ${(props): string => (props.stepIndex === 2 ? "none" : "block")};
    .react-pdf {
      width: 100%;
      &__Document {
        max-height: 50vh;
        overflow-y: auto;
      }
    }
  }
`;

export const SignatureFormInfo = styled.div`
  grid-area: info;
  width: 74%;
  display: flex;
  flex-direction: column;

  @media all and (${sizes.mobile}) {
    width: 100%;
  }
`;

export const SignatureFormActions = styled.div`
  grid-area: actions;
  width: 74%;
  margin-top: -8px;
  button {
    width: 100%;
  }

  @media all and (${sizes.mobile}) {
    width: 100%;
  }
`;

export const FormDescription = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: ${colors.gray.gray300};
  margin: 0;
  span {
    font-weight: 600;
    color: ${colors.red.general};
    margin-right: 4px;
  }
`;

export const FormItem = styled.div`
  width: 100%;
  p {
    width: fit-content;
    font-family: Gilroy, serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    margin: 16px 0 2px 16px;
  }

  @media all and (${sizes.mobile}) {
    p {
      font-weight: 600;
    }
  }
`;
