import React from "react";
import {
  Control,
  FieldValues,
  FormState,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import dayjs from "dayjs";
import { Input } from "../../../../shared/native/input.styles";
import Checkbox from "../../../../shared/checkbox/Checkbox";
import RadioButtons from "../../../../shared/radioButtons/RadioButtons";
import genderRadioButtonItems from "../../../../../core/constants/genderRadioButtonItems";
import Calendar from "../../../../shared/calendar/Calendar";
import { FormContainer } from "../../../../shared/form/form.styles";
import { FieldData } from "../../../../shared/form/fieldData.styles";
import { FieldError, FieldInfo, FieldName } from "../../../../shared/form/fieldInfo.styles";
import ProfileForm from "../../interfaces/ProfileForm";
import cyrillicRegexp from "../../../../../core/regexp/cyrillicRegexp";
import DateRegexp from "../../../../../core/regexp/DateRegexp";

const PersonalDataForm: React.FC<{
  register: UseFormRegister<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<ProfileForm>;
}> = ({ register, formState: { errors }, control, setValue }) => {
  const hasNotSecondNameWatch = useWatch({
    control,
    name: "hasNotSecondName",
  });

  React.useEffect(() => {
    if (hasNotSecondNameWatch) {
      setValue("secondName", "");
    }
  }, [hasNotSecondNameWatch]);

  return (
    <FormContainer>
      <FieldInfo>
        <FieldName>Фамилия</FieldName>
        {errors.lastname && <FieldError>{errors.lastname?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.lastname}
          {...register("lastname", {
            required: "Обязательное поле",
            pattern: {
              value: cyrillicRegexp,
              message: "Только киррилица",
            },
          })}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Имя</FieldName>
        {errors.firstname && <FieldError>{errors.firstname?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.firstname}
          {...register("firstname", {
            required: "Обязательное поле",
            pattern: {
              value: cyrillicRegexp,
              message: "Только киррилица",
            },
          })}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Отчество</FieldName>
        {errors.secondName && <FieldError>{errors.secondName?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.secondName}
          disabled={hasNotSecondNameWatch}
          {...register("secondName", {
            required: !hasNotSecondNameWatch ? "Обязательное поле" : false,
            pattern: {
              value: cyrillicRegexp,
              message: "Только киррилица",
            },
          })}
        />
        <Checkbox
          style={{ marginLeft: 8 }}
          label="Отсутствует"
          control={control}
          name="hasNotSecondName"
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Пол</FieldName>
        {errors.gender && <FieldError>{errors.gender?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <RadioButtons
          items={genderRadioButtonItems}
          control={control}
          name="gender"
          options={{
            required: "Обязательное поле",
          }}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Дата рождения</FieldName>
        {errors.dateOfBirth && <FieldError>{errors.dateOfBirth?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Calendar
          className="defaultCalendar"
          placeholder="ДД.ММ.ГГГГ"
          control={control}
          name="dateOfBirth"
          minDate={new Date("01.01.1930")}
          maxDate={dayjs().subtract(15, "year").toDate()}
          options={{
            required: "Обязательное поле",
            pattern: {
              value: DateRegexp,
              message: "Неверная дата",
            },
          }}
          defaultActiveStartDate={dayjs().subtract(15, "year").toDate()}
        />
      </FieldData>
    </FormContainer>
  );
};

export default PersonalDataForm;
