import SelectItem from "../../ui/shared/select/SelectItem";

const personalDocumentSelectItems: SelectItem[] = [
  {
    label: "Паспорт гражданина РФ",
    value: "000000047",
  },
  {
    label: "Паспорт иностранного гражданина",
    value: "000000049",
  },
];

export default personalDocumentSelectItems;
