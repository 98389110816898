import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../../../core/hooks/redux";
import UseAlert from "../../../../../../../../core/hooks/useAlert";
import { setViewedStep } from "../../../../../../../../store/modules/applications/additionalPostgraduateData/additionalPostgraduateDataSlice";
import { fetchGetAccount } from "../../../../../../../../store/core/account/accountThunk";
import { Actions, FormPreview, Header } from "../../../../../../../shared/form/formPreview.styles";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import { OutlinedButton, PrimaryButton } from "../../../../../../../shared/native/button.styles";
import {
  FieldData,
  FieldPhoto,
  FieldText,
} from "../../../../../../../shared/form/fieldData.styles";
import RadioButtons from "../../../../../../../shared/radioButtons/RadioButtons";
import {
  FieldDescription,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import { TitleH2 } from "../../../../../../../shared/native/typography.styles";
import File from "../../../../../../../../core/interfaces/File";
import { submitAdditionalPostgraduateDataFormRequest } from "../../../../../../../../http/requests/applications/additionalPosgraduateData.http";
import Checkbox from "../../../../../../../shared/checkbox/Checkbox";
import getAdditionalPostgraduateDataFields from "./utils/getAdditionalPostgraduateDataFields";
import useScrollPosition from "../../../../../../../../core/hooks/useScrollPosition";
import loading from "../../../../../../../../core/types/loading";
import { fetchGetPostgraduateEducationDictionaries } from "../../../../../../../../store/core/dictionaries/dictionariesThunk";

const PreviewAdditionalPostgraduateDataForm: React.FC<{ mode: "finallyCheck" | "view" }> = ({
  mode,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const alertHandler = UseAlert();
  const previewRef = React.useRef(null);
  const [previewScrollPosition, setPreviewScrollPosition] = React.useState(0);
  const [disableNextButton, setDisableNextButton] = React.useState(true);
  const [viewedFields, setViewedFields] = React.useState([]);
  const [submitLoadingStatus, setSubmitLoadingStatus] = React.useState<loading>(null);
  const { additionalPostgraduateDataState } = useAppSelector(
    (state) => state.modulesState.applicationsState
  );

  useScrollPosition(previewRef, setPreviewScrollPosition);

  React.useEffect(() => {
    if (previewScrollPosition >= 95) {
      setDisableNextButton(false);
    }
  }, [previewScrollPosition]);

  React.useEffect(() => {
    if (!additionalPostgraduateDataState.educationInfo.educationLevel) {
      return;
    }

    setViewedFields(
      getAdditionalPostgraduateDataFields({
        ...additionalPostgraduateDataState.educationInfo,
        ...additionalPostgraduateDataState.educationDocument,
        ...additionalPostgraduateDataState.healthLimitations,
      })
    );
  }, [additionalPostgraduateDataState]);

  const backToEdit = (): void => {
    dispatch(setViewedStep(0));
    document.cookie = `lastModifiedAdditionalPostgraduateDataStep=0; path=/`;
    navigate("/applications/additional-postgraduate-data/form");
  };

  const submitData = (): void => {
    setSubmitLoadingStatus("LOADING");
    submitAdditionalPostgraduateDataFormRequest().then((res) => {
      if (res.data.error) {
        setSubmitLoadingStatus("ERROR");
        alertHandler({ message: res.data.text, type: "error" });
        return;
      }

      setSubmitLoadingStatus("COMPLETE");
      alertHandler({ message: "Информация сохранена", type: "success" });
      dispatch(fetchGetAccount());
      dispatch(fetchGetPostgraduateEducationDictionaries());
      navigate("/applications/additional-postgraduate-data/complete");
    });
  };

  return (
    <FormPreview mode={mode}>
      <Header>
        <TitleH2>
          {mode === "finallyCheck" ? "Проверка внесенных данных" : "Дополнительные данные"}
        </TitleH2>
        {mode === "finallyCheck" && (
          <p>Если вы нашли ошибку в данных, то внесите необходимые правки.</p>
        )}
      </Header>
      <FormContainer ref={previewRef} className="form">
        {viewedFields.map(
          (field) =>
            !field?.hideField && (
              <React.Fragment key={field.name}>
                <FieldInfo>
                  {field.type !== "checkbox" && <FieldName>{field.name}</FieldName>}
                  {!!field?.description && <FieldDescription>{field.description}</FieldDescription>}
                </FieldInfo>
                <FieldData>
                  {field.type === "text" && (
                    <FieldText $gray={!(field.value as string)?.length}>
                      {(field.value as string)?.length ? (field.value as string) : "Отсутствует"}
                    </FieldText>
                  )}
                  {field.type === "radio" && (
                    <RadioButtons
                      style={{ marginLeft: 6 }}
                      items={field.items}
                      value={field.value as string}
                    />
                  )}
                  {field.type === "checkbox" && (
                    <Checkbox
                      label={field.name}
                      value={field.value as boolean}
                      style={{ marginLeft: 8 }}
                    />
                  )}
                  {field.type === "files" &&
                    ((field.value as string[])?.every((item) => !item) ? (
                      <FieldText $gray>Отсутствует</FieldText>
                    ) : (
                      (field.value as File[])?.map(
                        (file, index) =>
                          file?.info?.name && (
                            <FieldPhoto
                              key={file.info.name + index.toString()}
                              src={file.file as string}
                              alt={file.info.name}
                            />
                          )
                      )
                    ))}
                </FieldData>
              </React.Fragment>
            )
        )}
      </FormContainer>
      {mode === "finallyCheck" && (
        <Actions>
          <OutlinedButton onClick={backToEdit}>Редактировать</OutlinedButton>
          <PrimaryButton
            disabled={disableNextButton || submitLoadingStatus === "LOADING"}
            onClick={submitData}
          >
            Подтвердить
          </PrimaryButton>
        </Actions>
      )}
    </FormPreview>
  );
};

export default PreviewAdditionalPostgraduateDataForm;
