import { HighEducationForm, SplitHighEducationForm } from "../interfaces/highEducationForm";
import convertFile from "../../../../../../../core/utils/convertFile";
import Exam from "../interfaces/Exam";
import store from "../../../../../../../store/store";
import getSelectedProgram from "./getSelectedProgram";

const convertSplitHighEducationForm = (data: SplitHighEducationForm): HighEducationForm => {
  const state = store.getState();
  const { dictionaries } = state.coreState.dictionariesState;

  const program = getSelectedProgram(
    data.educationLevel,
    data.directionAndSpeciality,
    data.profile,
    data.form,
    data.financing,
    data.kvota
  );

  return {
    application: {
      educationLevel: program.level_id,
      educationLevelName: program.level,
      directionAndSpeciality: program.specialty_id,
      directionAndSpecialityName: program.specialty,
      profile: program.profile_id,
      profileName: program.profile,
      form: program.form_id,
      formName: program.form,
      financing: program.type_budget_id,
      financingName: program.type_budget,
      program_uid: program.program_uid,
      kvota: program.kvota,
    },
    entranceExams: {
      exams: Object.values(data.exams).map((exam) => ({
        ...exam,
        scores: exam.hasUSE ? exam.scores : null,
        year: exam.hasUSE ? exam.year : null,
      })) as Exam[],
    },
    discountsAndBenefits: {
      achievements: Object.values(data.achievements)
        .filter((item) => !!item.achievement && item.achievement !== "0")
        .map((item) => ({
          achievement: item.achievement,
          document: convertFile("to", item.document),
        })),
      discountsAndBenefits: data.discountsAndBenefits,
      discountsPercentage:
        dictionaries.discounts.find((discount) => discount.id === data.discountsAndBenefits)?.val ??
        null,
      discountsConfirm: convertFile("to", data.discountsConfirm),
    },
  };
};

export default convertSplitHighEducationForm;
