import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HighEducationForm } from "../../../../ui/modules/applications/components/highEducation/highEducationForm/interfaces/highEducationForm";
import { fetchGetHighEducation } from "./highEducationThunk";
import convertHighEducationFromSavedData from "../../../../ui/modules/applications/components/highEducation/highEducationForm/utils/convertHighEducationFromSavedData";
import loading from "../../../../core/types/loading";

interface HighEducationState extends HighEducationForm {
  viewedStep: number;
  dataLoadingStatus: loading;
}

const initialState: HighEducationState = {
  viewedStep: 0,
  id: null,
  application: {
    educationLevel: "",
    educationLevelName: "",
    directionAndSpeciality: "",
    directionAndSpecialityName: "",
    profile: "",
    profileName: "",
    form: "",
    formName: "",
    financing: "",
    financingName: "",
    kvota: "",
    program_uid: "",
  },
  entranceExams: {
    exams: [],
  },
  discountsAndBenefits: {
    achievements: [],
    discountsAndBenefits: "0",
    discountsPercentage: null,
    discountsConfirm: null,
  },
  dataLoadingStatus: null,
};

const highEducationAdapter = createEntityAdapter<HighEducationState>();

const highEducationSlice = createSlice({
  name: "highEducation",
  initialState: highEducationAdapter.getInitialState(initialState),
  reducers: {
    setViewedStep: (state, action: PayloadAction<number>) => {
      state.viewedStep = action.payload;
    },
    resetHighEducationState: (state) => {
      state.viewedStep = 0;
      state.id = null;
      state.application = {
        educationLevel: "",
        educationLevelName: "",
        directionAndSpeciality: "",
        directionAndSpecialityName: "",
        profile: "",
        profileName: "",
        form: "",
        formName: "",
        financing: "",
        financingName: "",
        program_uid: "",
        kvota: "",
      };
      state.entranceExams = {
        exams: [],
      };
      state.discountsAndBenefits = {
        achievements: [],
        discountsAndBenefits: "0",
        discountsPercentage: null,
        discountsConfirm: null,
      };
      state.dataLoadingStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetHighEducation.pending, (state) => {
      state.dataLoadingStatus = "LOADING";
    });
    builder.addCase(fetchGetHighEducation.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.dataLoadingStatus = "ERROR";
        return;
      }
      state.dataLoadingStatus = "COMPLETE";
      if (!action.payload.payload) {
        return;
      }

      const convertedData = convertHighEducationFromSavedData(action.payload.payload);
      state.id = convertedData.id;
      if (convertedData.application) {
        state.application = convertedData.application;
      }
      if (convertedData.entranceExams) {
        state.entranceExams = convertedData.entranceExams;
      }
      if (convertedData.discountsAndBenefits) {
        state.discountsAndBenefits = convertedData.discountsAndBenefits;
      }
    });
  },
});

export const { setViewedStep, resetHighEducationState } = highEducationSlice.actions;
export default highEducationSlice.reducer;
