import styled from "styled-components";
import colors from "../../../core/styles/colors";
import zIndex from "../../../core/styles/z-index";
import sizes from "../../../core/styles/sizes";

export const Container = styled.div`
  position: absolute;
  z-index: ${zIndex.modal};
  top: 32px;
  right: 56px;

  @media all and (${sizes.mobile}) {
    display: none;
  }
`;

export const ShowAccountPopupButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    p {
      color: ${colors.gray.gray300};
    }
    svg {
      path {
        fill: ${colors.gray.gray300};
      }
    }
  }
  p {
    font-family: Gilroy, serif;
    font-weight: 600;
    color: ${colors.black.general};
    padding: 0 35px;
    margin: 0;
    transition: ease-in-out 0.3s;
  }
  svg {
    path {
      transition: ease-in-out 0.3s;
    }
  }
`;

export const AccountPopupContainer = styled.div`
  min-width: calc(290px - 48px);
  height: fit-content;
  padding: 16px 24px;
  background: ${colors.white.general};
  box-shadow: 0 0 15px ${colors.white.white700};
  border-radius: 10px;
  .header {
    width: 100%;
    display: grid;
    grid-template:
      "name hideButton"
      "role role";
    grid-template-columns: 1fr auto;
    align-items: center;
    cursor: pointer;
    &:hover {
      svg {
        path {
          fill: ${colors.red.hover};
        }
      }
    }
    b {
      grid-area: name;
      font-family: "Gilroy", serif;
      font-weight: 600;
      font-size: 14px;
    }
    p {
      grid-area: role;
      font-family: "Proxima Nova Lt", serif;
      font-weight: 300;
      font-size: 12px;
      margin: 0;
    }
    svg {
      grid-area: hideButton;
      transform: rotate(180deg);
      path {
        fill: ${colors.red.general};
        transition: ease-in-out 0.3s;
      }
    }
  }
  button {
    width: 100%;
    margin-top: 16px;
    font-size: 16px;
  }
`;
