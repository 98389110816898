import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  fetchGetAdditionalEducationDictionaries,
  fetchGetDictionaries,
  fetchGetPostgraduateEducationDictionaries,
  fetchGetProgramDictionaries,
} from "./dictionariesThunk";
import Dictionaries from "../../../core/interfaces/dictionaries/Dictionaries";
import loading from "../../../core/types/loading";
import ProgramDictionary from "../../../core/interfaces/dictionaries/programDictionaries/ProgramDictionary";
import AdditionalEducationDictionary from "../../../core/interfaces/dictionaries/additionalEducationDictionaries/AdditionalEducationDictionary";

interface DictionariesState {
  dictionaries?: Dictionaries;
  dataLoadingStatus: loading;
  programDictionaries: ProgramDictionary[];
  programDictionariesLoadingStatus: loading;
  additionalEducationDictionaries: AdditionalEducationDictionary;
  additionalEducationLoadingStatus: loading;
  postgraduateEducationDictionaries: ProgramDictionary[];
  postgraduateEducationLoadingStatus: loading;
}

const initialState: DictionariesState = {
  dictionaries: null,
  dataLoadingStatus: null,
  programDictionaries: null,
  programDictionariesLoadingStatus: null,
  additionalEducationDictionaries: null,
  additionalEducationLoadingStatus: null,
  postgraduateEducationDictionaries: null,
  postgraduateEducationLoadingStatus: null,
};

const dictionariesAdapter = createEntityAdapter<DictionariesState>();

const dictionariesSlice = createSlice({
  name: "dictionaries",
  initialState: dictionariesAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    // DICTIONARIES
    builder.addCase(fetchGetDictionaries.pending, (state) => {
      state.dataLoadingStatus = "LOADING";
    });
    builder.addCase(fetchGetDictionaries.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.dataLoadingStatus = "ERROR";
        return;
      }
      state.dataLoadingStatus = "COMPLETE";

      state.dictionaries = action.payload.payload;
    });
    // PROGRAM DICTIONARIES
    builder.addCase(fetchGetProgramDictionaries.pending, (state) => {
      state.programDictionariesLoadingStatus = "LOADING";
    });
    builder.addCase(fetchGetProgramDictionaries.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.programDictionariesLoadingStatus = "ERROR";
        return;
      }
      state.programDictionariesLoadingStatus = "COMPLETE";

      state.programDictionaries = action.payload.payload;
    });
    // ADDITIONAL EDUCATION DICTIONARIES
    builder.addCase(fetchGetAdditionalEducationDictionaries.pending, (state) => {
      state.additionalEducationLoadingStatus = "LOADING";
    });
    builder.addCase(fetchGetAdditionalEducationDictionaries.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.additionalEducationLoadingStatus = "ERROR";
        return;
      }
      state.additionalEducationLoadingStatus = "COMPLETE";

      state.additionalEducationDictionaries = action.payload.payload;
    });
    // POSTGRADUATE EDUCATION DICTIONARIES
    builder.addCase(fetchGetPostgraduateEducationDictionaries.pending, (state) => {
      state.postgraduateEducationLoadingStatus = "LOADING";
    });
    builder.addCase(fetchGetPostgraduateEducationDictionaries.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.postgraduateEducationLoadingStatus = "ERROR";
        return;
      }
      state.postgraduateEducationLoadingStatus = "COMPLETE";

      state.postgraduateEducationDictionaries = action.payload.payload.programs;
    });
  },
});

export default dictionariesSlice.reducer;
