import store from "../../../../../../../store/store";
import ProgramDictionary from "../../../../../../../core/interfaces/dictionaries/programDictionaries/ProgramDictionary";

const getSelectedProgram = (
  level: string,
  speciality: string,
  form: string,
  financing: string
): ProgramDictionary => {
  const state = store.getState();
  const { postgraduateEducationDictionaries } = state.coreState.dictionariesState;

  const selected = postgraduateEducationDictionaries?.find(
    (program) =>
      program.level_id === level &&
      program.specialty_id === speciality &&
      program.form_id === form &&
      program.type_budget_id === financing
  );

  return selected;
};

export default getSelectedProgram;
