import React from "react";
import { Control, FieldValues, FormState, UseFormSetValue, useWatch } from "react-hook-form";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import {
  FieldDescription,
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import { FieldData } from "../../../../../../../shared/form/fieldData.styles";
import AddFileButton from "../../../../../../../shared/addFileButton/AddFileButton";
import Select from "../../../../../../../shared/select/Select";
import { useAppSelector } from "../../../../../../../../core/hooks/redux";
import specialConditionsSelectItems from "../../constants/specialConditionsSelectItems";
import nosologySelectItems from "../../constants/nosologySelectItems";
import { SplitAdditionalPostgraduateDataForm } from "../../interfaces/additionalPostgraduateDataForm";
import ovzSelectItems from "./constants/ovzSelectItems";

const HealthLimitationsForm: React.FC<{
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<SplitAdditionalPostgraduateDataForm>;
}> = ({ formState: { errors }, control, setValue }) => {
  const { dictionariesState } = useAppSelector((state) => state.coreState);
  const hasOvzWatch = useWatch({ control, name: "hasOvz" });
  const disabilityTypeWatch = useWatch({ control, name: "disabilityType" });

  React.useEffect(() => {
    if (disabilityTypeWatch === "0") {
      setValue("disabilityDocument", null);
    }
  }, [hasOvzWatch]);

  return (
    <FormContainer>
      <FieldInfo>
        <FieldName>Ограниченные возможности</FieldName>
        {errors.hasOvz && <FieldError>{errors.hasOvz?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите вариант ответа"
          items={ovzSelectItems}
          control={control}
          name="hasOvz"
          options={{
            required: "Обязательное поле",
          }}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Инвалидность</FieldName>
        {errors.disabilityType && (
          <FieldError>{errors.disabilityType?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        {dictionariesState?.dictionaries && (
          <Select
            className="defaultSelect"
            placeholder="Выберите вариант ответа"
            items={[{ name: "Нет", id: "0" }, ...dictionariesState.dictionaries.category_ovz].map(
              (item) => ({
                label: item.name,
                value: item.id,
              })
            )}
            control={control}
            name="disabilityType"
            options={{
              required: "Обязательное поле",
            }}
          />
        )}
      </FieldData>
      {disabilityTypeWatch !== "0" && (
        <>
          <FieldInfo>
            <FieldName>Подтверждение инвалидности</FieldName>
            {errors.disabilityDocument && (
              <FieldError>{errors.disabilityDocument?.message as string}</FieldError>
            )}
          </FieldInfo>
          <FieldData>
            <AddFileButton
              name="disabilityDocument"
              control={control}
              setValue={setValue}
              options={{
                required: "Обязательное поле",
              }}
              accept={"image/*"}
            >
              Загрузка документа
            </AddFileButton>
          </FieldData>
        </>
      )}
      <FieldInfo>
        <FieldName>Специальные условия</FieldName>
        <FieldDescription>
          необходимые для прохождения вступительного испытания, исходя из вашей нозологии
        </FieldDescription>
        {errors.specialConditions && (
          <FieldError>{errors.specialConditions?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите вариант ответа"
          items={specialConditionsSelectItems}
          control={control}
          options={{
            required: "Обязательное поле",
          }}
          name="specialConditions"
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Нозология</FieldName>
        {errors.nosology && <FieldError>{errors.nosology?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите вариант ответа"
          items={nosologySelectItems}
          control={control}
          options={{
            required: "Обязательное поле",
          }}
          name="nosology"
        />
      </FieldData>
    </FormContainer>
  );
};

export default HealthLimitationsForm;
