import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPostgraduateEducationFormRequest } from "../../../../http/requests/applications/postgraduateEducation.http";

export const fetchGetPostgraduateEducation = createAsyncThunk(
  "postgraduateEducation/get",
  async (applicationId: number) => {
    const response = await getPostgraduateEducationFormRequest(applicationId);
    return response.data;
  }
);
