import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import PostForm from "./components/PostForm";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import OrganizationForm from "../formType/components/legalPerson/OrganizationInfoForm";
import AddressForm from "../formType/components/legalPerson/AddressForm";
import NotificationCard from "../../notificationCard/NotificationCard";
import { useAppDispatch } from "../../../../../../../../core/hooks/redux";
import { submitNotificationRequest } from "../../../../../../../../http/requests/notifications.http";
import convertFormData from "./utils/convertFormData";
import { removeNotification } from "../../../../../../../../store/modules/notifications/notificationsSlice";
import Notification from "../../../../../interfaces/Notification";
import degreeType from "../../../../../../../../core/types/degreeType";
import AdditionalEducationLegalPersonForm from "./interfaces/AdditionalEducationLegalPersonForm";
import defaultFormValues from "./constants/defaultFormValues";

const AdditionalEducationLegalPersonType: React.FC<{
  notification: Notification;
  degree: degreeType;
}> = ({ notification }) => {
  const dispatch = useAppDispatch();
  const [disableResponseButton, setDisableResponseButton] = React.useState(false);
  const { handleSubmit, ...form } = useForm<FieldValues>({
    defaultValues: defaultFormValues,
  });

  const submitHandler = (data: FieldValues): void => {
    setDisableResponseButton(true);

    submitNotificationRequest({
      notify_id: notification.uid,
      type: notification.notification_type,
      files: [],
      answer: 3,
      form: convertFormData(data as AdditionalEducationLegalPersonForm),
    }).then((res) => {
      setDisableResponseButton(false);
      if (res.data.error) {
        return;
      }

      dispatch(removeNotification(notification.uid));
    });
  };

  return (
    <NotificationCard
      title={notification?.document_name}
      comment={notification.comment}
      handleSubmit={handleSubmit(submitHandler)}
      disableResponseButton={disableResponseButton}
    >
      <FormContainer leftContent noScrollContent>
        <OrganizationForm {...form} />
        <AddressForm {...form} />
        <PostForm {...form} authorityList={notification.dict} />
      </FormContainer>
    </NotificationCard>
  );
};

export default AdditionalEducationLegalPersonType;
