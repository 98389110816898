import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import AlertInfo from "../../../ui/shared/alertService/interfaces/alertInfo";
import AlertProps from "../../../ui/shared/alertService/interfaces/alertProps";

interface AlertState {
  alertsIndex: number;
  alerts: AlertInfo[];
}

const initialState: AlertState = {
  alertsIndex: 0,
  alerts: [],
};

const alertAdapter = createEntityAdapter<AlertState>();

const alertSlice = createSlice({
  name: "alert",
  initialState: alertAdapter.getInitialState(initialState),
  reducers: {
    addAlert: (state, action: PayloadAction<AlertProps>) => {
      // add
      state.alertsIndex += 1;
      state.alerts = [
        ...state.alerts,
        {
          ...action.payload,
          id: state.alertsIndex,
          autoClose: action.payload.autoClose ?? 3000,
        },
      ];
    },
    removeAlertById: (state, action: PayloadAction<number>) => {
      const newAlerts = [...state.alerts];

      newAlerts.splice(
        state.alerts.findIndex((alert) => alert.id === action.payload),
        1
      );

      state.alerts = newAlerts;
    },
  },
});

export const { addAlert, removeAlertById } = alertSlice.actions;
export default alertSlice.reducer;
