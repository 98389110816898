import styled from "styled-components";
import colors from "../../../core/styles/colors";
import sizes from "../../../core/styles/sizes";

export const Component = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media all and (${sizes.mobile}) {
    flex-direction: column;
  }
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
`;

export const Line = styled.div<{ $active: boolean; complete: boolean }>`
  width: 30px;
  height: 4px;
  background: ${(props): string =>
    props?.$active || props?.complete ? colors.red.general : colors.white.hoverWhite2};
`;

export const Info = styled.div`
  display: flex;
  align-items: stretch;
`;

export const Number = styled.div<{ complete: boolean }>`
  width: 32px;
  height: 32px;
  padding: 0;
  background: ${(props): string =>
    props?.complete ? colors.red.general : colors.white.hoverWhite2};
  border-radius: 32px;
  font-size: 14px;
  font-weight: 400;
  color: ${(props): string => (props?.complete ? colors.white.general : colors.gray.gray200)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.span`
  height: 36px;
  border: 2px solid ${colors.red.general};
  border-radius: 40px;
  font-size: 18px;
  font-weight: 600;
  color: ${colors.red.general};
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

export const MobileStep = styled.div`
  width: 100%;
  h1 {
    margin: 16px 0 0 0;
  }
  p {
    width: 100%;
    margin: 2px 0 0 0;
    text-align: center;
    color: ${colors.gray.gray300};
    span {
      color: ${colors.red.general};
    }
  }
`;
