import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import loading from "../../../core/types/loading";

interface SignatureState {
  viewedStep: number;
  dataLoadingStatus: loading;
}

const initialState: SignatureState = {
  viewedStep: 0,
  dataLoadingStatus: null,
};

const signatureAdapter = createEntityAdapter<SignatureState>();

const signatureSlice = createSlice({
  name: "signature",
  initialState: signatureAdapter.getInitialState(initialState),
  reducers: {
    setViewedStep: (state, action: PayloadAction<number>) => {
      state.viewedStep = action.payload;
    },
  },
});

export const { setViewedStep } = signatureSlice.actions;
export default signatureSlice.reducer;
