import React, { useRef } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import fullLogoImg from "../../../core/static/img/full-logo.svg";
import { ReactComponent as MenuIcon } from "../../../core/static/icons/menu.svg";
import {
  Container,
  Navigation as StyledNavigation,
  NavigationHeader,
  Links,
  Link,
  Arrow,
  NavigationBody,
  Actions,
} from "./navigation.styles";
import { Link as LinkInterface } from "./interfaces/link";
import { useAppDispatch, useAppSelector } from "../../../core/hooks/redux";
import Subgroup from "./components/subgroup/Subgroup";
import useOutsideClick from "../../../core/hooks/useOutsideClick";
import { GrayToBlackButton } from "../native/button.styles";
import getLinks from "./constants/getLinks";
import { logout } from "../../../store/core/account/accountSlice";
import { logoutRequest } from "../../../http/requests/account.http";
import { resetProfileState } from "../../../store/modules/profile/profileForm/profileFormSlice";
import { resetAdditionalDataState } from "../../../store/modules/applications/additionalData/additionalDataSlice";
import { resetHighEducationState } from "../../../store/modules/applications/highEducation/highEducationSlice";
import { resetAdditionalEducationState } from "../../../store/modules/applications/additionalEducation/additionalEducationSlice";
import { resetAdditionalAeDataState } from "../../../store/modules/applications/additionalAEData/additionalAEDataSlice";
import { resetAdditionalPostgraduateDataState } from "../../../store/modules/applications/additionalPostgraduateData/additionalPostgraduateDataSlice";
import { resetPostgraduateEducationState } from "../../../store/modules/applications/postgraduateEducation/postgraduateEducationSlice";

const Navigation: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navRef = useRef(null);
  const { accountState } = useAppSelector((state) => state.coreState);
  const {
    applicationsState: { additionalDataState },
    notificationsState,
  } = useAppSelector((state) => state.modulesState);
  const [showLinks, setShowLinks] = React.useState(false);
  const [activeLink, setActiveLink] = React.useState<string>(null);
  const [viewedLinks, setViewedLinks] = React.useState(getLinks());

  useOutsideClick(navRef, () => setShowLinks(false), showLinks);

  React.useEffect(() => {
    setActiveLink(`/${location.pathname.split("/")[1]}`);
  }, [location]);

  React.useEffect(() => {
    setViewedLinks(getLinks());
  }, [accountState, additionalDataState, notificationsState.info]);

  const logoutHandler = (): void => {
    setShowLinks(false);
    logoutRequest().then((res) => {
      if (res.data.error) {
        return;
      }
      dispatch(logout());
      dispatch(resetProfileState());
      dispatch(resetAdditionalDataState());
      dispatch(resetAdditionalAeDataState());
      dispatch(resetAdditionalPostgraduateDataState());
      dispatch(resetHighEducationState());
      dispatch(resetAdditionalEducationState());
      dispatch(resetPostgraduateEducationState());
    });
  };

  const getSubgroups = (link: LinkInterface): React.ReactNode => {
    if (!link?.subgroups?.length) {
      return;
    }

    const getKey = (links: LinkInterface[]): string =>
      links.reduce((acc, current) => {
        acc += current.path;
        return acc;
      }, "");

    if (link?.displaySubgroupsCondition) {
      if (link?.displaySubgroupsCondition(activeLink)) {
        return link.subgroups.map((subgroup) => (
          <Subgroup
            key={getKey(subgroup?.links)}
            onClick={(): void => setShowLinks(false)}
            subgroup={subgroup}
          />
        ));
      }
      return;
    }

    if (activeLink === link.path) {
      return link.subgroups.map((subgroup) => (
        <Subgroup
          key={getKey(subgroup?.links)}
          onClick={(): void => setShowLinks(false)}
          subgroup={subgroup}
        />
      ));
    }
  };

  return (
    <StyledNavigation ref={navRef} $show={showLinks}>
      <Container>
        <NavigationHeader>
          <img src={fullLogoImg} alt="Logo" />
          {!!accountState?.token && (
            <MenuIcon
              className="openNavigationButton"
              onClick={(): void => setShowLinks((prev) => !prev)}
            />
          )}
        </NavigationHeader>
        <NavigationBody $show={showLinks}>
          <Links>
            {viewedLinks.map(
              (link) =>
                !link.hide && (
                  <Link
                    key={link.path}
                    $active={activeLink === link.path}
                    disabled={link.isDisabled}
                    $text={link.isText}
                  >
                    {link.isDisabled ? (
                      <span>{link.text}</span>
                    ) : (
                      <>
                        {activeLink === link.path && <Arrow />}
                        {link.isText ? (
                          <span>{link.text}</span>
                        ) : (
                          <RouterLink
                            key={link.path}
                            to={link.path}
                            onClick={(): void => setShowLinks(false)}
                          >
                            {link.text}
                          </RouterLink>
                        )}
                        {getSubgroups(link)}
                      </>
                    )}
                  </Link>
                )
            )}
          </Links>
          <Actions>
            <div className="userInfo">
              {accountState?.user?.name ? (
                <b>{accountState?.user?.name}</b>
              ) : (
                <b>{accountState?.user?.email}</b>
              )}
            </div>
            <GrayToBlackButton onClick={logoutHandler}>Выйти</GrayToBlackButton>
          </Actions>
        </NavigationBody>
      </Container>
    </StyledNavigation>
  );
};

export default Navigation;
