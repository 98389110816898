import { AdditionalDataForm, SplitAdditionalDataForm } from "../interfaces/additionalDataForm";
import convertFile from "../../../../../../../core/utils/convertFile";

const convertSplitAdditionalDataForm = (data: SplitAdditionalDataForm): AdditionalDataForm => ({
  educationInfo: {
    educationLevel: data.educationLevel,
    educationRegion: data.educationRegion,
    educationDocument: convertFile("to", data.educationDocument),
    educationDocumentAppendix: convertFile("to", data.educationDocumentAppendix),
    transferredFromAnotherUniversity: data.transferredFromAnotherUniversity,
  },
  educationDocument: {
    educationDocumentType: data.educationDocumentType,
    serial: data.serial,
    number: data.number,
    issuedByWhom: data.issuedByWhom,
    dateOfIssue: data.dateOfIssue,
    ownerCode: data.ownerCode,
  },
  trainingCertificate: {
    trainingCertificate: convertFile("to", data.trainingCertificate) ?? null,
    nameOfUniversity: data.nameOfUniversity,
    certificateRegistrationNumber: data.certificateRegistrationNumber,
    certificateDateIssue: data.certificateDateIssue,
    certificateAboutOfStudentStatus:
      convertFile("to", data.certificateAboutOfStudentStatus) ?? null,
    certificateAboutOfUniversityLicense:
      convertFile("to", data.certificateAboutOfUniversityLicense) ?? null,
  },
  healthLimitations: {
    hasOvz: data.hasOvz,
    disabilityType: data.disabilityType,
    disabilityDocument: convertFile("to", data.disabilityDocument) ?? null,
    specialConditions: data.specialConditions,
    nosology: data.nosology,
  },
});

export default convertSplitAdditionalDataForm;
