import axios from "axios";
import environments from "../env/environments";
import { logout } from "../store/core/account/accountSlice";
import store from "../store/store";
import { resetProfileState } from "../store/modules/profile/profileForm/profileFormSlice";
import { resetAdditionalDataState } from "../store/modules/applications/additionalData/additionalDataSlice";
import { resetHighEducationState } from "../store/modules/applications/highEducation/highEducationSlice";
import { resetAdditionalEducationState } from "../store/modules/applications/additionalEducation/additionalEducationSlice";
import { resetAdditionalAeDataState } from "../store/modules/applications/additionalAEData/additionalAEDataSlice";
import { resetAdditionalPostgraduateDataState } from "../store/modules/applications/additionalPostgraduateData/additionalPostgraduateDataSlice";
import { resetPostgraduateEducationState } from "../store/modules/applications/postgraduateEducation/postgraduateEducationSlice";

// AUTH API
export const $authApi = axios.create({
  baseURL: environments.API_URL,
});

$authApi.interceptors.request.use((config) => {
  return config;
});

// BASE API
export const $baseApi = axios.create({
  baseURL: environments.API_URL,
});

$baseApi.interceptors.request.use((config) => {
  return {
    ...config,
    data: {
      ...config.data,
      token: localStorage.getItem("token"),
    },
  };
});

$baseApi.interceptors.response.use((res) => {
  if (res.data.error === true && res.data.text === "Пользователь не авторизован") {
    store.dispatch(logout());
    store.dispatch(resetProfileState());
    store.dispatch(resetAdditionalDataState());
    store.dispatch(resetAdditionalAeDataState());
    store.dispatch(resetAdditionalPostgraduateDataState());
    store.dispatch(resetHighEducationState());
    store.dispatch(resetAdditionalEducationState());
    store.dispatch(resetPostgraduateEducationState());
  }

  return res;
});
