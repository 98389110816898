import AdditionalEducationLegalPersonForm from "../interfaces/AdditionalEducationLegalPersonForm";

const defaultFormValues: AdditionalEducationLegalPersonForm = {
  // ADDRESS
  residingCoincidesRegistration: false,
  registerAddress: {
    // Адрес регистрации
    country: "",
    region: "",
    city: "",
    street: "",
    house: "",
    flat: "",
    index: "",
  },
  residingAddress: {
    // Адрес проживания
    country: "",
    region: "",
    city: "",
    street: "",
    house: "",
    flat: "",
    index: "",
  },
  // ORGANIZATION INFO
  organizationName: "", // Наименование организации
  inn: "", // ИНН
  kpp: "", // КПП
  ogrn: "", // ОГРН
  bankName: "", // Наименование банка
  paymentAccount: "", // Рассчетный счет
  bik: "", // БИК
  correspondentAccount: "", // Корреспондентский счет
  organizationPhone: "", // Телефон организации
  organizationEmail: "", // Email организации
  // POST
  post: "", // Должность
  basisOfAuthority: "", // Основание полномочий
  exactBasisOfAuthority: "", // Точное основание полномочий (если выбрано "Иное" в основании полномочий)
  lastnameOfAuthorizedPerson: "", // Фамилия уполномоченного лица
  firstnameOfAuthorizedPerson: "", // Имя уполномоченного лица
  middleNameOfAuthorizedPerson: "", // Отчество уполномоченного лица
  hasNoMiddleNameOfAuthorizedPerson: "", // Нет отчества
};

export default defaultFormValues;
