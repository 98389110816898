import React from "react";
import { AddFileInfoStyles } from "./addFileInfo.styles";

const AddFileInfo: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ ...attrs }) => {
  return (
    <AddFileInfoStyles {...attrs}>
      <b>Как добавить несколько файлов</b>
      <p>
        Для загрузки нескольких файлов зажмите <code>ctrl</code> или <code>cmd</code>
      </p>
    </AddFileInfoStyles>
  );
};

export default AddFileInfo;
