import React from "react";
import {
  Control,
  FieldValues,
  FormState,
  UseFormRegister,
  useWatch,
  Controller,
  UseFormSetValue,
} from "react-hook-form";
import InputMask from "react-input-mask";
import { Input } from "../../../../shared/native/input.styles";
import Checkbox from "../../../../shared/checkbox/Checkbox";
import AddFileButton from "../../../../shared/addFileButton/AddFileButton";
import Modal from "../../../../shared/modal/Modal";
import PhotoRequirementsModal from "./photoRequirementsModal/PhotoRequirementsModal";
import { FormContainer } from "../../../../shared/form/form.styles";
import { FieldData, AddFileContainer } from "../../../../shared/form/fieldData.styles";
import {
  FieldError,
  FieldInfo,
  FieldLink,
  FieldName,
} from "../../../../shared/form/fieldInfo.styles";
import ProfileForm from "../../interfaces/ProfileForm";
import emailRegexp from "../../../../../core/regexp/emailRegexp";
import noUnderlines from "../../../../../core/regexp/noUnderlines";
import { useAppSelector } from "../../../../../core/hooks/redux";
import isSnils from "../../../../../core/validators/isSnils";

const ContactsDataForm: React.FC<{
  register: UseFormRegister<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<ProfileForm>;
}> = ({ register, control, formState: { errors }, setValue }) => {
  const { accountState } = useAppSelector((state) => state.coreState);
  const hasNoSnilsWatch = useWatch({ control, name: "hasNoSnils" });
  const emailWatch = useWatch({ control, name: "email" });
  const [showPhotoRequirementsModal, setShowPhotoRequirementsModal] = React.useState(false);

  React.useEffect(() => {
    if (accountState.user?.email && !emailWatch) {
      setValue("email", accountState.user.email);
    }
  }, [accountState]);

  React.useEffect(() => {
    if (hasNoSnilsWatch) {
      setValue("snils", "");
    }
  }, [hasNoSnilsWatch]);

  return (
    <>
      <FormContainer>
        <FieldInfo>
          <FieldName>СНИЛС</FieldName>
          {errors.snils && <FieldError>{errors.snils?.message as string}</FieldError>}
        </FieldInfo>
        <FieldData>
          <Controller
            name="snils"
            control={control}
            rules={{
              required: !hasNoSnilsWatch ? "Обязательное поле" : false,
              pattern: {
                value: noUnderlines,
                message: "Неполное значение",
              },
              validate: (value) => (!hasNoSnilsWatch ? isSnils(value) : true),
            }}
            render={({ field: { value, onChange } }): React.ReactElement => (
              <InputMask
                mask="999-999-999 99"
                value={value}
                onChange={onChange}
                disabled={hasNoSnilsWatch}
              >
                <Input
                  className="defaultInput"
                  placeholder="XXX-XXX-XXX YY"
                  hasErrors={!!errors.snils}
                />
              </InputMask>
            )}
          />
          <Checkbox
            style={{ marginLeft: 8 }}
            label="Отсутствует"
            control={control}
            name="hasNoSnils"
          />
        </FieldData>
        <FieldInfo>
          <FieldName>Email</FieldName>
          {errors.email && <FieldError>{errors.email?.message as string}</FieldError>}
        </FieldInfo>
        <FieldData>
          <Input
            className="defaultInput"
            placeholder="Начните вводить"
            hasErrors={!!errors.email}
            {...register("email", {
              required: "Обязательное поле",
              pattern: {
                value: emailRegexp,
                message: "Неверный формат",
              },
            })}
          />
        </FieldData>
        <FieldInfo>
          <FieldName>Телефон</FieldName>
          {errors.phone && <FieldError>{errors.phone?.message as string}</FieldError>}
        </FieldInfo>
        <FieldData>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: "Обязательное поле",
              pattern: {
                value: noUnderlines,
                message: "Неполное значение",
              },
            }}
            render={({ field: { value, onChange } }): React.ReactElement => (
              <InputMask mask="+9 (999) 999-99-99" value={value} onChange={onChange}>
                <Input
                  className="defaultInput"
                  placeholder="+X (XXX) XXX-XX-XX"
                  hasErrors={!!errors.phone}
                />
              </InputMask>
            )}
          />
        </FieldData>
        <FieldInfo>
          <FieldName>Ваше фото</FieldName>
          {errors.photo && <FieldError>{errors.photo?.message as string}</FieldError>}
          <FieldLink type="button" onClick={(): void => setShowPhotoRequirementsModal(true)}>
            Требования к фото
          </FieldLink>
        </FieldInfo>
        <FieldData>
          <AddFileContainer>
            <AddFileButton
              accept={"image/*"}
              name="photo"
              control={control}
              options={{ required: "Обязательное поле" }}
              setValue={setValue}
            >
              Прикрепить
            </AddFileButton>
          </AddFileContainer>
        </FieldData>
      </FormContainer>
      {showPhotoRequirementsModal && (
        <Modal closeModal={(): void => setShowPhotoRequirementsModal(false)}>
          <PhotoRequirementsModal />
        </Modal>
      )}
    </>
  );
};

export default ContactsDataForm;
