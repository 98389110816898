import Step from "../../../../../../shared/stepper/interfaces/Step";
import EducationForm from "../forms/educationForm/EducationForm";
import WorkForm from "../forms/workForm/WorkForm";

const steps: Step[] = [
  {
    title: "Сведения об образовании",
    element: (props: any) => <EducationForm {...props} />,
  },
  {
    title: "Сведения о трудоустройстве",
    element: (props: any) => <WorkForm {...props} />,
  },
];

export default steps;
