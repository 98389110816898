import styled from "styled-components";
import colors from "../../../../../core/styles/colors";
import sizes from "../../../../../core/styles/sizes";

export const ApplicationsListContainer = styled.div`
  width: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media all and (${sizes.mobile}) {
    height: calc(100% - 200px);
    padding: 160px 0 40px 0;
    justify-content: space-between;
  }
`;

export const ApplicationsListContent = styled.div`
  width: 100%;
  h1 {
    width: calc(100% - 64px);
    padding: 0 32px;
    margin: 0;
  }
  h2 {
    width: 80%;
    margin-top: 8px;
    padding: 0 10%;
    color: ${colors.black.lightBlack};
    font-weight: 300;
  }

  @media all and (${sizes.mobile}) {
    width: calc(100% - 96px);
    background: ${colors.white.general};
    border-radius: 10px;
    padding: 25px 32px;
  }
`;

export const ApplicationsList = styled.div`
  width: calc(100% - 16px);
  padding: 24px 8px;
  max-height: calc(55vh - 24px);
  overflow-y: auto;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 250px);
  grid-gap: 16px;

  @media all and (${sizes.mobile}) {
    height: fit-content;
    max-height: 50vh;
  }

  @media all and (max-width: 570px) {
    grid-template-columns: 1fr;
  }
`;

export const ApplicationCardContent = styled.div<{ isNew?: boolean }>`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: ${(props): string => (props.isNew ? "center" : "flex-start")};
  justify-content: ${(props): string => (props.isNew ? "center" : "flex-start")};
  ${(props): string =>
    props.isNew &&
    `
    p {
    width: calc(100% - 8px);
    padding: 4px 0;
    margin: 0;
    font-family: Gilroy, serif;
    font-weight: 300;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    color: ${colors.gray.gray300};
  }
  `}
`;

export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  b {
    font-family: Gilroy, serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
  }
  p {
    margin: 0;
    font-family: Gilroy, serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.gray.gray300};
  }
`;

export const CardBody = styled.div`
  width: 100%;
  flex-grow: 1;
  b {
    font-family: Gilroy, serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
  }
  p {
    margin: 0;
    font-family: Gilroy, serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.gray.gray300};
  }
`;

export const CardFooter = styled.div`
  width: 100%;
  p {
    margin: 8px 0 0 0;
    text-transform: uppercase;
    font-family: Gilroy, serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.red.general};
  }
`;
