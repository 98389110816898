import Step from "../../../../../../shared/stepper/interfaces/Step";
import EducationInfoForm from "../forms/educationInfoForm/EducationInfoForm";
import EducationDocumentForm from "../forms/educationDocumentForm/EducationDocumentForm";
import TrainingCertificateForm from "../forms/trainingCertificateForm/TrainingCertificateForm";
import HealthLimitationsForm from "../forms/healthLimitationsForm/healthLimitationsForm";

const steps: Step[] = [
  {
    title: "Сведения об образовании",
    element: (props: any) => <EducationInfoForm {...props} />,
  },
  {
    title: "Документ об образовании",
    element: (props: any) => <EducationDocumentForm {...props} />,
  },
  {
    title: "Справка об обучении",
    element: (props: any) => <TrainingCertificateForm {...props} />,
  },
  {
    title: "ОВЗ",
    element: (props: any) => <HealthLimitationsForm {...props} />,
  },
];

export default steps;
