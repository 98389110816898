import React from "react";
import InputMask from "react-input-mask";
import { Control, Controller, FieldValues, FormState } from "react-hook-form";
import { FormDescription, FormItem } from "../../signatureForm.styles";
import { Input } from "../../../../shared/native/input.styles";
import { GrayToRedLinkedButton } from "../../../../shared/native/button.styles";
import noUnderlines from "../../../../../core/regexp/noUnderlines";
import { sendVerificationCodeRequest } from "../../../../../http/requests/signature.http";
import colors from "../../../../../core/styles/colors";

const SubmitCode: React.FC<{ control: Control; formState: FormState<FieldValues> }> = ({
  control,
  formState: { errors },
}) => {
  const [repeatSendCodeText, setRepeatSendCodeText] = React.useState(null);

  const repeatSendCode = (): void => {
    sendVerificationCodeRequest("sms").then((res) => {
      setRepeatSendCodeText(res.data.text);
    });
  };

  return (
    <>
      <FormDescription>
        На ваш мобильный телефон отправлен код подтверждения, который необходимо вставить в поле для
        ввода и подписать документ.
        <hr />
        <span>!</span>Если номер вашего телефона не принадлежит Российской Федерации, проверьте вашу
        электронную почту, код автоматически был отправлен на нее.
      </FormDescription>
      <FormItem>
        <p>Код подтверждения</p>
        <Controller
          name="code"
          control={control}
          rules={{
            required: "Обязательное поле",
            pattern: {
              value: noUnderlines,
              message: "Неполное значение",
            },
          }}
          render={({ field: { value, onChange } }): React.ReactElement => (
            <InputMask mask="****" value={value} onChange={onChange}>
              <Input placeholder="XXXX" hasErrors={!!errors.code} />
            </InputMask>
          )}
        />
      </FormItem>
      {!!repeatSendCodeText && (
        <FormDescription
          style={{
            margin: "4px 0 0 16px",
            color:
              repeatSendCodeText === "Код успешно отправлен"
                ? colors.green.general
                : colors.red.dark,
          }}
        >
          {repeatSendCodeText}
        </FormDescription>
      )}
      <GrayToRedLinkedButton
        type="button"
        style={{ margin: "8px 0 0 16px" }}
        onClick={repeatSendCode}
      >
        Не получил код, отправить повторно
      </GrayToRedLinkedButton>
    </>
  );
};

export default SubmitCode;
