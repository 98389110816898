import React from "react";
import { Control, FieldValues, FormState, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { FormContainer } from "../../../../../../../../../shared/form/form.styles";
import OrganizationForm from "../legalPerson/OrganizationInfoForm";
import AddressForm from "../legalPerson/AddressForm";
import PostForm from "./PostForm";

const LegalPerson: React.FC<{
  register: UseFormRegister<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<FieldValues>;
}> = (props) => {
  return (
    <FormContainer leftContent noScrollContent>
      <OrganizationForm {...props} />
      <AddressForm {...props} />
      <PostForm {...props} />
    </FormContainer>
  );
};

export default LegalPerson;
