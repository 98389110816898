import React from "react";
import { useNavigate } from "react-router-dom";
import LinkCard from "../../../../../../shared/cards/linkCard/LinkCard";
import {
  ApplicationCardContent,
  CardBody,
  CardFooter,
  CardHeader,
  CardPriority,
} from "../../applicationsListCard.styles";
import formatDate from "../../../../../../../core/utils/formatDate";
import ApplicationInfo from "../../../../../../../core/interfaces/applications/ApplicationInfo";
import degreeType from "../../../../../../../core/types/degreeType";
import PaymentInfo from "../constants/paymentInfo";
import capitalize from "../../../../../../../core/utils/capitalize";

const ApplicationCard: React.FC<{
  application: ApplicationInfo;
  priority?: number;
  degree: degreeType;
  paymentGroup: PaymentInfo;
  className?: string;
}> = ({ application, priority, degree, paymentGroup, className }) => {
  const navigate = useNavigate();

  const applicationClickHandler = (applicationId: string, completed: boolean): void => {
    document.cookie = `lastModified${capitalize(
      degree
    )}EducationApplicationId=${applicationId}; path=/`;
    document.cookie = `lastModified${capitalize(degree)}EducationStep=0; path=/`;
    navigate(`/applications/${degree}-education/${completed ? applicationId : "submission-info"}`);
  };

  return (
    <LinkCard
      key={application.id}
      onClick={(): void => applicationClickHandler(application.id, !!+application.completed)}
      className={className}
    >
      <ApplicationCardContent>
        <CardHeader>
          <b>{degree === "high" ? application.level : ""}</b>
          {paymentGroup.name === "budget" && priority !== null && (
            <CardPriority className="cardPriority">
              Приоритет: <span>{priority}</span>
            </CardPriority>
          )}
        </CardHeader>
        <CardBody>
          <b>{degree === "high" ? application.speciality : application.programm}</b>
          <p>{application.form}</p>
          <p>{degree === "high" ? application.financing : ""}</p>
          <p className="date">{formatDate(application.datetime.split(" ")?.[0])}</p>
        </CardBody>
        <CardFooter />
      </ApplicationCardContent>
    </LinkCard>
  );
};

export default ApplicationCard;
