import styled from "styled-components";
import colors from "../../../core/styles/colors";

export const Button = styled.button`
  height: 48px;
  border-radius: 13px;
  border: none;
  font-family: Gilroy, serif;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  transition: ease-in-out 0.3s;
`;

export const OutlinedButton = styled(Button)<{ initialBackground?: string }>`
  border: 2px solid ${colors.red.general};
  background: ${(props): string => props.initialBackground ?? "none"};
  color: ${colors.red.general};
  text-transform: uppercase;
  &:hover {
    background: ${colors.red.general};
    color: ${colors.white.general};
    svg {
      path {
        fill: ${colors.white.general};
      }
    }
  }
  svg {
    path {
      fill: ${colors.red.general};
    }
  }
`;

export const PrimaryButton = styled(Button)`
  border: 2px solid ${colors.red.general};
  background: ${colors.red.general};
  color: ${colors.white.general};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 0.3s;
  &:hover {
    background: ${colors.red.hover};
  }
  &:disabled {
    border: 2px solid ${colors.gray.gray100};
    background: ${colors.gray.gray100};
    cursor: auto;
  }
`;

export const DashedButton = styled(Button)`
  outline: none;
  width: fit-content;
  height: 48px;
  padding: 0 32px;
  border: 2px dashed ${colors.gray.gray300};
  background: ${colors.white.hoverWhite3};
  border-radius: 10px;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.gray.gray300};
  cursor: pointer;
  transition: ease-in-out 0.3s;
  &:hover {
    border: 2px solid ${colors.red.general};
    background: ${colors.red.general};
    color: ${colors.white.general};
  }
`;

export const GrayToBlackButton = styled(Button)`
  border: 2px solid ${colors.white.hoverWhite};
  background: ${colors.white.hoverWhite};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 2px solid ${colors.black.general};
    background: ${colors.black.general};
    color: ${colors.white.general};
  }
`;

export const GrayToRedButton = styled(Button)`
  border: 2px solid ${colors.white.hoverWhite};
  background: ${colors.white.hoverWhite};
  text-transform: uppercase;
  transition: ease-in-out 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transition: ease-in-out 0.3s;
  }
  &:hover {
    border: 2px solid ${colors.red.general};
    background: ${colors.red.general};
    color: ${colors.white.general};
  }
`;

export const GrayToRedLinkedButton = styled.button`
  width: fit-content;
  padding: 0;
  background: none;
  border: none;
  text-decoration: underline 1px solid ${colors.black.lightBlack};
  font-family: Gilroy, serif;
  font-size: 14px;
  color: ${colors.black.lightBlack};
  cursor: pointer;
  transition: ease-in-out 0.3s;
  &:hover {
    color: ${colors.red.general};
    text-decoration: underline 1px solid ${colors.red.general};
  }
`;
