import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../../../core/hooks/redux";
import UseAlert from "../../../../../../../../core/hooks/useAlert";
import { fetchGetAccount } from "../../../../../../../../store/core/account/accountThunk";
import { Actions, FormPreview, Header } from "../../../../../../../shared/form/formPreview.styles";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import { OutlinedButton, PrimaryButton } from "../../../../../../../shared/native/button.styles";
import {
  FieldData,
  FieldPhoto,
  FieldText,
} from "../../../../../../../shared/form/fieldData.styles";
import {
  FieldDescription,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import { TitleH2 } from "../../../../../../../shared/native/typography.styles";
import File from "../../../../../../../../core/interfaces/File";
import { setViewedStep } from "../../../../../../../../store/modules/applications/additionalEducation/additionalEducationSlice";
import PreviewField from "../../../../../../../shared/form/interfaces/previewField";
import getCookie from "../../../../../../../../core/utils/getCookie";
import Loader from "../../../../../../../shared/loaders/Loader";
import loading from "../../../../../../../../core/types/loading";
import getAdditionalEducationFields from "../../utils/getAdditionalEducationFields";
import { submitAdditionalEducationFormRequest } from "../../../../../../../../http/requests/applications/additionalEducation.http";
import { fetchGetAdditionalEducation } from "../../../../../../../../store/modules/applications/additionalEducation/additionalEducationThunk";

const PreviewAdditionalEducationForm: React.FC<{ mode: "finallyCheck" | "view" }> = ({ mode }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const alertHandler = UseAlert();
  const previewRef = React.useRef<HTMLDivElement>(null);
  const [viewedFields, setViewedFields] = React.useState<PreviewField[]>([]);
  const [submitLoadingStatus, setSubmitLoadingStatus] = React.useState<loading>(null);
  const { dictionariesState } = useAppSelector((state) => state.coreState);
  const { additionalEducationState } = useAppSelector(
    (state) => state.modulesState.applicationsState
  );

  React.useEffect(() => {
    const cookie = getCookie();
    if (cookie?.lastModifiedAdditionalEducationApplicationId) {
      dispatch(
        fetchGetAdditionalEducation(Number(cookie.lastModifiedAdditionalEducationApplicationId))
      );
    }
  }, []);

  React.useEffect(() => {
    if (!additionalEducationState.application.form) {
      return;
    }

    setViewedFields(
      getAdditionalEducationFields({
        ...additionalEducationState.application,
        ...additionalEducationState.paymentForm,
      })
    );
  }, [additionalEducationState]);

  const backToEdit = (): void => {
    dispatch(setViewedStep(0));
    document.cookie = `lastModifiedAdditionalEducationStep=0; path=/`;
    navigate("/applications/additional-education/form");
  };

  const submitData = (): void => {
    if (!additionalEducationState.id) {
      return;
    }

    setSubmitLoadingStatus("LOADING");
    submitAdditionalEducationFormRequest(additionalEducationState.id).then((res) => {
      if (res.data.error) {
        setSubmitLoadingStatus("ERROR");
        alertHandler({ message: res.data.text, type: "error" });
        return;
      }

      setSubmitLoadingStatus("COMPLETE");
      alertHandler({ message: "Заявление добавлено", type: "success" });
      dispatch(fetchGetAccount());
      navigate("/applications/additional-education/complete");
    });
  };

  return (
    <FormPreview mode={mode}>
      <Header>
        <TitleH2>
          {mode === "finallyCheck" ? "Проверка внесенных данных" : "Данные заявления"}
        </TitleH2>
        {mode === "finallyCheck" && (
          <p>Если вы нашли ошибку в данных, то внесите необходимые правки.</p>
        )}
      </Header>
      <FormContainer ref={previewRef} className="form">
        {additionalEducationState.dataLoadingStatus === "LOADING" ||
        dictionariesState.additionalEducationLoadingStatus === "LOADING" ? (
          <Loader type="blank" />
        ) : (
          viewedFields.map(
            (field) =>
              !field?.hideField && (
                <React.Fragment key={field.name}>
                  <FieldInfo>
                    <FieldName>{field.name}</FieldName>
                    {!!field?.description && (
                      <FieldDescription>{field.description}</FieldDescription>
                    )}
                  </FieldInfo>
                  <FieldData>
                    {field.type === "text" && (
                      <FieldText $gray={!(field.value as string)?.length}>
                        {(field.value as string)?.length ? (field.value as string) : "Отсутствует"}
                      </FieldText>
                    )}
                    {field.type === "files" &&
                      ((field.value as string[])?.every((item) => !item) ? (
                        <FieldText $gray>Отсутствует</FieldText>
                      ) : (
                        (field.value as File[])?.map(
                          (file, index) =>
                            file?.info?.name && (
                              <FieldPhoto
                                key={file.info.name + index.toString()}
                                src={file.file as string}
                                alt={file.info.name}
                              />
                            )
                        )
                      ))}
                  </FieldData>
                </React.Fragment>
              )
          )
        )}
      </FormContainer>
      {mode === "finallyCheck" && (
        <Actions>
          <OutlinedButton onClick={backToEdit}>Редактировать</OutlinedButton>
          <PrimaryButton disabled={submitLoadingStatus === "LOADING"} onClick={submitData}>
            Подтвердить
          </PrimaryButton>
        </Actions>
      )}
    </FormPreview>
  );
};

export default PreviewAdditionalEducationForm;
