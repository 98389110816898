import { AxiosResponse } from "axios";
import { $authApi, $baseApi } from "../index";
import LoginForm from "../../ui/modules/auth/interfaces/loginForm";
import User from "../../core/interfaces/User";
import RequestBody from "../models/RequestBody";
import RememberPasswordForm from "../../ui/modules/auth/interfaces/rememberPasswordForm";
import ResponseBody from "../models/ResponseBody";

export const loginRequest = (
  loginForm: LoginForm
): Promise<AxiosResponse<ResponseBody<{ ss: "0" | "1" }>>> => {
  return $authApi.post<ResponseBody<{ ss: "0" | "1" }>>(
    "",
    new RequestBody("auth.login", loginForm)
  );
};

export const logoutRequest = (): Promise<AxiosResponse<ResponseBody<null>>> => {
  return $authApi.post<ResponseBody<null>>("", new RequestBody("auth.logout", null));
};

export const rememberPasswordRequest = (
  rememberPasswordForm: RememberPasswordForm
): Promise<AxiosResponse<ResponseBody<null>>> => {
  return $authApi.post<ResponseBody<null>>(
    "",
    new RequestBody("auth.remember", rememberPasswordForm)
  );
};

export const getAccountRequest = (): Promise<AxiosResponse<ResponseBody<User>>> => {
  return $baseApi.post<ResponseBody<User>>("", new RequestBody("user.info", null));
};
