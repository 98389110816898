import styled from "styled-components";
import colors from "../../../core/styles/colors";

export const Container = styled.div<{ showPassword: boolean }>`
  position: relative;
  button {
    width: fit-content;
    height: fit-content;
    background: none;
    border: none;
    outline: none;
    position: absolute;
    right: 16px;
    top: ${(props): string => (props.showPassword ? "6px" : "14px")};
    cursor: pointer;
    img {
      transition: filter ease-in-out 0.3s;
      &:hover {
        filter: contrast(5%);
      }
    }
  }
`;

export const StyledPasswordInput = styled.input<{ hasErrors?: boolean }>`
  height: 44px;
  padding: 0 13px;
  border: 2px solid
    ${(props: { hasErrors?: boolean }): string =>
      props.hasErrors ? colors.red.general : colors.white.hoverWhite3};
  background: ${colors.white.hoverWhite3};
  border-radius: 10px;
  font-family: "Gilroy", serif;
  font-size: 18px;
  font-weight: 300;
  color: ${colors.black.general};
  outline: none;
  transition: ease-in-out 0.3s;
  &:focus {
    border: 2px solid
      ${(props: { hasErrors?: boolean }): string =>
        props.hasErrors ? colors.red.general : colors.gray.gray200};
  }
  &::placeholder {
    color: ${colors.gray.gray300};
  }
  &:disabled {
    cursor: not-allowed;
    border-color: ${colors.white.disabled};
    background: ${colors.white.disabled};
    &::placeholder {
      color: ${colors.white.disabledText};
    }
  }
`;
