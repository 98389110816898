import styled from "styled-components";
import sizes from "../../../../../../../core/styles/sizes";

export const AddressContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-gap: 0 12px;
  input[type="text"] {
    width: calc(100% - 30px);
  }
  .flatAndIndex {
    display: flex;
    input:first-child {
      width: 30%;
      margin-right: 8px;
    }
    input:last-child {
      width: 70%;
      margin-right: 0;
    }
  }

  @media all and (${sizes.mobile}) {
    input {
      width: calc(100% - 30px);
    }
  }
`;
