import React from "react";
import { Outlet } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import Navigation from "../navigation/Navigation";
import AccountPopup from "../accountPopup/AccountPopup";
import LeftBackgroundImg from "../../../core/static/img/left_background.png";
import RightBackgroundImg from "../../../core/static/img/right_background.png";
import { Container, Content } from "./mainLayout.styles";
import ErrorPage from "../../modules/service/ErrorPage";

const MainLayout: React.FC = () => {
  return (
    <Container>
      <div className="globalBackground">
        <img src={LeftBackgroundImg} alt="leftBackground" />
        <img src={RightBackgroundImg} alt="rightBackground" />
      </div>
      <Navigation />
      <AccountPopup />
      <Content>
        <ErrorBoundary fallback={<ErrorPage />}>
          <Outlet />
        </ErrorBoundary>
      </Content>
    </Container>
  );
};

export default MainLayout;
