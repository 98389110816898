import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchGetAdditionalPostgraduateData } from "./additionalPostgraduateDataThunk";
import convertFromSavedData from "../../../../ui/modules/applications/components/postgraduateEducation/additionalPostgraduateDataForm/utils/convertFromSavedData";
import loading from "../../../../core/types/loading";
import { AdditionalPostgraduateDataForm } from "../../../../ui/modules/applications/components/postgraduateEducation/additionalPostgraduateDataForm/interfaces/additionalPostgraduateDataForm";

interface AdditionalPostgraduateDataState extends AdditionalPostgraduateDataForm {
  viewedStep: number;
  dataLoadingStatus: loading;
}

const initialState: AdditionalPostgraduateDataState = {
  viewedStep: 0,
  educationInfo: {
    educationLevel: "",
    educationDocument: null,
    educationDocumentAppendix: null,
    graduateOfMIP: false,
  },
  educationDocument: {
    educationDocumentType: "",
    serial: "",
    number: "",
    issuedByWhom: "",
    dateOfIssue: "",
  },
  healthLimitations: {
    hasOvz: "1",
    disabilityType: "0",
    disabilityDocument: null,
    specialConditions: "2",
    nosology: "1",
  },
  dataLoadingStatus: null,
};

const additionalPostgraduateDataAdapter = createEntityAdapter<AdditionalPostgraduateDataState>();

const AdditionalPostgraduateDataSlice = createSlice({
  name: "additionalPostgraduateData",
  initialState: additionalPostgraduateDataAdapter.getInitialState(initialState),
  reducers: {
    setViewedStep: (state, action: PayloadAction<number>) => {
      state.viewedStep = action.payload;
    },
    resetAdditionalPostgraduateDataState: (state) => {
      state.viewedStep = 0;
      state.educationInfo = {
        educationLevel: "",
        educationDocument: null,
        educationDocumentAppendix: null,
        graduateOfMIP: false,
      };
      state.educationDocument = {
        educationDocumentType: "",
        serial: "",
        number: "",
        issuedByWhom: "",
        dateOfIssue: "",
      };
      state.healthLimitations = {
        hasOvz: "1",
        disabilityType: "0",
        disabilityDocument: null,
        specialConditions: "2",
        nosology: "1",
      };
      state.dataLoadingStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetAdditionalPostgraduateData.pending, (state) => {
      state.dataLoadingStatus = "LOADING";
    });
    builder.addCase(fetchGetAdditionalPostgraduateData.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.dataLoadingStatus = "ERROR";
        return;
      }
      state.dataLoadingStatus = "COMPLETE";
      if (!action.payload.payload) {
        return;
      }

      const convertedData = convertFromSavedData(action.payload.payload);
      if (convertedData.educationInfo) {
        state.educationInfo = convertedData.educationInfo;
      }
      if (convertedData.educationDocument) {
        state.educationDocument = convertedData.educationDocument;
      }
      if (convertedData.healthLimitations) {
        state.healthLimitations = convertedData.healthLimitations;
      }
    });
  },
});

export const { setViewedStep, resetAdditionalPostgraduateDataState } =
  AdditionalPostgraduateDataSlice.actions;
export default AdditionalPostgraduateDataSlice.reducer;
