import {
  getDetailedNotificationRequest,
  getNotificationsListRequest,
} from "../../../../../../http/requests/notifications.http";
import Notification from "../../../interfaces/Notification";

const getDetailingNotificationsList = async (
  type: "vpo" | "dpo" | "asp",
  applicationId: string
): Promise<Notification[]> => {
  try {
    const detailedNotificationTypes = [1, 2, 4, 5, 9, 10, 11];

    const notifications = (await getNotificationsListRequest(type, applicationId)).data.payload
      .notification;

    return await Promise.all(
      notifications.map(async (notification) => {
        if (detailedNotificationTypes.includes(notification.notification_type)) {
          return (await getDetailedNotificationRequest(notification.uid)).data.payload;
        }
        return notification;
      })
    );
  } catch (e) {
    console.error(e);
    return [];
  }
};

export default getDetailingNotificationsList;
