import React from "react";
import { Route, Routes } from "react-router-dom";
import SelectApplicationType from "../../../ui/modules/applications/components/selectApplicationType/SelectApplicationType";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import AdditionalHighDataRoutes from "./highEducation/additionalHighDataRoutes";
import HighEducationRoutes from "./highEducation/highEducationRoutes";
import {
  fetchGetAdditionalEducationDictionaries,
  fetchGetPostgraduateEducationDictionaries,
  fetchGetProgramDictionaries,
} from "../../../store/core/dictionaries/dictionariesThunk";
import Loader from "../../../ui/shared/loaders/Loader";
import { fetchGetAdditionalData } from "../../../store/modules/applications/additionalData/additionalDataThunk";
import { fetchGetAdditionalAEData } from "../../../store/modules/applications/additionalAEData/additionalAEDataThunk";
import AdditionalEducationRoutes from "./additionalEducation/additionalEducationRoutes";
import AdditionalAeDataRoutes from "./additionalEducation/AdditionalAEDataRoutes";
import PostgraduateEducationRoutes from "./postgraduateEducation/postgraduateEducationRoutes";
import AdditionalPostgraduateDataRoutes from "./postgraduateEducation/additionalPostgraduateDataRoutes";
import { fetchGetAdditionalPostgraduateData } from "../../../store/modules/applications/additionalPostgraduateData/additionalPostgraduateDataThunk";

const ApplicationsRoutes: React.FC = () => {
  const dispatch = useAppDispatch();
  const { accountState, dictionariesState } = useAppSelector((state) => state.coreState);
  const { additionalDataState, additionalAEDataState } = useAppSelector(
    (state) => state.modulesState.applicationsState
  );

  React.useEffect(() => {
    if (accountState.user?.hasVO) {
      dispatch(fetchGetProgramDictionaries());
    }
    if (accountState.user?.hasDPO) {
      dispatch(fetchGetAdditionalEducationDictionaries());
    }
    if (accountState.user?.hasASP) {
      dispatch(fetchGetPostgraduateEducationDictionaries());
    }
    dispatch(fetchGetAdditionalData());
    dispatch(fetchGetAdditionalAEData());
    dispatch(fetchGetAdditionalPostgraduateData());
  }, []);

  if (
    dictionariesState.programDictionariesLoadingStatus === "LOADING" ||
    dictionariesState.additionalEducationLoadingStatus === "LOADING" ||
    dictionariesState.postgraduateEducationLoadingStatus === "LOADING" ||
    additionalDataState.dataLoadingStatus === "LOADING" ||
    additionalAEDataState.dataLoadingStatus === "LOADING"
  ) {
    return (
      <Routes>
        <Route path="*" element={<Loader type="spinner" />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="" element={<SelectApplicationType />} />

      <Route path="additional-high-data/*" element={<AdditionalHighDataRoutes />} />
      <Route path="additional-ae-data/*" element={<AdditionalAeDataRoutes />} />
      <Route path="additional-postgraduate-data/*" element={<AdditionalPostgraduateDataRoutes />} />

      {accountState?.user?.hasVO &&
        !additionalDataState.educationInfo.transferredFromAnotherUniversity && (
          // HIGH EDUCATION
          <Route path="high-education/*" element={<HighEducationRoutes />} />
        )}

      {accountState?.user?.hasDPO && (
        // ADDITIONAL EDUCATION
        <Route path="additional-education/*" element={<AdditionalEducationRoutes />} />
      )}

      {accountState?.user?.hasASP && (
        // POSTGRADUATE EDUCATION
        <Route path="postgraduate-education/*" element={<PostgraduateEducationRoutes />} />
      )}
    </Routes>
  );
};

export default ApplicationsRoutes;
