import convertFile from "../../../../../../../core/utils/convertFile";
import store from "../../../../../../../store/store";
import {
  AdditionalAEDataForm,
  SplitAdditionalAeDataForm,
} from "../interfaces/additionalAEDataForm";

const convertSplitAdditionalAEForm = (data: SplitAdditionalAeDataForm): AdditionalAEDataForm => {
  const state = store.getState();
  const { dictionaries } = state.coreState.dictionariesState;

  return {
    education: {
      educationLevel: data.educationLevel,
      educationLevelName: dictionaries.edu_type_dpo?.find((type) => type.id === data.educationLevel)
        ?.name,
      educationDocumentType: data.educationDocumentType,
      serial: data.serial,
      number: data.number,
      issuedByWhom: data.issuedByWhom,
      dateOfIssue: data.dateOfIssue,
      educationDocument: convertFile("to", data.educationDocument),
      educationDocumentAppendix: convertFile("to", data.educationDocumentAppendix),
    },
    employment: {
      organizationType: data.organizationType,
      position: data.position,
    },
  };
};

export default convertSplitAdditionalAEForm;
