import { SplitAdditionalPostgraduateDataForm } from "../../../interfaces/additionalPostgraduateDataForm";
import PreviewField from "../../../../../../../../shared/form/interfaces/previewField";
import DictionaryItem from "../../../../../../../../../core/interfaces/dictionaries/DictionaryItem";
import specialConditionsSelectItems from "../../../constants/specialConditionsSelectItems";
import nosologySelectItems from "../../../constants/nosologySelectItems";
import store from "../../../../../../../../../store/store";
import ovzSelectItems from "../../../forms/healthLimitationsForm/constants/ovzSelectItems";

const getAdditionalPostgraduateDataFields = (
  form: SplitAdditionalPostgraduateDataForm
): PreviewField[] => {
  const state = store.getState();

  return [
    {
      type: "text",
      name: "Уровень образования",
      value: state.coreState.dictionariesState.dictionaries?.edu_type.find(
        (eduType: DictionaryItem) => eduType.id === form.educationLevel
      )?.name,
      description: "на основании вашего документа об образовании",
    },
    {
      type: "files",
      name: "Загрузка документов",
      value: [...form.educationDocument, ...form.educationDocumentAppendix],
    },
    {
      type: "checkbox",
      name: "Выпускник специалитета/магистратуры МИП",
      value: form.graduateOfMIP,
    },
    {
      type: "text",
      name: "Документ об образовании",
      value: state.coreState.dictionariesState.dictionaries?.edu_type_asp
        .find((eduTypeItem) => eduTypeItem.id === form.educationLevel)
        ?.docyments_type.find(
          (documentTypeItem) => documentTypeItem.id === form.educationDocumentType
        )?.name,
    },
    {
      type: "text",
      name: "Серия и номер",
      value: `${form.serial} ${form.number}`,
    },
    {
      type: "text",
      name: "Кем выдан",
      value: form.issuedByWhom,
    },
    {
      type: "text",
      name: "Дата выдачи",
      value: form.dateOfIssue,
    },
    {
      type: "text",
      name: "Ограниченные возможности",
      value: ovzSelectItems.find((ovzItem) => ovzItem.value === form.hasOvz)?.label ?? null,
    },
    {
      type: "text",
      name: "Инвалидность",
      value:
        state.coreState.dictionariesState.dictionaries?.category_ovz.find(
          (categoryOvzItem) => categoryOvzItem.id === form.disabilityType
        )?.name ?? null,
    },
    {
      type: "files",
      name: "Подтверждение инвалидности",
      value: form.disabilityDocument ?? [],
      hideField: form.disabilityType === "0",
    },
    {
      type: "text",
      name: "Специальные условия",
      value:
        specialConditionsSelectItems.find((item) => item.value === form.specialConditions)?.label ??
        "",
      description:
        "необходимые для прохождения вступительного испытания, исходя из вашей нозологии",
    },
    {
      type: "text",
      name: "Нозология",
      value: nosologySelectItems.find((item) => item.value === form.nosology)?.label ?? "",
    },
  ];
};

export default getAdditionalPostgraduateDataFields;
