import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAppSelector } from "../hooks/redux";
import MainLayout from "../../ui/shared/mainLayout/MainLayout";
import NotFoundPage from "../../ui/modules/service/NotFoundPage";
import LoginForm from "../../ui/modules/auth/loginForm/LoginForm";
import RememberPasswordForm from "../../ui/modules/auth/rememberPasswordForm/RememberPasswordForm";
import ProfileRoutes from "./profileRoutes";
import ApplicationsRoutes from "./applications/applicationsRoutes";
import Loader from "../../ui/shared/loaders/Loader";
import NotificationsRoutes from "./notificationsRoutes";

const AppRoutes: React.FC = () => {
  const { accountState, dictionariesState } = useAppSelector((state) => state.coreState);

  if (accountState.token) {
    return (
      <Routes>
        <Route path="" element={<MainLayout />}>
          {accountState.userLoadingStatus === "LOADING" ||
          dictionariesState.dataLoadingStatus === "LOADING" ? (
            <Route path="*" element={<Loader type="spinner" />} />
          ) : (
            <>
              {accountState.ss === "1" ? (
                <>
                  <Route path="" element={<Navigate to="/notifications" />} />
                  <Route path={"profile/*"} element={<ProfileRoutes />} />
                  <Route path={"notifications/*"} element={<NotificationsRoutes />} />
                </>
              ) : (
                <>
                  <Route path="" element={<Navigate to="/profile" />} />
                  <Route path={"profile/*"} element={<ProfileRoutes />} />
                  {accountState.user?.hasProfile && (
                    <Route path={"applications/*"} element={<ApplicationsRoutes />} />
                  )}
                  {(accountState.hasCompletedHighEducationApplications ||
                    accountState.hasCompletedAdditionalEducationApplications ||
                    accountState.hasCompletedPostgraduateEducationApplications) && (
                    <Route path={"notifications/*"} element={<NotificationsRoutes />} />
                  )}
                </>
              )}
            </>
          )}
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="" element={<MainLayout />}>
        <Route path="" element={<LoginForm />} />
        <Route path="remember-password" element={<RememberPasswordForm />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
