import Step from "../../../../../../shared/stepper/interfaces/Step";
import ApplicationForm from "../forms/applicationForm/ApplicationForm";
import PaymentForm from "../forms/paymentForm/PaymentForm";

const steps: Step[] = [
  {
    title: "Подача заявления ДПО",
    element: (props: any) => <ApplicationForm {...props} />,
  },
  {
    title: "Вариант оплаты",
    element: (props: any) => <PaymentForm {...props} />,
  },
];

export default steps;
