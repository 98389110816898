import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAppSelector } from "../../../hooks/redux";
import AddingDataInfo from "../../../../ui/modules/applications/components/additionalEducation/additionalAEDataForm/components/addingDataInfo/AddingDataInfo";
import AdditionalAEDataForm from "../../../../ui/modules/applications/components/additionalEducation/additionalAEDataForm/AdditionalAEDataForm";
import PreviewAdditionalAEForm from "../../../../ui/modules/applications/components/additionalEducation/additionalAEDataForm/components/previewAdditionalAEForm/PreviewAdditionalAEForm";
import CompleteAdditionalAEForm from "../../../../ui/modules/applications/components/additionalEducation/additionalAEDataForm/components/completeAdditionalAEForm/CompleteAdditionalAEForm";

const AdditionalAeDataRoutes: React.FC = () => {
  const { accountState } = useAppSelector((state) => state.coreState);

  if (accountState?.user?.hasDPO) {
    return (
      <Routes>
        <Route path="" element={<PreviewAdditionalAEForm mode="view" />} />
        <Route path="complete" element={<CompleteAdditionalAEForm />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="" element={<AddingDataInfo />} />
      <Route path="form" element={<AdditionalAEDataForm />} />
      <Route path="check" element={<PreviewAdditionalAEForm mode="finallyCheck" />} />
    </Routes>
  );
};

export default AdditionalAeDataRoutes;
