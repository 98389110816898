import { SplitAdditionalEducationForm } from "../interfaces/additionalEducationForm";
import store from "../../../../../../../store/store";
import PreviewField from "../../../../../../shared/form/interfaces/previewField";
import getSelectedAdditionalEducationItem from "./getSelectedAdditionalEducationItem";
import ProgramDictionary from "../../../../../../../core/interfaces/dictionaries/additionalEducationDictionaries/ProgramDictionary";
import TypesDictionary from "../../../../../../../core/interfaces/dictionaries/additionalEducationDictionaries/TypesDictionary";
import LevelDictionary from "../../../../../../../core/interfaces/dictionaries/additionalEducationDictionaries/LevelDictionary";

const getAdditionalEducationFields = (form: SplitAdditionalEducationForm): PreviewField[] => {
  const state = store.getState();
  const { dictionariesState } = state.coreState;

  const selectedLevel = getSelectedAdditionalEducationItem("level", form.level) as LevelDictionary;
  const selectedProgram = getSelectedAdditionalEducationItem(
    "program",
    form.level,
    form.program
  ) as ProgramDictionary;
  const selectedForm = getSelectedAdditionalEducationItem(
    "form",
    form.level,
    form.program,
    form.form
  ) as TypesDictionary;

  return [
    {
      type: "text",
      name: "Вид программы",
      value: selectedLevel?.level,
    },
    {
      type: "text",
      name: "Программа",
      value: selectedProgram?.name,
    },
    {
      type: "text",
      name: "Форма обучения",
      value: selectedForm?.forma,
    },
    {
      type: "text",
      name: "Вариант оплаты",
      value: selectedForm.finances.find(
        (financesItem) => financesItem.finance_id === form.paymentMethod
      )?.finance,
    },
    {
      type: "text",
      name: "Скидки и льготы",
      value: [
        { name: "Нет", id: "0" },
        ...dictionariesState.additionalEducationDictionaries.discounts,
      ].find((item) => item.id === form.discountsAndBenefits)?.name,
    },
    {
      type: "files",
      name: "Загрузка документа",
      value: form.discountConfirmation,
      description: "без документа скидка не будет применена",
    },
  ];
};

export default getAdditionalEducationFields;
