import React from "react";
import { Control, RegisterOptions, useController } from "react-hook-form";
import { Container, StyledPasswordInput } from "./passwordInput.styles";
import showEyeIcon from "../../../core/static/icons/show-eye.svg";
import closeEyeIcon from "../../../core/static/icons/close-eye.svg";

const PasswordInput: React.FC<
  {
    control: Control;
    name: string;
    options?: RegisterOptions;
  } & React.InputHTMLAttributes<HTMLInputElement>
> = ({ control, name, options = {}, ...attrs }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const controller = useController({ control, name, rules: options });

  return (
    <Container {...attrs} showPassword={showPassword}>
      <StyledPasswordInput
        value={controller.field.value}
        onChange={controller.field.onChange}
        type={showPassword ? "text" : "password"}
      />
      <button type="button" onClick={(): void => setShowPassword(!showPassword)}>
        <img
          src={showPassword ? showEyeIcon : closeEyeIcon}
          alt={`${showPassword ? "Show" : "Hide"} password`}
        />
      </button>
    </Container>
  );
};

export default PasswordInput;
