import styled from "styled-components";
import colors from "../../../core/styles/colors";
import sizes from "../../../core/styles/sizes";

export const FormContainer = styled.div<{ leftContent?: boolean; noScrollContent?: boolean }>`
  width: 100%;
  margin-top: 20px;
  display: grid;
  grid-template-columns: ${(props): string =>
    props.leftContent ? "auto 1fr" : "minmax(100px, 0.65fr) 1fr"};
  grid-gap: 0 15px;
  align-items: center;

  @media all and (${sizes.mobile}) {
    width: calc(100% - 32px);
    height: ${(props): string => (props.noScrollContent ? "auto" : "67vh")};
    overflow-y: auto;
    margin: 10px 8px 0 8px;
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Form = styled.form`
  width: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  .stepper {
    width: calc(100% - 55px);
    margin: 10px 0 10px 55px;
  }

  @media all and (${sizes.mobile}) {
    height: calc(100% - 80px);
    padding: 40px 0;
    background: ${colors.white.general};
    .stepper {
      width: 100%;
      margin: 25px 0 0 0;
    }
  }
`;

export const FormContent = styled.div`
  flex-grow: 1;
  max-height: calc(55vh - 24px);
  overflow-y: auto;

  @media all and (${sizes.mobile}) {
    max-height: none;
  }
`;

export const FormActions = styled.div<{ lastStep: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    width: 50px;
    margin-right: 25px;
  }
  .leftButton {
    svg {
      transform: rotate(180deg);
    }
  }
  .rightButton {
    margin-right: 0;
    width: ${(props): string => (props.lastStep ? "auto" : "50px")};
    padding: ${(props): string => (props.lastStep ? "0 28px 0 28px" : "0")};
  }

  @media all and (${sizes.mobile}) {
    width: calc(100% - 32px);
    padding: 25px 16px;
  }
`;

// COLUMN FORM

export const ColumnFormContainer = styled.div`
  width: calc(100% - 196px);
  padding: 0 98px;
  margin-top: 20px;

  @media all and (${sizes.mobile}) {
    width: calc(100% - 32px);
    height: 67vh;
    overflow-y: auto;
    margin: 10px 8px 0 8px;
    padding: 0 8px;
  }
`;
