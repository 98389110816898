import FormComponentByAnswer from "../interfaces/FormComponentByAnswer";
import PhysicalPerson from "../components/physicalPerson/PhysicalPerson";
import LegalPerson from "../components/legalPerson/LegalPerson";
import MaternityCapital from "../components/maternityCapital/MaternityCapital";
import DegreeType from "../../../../../../../../../core/types/degreeType";
import AdditionalEducationLegalPerson from "../components/additionalEducationLegalPerson/AdditionalEducationLegalPerson";

const getFormComponentByAnswer = (
  degree: DegreeType
): { [key: number]: FormComponentByAnswer } => ({
  0: {
    element: null,
  },
  2: {
    element: (props: any) => <PhysicalPerson {...props} />,
  },
  3: {
    element: (props) =>
      degree === "additional" ? (
        <AdditionalEducationLegalPerson {...props} />
      ) : (
        <LegalPerson {...props} />
      ),
  },
  4: {
    element: (props) => <MaternityCapital {...props} />,
  },
});

export default getFormComponentByAnswer;
