import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHighEducationFormRequest } from "../../../../http/requests/applications/highEducation.http";

export const fetchGetHighEducation = createAsyncThunk(
  "highEducation/get",
  async (applicationId: number) => {
    const response = await getHighEducationFormRequest(applicationId);
    return response.data;
  }
);
