export default class RequestBody {
  action: string;

  payload: object;

  constructor(action: string, payload: object) {
    this.action = action;
    this.payload = payload;
  }
}
