import React from "react";
import { useNavigate } from "react-router-dom";
import { TitleH1, TitleH3 } from "../../../../../../../shared/native/typography.styles";
import { PrimaryButton } from "../../../../../../../shared/native/button.styles";
import { Ul } from "../../../../../../../shared/native/ul.styles";
import { Li } from "../../../../../../../shared/native/li.styles";
import {
  InfoPage,
  InfoPageContent,
  InfoPageTextInfo,
} from "../../../../../../../shared/infoPage/infoPage.styles";
import { fetchGetHighEducation } from "../../../../../../../../store/modules/applications/highEducation/highEducationThunk";
import getCookie from "../../../../../../../../core/utils/getCookie";
import { useAppDispatch } from "../../../../../../../../core/hooks/redux";
import ResponseBody from "../../../../../../../../http/models/ResponseBody";
import { HighEducationForm } from "../../interfaces/highEducationForm";
import { setViewedStep } from "../../../../../../../../store/modules/applications/highEducation/highEducationSlice";

const SubmissionInfo: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const cookie = getCookie();
    if (cookie?.lastModifiedHighEducationApplicationId) {
      dispatch(fetchGetHighEducation(Number(cookie.lastModifiedHighEducationApplicationId))).then(
        (res) => {
          const data = res.payload as ResponseBody<HighEducationForm>;

          if (data.payload?.entranceExams?.exams?.length) {
            dispatch(setViewedStep(2));
            navigate("/applications/high-education/form");
            return;
          }

          if (data.payload?.application?.program_uid) {
            dispatch(setViewedStep(1));
            navigate("/applications/high-education/form");
          }
        }
      );
    }
  }, []);

  return (
    <InfoPage>
      <InfoPageContent>
        <InfoPageTextInfo>
          <TitleH1>Подача заявления на поступление</TitleH1>
          <p>
            Используя ранее созданный профиль физического лица, подайте заявление на интересующую
            вас программу и форму обучения.
          </p>
          <TitleH3>Что нужно для заполнения:</TitleH3>
          <Ul>
            <Li>Документ подтвеждающий наличие индивидуальных достижений (при наличии)</Li>
          </Ul>
        </InfoPageTextInfo>
        <PrimaryButton onClick={(): void => navigate("/applications/high-education/form")}>
          Продолжить
        </PrimaryButton>
      </InfoPageContent>
    </InfoPage>
  );
};

export default SubmissionInfo;
