import PersonalDataForm from "../interfaces/PersonalDataForm";
import PersonalData from "../interfaces/requests/PersonalData";
import ContactsData from "../interfaces/requests/ContactsData";
import ContactsDataForm from "../interfaces/ContactsDataForm";
import PassportDataForm from "../interfaces/PassportDataForm";
import PassportData from "../interfaces/requests/PassportData";
import ResidingPlaceDataForm from "../interfaces/ResidingPlaceDataForm";
import ResidingPlaceData from "../interfaces/requests/ResidingPlaceData";
import convertAddressObject from "./convertAddressObject";
import convertFile from "../../../../core/utils/convertFile";

export const convertPersonalForm = (data: PersonalDataForm): PersonalData => {
  return {
    surname: data.lastname,
    name: data.firstname,
    middlename: data.secondName,
    none_middlename_none: data.hasNotSecondName,
    sex: data.gender,
    birthdate: data.dateOfBirth,
  };
};

export const convertContactsForm = (data: ContactsDataForm): ContactsData => {
  return {
    snils: data.snils,
    snils_none: data.hasNotSnils,
    email: data.email,
    phone: data.phone,
    photo: convertFile("to", data.photo),
  };
};

export const convertPassportForm = (data: PassportDataForm): PassportData => {
  return {
    pcitizen: data.documentType,
    citizenship: data.documentType === "000000047" ? "643" : data.citizenship,
    pcode: data.divisionCode,
    pseria: data.serial,
    pnumber: data.number,
    serialAndNumber: data.serialAndNumber,
    pissue: data.issuedByWhom,
    pdate: data.dateOfIssue,
    changeFullnameDocument: convertFile("to", data.changeFullnameDocument),
  };
};

export const convertResidingPlaceForm = (data: ResidingPlaceDataForm): ResidingPlaceData => {
  return {
    residingCoincidesRegistration: data.residingCoincidesRegistration,
    registerAddress: convertAddressObject("to", data.registerAddress),
    residingAddress: data.residingCoincidesRegistration
      ? convertAddressObject("to", data.registerAddress)
      : convertAddressObject("to", data.residingAddress),
    placeOfBirth: data.placeOfBirth,
    documents: {
      passport: {
        main: convertFile("to", data.mainPageOfPassport),
        registration: convertFile("to", data.registrationPageOfPassport),
      },
    },
  };
};
