import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchGetAdditionalAEData } from "./additionalAEDataThunk";
import loading from "../../../../core/types/loading";
import { AdditionalAEDataForm } from "../../../../ui/modules/applications/components/additionalEducation/additionalAEDataForm/interfaces/additionalAEDataForm";
import convertAdditionalAEFromSavedData from "../../../../ui/modules/applications/components/additionalEducation/additionalAEDataForm/utils/convertAdditionalAEFromSavedData";

interface AdditionalAEDataState extends AdditionalAEDataForm {
  viewedStep: number;
  dataLoadingStatus: loading;
}

const initialState: AdditionalAEDataState = {
  viewedStep: 0,
  education: {
    educationLevel: "",
    educationLevelName: "",
    educationDocumentType: "",
    serial: "",
    number: "",
    issuedByWhom: "",
    dateOfIssue: "",
    educationDocument: null,
    educationDocumentAppendix: null,
  },
  employment: {
    organizationType: "",
    position: "",
  },
  dataLoadingStatus: null,
};

const additionalAeDataAdapter = createEntityAdapter<AdditionalAEDataState>();

const AdditionalAeDataSlice = createSlice({
  name: "additionalAeData",
  initialState: additionalAeDataAdapter.getInitialState(initialState),
  reducers: {
    setViewedStep: (state, action: PayloadAction<number>) => {
      state.viewedStep = action.payload;
    },
    resetAdditionalAeDataState: (state) => {
      state.viewedStep = 0;
      state.education = {
        educationLevel: "",
        educationLevelName: "",
        educationDocumentType: "",
        serial: "",
        number: "",
        issuedByWhom: "",
        dateOfIssue: "",
        educationDocument: null,
        educationDocumentAppendix: null,
      };
      state.employment = {
        organizationType: "",
        position: "",
      };
      state.dataLoadingStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetAdditionalAEData.pending, (state) => {
      state.dataLoadingStatus = "LOADING";
    });
    builder.addCase(fetchGetAdditionalAEData.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.dataLoadingStatus = "ERROR";
        return;
      }
      state.dataLoadingStatus = "COMPLETE";
      if (!action.payload.payload) {
        return;
      }

      const convertedData = convertAdditionalAEFromSavedData(action.payload.payload);
      if (convertedData.education) {
        state.education = convertedData.education;
      }
      if (convertedData.employment) {
        state.employment = convertedData.employment;
      }
    });
  },
});

export const { setViewedStep, resetAdditionalAeDataState } = AdditionalAeDataSlice.actions;
export default AdditionalAeDataSlice.reducer;
