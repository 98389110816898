import styled from "styled-components";
import colors from "../../../../../../core/styles/colors";
import sizes from "../../../../../../core/styles/sizes";

export const RatingApplicationsListContainer = styled.div`
  width: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media all and (${sizes.mobile}) {
    height: calc(100% - 200px);
    padding: 160px 0 40px 0;
    justify-content: space-between;
  }
`;

export const RatingApplicationsListContent = styled.div`
  width: 100%;

  @media all and (${sizes.mobile}) {
    width: calc(100% - 96px);
    background: ${colors.white.general};
    border-radius: 10px;
    padding: 25px 32px;
  }
`;

export const RatingApplicationsListHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  h1 {
    width: calc(100% - 64px);
    padding: 0 32px;
    margin: 0;
  }

  button {
    position: absolute;
    top: -8px;
    right: 0;
  }

  @media all and (max-width: 1440px) {
    flex-direction: column;

    button {
      position: initial;
      margin-top: 8px;
    }
  }

  @media all and (${sizes.mobile}) {
    button {
      width: 100%;
    }
  }
`;

export const RatingApplicationsList = styled.div<{ $hasSortable: boolean }>`
  width: 100%;

  ${(props): string =>
    props.$hasSortable
      ? `.sortableList {
    width: 100%;
    max-height: calc(50vh - 32px);
    padding: 24px 0;
    overflow-y: auto;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 250px);
    grid-gap: 16px;
  }`
      : `
    width: 100%;
    max-height: calc(50vh - 32px);
    padding: 24px 0;
    overflow-y: auto;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 250px);
    grid-gap: 16px;
  `}

  .sortable-ghost {
    opacity: 0;
  }

  @media all and (${sizes.mobile}) {
    height: fit-content;
    max-height: 50vh;
  }

  @media all and (max-width: 570px) {
    grid-template-columns: 1fr;
  }
`;
