import SelectItem from "../../../../../../shared/select/SelectItem";

const nosologySelectItems: SelectItem[] = [
  {
    label: "Нет",
    value: "1",
  },
  {
    label: "По зрению",
    value: "2",
  },
  {
    label: "По слуху",
    value: "3",
  },
  {
    label: "По речи",
    value: "4",
  },
  {
    label: "Опорно-двигательный аппарат",
    value: "5",
  },
  {
    label: "Нарушение интеллекта",
    value: "6",
  },
];

export default nosologySelectItems;
