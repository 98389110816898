import { AxiosResponse } from "axios/index";
import ResponseBody from "../../models/ResponseBody";
import { $baseApi } from "../../index";
import RequestBody from "../../models/RequestBody";
import { AdditionalPostgraduateDataForm } from "../../../ui/modules/applications/components/postgraduateEducation/additionalPostgraduateDataForm/interfaces/additionalPostgraduateDataForm";

export const getAdditionalPostgraduateDataFormRequest = (): Promise<
  AxiosResponse<ResponseBody<AdditionalPostgraduateDataForm>>
> => {
  return $baseApi.post<ResponseBody<AdditionalPostgraduateDataForm>>(
    "",
    new RequestBody("draft.read", {
      type: "additionalASP",
    })
  );
};

export const saveAdditionalPostgraduateDataFormRequest = (
  data: AdditionalPostgraduateDataForm
): Promise<AxiosResponse<ResponseBody<null>>> => {
  const resultBody = {
    type: "additionalASP",
    data: {
      ...data,
    },
  };
  return $baseApi.post<ResponseBody<null>>("", new RequestBody("draft.save", resultBody));
};

export const submitAdditionalPostgraduateDataFormRequest = (): Promise<
  AxiosResponse<ResponseBody<null>>
> => {
  return $baseApi.post<ResponseBody<null>>("", new RequestBody("profile.save", null));
};
