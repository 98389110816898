import convertFile from "../../../../../../../core/utils/convertFile";
import store from "../../../../../../../store/store";
import {
  AdditionalEducationForm,
  SplitAdditionalEducationForm,
} from "../interfaces/additionalEducationForm";
import getSelectedAdditionalEducationItem from "./getSelectedAdditionalEducationItem";
import ProgramDictionary from "../../../../../../../core/interfaces/dictionaries/additionalEducationDictionaries/ProgramDictionary";
import TypesDictionary from "../../../../../../../core/interfaces/dictionaries/additionalEducationDictionaries/TypesDictionary";
import LevelDictionary from "../../../../../../../core/interfaces/dictionaries/additionalEducationDictionaries/LevelDictionary";

const convertSplitAdditionalEducationForm = (
  data: SplitAdditionalEducationForm
): AdditionalEducationForm => {
  const state = store.getState();
  const { dictionaries } = state.coreState.dictionariesState;

  const level = getSelectedAdditionalEducationItem("level", data.level) as LevelDictionary;
  const program = getSelectedAdditionalEducationItem(
    "program",
    data.level,
    data.program
  ) as ProgramDictionary;
  const form = getSelectedAdditionalEducationItem(
    "form",
    data.level,
    data.program,
    data.form
  ) as TypesDictionary;

  return {
    application: {
      level: level.level_id,
      levelName: level.level,
      program: program?.id ?? "",
      programName: program?.name ?? "",
      form: form?.forma_id ?? "",
      formName: form?.forma ?? "",
      format: form?.format_id ?? "",
      formatName: form?.format ?? "",
    },
    paymentForm: {
      paymentMethod: data.paymentMethod,
      discountsAndBenefits: data.discountsAndBenefits,
      discountsPercentage:
        dictionaries.discounts.find((discount) => discount.id === data.discountsAndBenefits)?.val ??
        null,
      discountConfirmation: convertFile("to", data.discountConfirmation),
    },
  };
};

export default convertSplitAdditionalEducationForm;
