import {
  AdditionalPostgraduateDataForm,
  SplitAdditionalPostgraduateDataForm,
} from "../interfaces/additionalPostgraduateDataForm";
import convertFile from "../../../../../../../core/utils/convertFile";

const convertSplitAdditionalPostgraduateDataForm = (
  data: SplitAdditionalPostgraduateDataForm
): AdditionalPostgraduateDataForm => ({
  educationInfo: {
    educationLevel: data.educationLevel,
    educationDocument: convertFile("to", data.educationDocument),
    educationDocumentAppendix: convertFile("to", data.educationDocumentAppendix),
    graduateOfMIP: data.graduateOfMIP,
  },
  educationDocument: {
    educationDocumentType: data.educationDocumentType,
    serial: data.serial,
    number: data.number,
    issuedByWhom: data.issuedByWhom,
    dateOfIssue: data.dateOfIssue,
  },
  healthLimitations: {
    hasOvz: data.hasOvz,
    disabilityType: data.disabilityType,
    disabilityDocument: convertFile("to", data.disabilityDocument) ?? null,
    specialConditions: data.specialConditions,
    nosology: data.nosology,
  },
});

export default convertSplitAdditionalPostgraduateDataForm;
