import React, { HTMLAttributes } from "react";
import StepInterface from "./interfaces/Step";
import { Component, Info, Step, Number, Title, Line, MobileStep } from "./stepper.styles";
import { TitleH1 } from "../native/typography.styles";
import UseIsMobile from "../../../core/hooks/UseIsMobile";

const Stepper: React.FC<
  {
    items: StepInterface[];
    currentStep?: number;
  } & HTMLAttributes<HTMLDivElement>
> = ({ items, currentStep, ...attrs }) => {
  const [isMobile, setIsMobile] = React.useState<boolean>(window.innerWidth < 1040);
  UseIsMobile(setIsMobile);

  if (isMobile) {
    return (
      <Component {...attrs}>
        <MobileStep>
          <TitleH1>{items.find((item, index) => index === currentStep).title}</TitleH1>
          <p>
            <span>{currentStep + 1}</span> из {items.length}
          </p>
        </MobileStep>
      </Component>
    );
  }

  return (
    <Component {...attrs}>
      {items &&
        items.map((item, key) => (
          <Step key={item.title}>
            {!!key && <Line $active={key === currentStep} complete={key <= currentStep} />}
            <Info>
              {key !== currentStep && <Number complete={key < currentStep}>{key + 1}</Number>}
              {key === currentStep && <Title>{item.title}</Title>}
            </Info>
            {key !== items.length - 1 && (
              <Line $active={key === currentStep} complete={key <= currentStep} />
            )}
          </Step>
        ))}
    </Component>
  );
};

export default Stepper;
