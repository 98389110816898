import React from "react";
import Notification from "../../../interfaces/Notification";
import FileUploadType from "../components/notificationTypes/FileUploadType";
import FormType from "../components/notificationTypes/formType/FormType";
import TextType from "../components/notificationTypes/TextType";
import PreviewAndUploadType from "../components/notificationTypes/PreviewAndUploadType";
import PreviewType from "../components/notificationTypes/PreviewType";
import SignatureType from "../components/notificationTypes/signatureType/SignatureType";
import InsideSignatureType from "../components/notificationTypes/InsideSignatureType";
import degreeType from "../../../../../../core/types/degreeType";
import AdditionalEducationLegalPersonType from "../components/notificationTypes/additionalEducationLegalPersonType/AdditionalEducationLegalPersonType";

const notificationTypeComponents: {
  [key: number]: (notification: Notification, degree: degreeType) => React.ReactNode;
} = {
  0: (notification) => <FileUploadType notification={notification} />,
  1: (notification) => <PreviewType notification={notification} />,
  2: (notification, degree) => <FormType notification={notification} degree={degree} />,
  3: (notification) => <TextType notification={notification} />,
  4: (notification) => <PreviewAndUploadType notification={notification} />,
  5: (notification) => <PreviewType notification={notification} />,
  9: (notification, degree) => <SignatureType notification={notification} degree={degree} />,
  10: (notification, degree) => <SignatureType notification={notification} degree={degree} />,
  11: (notification, degree) => (
    <AdditionalEducationLegalPersonType notification={notification} degree={degree} />
  ),
  333: (notification) => <InsideSignatureType notification={notification} />,
};

export default notificationTypeComponents;
