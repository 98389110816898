import styled from "styled-components";
import colors from "../../../core/styles/colors";
import sizes from "../../../core/styles/sizes";

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  .radioButton {
    margin: 0 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    .label {
      margin-left: 10px;
      font-size: 18px;
      font-weight: 300;
    }
  }

  @media all and (${sizes.mobile}) {
    padding: 0 10px;
  }
`;

export const RadioItemContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  input[type="radio"] {
    position: relative;
    width: 16px;
    height: 16px;
    margin: 0;
    appearance: none;
    border: 1px solid ${colors.red.general};
    border-radius: 50%;
    cursor: pointer;
    &:checked::before {
      position: absolute;
      display: block;
      content: "";
      width: 8px;
      height: 8px;
      top: calc(50% - 4px);
      left: calc(50% - 4px);
      background: ${colors.red.general};
      border-radius: 50%;
    }
  }
  span {
    margin: 0 24px 0 8px;
    font-family: Gilroy, serif;
    font-size: 18px;
    font-weight: 300;
  }
  &:has(input[type="radio"]:disabled) {
    input[type="radio"] {
      border-color: ${colors.red.light};
      &:checked::before {
        background: ${colors.red.light};
      }
    }
    span {
      color: ${colors.gray.gray300};
    }
  }
`;
