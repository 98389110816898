import store from "../../../../../../store/store";
import GenderRadioButtonItems from "../../../../../../core/constants/genderRadioButtonItems";
import personalDocumentSelectItems from "../../../../../../core/constants/personalDocumentSelectItems";
import convertAddressToString from "../../../../../../core/utils/convertAddressToString";
import ProfileForm from "../../../interfaces/ProfileForm";
import PreviewField from "../../../../../shared/form/interfaces/previewField";

const getPreviewProfileFields = (form: ProfileForm): PreviewField[] => {
  const state = store.getState();

  if (!form.firstname || !form.phone || !form.documentType || !form.placeOfBirth) {
    return;
  }

  return [
    {
      type: "text",
      name: "Фамилия",
      value: form.lastname,
    },
    {
      type: "text",
      name: "Имя",
      value: form.firstname,
    },
    {
      type: "text",
      name: "Отчество",
      value: form.secondName,
      description: "При наличии",
    },
    {
      type: "radio",
      name: "Пол",
      value: form.gender,
      items: GenderRadioButtonItems.map((field) => ({
        ...field,
        disabled: form.gender !== field.value,
      })),
    },
    {
      type: "text",
      name: "Дата рождения",
      value: form.dateOfBirth,
    },
    {
      type: "text",
      name: "Снилс",
      value: form.snils,
      description: "При наличии",
    },
    {
      type: "text",
      name: "Email",
      value: form.email,
    },
    {
      type: "text",
      name: "Телефон",
      value: form.phone,
    },
    {
      type: "files",
      name: "Ваше фото",
      value: form.photo,
    },
    {
      type: "text",
      name: "Тип документа",
      value:
        personalDocumentSelectItems.find((document) => document.value === form.documentType)
          ?.label ?? "",
      description: "Удостоверяющего личность",
    },
    {
      type: "text",
      name: "Гражданство",
      value:
        state.coreState.dictionariesState.dictionaries?.country.find(
          (item) => item.id === form.citizenship
        )?.name ?? null,
    },
    {
      type: "text",
      name: "Серия и номер",
      value: form.serial || form.number ? `${form.serial} ${form.number}` : form.serialAndNumber,
    },
    {
      type: "text",
      name: "Дата выдачи",
      value: form.dateOfIssue,
    },
    {
      type: "text",
      name: "Кем выдан",
      value: form.issuedByWhom,
    },
    {
      type: "text",
      name: "Код подразделения",
      value: form.divisionCode,
      hideField: form.documentType !== "000000047",
    },
    {
      type: "files",
      name: "Документ о смене ФИО",
      value: form.changeFullnameDocument,
    },
    {
      type: "text",
      name: "Адрес регистрации",
      value: convertAddressToString(form.registerAddress),
    },
    {
      type: "text",
      name: "Адрес проживания",
      value: convertAddressToString(form.residingAddress),
    },
    {
      type: "text",
      name: "Место рождения",
      value: form.placeOfBirth,
    },
    {
      type: "files",
      name: "Загрузка документа",
      value: [...form.mainPageOfPassport, ...form.registrationPageOfPassport],
      description: "Удостоверяющего личность",
    },
  ];
};

export default getPreviewProfileFields;
