import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ServicePage as StyledServicePage,
  ServicePageActions,
  ServicePageContent,
} from "./servicePage.styles";
import { TitleH1 } from "../../shared/native/typography.styles";
import { OutlinedButton, PrimaryButton } from "../../shared/native/button.styles";

const ErrorPage: React.FC<{ error?: Error }> = ({ error }) => {
  const navigate = useNavigate();

  const reloadPage = (): void => {
    window.location.reload();
  };

  const navigateAndReloadPage = (): void => {
    navigate("/");
    reloadPage();
  };

  return (
    <StyledServicePage>
      <ServicePageContent>
        <TitleH1>Возникла ошибка</TitleH1>
        {!!error && (
          <details>
            <summary>Подробнее</summary>
            <pre style={{ color: "red" }}>{error.message}</pre>
          </details>
        )}
        <ServicePageActions>
          <PrimaryButton onClick={navigateAndReloadPage} style={{ marginRight: 8 }}>
            На главную
          </PrimaryButton>
          <OutlinedButton onClick={reloadPage}>Перезагрузить страницу</OutlinedButton>
        </ServicePageActions>
      </ServicePageContent>
    </StyledServicePage>
  );
};

export default ErrorPage;
