import { AxiosResponse } from "axios";
import ResponseBody from "../../models/ResponseBody";
import { $baseApi } from "../../index";
import RequestBody from "../../models/RequestBody";
import { AdditionalEducationForm } from "../../../ui/modules/applications/components/additionalEducation/additionalEducationForm/interfaces/additionalEducationForm";

export const getAdditionalEducationFormRequest = (
  applicationId: number
): Promise<AxiosResponse<ResponseBody<AdditionalEducationForm>>> => {
  return $baseApi.post<ResponseBody<AdditionalEducationForm>>(
    "",
    new RequestBody("draft.read", {
      type: "applicationDPO",
      id: applicationId,
    })
  );
};

export const saveAdditionalEducationFormRequest = (
  applicationId: number,
  data: AdditionalEducationForm
): Promise<AxiosResponse<ResponseBody<{ id: number }>>> => {
  const resultBody = {
    type: "applicationDPO",
    data: {
      ...data,
      id: applicationId,
    },
  };
  return $baseApi.post<ResponseBody<{ id: number }>>("", new RequestBody("draft.save", resultBody));
};

export const submitAdditionalEducationFormRequest = (
  applicationId: number
): Promise<AxiosResponse<ResponseBody<null>>> => {
  return $baseApi.post<ResponseBody<null>>(
    "",
    new RequestBody("application.save", { id: applicationId })
  );
};
