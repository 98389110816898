import React, { useRef } from "react";
import { AddressSuggestions as DadataAddressSuggestions } from "react-dadata";
import { BaseProps } from "react-dadata/dist/BaseSuggestions";
import { DaDataAddress, DaDataAddressBounds } from "react-dadata/dist/types";
import { DadataInput as StyledDadataInput } from "./addressSuggestions.styles";

const AddressSuggestions: React.FC<
  {
    hasErrors?: boolean;
    dadataAttrs?: {
      filterLanguage?: "ru" | "en";
      filterFromBound?: DaDataAddressBounds;
      filterToBound?: DaDataAddressBounds;
      filterLocations?: { [key: string]: any }[];
      filterLocationsBoost?: { [key: string]: any }[];
      filterRestrictValue?: boolean;
    };
  } & BaseProps<DaDataAddress>
> = ({ hasErrors, dadataAttrs, ...attrs }) => {
  const suggestionsRef = useRef(null);

  return (
    <StyledDadataInput width={suggestionsRef.current?.offsetWidth} hasErrors={hasErrors}>
      <DadataAddressSuggestions {...dadataAttrs} {...attrs} ref={suggestionsRef} />
    </StyledDadataInput>
  );
};

export default AddressSuggestions;
