import React from "react";
import { PaymentGroupsButton, SelectPaymentGroupContainer } from "./selectPaymentGroup.styles";
import PaymentInfo from "../../constants/paymentInfo";
import degreeType from "../../../../../../../../core/types/degreeType";

const SelectPaymentGroup: React.FC<{
  degree: degreeType;
  paymentGroups: PaymentInfo[];
  selectedGroup: PaymentInfo;
  selectGroupCallback: (paymentGroup: PaymentInfo) => void;
}> = ({ degree, paymentGroups, selectedGroup, selectGroupCallback }) => {
  React.useEffect(() => {
    if (paymentGroups?.length) {
      const lastViewedGroupName = localStorage.getItem(`${degree}EducationListPaymentGroup`);
      const notEmptyPaymentGroup = paymentGroups.find((group) => !!group.applications);

      if (!lastViewedGroupName) {
        localStorage.setItem(`${degree}EducationListPaymentGroup`, notEmptyPaymentGroup.name);
        selectGroupCallback(notEmptyPaymentGroup);
        return;
      }

      selectGroupCallback(paymentGroups.find((group) => group.name === lastViewedGroupName));
    }
  }, [paymentGroups]);

  const selectGroup = (group: PaymentInfo): void => {
    localStorage.setItem(`${degree}EducationListPaymentGroup`, group.name);
    selectGroupCallback(group);
  };

  return (
    <SelectPaymentGroupContainer>
      {paymentGroups.map((group) => (
        <PaymentGroupsButton
          type="button"
          key={group.name}
          $active={group.name === selectedGroup?.name}
          onClick={(): void => selectGroup(group)}
          disabled={group.disabled}
        >
          {group.viewedName}
        </PaymentGroupsButton>
      ))}
    </SelectPaymentGroupContainer>
  );
};

export default SelectPaymentGroup;
