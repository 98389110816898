import { SplitPostgraduateEducationForm } from "../../../interfaces/postgraduateEducationForm";
import PreviewField from "../../../../../../../../shared/form/interfaces/previewField";
import store from "../../../../../../../../../store/store";
import getSelectedProgram from "../../../utils/getSelectedProgram";

const getPostgraduateEducationFields = (form: SplitPostgraduateEducationForm): PreviewField[] => {
  const state = store.getState();
  const { dictionaries } = state.coreState.dictionariesState;
  const selectedProgram = getSelectedProgram(
    form.educationLevel,
    form.directionAndSpeciality,
    form.form,
    form.financing
  );

  return [
    {
      type: "text",
      name: "Уровень образования для поступления",
      value: selectedProgram?.level,
    },
    {
      type: "text",
      name: "Научная специальность",
      value: selectedProgram?.specialty,
    },
    {
      type: "text",
      name: "Форма",
      value: selectedProgram?.form,
    },
    {
      type: "text",
      name: "Источник финансирования",
      value: selectedProgram?.type_budget,
    },
    {
      type: "achievements",
      name: "Достижения",
      value: form.achievements,
      selectedProgram,
    },
    {
      type: "files",
      name: "Мотивационное письмо",
      value: form.letter,
    },
    {
      type: "text",
      name: "Скидки и льготы",
      value: [{ name: "Нет", id: "0" }, ...dictionaries.discounts].find(
        (item) => item.id === form.discountsAndBenefits
      )?.name,
    },
    {
      type: "files",
      name: "Загрузка документа",
      value: form.discountsConfirm,
      description: "без документа скидка не будет применена",
      hideField: !form.discountsAndBenefits || form.discountsAndBenefits === "0",
    },
  ];
};

export default getPostgraduateEducationFields;
