import Step from "../../../shared/stepper/interfaces/Step";
import Familiarization from "../components/familiarization/Familiarization";
import RequestCode from "../components/requestCode/RequestCode";
import SubmitCode from "../components/submitCode/SubmitCode";

const steps: Step[] = [
  {
    title: "Согласие на взаимодействие с подписью",
    element: (props) => <Familiarization {...props} />,
    submitButtonText: "Ознакомлен(-а)",
  },
  {
    title: "Акцепт на получение ЭП",
    element: (props) => <RequestCode {...props} />,
    submitButtonText: "Получить код",
  },
  {
    title: "Подтверждение получения ЭП",
    element: (props) => <SubmitCode {...props} />,
    submitButtonText: "Отправить",
  },
];

export default steps;
