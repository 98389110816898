import styled from "styled-components";

export default styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  button {
    padding: 0 16px;
    text-transform: none;
    font-weight: 300;
  }
  button,
  input {
    width: fit-content;
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
    &:not(&:first-child, &:last-child) {
      border-radius: 0;
      border-left: none;
    }
  }
`;
