import { AxiosResponse } from "axios";
import { $baseApi } from "../../index";
import ResponseBody from "../../models/ResponseBody";
import RequestBody from "../../models/RequestBody";
import ApplicationInfo from "../../../core/interfaces/applications/ApplicationInfo";
import degreeType from "../../../core/types/degreeType";
import graduateTypes from "../../../core/constants/graduateTypes";
import ApplicationsLists from "../../../core/interfaces/applications/ApplicationsLists";

export const getApplicationsRequest = (
  degree: degreeType
): Promise<AxiosResponse<ResponseBody<ApplicationInfo[] | ApplicationsLists>>> => {
  const response = $baseApi.post<ResponseBody<ApplicationInfo[] | ApplicationsLists>>(
    "",
    new RequestBody("application.list", {
      type: graduateTypes[degree],
    })
  );

  return degree === "high"
    ? (response as Promise<AxiosResponse<ResponseBody<ApplicationsLists>>>)
    : (response as Promise<AxiosResponse<ResponseBody<ApplicationInfo[]>>>);
};

export const getApplicationByIdRequest = (
  applicationId: string
): Promise<AxiosResponse<ResponseBody<ApplicationInfo>>> => {
  return $baseApi.post<ResponseBody<ApplicationInfo>>(
    "",
    new RequestBody("application.info", { id: applicationId })
  );
};

export const saveApplicationsPriorityRequest = (
  applications: ApplicationsLists
): Promise<AxiosResponse<ResponseBody<ApplicationsLists>>> => {
  return $baseApi.post<ResponseBody<ApplicationsLists>>(
    "",
    new RequestBody("application.savePriority", applications)
  );
};
