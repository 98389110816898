import React from "react";
import { useAppSelector } from "../../../core/hooks/redux";
import { AlertsContainer, Alert } from "./alertService.styles";
import alertTypes from "./constants/alertTypes";

const AlertService: React.FC = () => {
  const { alertState } = useAppSelector((state) => state.coreState);

  return (
    <AlertsContainer>
      {alertState.alerts.map((alert) => (
        <Alert key={alert.id} type={alert.type} autoClose={alert.autoClose}>
          {alertTypes.find((alertType) => alertType.type === alert.type).icon}
          <p>{alert.message}</p>
        </Alert>
      ))}
    </AlertsContainer>
  );
};

export default AlertService;
