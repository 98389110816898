import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchGetPostgraduateEducation } from "./postgraduateEducationThunk";
import loading from "../../../../core/types/loading";
import { PostgraduateEducationForm } from "../../../../ui/modules/applications/components/postgraduateEducation/postgraduateEducationForm/interfaces/postgraduateEducationForm";
import convertPostgraduateEducationFromSavedData from "../../../../ui/modules/applications/components/postgraduateEducation/postgraduateEducationForm/utils/convertPostgraduateEducationFromSavedData";

interface PostgraduateEducationState extends PostgraduateEducationForm {
  viewedStep: number;
  dataLoadingStatus: loading;
}

const initialState: PostgraduateEducationState = {
  viewedStep: 0,
  id: null,
  application: {
    educationLevel: "",
    educationLevelName: "",
    directionAndSpeciality: "",
    directionAndSpecialityName: "",
    form: "",
    formName: "",
    financing: "",
    financingName: "",
    program_uid: "",
    vi: [],
  },
  achievements: [],
  letter: [],
  discountsAndBenefits: {
    discountsAndBenefits: "0",
    discountsPercentage: null,
    discountsConfirm: null,
  },
  dataLoadingStatus: null,
};

const postgraduateEducationAdapter = createEntityAdapter<PostgraduateEducationState>();

const postgraduateEducationSlice = createSlice({
  name: "postgraduateEducation",
  initialState: postgraduateEducationAdapter.getInitialState(initialState),
  reducers: {
    setViewedStep: (state, action: PayloadAction<number>) => {
      state.viewedStep = action.payload;
    },
    resetPostgraduateEducationState: (state) => {
      state.viewedStep = 0;
      state.id = null;
      state.application = {
        educationLevel: "",
        educationLevelName: "",
        directionAndSpeciality: "",
        directionAndSpecialityName: "",
        form: "",
        formName: "",
        financing: "",
        financingName: "",
        program_uid: "",
        vi: [],
      };
      state.achievements = [];
      state.letter = [];
      state.discountsAndBenefits = {
        discountsAndBenefits: "0",
        discountsPercentage: null,
        discountsConfirm: null,
      };
      state.dataLoadingStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetPostgraduateEducation.pending, (state) => {
      state.dataLoadingStatus = "LOADING";
    });
    builder.addCase(fetchGetPostgraduateEducation.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.dataLoadingStatus = "ERROR";
        return;
      }
      state.dataLoadingStatus = "COMPLETE";
      if (!action.payload.payload) {
        return;
      }

      const convertedData = convertPostgraduateEducationFromSavedData(action.payload.payload);
      state.id = convertedData.id;
      if (convertedData.application) {
        state.application = convertedData.application;
      }
      if (convertedData.achievements) {
        state.achievements = convertedData.achievements;
      }
      if (convertedData.letter) {
        state.letter = convertedData.letter;
      }
      if (convertedData.discountsAndBenefits) {
        state.discountsAndBenefits = convertedData.discountsAndBenefits;
      }
    });
  },
});

export const { setViewedStep, resetPostgraduateEducationState } =
  postgraduateEducationSlice.actions;
export default postgraduateEducationSlice.reducer;
