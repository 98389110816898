import React from "react";
import dayjs from "dayjs";
import {
  Control,
  Controller,
  FieldValues,
  FormState,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import InputMask from "react-input-mask";
import {
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../../../shared/form/fieldInfo.styles";
import { FieldData } from "../../../../../../../../../shared/form/fieldData.styles";
import { Input } from "../../../../../../../../../shared/native/input.styles";
import cyrillicRegexp from "../../../../../../../../../../core/regexp/cyrillicRegexp";
import Checkbox from "../../../../../../../../../shared/checkbox/Checkbox";
import Calendar from "../../../../../../../../../shared/calendar/Calendar";
import DateRegexp from "../../../../../../../../../../core/regexp/DateRegexp";
import noUnderlines from "../../../../../../../../../../core/regexp/noUnderlines";
import { TitleH2 } from "../../../../../../../../../shared/native/typography.styles";
import isSnils from "../../../../../../../../../../core/validators/isSnils";

const PersonalForm: React.FC<{
  sectionTitle?: string;
  register: UseFormRegister<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<FieldValues>;
}> = ({ sectionTitle, register, formState: { errors }, control, setValue }) => {
  const hasNotSecondNameWatch = useWatch({
    control,
    name: "hasNotSecondName",
  });
  const hasNoSnilsWatch = useWatch({ control, name: "hasNoSnils" });

  React.useEffect(() => {
    if (hasNotSecondNameWatch) {
      setValue("secondName", "");
    }
  }, [hasNotSecondNameWatch]);

  React.useEffect(() => {
    if (hasNoSnilsWatch) {
      setValue("snils", "");
    }
  }, [hasNoSnilsWatch]);

  return (
    <>
      <FieldInfo>
        <TitleH2 style={{ textAlign: "left" }}>{sectionTitle ?? "Личные данные"}</TitleH2>
      </FieldInfo>
      <FieldData />
      <FieldInfo leftContent>
        <FieldName leftContent>Фамилия</FieldName>
        {errors.lastname && (
          <FieldError leftContent>{errors.lastname?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.lastname}
          {...register("lastname", {
            required: "Обязательное поле",
            pattern: {
              value: cyrillicRegexp,
              message: "Только киррилица",
            },
          })}
        />
      </FieldData>
      <FieldInfo>
        <FieldName leftContent>Имя</FieldName>
        {errors.firstname && (
          <FieldError leftContent>{errors.firstname?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.firstname}
          {...register("firstname", {
            required: "Обязательное поле",
            pattern: {
              value: cyrillicRegexp,
              message: "Только киррилица",
            },
          })}
        />
      </FieldData>
      <FieldInfo>
        <FieldName leftContent>Отчество</FieldName>
        {errors.secondName && (
          <FieldError leftContent>{errors.secondName?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.secondName}
          disabled={hasNotSecondNameWatch}
          {...register("secondName", {
            required: !hasNotSecondNameWatch ? "Обязательное поле" : false,
            pattern: {
              value: cyrillicRegexp,
              message: "Только киррилица",
            },
          })}
        />
        <Checkbox
          style={{ marginLeft: 8 }}
          label="Отсутствует"
          control={control}
          name="hasNotSecondName"
        />
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>Дата рождения</FieldName>
        {errors.dateOfBirth && (
          <FieldError leftContent>{errors.dateOfBirth?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Calendar
          className="defaultCalendar"
          placeholder="ДД.ММ.ГГГГ"
          control={control}
          name="dateOfBirth"
          minDate={new Date("01.01.1930")}
          maxDate={dayjs().subtract(15, "year").toDate()}
          options={{
            required: "Обязательное поле",
            pattern: {
              value: DateRegexp,
              message: "Неверная дата",
            },
          }}
          defaultActiveStartDate={dayjs().subtract(15, "year").toDate()}
        />
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>Телефон</FieldName>
        {errors.phone && <FieldError leftContent>{errors.phone?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Controller
          name="phone"
          control={control}
          rules={{
            required: "Обязательное поле",
            pattern: {
              value: noUnderlines,
              message: "Неполное значение",
            },
          }}
          render={({ field: { value, onChange } }): React.ReactElement => (
            <InputMask mask="+9 (999) 999-99-99" value={value} onChange={onChange}>
              <Input
                className="defaultInput"
                placeholder="+X (XXX) XXX-XX-XX"
                hasErrors={!!errors.phone}
              />
            </InputMask>
          )}
        />
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>СНИЛС</FieldName>
        {errors.snils && <FieldError leftContent>{errors.snils?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Controller
          name="snils"
          control={control}
          rules={{
            required: !hasNoSnilsWatch ? "Обязательное поле" : false,
            pattern: {
              value: noUnderlines,
              message: "Неполное значение",
            },
            validate: (value) => (!hasNoSnilsWatch ? isSnils(value) : true),
          }}
          render={({ field: { value, onChange } }): React.ReactElement => (
            <InputMask
              mask="999-999-999 99"
              value={value}
              onChange={onChange}
              disabled={hasNoSnilsWatch}
            >
              <Input
                className="defaultInput"
                placeholder="XXX-XXX-XXX YY"
                hasErrors={!!errors.snils}
              />
            </InputMask>
          )}
        />
        <Checkbox
          style={{ marginLeft: 8 }}
          label="Отсутствует"
          control={control}
          name="hasNoSnils"
        />
      </FieldData>
    </>
  );
};

export default PersonalForm;
