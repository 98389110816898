import DocumentInfo from "../interfaces/DocumentInfo";
import isSnils from "../../../../../../core/validators/isSnils";

const documentsInfo: { [key: string]: DocumentInfo } = {
  "000000098": {
    mask: "999-999-999 99",
    placeholder: "Введите СНИЛС",
    validate: isSnils,
  },
};

export default documentsInfo;
