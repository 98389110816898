import { DaDataAddress, DaDataSuggestion } from "react-dadata/dist/types";
import Address from "../interfaces/Address";

export default (address: Address): string => {
  return `${address.country}, ${(address.region as DaDataSuggestion<DaDataAddress>).value}, ${
    (address.city as DaDataSuggestion<DaDataAddress>).value
  }, ${(address.street as DaDataSuggestion<DaDataAddress>).value}, ${
    (address.house as DaDataSuggestion<DaDataAddress>).value
  }, ${address?.flat?.length ? `кв. ${address.flat}, ` : ""} ${address.index}`;
};
