import { combineReducers } from "@reduxjs/toolkit";
import profileFormSlice from "./profile/profileForm/profileFormSlice";
import applicationsState from "./applications/applicationsStore";
import notificationsSlice from "./notifications/notificationsSlice";
import signatureSlice from "./signature/signatureSlice";

export default combineReducers({
  profileFormState: profileFormSlice,
  signatureState: signatureSlice,
  applicationsState,
  notificationsState: notificationsSlice,
});
