import React from "react";
import { Control, FieldValues, FormState, UseFormSetValue, useWatch } from "react-hook-form";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import {
  FieldDescription,
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import { AddFileContainer, FieldData } from "../../../../../../../shared/form/fieldData.styles";
import Select from "../../../../../../../shared/select/Select";
import AddFileButton from "../../../../../../../shared/addFileButton/AddFileButton";
import Checkbox from "../../../../../../../shared/checkbox/Checkbox";
import { useAppSelector } from "../../../../../../../../core/hooks/redux";
import { SplitAdditionalPostgraduateDataForm } from "../../interfaces/additionalPostgraduateDataForm";
import AddFileInfo from "../../../../../../../shared/addFileInfo/AddFileInfo";

const EducationInfoForm: React.FC<{
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<SplitAdditionalPostgraduateDataForm>;
}> = ({ formState: { errors }, control, setValue }) => {
  const { dictionariesState } = useAppSelector((state) => state.coreState);
  const graduateOfMIPWatch = useWatch({
    control,
    name: "graduateOfMIP",
  });

  React.useEffect(() => {
    if (!graduateOfMIPWatch) {
      setValue("educationDocumentType", "");
      setValue("serial", "");
      setValue("number", "");
      setValue("issuedByWhom", "");
      setValue("dateOfIssue", "");
      setValue("hasOvz", "1");
      setValue("disabilityType", "0");
      setValue("disabilityDocument", null);
      setValue("specialConditions", "2");
      setValue("nosology", "1");
    }
  }, [graduateOfMIPWatch]);

  return (
    <FormContainer>
      <FieldInfo>
        <FieldName>Уровень образования</FieldName>
        <FieldDescription>на основании вашего документа об образовании</FieldDescription>
        {errors.educationLevel && (
          <FieldError>{errors.educationLevel?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        {dictionariesState?.dictionaries && (
          <Select
            className="defaultSelect"
            placeholder="Выберите уровень"
            items={dictionariesState.dictionaries.edu_type_asp.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            control={control}
            options={{
              required: "Обязательное поле",
            }}
            name="educationLevel"
          />
        )}
      </FieldData>
      <FieldInfo>
        <FieldName>Загрузка документов</FieldName>
        <FieldDescription>можно прикрепить одновременно несколько файлов</FieldDescription>
        {(errors.educationDocument || errors.educationDocumentAppendix) && (
          <FieldError>
            {(errors.educationDocument?.message as string) ??
              (errors.educationDocumentAppendix?.message as string)}
          </FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <AddFileContainer>
          <AddFileButton
            name="educationDocument"
            control={control}
            options={{ required: "Обязательные поля" }}
            setValue={setValue}
            accept={"image/*"}
            multiple
          >
            Документ об образовании
          </AddFileButton>
          <AddFileButton
            name="educationDocumentAppendix"
            control={control}
            options={{ required: "Обязательные поля" }}
            setValue={setValue}
            accept={"image/*"}
            multiple
            description="(Все страницы)"
          >
            Приложение к документу
          </AddFileButton>
        </AddFileContainer>
      </FieldData>
      <FieldInfo />
      <FieldData>
        <Checkbox
          style={{ marginTop: 8 }}
          label="Выпускник специалитета/магистратуры МИП"
          control={control}
          name="graduateOfMIP"
        />
      </FieldData>
      <FieldInfo />
      <FieldData>
        <AddFileInfo />
      </FieldData>
    </FormContainer>
  );
};

export default EducationInfoForm;
