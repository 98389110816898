import { ValidateResult } from "react-hook-form";
import dayjs from "dayjs";
import dateFormat from "../constants/dateFormat";

const isMinMax = (value: string, minDate: Date, maxDate: Date): ValidateResult => {
  if (minDate && dayjs(value, dateFormat).isBefore(minDate)) {
    return "Дата меньше допустимой";
  }

  if (maxDate && dayjs(value, dateFormat).isAfter(maxDate)) {
    return "Дата больше допустимой";
  }

  return true;
};

export default isMinMax;
