import React, { HTMLAttributes } from "react";
import { ImageCardContainer } from "./imageCard.styles";
import { TitleH3 } from "../../native/typography.styles";

const ImageCard: React.FC<
  {
    name: string;
    image: string;
    hoverImage?: string;
    footer: string;
    activeByDefault?: boolean;
  } & HTMLAttributes<HTMLDivElement>
> = ({ name, image, hoverImage, footer, activeByDefault, ...attrs }) => {
  return (
    <ImageCardContainer hasHoverImage={!!hoverImage} activeByDefault={activeByDefault} {...attrs}>
      <div className="content">
        <img className="defaultImg" src={image} alt={name} />
        {!!hoverImage && <img className="hoverImg" src={hoverImage} alt={name} />}
      </div>
      <div className="footer">
        <TitleH3>{footer}</TitleH3>
      </div>
    </ImageCardContainer>
  );
};

export default ImageCard;
