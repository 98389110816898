import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAdditionalEducationFormRequest } from "../../../../http/requests/applications/additionalEducation.http";

export const fetchGetAdditionalEducation = createAsyncThunk(
  "additionalEducation/get",
  async (applicationId: number) => {
    const response = await getAdditionalEducationFormRequest(applicationId);
    return response.data;
  }
);
