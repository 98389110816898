import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ApplicationInfoContainer,
  GoBackButton,
  Header,
  NotificationsContainer,
} from "./applicationInfo.styles";
import { NoContentText, TitleH1, TitleH2 } from "../../../../shared/native/typography.styles";
import { ReactComponent as LineArrowIcon } from "../../../../../core/static/icons/line-arrow.svg";
// import useIsMobile from "../../../../../core/hooks/UseIsMobile";
import loading from "../../../../../core/types/loading";
import Loader from "../../../../shared/loaders/Loader";
import Notification from "../../interfaces/Notification";
import notificationTypeComponents from "./constants/notificationTypeComponents";
import getDetailingNotificationsList from "./utils/getDetailingNotificationsList";
import { useAppDispatch, useAppSelector } from "../../../../../core/hooks/redux";
import { setNotifications } from "../../../../../store/modules/notifications/notificationsSlice";
import graduateTypes from "../../../../../core/constants/graduateTypes";
import { getApplicationByIdRequest } from "../../../../../http/requests/applications/applications.http";
import ApplicationInfoInterface from "../../../../../core/interfaces/applications/ApplicationInfo";
import formatDate from "../../../../../core/utils/formatDate";
import degreeType from "../../../../../core/types/degreeType";

const ApplicationInfo: React.FC<{
  degree: degreeType;
  isGeneral?: boolean;
}> = ({ degree, isGeneral }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { notificationsState } = useAppSelector((state) => state.modulesState);
  const [applicationInfo, setApplicationInfo] = React.useState<ApplicationInfoInterface>(null);
  const [loadingStatus, setLoadingStatus] = React.useState<loading>(null);
  // const [isMobile, setIsMobile] = React.useState<boolean>(window.innerWidth < 1040);

  // useIsMobile(setIsMobile);

  const goBackHandler = (): void => {
    navigate(`/notifications/${degree}-education`);
  };

  const getNotificationCard = (notification: Notification): React.ReactNode => {
    if (![0, 1, 2, 3, 4, 5, 9, 10, 11, 333].includes(notification.notification_type)) {
      return null;
    }

    return notificationTypeComponents[notification.notification_type](notification, degree);
  };

  React.useEffect(() => {
    const applicationId = location.pathname.split("/")[3];

    setLoadingStatus("LOADING");
    getDetailingNotificationsList(
      graduateTypes[degree] as "vpo" | "dpo" | "asp",
      isGeneral ? null : applicationId
    )
      .then((list) => {
        dispatch(setNotifications(list));
        setLoadingStatus("COMPLETE");
      })
      .catch(() => {
        setLoadingStatus("ERROR");
      });

    if (isGeneral) {
      return;
    }

    getApplicationByIdRequest(applicationId).then((applicationByIdRes) => {
      setApplicationInfo(applicationByIdRes.data.payload);
    });
  }, []);

  return (
    <ApplicationInfoContainer>
      <GoBackButton onClick={goBackHandler}>
        <LineArrowIcon />
        <span>К уведомлениям</span>
      </GoBackButton>
      <Header>
        <TitleH1 className="date">
          {isGeneral
            ? "Общие документы"
            : `Заявление от ${
                applicationInfo?.datetime
                  ? formatDate(applicationInfo.datetime.split(" ")?.[0])
                  : "(дата отсутствует)"
              }`}
        </TitleH1>
        <TitleH2 className="program">
          {isGeneral ? "Проверка документов" : applicationInfo?.programm || "Программа отсутствует"}
        </TitleH2>
      </Header>
      {loadingStatus !== "LOADING" ? (
        <>
          {/* <StatusesContainer> */}
          {/*  <StatusCard title="Договор" /> */}
          {/*  <StatusCard title="Оплата" /> */}
          {/*  <StatusCard title="Исполнено" /> */}
          {/* </StatusesContainer> */}
          <NotificationsContainer isGeneral>
            {notificationsState.notifications?.length ? (
              notificationsState.notifications.map((notification) => (
                <React.Fragment key={notification.uid}>
                  {getNotificationCard(notification)}
                </React.Fragment>
              ))
            ) : (
              <NoContentText>Уведомления отсутствуют</NoContentText>
            )}
          </NotificationsContainer>
        </>
      ) : (
        <Loader type="spinner" />
      )}
    </ApplicationInfoContainer>
  );
};

export default ApplicationInfo;
