import React from "react";
import {
  Control,
  Controller,
  FieldValues,
  FormState,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import InputMask from "react-input-mask";
import {
  FieldDescription,
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../../../shared/form/fieldInfo.styles";
import { FieldData } from "../../../../../../../../../shared/form/fieldData.styles";
import Select from "../../../../../../../../../shared/select/Select";
import personalDocumentSelectItems from "../../../../../../../../../../core/constants/personalDocumentSelectItems";
import SelectItem from "../../../../../../../../../shared/select/SelectItem";
import { Input } from "../../../../../../../../../shared/native/input.styles";
import noUnderlines from "../../../../../../../../../../core/regexp/noUnderlines";
import Calendar from "../../../../../../../../../shared/calendar/Calendar";
import DateRegexp from "../../../../../../../../../../core/regexp/DateRegexp";
import { useAppSelector } from "../../../../../../../../../../core/hooks/redux";
import { TitleH2 } from "../../../../../../../../../shared/native/typography.styles";

const PassportForm: React.FC<{
  register: UseFormRegister<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<FieldValues>;
}> = ({ register, formState: { errors }, control, setValue }) => {
  const { dictionariesState } = useAppSelector((state) => state.coreState);
  const documentTypeWatch = useWatch({ control, name: "documentType" });

  React.useEffect(() => {
    if (documentTypeWatch === "000000047") {
      setValue("citizenship", "");
      setValue("serialAndNumber", "");
      return;
    }

    setValue("serial", "");
    setValue("number", "");
  }, [documentTypeWatch]);

  return (
    <>
      <FieldInfo>
        <TitleH2 style={{ textAlign: "left" }}>Паспортные данные</TitleH2>
      </FieldInfo>
      <FieldData />
      <FieldInfo leftContent>
        <FieldName leftContent>Тип документа</FieldName>
        <FieldDescription leftContent>Удостоверяющего личность</FieldDescription>
        {errors.documentType && (
          <FieldError leftContent>{errors.documentType?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          items={personalDocumentSelectItems}
          placeholder="Выберите документ"
          control={control}
          name="documentType"
          options={{ required: "Обязательное поле" }}
        />
      </FieldData>
      {documentTypeWatch !== "000000047" && (
        <>
          <FieldInfo leftContent>
            <FieldName leftContent>Гражданство</FieldName>
            {errors.citizenship && (
              <FieldError leftContent>{errors.citizenship?.message as string}</FieldError>
            )}
          </FieldInfo>
          <FieldData>
            {!!dictionariesState.dictionaries?.country && (
              <Select
                className="defaultSelect"
                items={dictionariesState.dictionaries.country.map(
                  ({ id, name }) => ({ label: name, value: id } as SelectItem)
                )}
                placeholder="Выберите гражданство"
                control={control}
                name="citizenship"
                options={{
                  required: documentTypeWatch === "000000049" ? "Обязательное поле" : false,
                }}
              />
            )}
          </FieldData>
        </>
      )}
      <FieldInfo leftContent>
        <FieldName leftContent>Серия и номер</FieldName>
        {(errors.serial || errors.number || errors.serialAndNumber) && (
          <FieldError leftContent>
            {(errors.serial?.message as string) ??
              (errors.number?.message as string) ??
              (errors.serialAndNumber?.message as string)}
          </FieldError>
        )}
      </FieldInfo>
      <FieldData>
        {documentTypeWatch !== "000000047" ? (
          <Input
            className="defaultInput"
            hasErrors={!!errors.serialAndNumber}
            placeholder="Начните вводить"
            {...register("serialAndNumber", {
              required: "Обязательное поле",
            })}
          />
        ) : (
          <>
            <Controller
              name="serial"
              control={control}
              rules={{
                required: "Обязательное поле",
                pattern: {
                  value: noUnderlines,
                  message: "Неполное значение",
                },
              }}
              render={({ field: { value, onChange } }): React.ReactElement => (
                <InputMask mask="99 99" value={value} onChange={onChange}>
                  <Input
                    placeholder="XX XX"
                    hasErrors={!!errors.serial}
                    style={{
                      width: 48,
                      marginRight: 8,
                    }}
                  />
                </InputMask>
              )}
            />
            <Controller
              name="number"
              control={control}
              rules={{
                required: "Обязательное поле",
                pattern: {
                  value: noUnderlines,
                  message: "Неполное значение",
                },
              }}
              render={({ field: { value, onChange } }): React.ReactElement => (
                <InputMask mask="999999" value={value} onChange={onChange}>
                  <Input
                    placeholder="YYYYYY"
                    hasErrors={!!errors.number}
                    style={{
                      width: 64,
                    }}
                  />
                </InputMask>
              )}
            />
          </>
        )}
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>Дата выдачи</FieldName>
        {errors.dateOfIssue && (
          <FieldError leftContent>{errors.dateOfIssue?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Calendar
          className="defaultCalendar"
          placeholder="ДД.ММ.ГГГГ"
          control={control}
          name="dateOfIssue"
          maxDate={new Date()}
          options={{
            required: "Обязательное поле",
            pattern: {
              value: DateRegexp,
              message: "Неверная дата",
            },
          }}
          defaultActiveStartDate={new Date()}
        />
      </FieldData>
      <FieldInfo leftContent>
        <FieldName leftContent>Кем выдан</FieldName>
        {errors.issuedByWhom && (
          <FieldError leftContent>{errors.issuedByWhom?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.issuedByWhom}
          {...register("issuedByWhom", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>
      {documentTypeWatch === "000000047" && (
        <>
          <FieldInfo leftContent>
            <FieldName leftContent>Код подразделения</FieldName>
            {errors.divisionCode && (
              <FieldError leftContent>{errors.divisionCode?.message as string}</FieldError>
            )}
          </FieldInfo>
          <FieldData>
            <Controller
              name="divisionCode"
              control={control}
              rules={{
                required: "Обязательное поле",
                pattern: {
                  value: noUnderlines,
                  message: "Неполное значение",
                },
              }}
              render={({ field: { value, onChange } }): React.ReactElement => (
                <InputMask mask="999-999" value={value} onChange={onChange}>
                  <Input
                    placeholder="XXX-XXX"
                    style={{ width: 70 }}
                    hasErrors={!!errors.divisionCode}
                  />
                </InputMask>
              )}
            />
          </FieldData>
        </>
      )}
    </>
  );
};

export default PassportForm;
