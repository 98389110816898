import React from "react";
import { useNavigate } from "react-router-dom";
import {
  InfoPage,
  InfoPageContent,
  InfoPageTextInfo,
} from "../../../../shared/infoPage/infoPage.styles";
import { TitleH1 } from "../../../../shared/native/typography.styles";
import { PrimaryButton } from "../../../../shared/native/button.styles";
import { getSignatureInfoRequest } from "../../../../../http/requests/signature.http";
import loading from "../../../../../core/types/loading";
import Loader from "../../../../shared/loaders/Loader";
import SignatureInfoInterface from "../../interfaces/SignatureInfo";

const SignatureInfo: React.FC = () => {
  const navigate = useNavigate();
  const [loadingStatus, setLoadingStatus] = React.useState<loading>(null);
  const [signatureInfo, setSignatureInfo] = React.useState<SignatureInfoInterface>(null);

  React.useEffect(() => {
    setLoadingStatus("LOADING");
    getSignatureInfoRequest().then((res) => {
      if (res.data.error) {
        setLoadingStatus("ERROR");
        return;
      }
      setSignatureInfo(res.data.payload);
      setLoadingStatus("COMPLETE");
    });
  }, []);

  if (loadingStatus === "LOADING") {
    return (
      <InfoPage>
        <Loader type="spinner" />
      </InfoPage>
    );
  }

  return (
    <InfoPage>
      <InfoPageContent>
        <InfoPageTextInfo>
          <TitleH1 style={{ textAlign: "center" }}>Данные ЭП</TitleH1>
          <span>Ваша электронная подпись готова.</span>
          <p>
            Дата начала действия: {signatureInfo?.date_begin.split(" ")[0]}
            <br />
            Дата окончания действия: {signatureInfo?.date_end.split(" ")[0]}
          </p>
        </InfoPageTextInfo>
        <PrimaryButton onClick={(): void => navigate(`/notifications`)}>
          К уведомлениям
        </PrimaryButton>
      </InfoPageContent>
    </InfoPage>
  );
};

export default SignatureInfo;
