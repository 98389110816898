import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import PersonalDataForm from "../../../../ui/modules/profile/interfaces/PersonalDataForm";
import ContactsDataForm from "../../../../ui/modules/profile/interfaces/ContactsDataForm";
import PassportDataForm from "../../../../ui/modules/profile/interfaces/PassportDataForm";
import ResidingPlaceDataForm from "../../../../ui/modules/profile/interfaces/ResidingPlaceDataForm";
import { fetchGetProfileForm } from "./profileThunk";
import convertFromSavedData from "../../../../ui/modules/profile/utils/convertFromSavedData";
import loading from "../../../../core/types/loading";

interface ProfileFormState {
  viewedStep: number;
  personalData: PersonalDataForm;
  contactsData: ContactsDataForm;
  passportData: PassportDataForm;
  residingPlaceData: ResidingPlaceDataForm;
  dataLoadingStatus: loading;
}

const initialState: ProfileFormState = {
  viewedStep: 0,
  personalData: {
    firstname: "",
    lastname: "",
    secondName: "",
    hasNotSecondName: false,
    gender: null,
    dateOfBirth: "",
  },
  contactsData: {
    snils: "",
    hasNotSnils: false,
    email: "",
    phone: "",
    photo: null,
  },
  passportData: {
    documentType: "",
    serial: "",
    number: "",
    serialAndNumber: "",
    dateOfIssue: "",
    issuedByWhom: "",
    divisionCode: "",
    citizenship: "",
  },
  residingPlaceData: {
    residingCoincidesRegistration: false,
    registerAddress: {
      country: "",
      region: "",
      city: "",
      street: "",
      house: "",
      flat: "",
      index: "",
    },
    residingAddress: {
      country: "",
      region: "",
      city: "",
      street: "",
      house: "",
      flat: "",
      index: "",
    },
    placeOfBirth: "",
    mainPageOfPassport: null,
    registrationPageOfPassport: null,
  },
  dataLoadingStatus: null,
};

const profileFormAdapter = createEntityAdapter<ProfileFormState>();

const profileFormSlice = createSlice({
  name: "profileForm",
  initialState: profileFormAdapter.getInitialState(initialState),
  reducers: {
    setViewedStep: (state, action: PayloadAction<number>) => {
      state.viewedStep = action.payload;
    },
    resetProfileState: (state) => {
      state.viewedStep = 0;
      state.personalData = {
        firstname: "",
        lastname: "",
        secondName: "",
        hasNotSecondName: false,
        gender: null,
        dateOfBirth: "",
      };
      state.contactsData = {
        snils: "",
        hasNotSnils: false,
        email: "",
        phone: "",
        photo: null,
      };
      state.passportData = {
        documentType: "",
        serial: "",
        number: "",
        serialAndNumber: "",
        dateOfIssue: "",
        issuedByWhom: "",
        divisionCode: "",
        citizenship: "",
      };
      state.residingPlaceData = {
        residingCoincidesRegistration: false,
        registerAddress: {
          country: "",
          region: "",
          city: "",
          street: "",
          house: "",
          flat: "",
          index: "",
        },
        residingAddress: {
          country: "",
          region: "",
          city: "",
          street: "",
          house: "",
          flat: "",
          index: "",
        },
        placeOfBirth: "",
        mainPageOfPassport: null,
        registrationPageOfPassport: null,
      };
      state.dataLoadingStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetProfileForm.pending, (state) => {
      state.dataLoadingStatus = "LOADING";
    });
    builder.addCase(fetchGetProfileForm.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.dataLoadingStatus = "ERROR";
        return;
      }
      state.dataLoadingStatus = "COMPLETE";
      if (!action.payload.payload) {
        return;
      }

      const convertedData = convertFromSavedData(action.payload.payload);
      if (convertedData.personalData) {
        state.personalData = convertedData.personalData;
      }
      if (convertedData.contactsData) {
        state.contactsData = convertedData.contactsData;
      }
      if (convertedData.passportData) {
        state.passportData = convertedData.passportData;
      }
      if (convertedData.residingPlaceData) {
        state.residingPlaceData = convertedData.residingPlaceData;
      }
    });
  },
});

export const { setViewedStep, resetProfileState } = profileFormSlice.actions;
export default profileFormSlice.reducer;
