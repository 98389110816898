import { SplitAdditionalDataForm } from "../../../interfaces/additionalDataForm";
import PreviewField from "../../../../../../../../shared/form/interfaces/previewField";
import DictionaryItem from "../../../../../../../../../core/interfaces/dictionaries/DictionaryItem";
import specialConditionsSelectItems from "../../../constants/specialConditionsSelectItems";
import nosologySelectItems from "../../../constants/nosologySelectItems";
import store from "../../../../../../../../../store/store";
import ovzSelectItems from "../../../forms/healthLimitationsForm/constants/ovzSelectItems";

const getAdditionalDataFields = (form: SplitAdditionalDataForm): PreviewField[] => {
  const state = store.getState();

  return [
    {
      type: "text",
      name: "Уровень образования",
      value: state.coreState.dictionariesState.dictionaries?.edu_type.find(
        (eduType: DictionaryItem) => eduType.id === form.educationLevel
      )?.name,
      description: "который вы получили ранее и на основании которого вы поступаете на ВО",
    },
    {
      type: "text",
      name: "Регион получения образования",
      value: form.educationRegion,
    },
    {
      type: "files",
      name: "Загрузка документов",
      value: [...form.educationDocument, ...form.educationDocumentAppendix],
    },
    {
      type: "checkbox",
      name: "Поступаю в порядке перевода из другого вуза",
      value: form.transferredFromAnotherUniversity,
    },
    {
      type: "text",
      name: "Документ об образовании",
      value: state.coreState.dictionariesState.dictionaries?.edu_type
        .find((eduTypeItem) => eduTypeItem.id === form.educationLevel)
        ?.docyments_type.find(
          (documentTypeItem) => documentTypeItem.id === form.educationDocumentType
        )?.name,
    },
    {
      type: "text",
      name: "Серия и номер",
      value: `${form.serial} ${form.number}`,
    },
    {
      type: "text",
      name: "Кем выдан",
      value: form.issuedByWhom,
    },
    {
      type: "text",
      name: "Дата выдачи",
      value: form.dateOfIssue,
    },
    {
      type: "text",
      name: "Код партнера",
      value: form.ownerCode,
      description: "(При наличии)",
    },
    {
      type: "files",
      name: "Справка об обучении",
      value: form.trainingCertificate,
      hideField: !form.nameOfUniversity?.length,
    },
    {
      type: "text",
      name: "Наименование вуза",
      value: form.nameOfUniversity,
      hideField: !form.nameOfUniversity?.length,
    },
    {
      type: "text",
      name: "Регистрационный номер справки",
      value: form.certificateRegistrationNumber,
      hideField: !form.nameOfUniversity?.length,
    },
    {
      type: "text",
      name: "Дата выдачи справки",
      value: form.certificateDateIssue,
      hideField: !form.nameOfUniversity?.length,
    },
    {
      type: "files",
      name: "Загрузка справок",
      value: [
        ...(form.certificateAboutOfStudentStatus ?? []),
        ...(form.certificateAboutOfUniversityLicense ?? []),
      ],
      hideField: !form.nameOfUniversity?.length,
    },
    {
      type: "text",
      name: "Ограниченные возможности",
      value: ovzSelectItems.find((ovzItem) => ovzItem.value === form.hasOvz)?.label ?? null,
    },
    {
      type: "text",
      name: "Инвалидность",
      value:
        state.coreState.dictionariesState.dictionaries?.category_ovz.find(
          (categoryOvzItem) => categoryOvzItem.id === form.disabilityType
        )?.name ?? null,
    },
    {
      type: "files",
      name: "Подтверждение инвалидности",
      value: form.disabilityDocument ?? [],
      hideField: form.disabilityType === "0",
    },
    {
      type: "text",
      name: "Специальные условия",
      value:
        specialConditionsSelectItems.find((item) => item.value === form.specialConditions)?.label ??
        "",
      description:
        "необходимые для прохождения вступительного испытания, исходя из вашей нозологии",
    },
    {
      type: "text",
      name: "Нозология",
      value: nosologySelectItems.find((item) => item.value === form.nosology)?.label ?? "",
    },
  ];
};

export default getAdditionalDataFields;
