import styled from "styled-components";
import sizes from "../../../../../../../../../core/styles/sizes";

export const ExamItem = styled.div`
  width: 100%;
  display: flex;
  .selectExam {
    width: 224px;
    margin-right: 8px;
  }
  .selectYear {
    width: 95px;
  }

  @media all and (${sizes.mobile}) {
    flex-wrap: wrap;
  }

  @media all and (max-width: 584px) {
    .selectExam {
      margin-bottom: 8px;
    }
  }
`;
