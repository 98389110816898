import styled from "styled-components";
import colors from "../../../core/styles/colors";
import sizes from "../../../core/styles/sizes";
import zIndex from "../../../core/styles/z-index";

export const StyledSelectContainer = styled.div<{ open: boolean; hideArrow: boolean }>`
  position: relative;
  .selectInput {
    width: calc(100% - 54px);
    padding-right: 36px;
    border-radius: ${(props): string => (props.open ? "10px 10px 0 0" : "10px 10px 10px 10px")};
    &:focus {
      border-bottom: ${(props): string =>
        props.open ? `2px solid ${colors.white.hoverWhite3}` : `2px solid ${colors.gray.gray200}`};
    }
  }
  &:before,
  &:after {
    pointer-events: none;
    z-index: ${zIndex.topElements - 1};
    position: absolute;
    display: ${(props): string => (props.hideArrow ? "none" : "block")};
    content: "";
    background: ${colors.red.general};
    width: 10px;
    height: 2px;
    border-radius: 5px;
  }
  &:before {
    top: 24px;
    right: 22px;
    transform: rotate(${(props): string => (props.open ? "-45deg" : "45deg")});
  }
  &:after {
    top: 24px;
    right: 16px;
    transform: rotate(${(props): string => (props.open ? "45deg" : "-45deg")});
  }
  &:has(input:disabled)&:before {
    background: ${colors.white.disabledText};
  }
  &:has(input:disabled)&:after {
    background: ${colors.white.disabledText};
  }

  @media all and (${sizes.mobile}) {
    .selectInput {
      width: calc(100% - 55px);
    }
  }
`;

export const DropdownContainer = styled.div<{ width?: number; whiteBackground: boolean }>`
  width: ${(props): string => (props.width ? `${props.width - 24}px` : "auto")};
  position: fixed;
  z-index: ${zIndex.modal - 1};
  max-height: 180px;
  overflow-y: auto;
  padding: 10px;
  background: ${(props): string =>
    props.whiteBackground ? colors.white.general : colors.white.hoverWhite3};
  border-radius: 0 0 10px 10px;
  border: 2px solid ${colors.gray.gray200};
  border-top: 1px solid ${colors.white.white700};
  .noContent {
    margin: 0;
    padding: 8px;
    color: ${colors.gray.gray300};
    font-family: Gilroy, serif;
    font-size: 16px;
  }

  @media all and (${sizes.mobile}) {
    max-height: 180px;
  }
`;

export const StyledSelectItem = styled.div<{ $active: boolean; whiteBackground: boolean }>`
  padding: 8px;
  border-radius: 5px;
  font-family: Gilroy, serif;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  ${(props): string =>
    props.$active &&
    `background: ${colors.red.general};
    color: ${colors.white.general};`}
  &:hover {
    background: ${(props): string =>
      props.whiteBackground ? colors.white.hoverWhite2 : colors.white.white700};
  }
  input {
    appearance: none;
  }
`;
