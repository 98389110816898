interface Environments {
  ENV: "dev" | "test" | "prod";
  API_URL: string;
  DADATA_TOKEN: string;
}

// const development: Environments = {
//   ENV: "dev",
//   API_URL: "https://devpriem.instudy.online/api/",
//   DADATA_TOKEN: "82d3af0030ddcccb783dd595af02440f4409bfff",
// };
//
// const test: Environments = {
//   ENV: "test",
//   API_URL: "https://devpriem.instudy.online/api/",
//   DADATA_TOKEN: "82d3af0030ddcccb783dd595af02440f4409bfff",
// };

const production: Environments = {
  ENV: "prod",
  API_URL: "https://priem.instudy.online/api/",
  DADATA_TOKEN: "82d3af0030ddcccb783dd595af02440f4409bfff",
};

const environments = (): Environments => {
  switch (process.env.NODE_ENV) {
    case "production": {
      return production;
    }
    default: {
      return production;
    }
  }
};

export default environments();
