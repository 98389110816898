import styled from "styled-components";
import colors from "../../../core/styles/colors";

export default styled.span`
  position: relative;
  width: 100px;
  height: 130px;
  background: #fff;
  border-radius: 4px;
  &:before {
    content: "";
    position: absolute;
    width: 54px;
    height: 25px;
    left: 50%;
    top: 0;
    background-image: radial-gradient(
        ellipse at center,
        ${colors.transparent} 24%,
        ${colors.red.general} 25%,
        ${colors.red.general} 64%,
        ${colors.transparent} 65%
      ),
      linear-gradient(to bottom, ${colors.transparent} 34%, ${colors.red.general} 35%);
    background-size: 12px 12px, 100% auto;
    background-repeat: no-repeat;
    background-position: center top;
    transform: translate(-50%, -65%);
    box-shadow: 0 -3px rgba(0, 0, 0, 0.25) inset;
  }
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
    width: 66%;
    height: 60%;
    background: linear-gradient(to bottom, ${colors.red.light} 30%, ${colors.transparent} 31%);
    background-size: 100% 16px;
    animation: writeDown 2s ease-out infinite;
  }

  @keyframes writeDown {
    0% {
      height: 0%;
      opacity: 0;
    }
    20% {
      height: 0%;
      opacity: 1;
    }
    80% {
      height: 65%;
      opacity: 1;
    }
    100% {
      height: 65%;
      opacity: 0;
    }
  }
`;
