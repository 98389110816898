import { HighEducationForm } from "../interfaces/highEducationForm";
import convertFile from "../../../../../../../core/utils/convertFile";

const convertHighEducationFromSavedData = (data: HighEducationForm): HighEducationForm => ({
  id: data.id,
  application: data?.application ?? null,
  entranceExams: data?.entranceExams ?? null,
  discountsAndBenefits: data?.discountsAndBenefits
    ? {
        achievements: data.discountsAndBenefits.achievements.map((fields) => ({
          achievement: fields.achievement,
          document: convertFile("from", fields.document),
        })),
        discountsAndBenefits: data.discountsAndBenefits.discountsAndBenefits,
        discountsPercentage: data.discountsAndBenefits.discountsPercentage,
        discountsConfirm: convertFile("from", data.discountsAndBenefits.discountsConfirm),
      }
    : null,
});

export default convertHighEducationFromSavedData;
