import store from "../../../../../../../store/store";
import PreviewField from "../../../../../../shared/form/interfaces/previewField";
import DictionaryItem from "../../../../../../../core/interfaces/dictionaries/DictionaryItem";
import { SplitAdditionalAeDataForm } from "../interfaces/additionalAEDataForm";

const getAdditionalAEFields = (form: SplitAdditionalAeDataForm): PreviewField[] => {
  const state = store.getState();

  return [
    {
      type: "text",
      name: "Уровень образования",
      value: state.coreState.dictionariesState.dictionaries?.edu_type_dpo.find(
        (eduType: DictionaryItem) => eduType.id === form.educationLevel
      )?.name,
    },
    {
      type: "text",
      name: "Документ об образовании",
      value: state.coreState.dictionariesState.dictionaries?.edu_type_dpo
        .find((eduTypeItem) => eduTypeItem.id === form.educationLevel)
        ?.docyments_type.find(
          (documentTypeItem) => documentTypeItem.id === form.educationDocumentType
        )?.name,
    },
    {
      type: "text",
      name: "Серия и номер",
      value: `${form.serial} ${form.number}`,
    },
    {
      type: "text",
      name: "Кем выдан",
      value: form.issuedByWhom,
    },
    {
      type: "text",
      name: "Дата выдачи",
      value: form.dateOfIssue,
    },
    {
      type: "files",
      name: "Загрузка документов",
      value: [...form.educationDocument, ...form.educationDocumentAppendix],
    },
    {
      type: "text",
      name: "Тип организации",
      value: state.coreState.dictionariesState.dictionaries?.type_organization.find(
        (orgType: DictionaryItem) => orgType.id === form.organizationType
      )?.name,
    },
    {
      type: "text",
      name: "Должность",
      value: state.coreState.dictionariesState.dictionaries?.positionsDPO.find(
        (position) => position.id === form.position
      )?.name,
    },
  ];
};

export default getAdditionalAEFields;
