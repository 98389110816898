import React from "react";
import {
  Control,
  FieldValues,
  FormState,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import {
  FieldDescription,
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import { AddFileContainer, FieldData } from "../../../../../../../shared/form/fieldData.styles";
import Select from "../../../../../../../shared/select/Select";
import { Input } from "../../../../../../../shared/native/input.styles";
import AddFileButton from "../../../../../../../shared/addFileButton/AddFileButton";
import Checkbox from "../../../../../../../shared/checkbox/Checkbox";
import { useAppSelector } from "../../../../../../../../core/hooks/redux";
import { SplitAdditionalDataForm } from "../../interfaces/additionalDataForm";
import AddFileInfo from "../../../../../../../shared/addFileInfo/AddFileInfo";

const EducationInfoForm: React.FC<{
  register: UseFormRegister<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<SplitAdditionalDataForm>;
}> = ({ register, formState: { errors }, control, setValue }) => {
  const { dictionariesState } = useAppSelector((state) => state.coreState);
  const transferredFromAnotherUniversityWatch = useWatch({
    control,
    name: "transferredFromAnotherUniversity",
  });

  React.useEffect(() => {
    if (!transferredFromAnotherUniversityWatch) {
      setValue("trainingCertificate", null);
      setValue("nameOfUniversity", "");
      setValue("certificateRegistrationNumber", "");
      setValue("certificateDateIssue", "");
      setValue("certificateAboutOfStudentStatus", null);
      setValue("certificateAboutOfUniversityLicense", null);
    }
  }, [transferredFromAnotherUniversityWatch]);

  return (
    <FormContainer>
      <FieldInfo>
        <FieldName>Уровень образования</FieldName>
        <FieldDescription>
          который вы получили ранее и на основании которого вы поступаете на ВО
        </FieldDescription>
        {errors.educationLevel && (
          <FieldError>{errors.educationLevel?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        {dictionariesState?.dictionaries && (
          <Select
            className="defaultSelect"
            placeholder="Выберите уровень"
            items={dictionariesState.dictionaries.edu_type.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            control={control}
            options={{
              required: "Обязательное поле",
            }}
            name="educationLevel"
          />
        )}
      </FieldData>
      <FieldInfo>
        <FieldName>Регион получения образования</FieldName>
        {errors.educationRegion && (
          <FieldError>{errors.educationRegion?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          {...register("educationRegion", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Загрузка документов</FieldName>
        <FieldDescription>Возможно прикрепить несколько документов</FieldDescription>
        {(errors.educationDocument || errors.educationDocumentAppendix) && (
          <FieldError>
            {(errors.educationDocument?.message as string) ??
              (errors.educationDocumentAppendix?.message as string)}
          </FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <AddFileContainer>
          <AddFileButton
            name="educationDocument"
            control={control}
            options={{ required: "Обязательные поля" }}
            setValue={setValue}
            accept={"image/*"}
            multiple
          >
            Документ об образовании
          </AddFileButton>
          <AddFileButton
            name="educationDocumentAppendix"
            control={control}
            options={{ required: "Обязательные поля" }}
            setValue={setValue}
            accept={"image/*"}
            multiple
            description="(Все страницы)"
          >
            Приложение к документу
          </AddFileButton>
        </AddFileContainer>
      </FieldData>
      <FieldInfo />
      <FieldData>
        <Checkbox
          style={{ marginTop: 8 }}
          label="Поступаю в порядке перевода из другого вуза "
          control={control}
          name="transferredFromAnotherUniversity"
        />
      </FieldData>
      <FieldInfo />
      <FieldData>
        <AddFileInfo />
      </FieldData>
    </FormContainer>
  );
};

export default EducationInfoForm;
