import React from "react";
import {
  Control,
  Controller,
  FieldValues,
  FormState,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import InputMask from "react-input-mask";
import { FormContainer } from "../../../../shared/form/form.styles";
import {
  FieldInfo,
  FieldName,
  FieldError,
  FieldDescription,
} from "../../../../shared/form/fieldInfo.styles";
import { AddFileContainer, FieldData } from "../../../../shared/form/fieldData.styles";
import { Input } from "../../../../shared/native/input.styles";
import Calendar from "../../../../shared/calendar/Calendar";
import Select from "../../../../shared/select/Select";
import personalDocumentSelectItems from "../../../../../core/constants/personalDocumentSelectItems";
import { useAppSelector } from "../../../../../core/hooks/redux";
import SelectItem from "../../../../shared/select/SelectItem";
import noUnderlines from "../../../../../core/regexp/noUnderlines";
import ProfileForm from "../../interfaces/ProfileForm";
import DateRegexp from "../../../../../core/regexp/DateRegexp";
import AddFileButton from "../../../../shared/addFileButton/AddFileButton";
import AddFileInfo from "../../../../shared/addFileInfo/AddFileInfo";

const PassportDataForm: React.FC<{
  register: UseFormRegister<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<ProfileForm>;
}> = ({ register, formState: { errors }, control, setValue }) => {
  const { dictionariesState } = useAppSelector((state) => state.coreState);
  const documentTypeWatch = useWatch({ control, name: "documentType" });

  React.useEffect(() => {
    if (documentTypeWatch === "000000047") {
      setValue("citizenship", "");
      setValue("serialAndNumber", "");
      return;
    }

    setValue("serial", "");
    setValue("number", "");
  }, [documentTypeWatch]);

  return (
    <FormContainer>
      <FieldInfo>
        <FieldName>Тип документа</FieldName>
        <FieldDescription>Удостоверяющего личность</FieldDescription>
        {errors.documentType && <FieldError>{errors.documentType?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          items={personalDocumentSelectItems}
          placeholder="Выберите документ"
          control={control}
          name="documentType"
          options={{ required: "Обязательное поле" }}
        />
      </FieldData>
      {documentTypeWatch !== "000000047" && (
        <>
          <FieldInfo>
            <FieldName>Гражданство</FieldName>
            {errors.citizenship && <FieldError>{errors.citizenship?.message as string}</FieldError>}
          </FieldInfo>
          <FieldData>
            {!!dictionariesState.dictionaries?.country && (
              <Select
                className="defaultSelect"
                items={dictionariesState.dictionaries.country.map(
                  ({ id, name }) => ({ label: name, value: id } as SelectItem)
                )}
                placeholder="Выберите гражданство"
                control={control}
                name="citizenship"
                options={{
                  required: documentTypeWatch === "000000049" ? "Обязательное поле" : false,
                }}
              />
            )}
          </FieldData>
        </>
      )}
      <FieldInfo>
        <FieldName>Серия и номер</FieldName>
        {(errors.serial || errors.number) && (
          <FieldError>
            {(errors.serial?.message as string) ?? (errors.number?.message as string)}
          </FieldError>
        )}
      </FieldInfo>
      <FieldData>
        {documentTypeWatch !== "000000047" ? (
          <Controller
            name="serialAndNumber"
            control={control}
            rules={{ required: "Обязательное поле" }}
            render={({ field: { value, onChange } }): React.ReactElement => (
              <Input
                className="defaultInput"
                hasErrors={!!errors.serial}
                value={value}
                onChange={onChange}
                placeholder="Начните вводить"
              />
            )}
          />
        ) : (
          <>
            <Controller
              name="serial"
              control={control}
              rules={{
                required: "Обязательное поле",
                pattern: {
                  value: noUnderlines,
                  message: "Неполное значение",
                },
              }}
              render={({ field: { value, onChange } }): React.ReactElement => (
                <InputMask mask="99 99" value={value} onChange={onChange}>
                  <Input
                    placeholder="XX XX"
                    hasErrors={!!errors.serial}
                    style={{
                      width: 48,
                      marginRight: 8,
                    }}
                  />
                </InputMask>
              )}
            />
            <Controller
              name="number"
              control={control}
              rules={{
                required: "Обязательное поле",
                pattern: {
                  value: noUnderlines,
                  message: "Неполное значение",
                },
              }}
              render={({ field: { value, onChange } }): React.ReactElement => (
                <InputMask mask="999999" value={value} onChange={onChange}>
                  <Input
                    placeholder="YYYYYY"
                    hasErrors={!!errors.number}
                    style={{
                      width: 64,
                    }}
                  />
                </InputMask>
              )}
            />
          </>
        )}
      </FieldData>
      <FieldInfo>
        <FieldName>Дата выдачи</FieldName>
        {errors.dateOfIssue && <FieldError>{errors.dateOfIssue?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Calendar
          className="defaultCalendar"
          placeholder="ДД.ММ.ГГГГ"
          control={control}
          name="dateOfIssue"
          maxDate={new Date()}
          options={{
            required: "Обязательное поле",
            pattern: {
              value: DateRegexp,
              message: "Неверная дата",
            },
          }}
          defaultActiveStartDate={new Date()}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Кем выдан</FieldName>
        {errors.issuedByWhom && <FieldError>{errors.issuedByWhom?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.issuedByWhom}
          {...register("issuedByWhom", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>
      {documentTypeWatch === "000000047" && (
        <>
          <FieldInfo>
            <FieldName>Код подразделения</FieldName>
            {errors.divisionCode && (
              <FieldError>{errors.divisionCode?.message as string}</FieldError>
            )}
          </FieldInfo>
          <FieldData>
            <Controller
              name="divisionCode"
              control={control}
              rules={{
                required: "Обязательное поле",
                pattern: {
                  value: noUnderlines,
                  message: "Неполное значение",
                },
              }}
              render={({ field: { value, onChange } }): React.ReactElement => (
                <InputMask mask="999-999" value={value} onChange={onChange}>
                  <Input
                    placeholder="XXX-XXX"
                    style={{ width: 70 }}
                    hasErrors={!!errors.divisionCode}
                  />
                </InputMask>
              )}
            />
          </FieldData>
          <FieldInfo>
            <FieldName>Документ о смене ФИО</FieldName>
            <FieldDescription>(При наличии)</FieldDescription>
            {errors.changeFullnameDocument && (
              <FieldError>{errors.changeFullnameDocument?.message as string}</FieldError>
            )}
          </FieldInfo>
          <FieldData>
            <AddFileContainer>
              <AddFileButton
                accept={"image/*"}
                name="changeFullnameDocument"
                control={control}
                setValue={setValue}
                multiple
              >
                Прикрепить
              </AddFileButton>
            </AddFileContainer>
          </FieldData>
          <FieldInfo />
          <FieldData>
            <AddFileInfo />
          </FieldData>
        </>
      )}
    </FormContainer>
  );
};

export default PassportDataForm;
