import React from "react";
import { Control, FieldValues, FormState, UseFormSetValue, useWatch } from "react-hook-form";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import {
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import { FieldData } from "../../../../../../../shared/form/fieldData.styles";
import Select from "../../../../../../../shared/select/Select";
import { useAppSelector } from "../../../../../../../../core/hooks/redux";
import SelectItem from "../../../../../../../shared/select/SelectItem";
import ProgramDictionary from "../../../../../../../../core/interfaces/dictionaries/programDictionaries/ProgramDictionary";
import { SplitHighEducationForm } from "../../interfaces/highEducationForm";
import getSelectedProgram from "../../utils/getSelectedProgram";

const ApplicationForm: React.FC<{
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<SplitHighEducationForm>;
}> = ({ formState: { errors }, control, setValue }) => {
  const { dictionariesState } = useAppSelector((state) => state.coreState);

  // STATES
  const [modifiedFormByUser, setModifiedFormByUser] = React.useState(false);

  // WATCHES
  const educationLevelWatch = useWatch({ control, name: "educationLevel" });
  const directionAndSpecialityWatch = useWatch({ control, name: "directionAndSpeciality" });
  const profileWatch = useWatch({ control, name: "profile" });
  const formWatch = useWatch({ control, name: "form" });
  const financingWatch = useWatch({ control, name: "financing" });
  const kvotaWatch = useWatch({ control, name: "kvota" });

  // FUNCTIONS
  const getFilteredItems = (
    type: "level" | "specialty" | "profile" | "form" | "type_budget" | "kvota",
    filter: (program: ProgramDictionary) => boolean
  ): SelectItem[] => {
    if (!dictionariesState.programDictionaries) {
      return [];
    }

    const filteredPrograms = dictionariesState.programDictionaries.filter(filter);
    const alreadyHasItems: ProgramDictionary[] = [];
    const uniquePrograms = filteredPrograms.filter((program) => {
      const alreadyHasItem = alreadyHasItems.find((item) => {
        if (type === "kvota") {
          return item.kvota === program.kvota;
        }

        return item[`${type}_id`] === program[`${type}_id`];
      });
      if (alreadyHasItem) {
        return false;
      }

      alreadyHasItems.push(program);
      return true;
    });

    return uniquePrograms.map((program) => {
      if (type === "kvota") {
        return {
          label: program.kvota,
          value: program.kvota,
        };
      }

      return {
        label: program[type],
        value: program[`${type}_id`],
      };
    });
  };

  const setModifiedFormByUserHandler = (): void => {
    setModifiedFormByUser(true);
  };

  // EFFECTS
  React.useEffect(() => {
    if (!modifiedFormByUser) {
      return;
    }

    setValue("directionAndSpeciality", "");
  }, [educationLevelWatch]);

  React.useEffect(() => {
    if (!modifiedFormByUser) {
      return;
    }

    setValue("profile", "");
  }, [directionAndSpecialityWatch]);

  React.useEffect(() => {
    if (!modifiedFormByUser) {
      return;
    }

    setValue("form", "");
  }, [profileWatch]);

  React.useEffect(() => {
    if (!modifiedFormByUser) {
      return;
    }

    setValue("financing", "");
  }, [formWatch]);

  React.useEffect(() => {
    if (!modifiedFormByUser) {
      return;
    }

    setValue("kvota", "");
  }, [financingWatch]);

  React.useEffect(() => {
    if (!modifiedFormByUser) {
      return;
    }

    setValue(
      "program_uid",
      getSelectedProgram(
        educationLevelWatch,
        directionAndSpecialityWatch,
        profileWatch,
        formWatch,
        financingWatch,
        kvotaWatch
      )?.program_uid
    );
  }, [
    educationLevelWatch,
    directionAndSpecialityWatch,
    profileWatch,
    formWatch,
    financingWatch,
    kvotaWatch,
  ]);

  return (
    <FormContainer>
      <FieldInfo>
        <FieldName>Уровень образования для поступления</FieldName>
        {errors.educationLevel && (
          <FieldError>{errors.educationLevel?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите вариант ответа"
          items={getFilteredItems("level", () => true)}
          control={control}
          options={{
            required: "Обязательное поле",
          }}
          name="educationLevel"
          onClick={setModifiedFormByUserHandler}
        />
      </FieldData>

      <FieldInfo>
        <FieldName>Направление и специальность</FieldName>
        {errors.directionAndSpeciality && (
          <FieldError>{errors.directionAndSpeciality?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите вариант ответа"
          items={getFilteredItems(
            "specialty",
            (program) => program.level_id === educationLevelWatch
          )}
          control={control}
          options={{
            required: "Обязательное поле",
          }}
          name="directionAndSpeciality"
          disabled={!educationLevelWatch}
          onClick={setModifiedFormByUserHandler}
        />
      </FieldData>

      <FieldInfo>
        <FieldName>Желаемый профиль</FieldName>
        {errors.profile && <FieldError>{errors.profile?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите вариант ответа"
          items={getFilteredItems(
            "profile",
            (program) =>
              program.level_id === educationLevelWatch &&
              program.specialty_id === directionAndSpecialityWatch
          )}
          control={control}
          options={{
            required: "Обязательное поле",
          }}
          name="profile"
          disabled={!directionAndSpecialityWatch}
          onClick={setModifiedFormByUserHandler}
        />
      </FieldData>

      <FieldInfo>
        <FieldName>Форма и формат</FieldName>
        {errors.form && <FieldError>{errors.form?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите вариант ответа"
          items={getFilteredItems(
            "form",
            (program) =>
              program.level_id === educationLevelWatch &&
              program.specialty_id === directionAndSpecialityWatch &&
              program.profile_id === profileWatch
          )}
          control={control}
          options={{
            required: "Обязательное поле",
          }}
          name="form"
          disabled={!profileWatch}
          onClick={setModifiedFormByUserHandler}
        />
      </FieldData>

      <FieldInfo>
        <FieldName>Источник финансирования</FieldName>
        {errors.financing && <FieldError>{errors.financing?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите вариант ответа"
          items={getFilteredItems(
            "type_budget",
            (program) =>
              program.level_id === educationLevelWatch &&
              program.specialty_id === directionAndSpecialityWatch &&
              program.profile_id === profileWatch &&
              program.form_id === formWatch
          )}
          control={control}
          options={{
            required: "Обязательное поле",
          }}
          name="financing"
          disabled={!formWatch}
          onClick={setModifiedFormByUserHandler}
        />
      </FieldData>

      {!getFilteredItems(
        "kvota",
        (program) =>
          program.level_id === educationLevelWatch &&
          program.specialty_id === directionAndSpecialityWatch &&
          program.profile_id === profileWatch &&
          program.form_id === formWatch &&
          program.type_budget_id === financingWatch
      ).every((item) => item.label === "Нет") && (
        <>
          <FieldInfo>
            <FieldName>Квота</FieldName>
            {errors.kvota && <FieldError>{errors.kvota?.message as string}</FieldError>}
          </FieldInfo>
          <FieldData>
            <Select
              className="defaultSelect"
              placeholder="Выберите вариант ответа"
              items={getFilteredItems(
                "kvota",
                (program) =>
                  program.level_id === educationLevelWatch &&
                  program.specialty_id === directionAndSpecialityWatch &&
                  program.profile_id === profileWatch &&
                  program.form_id === formWatch &&
                  program.type_budget_id === financingWatch
              )}
              control={control}
              options={{
                required: "Обязательное поле",
              }}
              name="kvota"
              disabled={!financingWatch}
              onClick={setModifiedFormByUserHandler}
            />
          </FieldData>
        </>
      )}
    </FormContainer>
  );
};

export default ApplicationForm;
