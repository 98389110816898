import { AxiosResponse } from "axios";
import ResponseBody from "../models/ResponseBody";
import { $baseApi } from "../index";
import RequestBody from "../models/RequestBody";
import SignatureInfo from "../../ui/modules/signature/interfaces/SignatureInfo";

export const getSignatureInfoRequest = (): Promise<AxiosResponse<ResponseBody<SignatureInfo>>> => {
  return $baseApi.post<ResponseBody<SignatureInfo>>("", new RequestBody("notify.getecp", null));
};

export const sendVerificationCodeRequest = (
  type: "email" | "sms"
): Promise<AxiosResponse<ResponseBody<null>>> => {
  return $baseApi.post<ResponseBody<null>>(
    "",
    new RequestBody("notify.codesend", {
      type,
    })
  );
};

export const verifyVerificationCodeRequest = (
  code: string
): Promise<AxiosResponse<ResponseBody<null>>> => {
  return $baseApi.post<ResponseBody<null>>(
    "",
    new RequestBody("notify.makeecp", {
      code,
    })
  );
};

export const getAcceptDocumentRequest = (): Promise<
  AxiosResponse<ResponseBody<{ url: string }>>
> => {
  return $baseApi.post<ResponseBody<{ url: string }>>("", new RequestBody("document.accept", null));
};
