import React from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../../shared/native/button.styles";
import { TitleH1 } from "../../../../shared/native/typography.styles";
import { Ul } from "../../../../shared/native/ul.styles";
import { Li } from "../../../../shared/native/li.styles";
import {
  InfoPage,
  InfoPageContent,
  InfoPageTextInfo,
} from "../../../../shared/infoPage/infoPage.styles";

const FillingOutProfile: React.FC = () => {
  const navigate = useNavigate();

  return (
    <InfoPage>
      <InfoPageContent>
        <InfoPageTextInfo>
          <TitleH1>Заполнение профиля</TitleH1>
          <p>Заполните данные о себе для дальнейшей идентификации и работы с заявлениями.</p>
          <span>Что нужно для заполнения:</span>
          <Ul>
            <Li>Паспорт или иной документ, удостоверяющий личность</Li>
            <Li>Скан или фото двух разворотов: основная страница, прописка</Li>
            <Li>Номер СНИЛС (при наличии)</Li>
            <Li>Ваше фото</Li>
          </Ul>
        </InfoPageTextInfo>
        <PrimaryButton onClick={(): void => navigate("/profile/profile-form")}>
          Продолжить
        </PrimaryButton>
      </InfoPageContent>
    </InfoPage>
  );
};

export default FillingOutProfile;
