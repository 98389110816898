import styled from "styled-components";
import sizes from "../../../../../core/styles/sizes";

export const Applications = styled.div`
  width: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    padding: 30px 0 48px 0;
    margin: 0;
  }

  @media all and (${sizes.mobile}) {
    min-height: auto;
    height: calc(100% - 200px);
    padding: 160px 0 40px 0;
  }
`;

export const ApplicationsCardsContainer = styled.div`
  width: 85%;
  display: grid;
  grid-template-columns: repeat(3, 0.5fr);
  grid-gap: 48px;
  justify-content: center;

  @media all and (${sizes.mobile}) {
    width: calc(100% - 32px);
    margin: 0 16px;
    grid-template-columns: repeat(auto-fit, minmax(160px, 0.5fr));
    grid-gap: 16px;
    place-content: center;
  }
`;
