import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { Form, FormActions, FormContent } from "../../../../../shared/form/form.styles";
import { useAppDispatch, useAppSelector } from "../../../../../../core/hooks/redux";
import UseAlert from "../../../../../../core/hooks/useAlert";
import getCookie from "../../../../../../core/utils/getCookie";
import { setViewedStep } from "../../../../../../store/modules/applications/additionalData/additionalDataSlice";
import {
  AdditionalDataForm as AdditionalDataFormInterface,
  SplitAdditionalDataForm,
} from "./interfaces/additionalDataForm";
import Stepper from "../../../../../shared/stepper/Stepper";
import { OutlinedButton, PrimaryButton } from "../../../../../shared/native/button.styles";
import { ReactComponent as LineArrowIcon } from "../../../../../../core/static/icons/line-arrow.svg";
import steps from "./constants/steps";
import { saveAdditionalDataFormRequest } from "../../../../../../http/requests/applications/additionalData.http";
import convertSplitAdditionalDataForm from "./utils/convertSplitAdditionalDataForm";
import { fetchGetAdditionalData } from "../../../../../../store/modules/applications/additionalData/additionalDataThunk";
import ResponseBody from "../../../../../../http/models/ResponseBody";
import loading from "../../../../../../core/types/loading";

const AdditionalDataForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const alertHandler = UseAlert();
  const navigate = useNavigate();
  const [submitLoadingStatus, setSubmitLoadingStatus] = React.useState<loading>(null);
  const { additionalDataState } = useAppSelector((state) => state.modulesState.applicationsState);
  const { register, control, formState, handleSubmit, reset, setValue } =
    useForm<SplitAdditionalDataForm>({
      values: {
        ...additionalDataState.educationInfo,
        ...additionalDataState.educationDocument,
        ...additionalDataState.trainingCertificate,
        ...additionalDataState.healthLimitations,
      },
      mode: "onBlur",
    });
  const transferredFromAnotherUniversityWatch = useWatch({
    control,
    name: "transferredFromAnotherUniversity",
  });
  const [viewedSteps, setViewedSteps] = React.useState(steps);

  React.useEffect(() => {
    const cookie = getCookie();
    if (cookie?.lastModifiedAdditionalDataStep) {
      dispatch(setViewedStep(Number(cookie.lastModifiedAdditionalDataStep)));
    }
  }, []);

  React.useEffect(() => {
    if (!transferredFromAnotherUniversityWatch) {
      const newSteps = [...steps];
      newSteps.splice(2, 1);
      setViewedSteps(newSteps);
      return;
    }

    setViewedSteps(steps);
  }, [transferredFromAnotherUniversityWatch]);

  const setLastModifiedStepCookie = (step: number): void => {
    document.cookie = `lastModifiedAdditionalDataStep=${step}; path=/`;
  };

  const getCurrentFormComponent = (): ((props?: any) => React.ReactNode) => {
    return viewedSteps.find((step, key) => additionalDataState.viewedStep === key)?.element;
  };

  const goToPreviousStep = (): void => {
    const previousStepIndex = additionalDataState.viewedStep - 1;
    dispatch(setViewedStep(previousStepIndex));
    setLastModifiedStepCookie(previousStepIndex);
  };

  const submitForm = (stepId: number, formData: SplitAdditionalDataForm): void => {
    setSubmitLoadingStatus("LOADING");

    saveAdditionalDataFormRequest(convertSplitAdditionalDataForm(formData)).then((saveRes) => {
      if (saveRes.data.error) {
        setSubmitLoadingStatus("ERROR");
        alertHandler({ message: saveRes.data.text, type: "error" });
        return;
      }
      setSubmitLoadingStatus("COMPLETE");

      alertHandler({ message: "Информация сохранена", type: "success" });

      dispatch(fetchGetAdditionalData()).then((action) => {
        const getRes = action.payload as ResponseBody<AdditionalDataFormInterface>;
        if (getRes.error) {
          alertHandler({ message: getRes.text, type: "error" });
        }

        if (stepId === viewedSteps.length - 1) {
          navigate("/applications/additional-high-data/check");
          return;
        }
        dispatch(setViewedStep(stepId + 1));
        setLastModifiedStepCookie(stepId + 1);
      });
    });
  };

  return (
    <Form onSubmit={handleSubmit((data) => submitForm(additionalDataState.viewedStep, data))}>
      <Stepper
        items={viewedSteps}
        currentStep={additionalDataState.viewedStep}
        className="stepper"
      />
      <FormContent>
        {getCurrentFormComponent()
          ? getCurrentFormComponent()({
              register,
              control,
              formState,
              reset,
              setValue,
            })
          : null}
      </FormContent>
      <FormActions lastStep={additionalDataState.viewedStep === viewedSteps.length - 1}>
        {additionalDataState.viewedStep !== 0 && (
          <OutlinedButton
            disabled={additionalDataState.dataLoadingStatus === "LOADING"}
            className="leftButton"
            type="button"
            onClick={goToPreviousStep}
          >
            <LineArrowIcon />
          </OutlinedButton>
        )}
        <PrimaryButton
          disabled={
            additionalDataState.dataLoadingStatus === "LOADING" || submitLoadingStatus === "LOADING"
          }
          className="rightButton"
          type="submit"
        >
          {additionalDataState.viewedStep !== viewedSteps.length - 1 ? (
            <LineArrowIcon />
          ) : (
            "Сохранить"
          )}
        </PrimaryButton>
      </FormActions>
    </Form>
  );
};

export default AdditionalDataForm;
