import React from "react";
import { Control, FieldValues, FormState, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { FormContainer } from "../../../../../../../../../shared/form/form.styles";
import PersonalForm from "../shared/PersonalForm";
import PassportForm from "../shared/PassportForm";
import CertificateInfo from "./CertificateInfo";

const MaternityCapital: React.FC<{
  register: UseFormRegister<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<FieldValues>;
}> = (props) => {
  return (
    <FormContainer leftContent noScrollContent>
      <PersonalForm {...props} />
      <PassportForm {...props} />
      <CertificateInfo {...props} />
    </FormContainer>
  );
};

export default MaternityCapital;
