import { SplitHighEducationForm } from "../../../interfaces/highEducationForm";
import PreviewField from "../../../../../../../../shared/form/interfaces/previewField";
import store from "../../../../../../../../../store/store";
import getSelectedProgram from "../../../utils/getSelectedProgram";

const getHighEducationFields = (form: SplitHighEducationForm): PreviewField[] => {
  const state = store.getState();
  const { dictionaries } = state.coreState.dictionariesState;
  const isBachelorOrSpecialist =
    form.educationLevel === "000000001" || form.educationLevel === "000000002";
  const selectedProgram = getSelectedProgram(
    form.educationLevel,
    form.directionAndSpeciality,
    form.profile,
    form.form,
    form.financing,
    form.kvota
  );

  return [
    {
      type: "text",
      name: "Уровень образования для поступления",
      value: selectedProgram?.level,
    },
    {
      type: "text",
      name: "Направление и специальность",
      value: selectedProgram?.specialty,
    },
    {
      type: "text",
      name: "Профиль",
      value: selectedProgram?.profile,
      hideField: isBachelorOrSpecialist,
    },
    {
      type: "text",
      name: "Форма и формат",
      value: selectedProgram?.form,
    },
    {
      type: "text",
      name: "Источник финансирования",
      value: selectedProgram?.type_budget,
    },
    {
      type: "text",
      name: "Квота",
      value: selectedProgram?.kvota,
    },
    {
      type: "exams",
      name: "Данные об экзаменах",
      value: form.exams,
      selectedProgram,
    },
    {
      type: "achievements",
      name: "Достижения",
      value: form.achievements,
      selectedProgram,
    },
    {
      type: "text",
      name: "Скидки и льготы",
      value: [{ name: "Нет", id: "0" }, ...dictionaries.discounts].find(
        (item) => item.id === form.discountsAndBenefits
      )?.name,
    },
    {
      type: "files",
      name: "Загрузка документа",
      value: form.discountsConfirm,
      description: "без документа скидка не будет применена",
      hideField: !form.discountsAndBenefits || form.discountsAndBenefits === "0",
    },
  ];
};

export default getHighEducationFields;
