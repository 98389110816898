import React from "react";
import { useNavigate } from "react-router-dom";
import { TitleH1 } from "../../../../shared/native/typography.styles";
import {
  Applications as StyledApplications,
  ApplicationsCardsContainer,
} from "./selectApplicationType.styles";
import ImageCard from "../../../../shared/cards/imageCard/ImageCard";
import HigherEducationImg from "../../../../../core/static/img/applications/higher_education.svg";
import HigherEducationRedImg from "../../../../../core/static/img/applications/higher_education_red.svg";
import AdditionalEducationImg from "../../../../../core/static/img/applications/additional_education.svg";
import AdditionalEducationRedImg from "../../../../../core/static/img/applications/additional_education_red.svg";
import PostgraduateEducationImg from "../../../../../core/static/img/applications/postgraduate_education.svg";
import PostgraduateEducationRedImg from "../../../../../core/static/img/applications/postgraduate_education_red.svg";
import useIsMobile from "../../../../../core/hooks/UseIsMobile";
import { useAppSelector } from "../../../../../core/hooks/redux";

const Applications: React.FC = () => {
  const navigate = useNavigate();
  const { accountState } = useAppSelector((state) => state.coreState);
  const { additionalDataState } = useAppSelector((state) => state.modulesState.applicationsState);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1040);

  useIsMobile(setIsMobile);

  const navigateToHighApplicationsList = (): void => {
    if (additionalDataState.educationInfo.transferredFromAnotherUniversity) {
      return;
    }

    navigate(
      `/applications/${accountState?.user?.hasVO ? "high-education" : "additional-high-data"}`
    );
  };

  const navigateToAdditionalApplicationsList = (): void => {
    navigate(
      `/applications/${accountState?.user?.hasDPO ? "additional-education" : "additional-ae-data"}`
    );
  };

  const navigateToPostgraduateApplicationsList = (): void => {
    navigate(
      `/applications/${
        accountState?.user?.hasASP ? "postgraduate-education" : "additional-postgraduate-data"
      }`
    );
  };

  return (
    <StyledApplications>
      <TitleH1>Подать документы:</TitleH1>
      <ApplicationsCardsContainer>
        <ImageCard
          name="higher education"
          image={HigherEducationImg}
          hoverImage={
            additionalDataState.educationInfo.transferredFromAnotherUniversity
              ? null
              : HigherEducationRedImg
          }
          footer="Высшее образование"
          activeByDefault={isMobile}
          onClick={navigateToHighApplicationsList}
        />
        <ImageCard
          name="addition education"
          image={AdditionalEducationImg}
          hoverImage={AdditionalEducationRedImg}
          footer="Дополнительное образование"
          activeByDefault={isMobile}
          onClick={navigateToAdditionalApplicationsList}
        />
        <ImageCard
          name="posgraduate education"
          image={PostgraduateEducationImg}
          hoverImage={PostgraduateEducationRedImg}
          footer="Аспирантура"
          activeByDefault={isMobile}
          onClick={navigateToPostgraduateApplicationsList}
        />
      </ApplicationsCardsContainer>
    </StyledApplications>
  );
};

export default Applications;
