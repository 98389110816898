import ProgramDictionary from "../../../../../../../core/interfaces/dictionaries/programDictionaries/ProgramDictionary";
import store from "../../../../../../../store/store";

const getSelectedProgram = (
  educationLevel: string,
  directionAndSpeciality: string,
  profile: string,
  form: string,
  financing: string,
  kvota: string
): ProgramDictionary => {
  const state = store.getState();
  const { programDictionaries } = state.coreState.dictionariesState;

  return programDictionaries?.find(
    (program) =>
      program.level_id === educationLevel &&
      program.specialty_id === directionAndSpeciality &&
      program.profile_id === profile &&
      program.form_id === form &&
      program.type_budget_id === financing &&
      (!kvota || program.kvota === kvota)
  );
};

export default getSelectedProgram;
