import styled from "styled-components";
import colors from "../../../../../../../core/styles/colors";
import sizes from "../../../../../../../core/styles/sizes";

export const NotificationCard = styled.form`
  width: 100% - 32px;
  padding: 16px;
  background: ${colors.white.card};
  border-radius: 20px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }

  @media all and (${sizes.mobile}) {
    width: 100%;
    padding: 0;
    background: ${colors.white.general};
  }
`;

export const NotificationCardHeader = styled.div`
  width: 100%;
  p {
    width: fit-content;
    margin: 0;
    padding-bottom: 8px;
    font-size: 16px;
    font-weight: 300;
    color: ${colors.black.general};
    border-bottom: 2px solid ${colors.red.light};
    span {
      font-weight: 600;
      color: ${colors.red.general};
      margin-right: 4px;
    }
  }
`;

export const NotificationCardContent = styled.div`
  width: 100%;
  margin-top: 16px;
`;

export const NotificationCardActions = styled.div`
  width: 100%;
  margin-top: 16px;
  button {
    min-width: 240px;
    padding: 0 16px;
  }

  @media all and (${sizes.mobile}) {
    button {
      width: 100%;
    }
  }
`;
