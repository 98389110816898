import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import PasswordInput from "../../../shared/passwordInput/PasswordInput";
import { Container, Form, LoginFormOther } from "./loginForm.styles";
import { PrimaryButton } from "../../../shared/native/button.styles";
import { Input } from "../../../shared/native/input.styles";
import { loginRequest } from "../../../../http/requests/account.http";
import { useAppDispatch } from "../../../../core/hooks/redux";
import { setToken, setSS } from "../../../../store/core/account/accountSlice";
import { ErrorText, TitleH1 } from "../../../shared/native/typography.styles";
import LoginFormInterface from "../interfaces/loginForm";

const LoginForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loginError, setLoginError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const { handleSubmit, register, control } = useForm();

  const submitForm = (data: LoginFormInterface): void => {
    setIsLoading(true);

    loginRequest(data).then((res) => {
      setIsLoading(false);
      if (res.data.error) {
        setLoginError(res.data.text);
        return;
      }

      setLoginError(null);
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("ss", res.data.payload.ss);
      dispatch(setToken(res.data.token));
      dispatch(setSS(res.data.payload.ss));
    });
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit((data) => submitForm(data as LoginFormInterface))}>
        <TitleH1>Поступай в МИП</TitleH1>
        <div className="fieldRow">
          <div className="fieldHeader">
            <p>Email</p>
          </div>
          <Input
            placeholder="Введите email"
            {...register("email", {
              required: "Обязательное поле",
            })}
          />
        </div>
        <div className="fieldRow">
          <div className="fieldHeader">
            <p>Пароль</p>
            <Link to="/remember-password">Забыли пароль?</Link>
          </div>
          <PasswordInput
            control={control}
            name="password"
            options={{ required: "Обязательное поле" }}
          />
        </div>
        {!!loginError && <ErrorText className="error">{loginError}</ErrorText>}
        <PrimaryButton disabled={isLoading} className="submitButton">
          Войти
        </PrimaryButton>
        <LoginFormOther>
          <p>
            Еще нет учетной записи?
            <a href="https://start.instudy.online/registration/">Зарегистрируйтесь!</a>
          </p>
        </LoginFormOther>
      </Form>
    </Container>
  );
};

export default LoginForm;
