import React from "react";
import { Control, FieldValues, FormState, UseFormSetValue, useWatch } from "react-hook-form";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import {
  FieldDescription,
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import { FieldData } from "../../../../../../../shared/form/fieldData.styles";
import Select from "../../../../../../../shared/select/Select";
import AddFileButton from "../../../../../../../shared/addFileButton/AddFileButton";
import { SplitPostgraduateEducationForm } from "../../interfaces/postgraduateEducationForm";
import { useAppSelector } from "../../../../../../../../core/hooks/redux";
import AddFileInfo from "../../../../../../../shared/addFileInfo/AddFileInfo";

const DiscountsAndBenefitsForm: React.FC<{
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<SplitPostgraduateEducationForm>;
}> = ({ formState: { errors }, control, setValue }) => {
  const { dictionariesState } = useAppSelector((state) => state.coreState);
  const discountsAndBenefitsWatch = useWatch({ control, name: "discountsAndBenefits" });

  return (
    <FormContainer>
      <FieldInfo>
        <FieldName>Скидки и льготы</FieldName>
        {errors.discountsAndBenefits && (
          <FieldError>{errors.discountsAndBenefits?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите льготу"
          items={[{ name: "Нет", id: "0" }, ...dictionariesState.dictionaries.discounts].map(
            (discount) => ({
              label: discount.name,
              value: discount.id,
            })
          )}
          control={control}
          name="discountsAndBenefits"
          options={{
            required: "Обязательное поле",
          }}
        />
      </FieldData>
      {!!discountsAndBenefitsWatch && discountsAndBenefitsWatch !== "0" && (
        <>
          <FieldInfo>
            <FieldName>Загрузка документа</FieldName>
            <FieldDescription>без документа скидка не будет применена</FieldDescription>
            {errors.achievementsConfirm && (
              <FieldError>{errors.achievementsConfirm?.message as string}</FieldError>
            )}
          </FieldInfo>
          <FieldData>
            <AddFileButton
              name="discountsConfirm"
              options={{
                required: "Обязательное поле",
              }}
              control={control}
              setValue={setValue}
              accept={"image/*"}
              multiple
            >
              Подтверждение скидки
            </AddFileButton>
          </FieldData>
          <FieldInfo />
          <FieldData>
            <AddFileInfo />
          </FieldData>
        </>
      )}
    </FormContainer>
  );
};

export default DiscountsAndBenefitsForm;
