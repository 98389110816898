import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdditionalEducationForm } from "../../../../ui/modules/applications/components/additionalEducation/additionalEducationForm/interfaces/additionalEducationForm";
import { fetchGetAdditionalEducation } from "./additionalEducationThunk";
import loading from "../../../../core/types/loading";
import convertAdditionalEducationFromSavedData from "../../../../ui/modules/applications/components/additionalEducation/additionalEducationForm/utils/convertAdditionalEducationFromSavedData";

interface AdditionalEducationState extends AdditionalEducationForm {
  viewedStep: number;
  dataLoadingStatus: loading;
}

const initialState: AdditionalEducationState = {
  viewedStep: 0,
  id: null,
  application: {
    level: "",
    levelName: "",
    program: "",
    programName: "",
    form: "",
    formName: "",
    format: "",
    formatName: "",
  },
  paymentForm: {
    paymentMethod: "",
    discountsAndBenefits: "0",
    discountsPercentage: null,
    discountConfirmation: null,
  },
  dataLoadingStatus: null,
};

const additionalEducationAdapter = createEntityAdapter<AdditionalEducationState>();

const additionalEducationSlice = createSlice({
  name: "additionalEducation",
  initialState: additionalEducationAdapter.getInitialState(initialState),
  reducers: {
    setViewedStep: (state, action: PayloadAction<number>) => {
      state.viewedStep = action.payload;
    },
    resetAdditionalEducationState: (state) => {
      state.viewedStep = 0;
      state.id = null;
      state.application = {
        level: "",
        levelName: "",
        program: "",
        programName: "",
        form: "",
        formName: "",
        format: "",
        formatName: "",
      };
      state.paymentForm = {
        paymentMethod: "",
        discountsAndBenefits: "0",
        discountsPercentage: null,
        discountConfirmation: null,
      };
      state.dataLoadingStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetAdditionalEducation.pending, (state) => {
      state.dataLoadingStatus = "LOADING";
    });
    builder.addCase(fetchGetAdditionalEducation.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.dataLoadingStatus = "ERROR";
        return;
      }
      state.dataLoadingStatus = "COMPLETE";
      if (!action.payload.payload) {
        return;
      }

      const convertedData = convertAdditionalEducationFromSavedData(action.payload.payload);
      state.id = convertedData.id;
      if (convertedData.application) {
        state.application = convertedData.application;
      }
      if (convertedData.paymentForm) {
        state.paymentForm = convertedData.paymentForm;
      }
    });
  },
});

export const { setViewedStep, resetAdditionalEducationState } = additionalEducationSlice.actions;
export default additionalEducationSlice.reducer;
