import store from "../../../../../../../store/store";
import TypesDictionary from "../../../../../../../core/interfaces/dictionaries/additionalEducationDictionaries/TypesDictionary";
import ProgramDictionary from "../../../../../../../core/interfaces/dictionaries/additionalEducationDictionaries/ProgramDictionary";
import LevelDictionary from "../../../../../../../core/interfaces/dictionaries/additionalEducationDictionaries/LevelDictionary";

const getSelectedAdditionalEducationItem = (
  type: "level" | "program" | "form",
  level: string,
  program?: string,
  form?: string
): LevelDictionary | ProgramDictionary | TypesDictionary => {
  if (!level) {
    return null;
  }

  const state = store.getState();
  const { additionalEducationDictionaries } = state.coreState.dictionariesState;
  const selectedLevel = additionalEducationDictionaries?.levels?.find(
    (levelItem) => levelItem.level_id === level
  );

  if (!selectedLevel) {
    return null;
  }

  if (type === "level") {
    return selectedLevel;
  }

  const selectedProgram = selectedLevel.programs.find((programItem) => programItem.id === program);

  if (!selectedProgram) {
    return null;
  }

  if (type === "program") {
    return selectedProgram;
  }

  if (!form) {
    return null;
  }

  return selectedProgram?.types?.find((formItem) => formItem.forma_id === form);
};

export default getSelectedAdditionalEducationItem;
