import convertFile from "../../../../../../../core/utils/convertFile";
import { AdditionalAEDataForm } from "../interfaces/additionalAEDataForm";

const convertAdditionalAEFromSavedData = (data: AdditionalAEDataForm): AdditionalAEDataForm => ({
  education: data?.education
    ? {
        educationLevel: data.education.educationLevel,
        educationLevelName: data.education.educationLevelName,
        educationDocumentType: data.education.educationDocumentType,
        serial: data.education.serial,
        number: data.education.number,
        issuedByWhom: data.education.issuedByWhom,
        dateOfIssue: data.education.dateOfIssue,
        educationDocument: convertFile("from", data.education.educationDocument),
        educationDocumentAppendix: convertFile("from", data.education.educationDocumentAppendix),
      }
    : null,
  employment: data?.employment ?? null,
});

export default convertAdditionalAEFromSavedData;
