import React from "react";

const UseIsMobile = (handler: React.Dispatch<boolean>): void => {
  const setIsMobileHandler = (): void => {
    handler(window.innerWidth < 1040);
  };

  React.useEffect(() => {
    window.addEventListener("resize", setIsMobileHandler);

    return () => {
      window.removeEventListener("resize", setIsMobileHandler);
    };
  }, []);
};

export default UseIsMobile;
