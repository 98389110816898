import { combineReducers } from "@reduxjs/toolkit";
import additionalDataSlice from "./additionalData/additionalDataSlice";
import highEducationSlice from "./highEducation/highEducationSlice";
import additionalEducationSlice from "./additionalEducation/additionalEducationSlice";
import additionalAEDataSlice from "./additionalAEData/additionalAEDataSlice";
import additionalPostgraduateDataSlice from "./additionalPostgraduateData/additionalPostgraduateDataSlice";
import postgraduateEducationSlice from "./postgraduateEducation/postgraduateEducationSlice";

export default combineReducers({
  additionalDataState: additionalDataSlice,
  additionalAEDataState: additionalAEDataSlice,
  additionalPostgraduateDataState: additionalPostgraduateDataSlice,
  highEducationState: highEducationSlice,
  additionalEducationState: additionalEducationSlice,
  postgraduateEducationState: postgraduateEducationSlice,
});
