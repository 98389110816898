import { AxiosResponse } from "axios/index";
import ResponseBody from "../../models/ResponseBody";
import { $baseApi } from "../../index";
import RequestBody from "../../models/RequestBody";
import { HighEducationForm } from "../../../ui/modules/applications/components/highEducation/highEducationForm/interfaces/highEducationForm";

export const getHighEducationFormRequest = (
  applicationId: number
): Promise<AxiosResponse<ResponseBody<HighEducationForm>>> => {
  return $baseApi.post<ResponseBody<HighEducationForm>>(
    "",
    new RequestBody("draft.read", {
      type: "application",
      id: applicationId,
    })
  );
};

export const saveHighEducationFormRequest = (
  applicationId: number,
  data: HighEducationForm
): Promise<AxiosResponse<ResponseBody<{ id: number }>>> => {
  const resultBody = {
    type: "application",
    data: {
      ...data,
      id: applicationId,
    },
  };
  return $baseApi.post<ResponseBody<{ id: number }>>("", new RequestBody("draft.save", resultBody));
};

export const submitHighEducationFormRequest = (
  applicationId: number
): Promise<AxiosResponse<ResponseBody<null>>> => {
  return $baseApi.post<ResponseBody<null>>(
    "",
    new RequestBody("application.save", { id: applicationId })
  );
};
