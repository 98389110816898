import { AdditionalDataForm } from "../interfaces/additionalDataForm";
import convertFile from "../../../../../../../core/utils/convertFile";

const convertFromSavedData = (data: AdditionalDataForm): AdditionalDataForm => ({
  educationInfo: data?.educationInfo
    ? {
        ...data.educationInfo,
        educationDocument: convertFile("from", data.educationInfo.educationDocument),
        educationDocumentAppendix: convertFile(
          "from",
          data.educationInfo.educationDocumentAppendix
        ),
      }
    : null,
  educationDocument: data?.educationDocument ?? null,
  trainingCertificate: data?.trainingCertificate
    ? {
        ...data.trainingCertificate,
        trainingCertificate: convertFile("from", data.trainingCertificate.trainingCertificate),
        certificateAboutOfStudentStatus: convertFile(
          "from",
          data.trainingCertificate.certificateAboutOfStudentStatus
        ),
        certificateAboutOfUniversityLicense: convertFile(
          "from",
          data.trainingCertificate.certificateAboutOfUniversityLicense
        ),
      }
    : null,
  healthLimitations: data?.healthLimitations
    ? {
        ...data.healthLimitations,
        disabilityDocument: convertFile("from", data.healthLimitations.disabilityDocument),
      }
    : null,
});

export default convertFromSavedData;
