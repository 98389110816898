import styled from "styled-components";
import alertType from "../../../core/constants/alertType";
import alertTypes from "./constants/alertTypes";
import colors from "../../../core/styles/colors";
import zIndex from "../../../core/styles/z-index";

export const AlertsContainer = styled.div`
  position: fixed;
  z-index: ${zIndex.special};
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Alert = styled.div<{ type: alertType; autoClose: number }>`
  min-width: 200px;
  width: fit-content;
  height: fit-content;
  padding: 5px 16px;
  margin-top: 16px;
  border-radius: 8px;
  background: ${(props): string =>
    alertTypes.find((alertTypeItem) => alertTypeItem.type === props.type).color};
  animation: show-and-hide ${(props): number => props.autoClose / 1000}s;
  display: flex;
  align-items: center;
  p {
    margin: 0;
    color: ${colors.white.general};
    text-align: center;
    flex-grow: 1;
  }
  span {
    margin-right: 8px;
    color: ${colors.white.general};
    font-weight: 600;
    font-size: 18px;
  }
  svg {
    width: 14px;
    height: 14px;
  }

  @keyframes show-and-hide {
    from {
      opacity: 0;
    }
    ${(props): string => `${0.5 * (props.autoClose / 100)}%`} {
      opacity: 1;
    }
    ${(props): string => `${100 - 0.5 * (props.autoClose / 100)}%`} {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;
