import styled from "styled-components";
import colors from "../../../core/styles/colors";
import sizes from "../../../core/styles/sizes";
import zIndex from "../../../core/styles/z-index";

// MAIN
export const Navigation = styled.div<{ $show: boolean }>`
  width: 360px;
  height: 100vh;
  border-radius: 0 25px 25px 0;
  background: ${colors.blue.darkBlue};

  @media all and (${sizes.mobile}) {
    position: fixed;
    z-index: ${zIndex.topElements};
    width: 100%;
    height: ${(props): string => (props.$show ? "auto" : "64px")};
    border-radius: 0 0 10px 10px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media all and (${sizes.mobile}) {
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    padding: 16px 16px;
  }
`;

// HEADER
export const NavigationHeader = styled.div`
  width: calc(100% - 106px);
  padding: 32px 53px;
  img {
    width: 175px;
  }
  svg {
    display: none;
  }
  @media all and (${sizes.mobile}) {
    width: 100%;
    height: 32px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: auto;
      height: 100%;
    }
    svg {
      display: block;
      width: 20px;
      height: 20px;
      cursor: pointer;
      path {
        transition: ease-in-out 0.3s;
      }
      &:hover {
        path {
          fill: ${colors.gray.gray100};
        }
      }
      @media (pointer: none), (pointer: coarse) {
        &:hover {
          path {
            fill: ${colors.white.general};
          }
        }
        &:active {
          path {
            fill: ${colors.gray.gray100};
          }
        }
      }
    }
  }
`;

// BODY
export const NavigationBody = styled.div<{ $show: boolean }>`
  width: calc(100% - 96px);
  padding: 80px 48px 0 48px;
  display: flex;
  flex-direction: column;

  @media all and (${sizes.mobile}) {
    display: ${(props): string => (props.$show ? "flex" : "none")};
    width: calc(100% - 90px);
    padding: 50px 45px;
  }
`;

// LINKS
export const Links = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Link = styled.div<{ $active?: boolean; disabled?: boolean; $text?: boolean }>`
  position: relative;
  width: 100%;
  margin: 0 0 32px 0;
  a,
  span {
    width: 100%;
    text-decoration: none;
    font-size: 18px;
    font-weight: ${(props): number => (props.$active ? 600 : 300)};
    color: ${(props): string => {
      if (props.disabled) {
        return colors.gray.gray300;
      }
      if (props.$active) {
        return colors.white.general;
      }

      return colors.gray.gray100;
    }};
    transition: ease-in-out 0.3s;
    ${(props): string =>
      !props.$text &&
      `&:hover {
      color: ${props.disabled ? colors.gray.gray300 : colors.white.general};
    }`}
  }
`;

export const Arrow = styled.div`
  position: absolute;
  height: 2.5px;
  width: 40px;
  background: ${colors.red.general};
  top: 9px;
  left: -48px;
  animation: ease-out showIn 0.4s;

  &:before,
  &:after {
    position: absolute;
    content: "";
    background: ${colors.red.general};
    height: 2.5px;
    width: 12px;
    border-radius: 3px;
    right: -3px;
  }
  &:before {
    bottom: -3.2px;
    transform: rotate(-45deg);
  }
  &:after {
    top: -3.2px;
    transform: rotate(45deg);
  }

  @keyframes showIn {
    from {
      left: -96px;
    }
    to {
      left: -48px;
    }
  }
`;

// ACTIONS
export const Actions = styled.div`
  width: 100%;
  padding: 16px 0 0 0;
  display: none;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid ${colors.gray.gray100};
  .userInfo {
    flex-grow: 1;
    b {
      font-family: "Gilroy", serif;
      font-weight: 600;
      font-size: 14px;
      color: ${colors.white.general};
    }
    p {
      font-family: "Proxima Nova Lt", serif;
      font-weight: 300;
      font-size: 12px;
      margin: 0;
      color: ${colors.white.hoverWhite};
    }
  }
  button {
    padding: 0 24px;
  }

  @media all and (${sizes.mobile}) {
    display: flex;
  }
`;
