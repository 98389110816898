import React from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { PDFDocumentProxy } from "pdfjs-dist";
import { getAcceptDocumentRequest } from "../../../../../http/requests/signature.http";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PreviewDocument: React.FC<{
  currentStep: number;
  setDisableNextButton: (value: boolean) => void;
  fileRefCallback: (fileRef: React.RefObject<HTMLDivElement>) => void;
}> = ({ currentStep, setDisableNextButton, fileRefCallback }) => {
  const [documentActivePage, setDocumentActivePage] = React.useState(1);
  const [signatureFile, setSignatureFile] = React.useState<string | File>(null);
  const fileRef = React.useRef<HTMLDivElement>(null);

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy): void => {
    setDocumentActivePage(numPages);
    if (documentActivePage === 2) {
      setDisableNextButton(false);
    }
  };

  React.useEffect(() => {
    fileRefCallback(fileRef);
  }, [fileRef]);

  React.useEffect(() => {
    if (currentStep === 0) {
      setSignatureFile("/documents/agreementECP.pdf");
    }

    if (currentStep === 1) {
      getAcceptDocumentRequest().then((res) => {
        if (res.data.error || !res.data?.payload?.url) {
          return;
        }

        setSignatureFile(res.data.payload.url);
      });
    }
  }, [currentStep]);

  return (
    <Document
      file={signatureFile}
      onLoadSuccess={onDocumentLoadSuccess}
      inputRef={fileRef}
      error="Возникла ошибка при загрузке документа"
      loading="Загрузка документа..."
    >
      {!!signatureFile &&
        Array.from(new Array(documentActivePage), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
    </Document>
  );
};

export default PreviewDocument;
