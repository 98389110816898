import Step from "../../../../../../shared/stepper/interfaces/Step";
import ApplicationForm from "../forms/applicationForm/ApplicationForm";
import EntranceExamsForm from "../forms/entranceExamsForm/entranceExamsForm";
import DiscountsAndBenefitsForm from "../forms/discountsAndBenefitsForm/DiscountsAndBenefitsForm";

const steps: Step[] = [
  {
    title: "Заявление на поступление",
    element: (props: any) => <ApplicationForm {...props} />,
  },
  {
    title: "Вступительные экзамены",
    element: (props: any) => <EntranceExamsForm {...props} />,
  },
  {
    title: "Cкидки и льготы",
    element: (props: any) => <DiscountsAndBenefitsForm {...props} />,
  },
];

export default steps;
