import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import { debounce } from "lodash";
import {
  RatingApplicationsList as StyledRatingApplicationsList,
  RatingApplicationsListContainer,
  RatingApplicationsListContent,
  RatingApplicationsListHeader,
} from "./ratingApplicationsList.styles";
import { TitleH1 } from "../../../../../shared/native/typography.styles";
import {
  getApplicationsRequest,
  saveApplicationsPriorityRequest,
} from "../../../../../../http/requests/applications/applications.http";
import { useAppDispatch } from "../../../../../../core/hooks/redux";
import { resetHighEducationState } from "../../../../../../store/modules/applications/highEducation/highEducationSlice";
import Loader from "../../../../../shared/loaders/Loader";
import { resetAdditionalEducationState } from "../../../../../../store/modules/applications/additionalEducation/additionalEducationSlice";
import capitalize from "../../../../../../core/utils/capitalize";
import graduateInfo from "../../selectApplicationType/constants/graduateInfo";
import { resetPostgraduateEducationState } from "../../../../../../store/modules/applications/postgraduateEducation/postgraduateEducationSlice";
import degreeType from "../../../../../../core/types/degreeType";
import ApplicationsLists from "../../../../../../core/interfaces/applications/ApplicationsLists";
import paymentType from "./types/paymentType";
import loading from "../../../../../../core/types/loading";
import { DashedButton } from "../../../../../shared/native/button.styles";
import SelectPaymentGroup from "./components/selectPaymentGroup/SelectPaymentGroup";
import PaymentInfo from "./constants/paymentInfo";
import ApplicationInfo from "../../../../../../core/interfaces/applications/ApplicationInfo";
import ApplicationCard from "./components/applicationsCards";
import { calculatePriority } from "./utils/calculatePriority";

const RatingApplicationsList: React.FC<{ degree: degreeType }> = ({ degree }) => {
  const viewedGroups: paymentType[] = ["paid", "budget", "draft"];
  const masterMinPriority = 1000;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loadingStatus, setLoadingStatus] = React.useState<loading>(null);
  const [paymentGroups, setPaymentGroups] = React.useState<PaymentInfo[]>([]);
  const [selectedPaymentGroup, setSelectedPaymentGroup] = React.useState<PaymentInfo>(null);

  // INIT
  const generatePaymentGroups = (applicationsLists: ApplicationsLists): PaymentInfo[] => {
    return viewedGroups.map(
      (paymentItem) =>
        new PaymentInfo(
          paymentItem,
          applicationsLists[paymentItem],
          !applicationsLists[paymentItem].length
        )
    );
  };

  React.useEffect(() => {
    setLoadingStatus("LOADING");

    getApplicationsRequest(degree).then((res) => {
      if (res.data.error) {
        setLoadingStatus("ERROR");
        return;
      }
      setLoadingStatus("COMPLETE");

      const resApplications = res.data.payload as ApplicationsLists;

      if (![...resApplications.budget, ...resApplications.paid, ...resApplications.draft]?.length) {
        navigate(`/applications/${degree}-education/submission-info`);
        return;
      }

      setPaymentGroups(generatePaymentGroups(resApplications));
    });
  }, []);

  const createNewApplication = (): void => {
    document.cookie = `lastModified${capitalize(degree)}EducationApplicationId=null; path=/`;
    document.cookie = `lastModified${capitalize(degree)}EducationStep=0; path=/`;
    dispatch(resetHighEducationState());
    dispatch(resetAdditionalEducationState());
    dispatch(resetPostgraduateEducationState());
    navigate(`/applications/${degree}-education/submission-info`);
  };

  // DRAG AND DROP
  const saveApplicationsListPriority = React.useCallback(
    debounce((paymentGroupsInfo: PaymentInfo[], selectedGroupInfo: PaymentInfo): void => {
      if (!paymentGroupsInfo?.length || !selectedGroupInfo) {
        return;
      }

      const findGroupByName = (type: paymentType): PaymentInfo =>
        paymentGroupsInfo.find((group) => group.name === type);

      const resultLists: ApplicationsLists = {
        budget: findGroupByName("budget").applications,
        paid: findGroupByName("paid").applications,
        draft: findGroupByName("draft").applications,
      };
      resultLists[selectedGroupInfo.name] = selectedGroupInfo.applications;

      saveApplicationsPriorityRequest(resultLists);
    }, 1000),
    []
  );

  const changeApplicationsPriority = (newList: ApplicationInfo[]): void => {
    const resultList = newList.map((item, index) => ({
      ...item,
      priority:
        item.level === "Магистратура"
          ? masterMinPriority + index
          : calculatePriority(selectedPaymentGroup, index),
    }));
    const newSelectedPaymentGroup = {
      ...selectedPaymentGroup,
      applications: resultList,
    };
    const newPaymentGroups = paymentGroups;
    newPaymentGroups[
      newPaymentGroups.findIndex((group) => group.name === newSelectedPaymentGroup.name)
    ] = newSelectedPaymentGroup;

    setSelectedPaymentGroup(newSelectedPaymentGroup);
    setPaymentGroups(newPaymentGroups);

    saveApplicationsListPriority(paymentGroups, {
      ...newSelectedPaymentGroup,
      applications: newSelectedPaymentGroup.applications.filter((item) => item.priority < 1000),
    });
  };

  // LIST
  const getApplicationsCardsList = (): React.ReactNode =>
    selectedPaymentGroup.applications.map((application, index) => {
      const isMaster = application.level === "Специалитет" || application.level === "Бакалавриат";
      const priority = calculatePriority(selectedPaymentGroup, index);

      return (
        <ApplicationCard
          key={application.id}
          application={application}
          priority={isMaster ? priority : null}
          degree={degree}
          paymentGroup={selectedPaymentGroup}
          className={isMaster ? "sortableItem" : null}
        />
      );
    });

  if (loadingStatus === "LOADING") {
    return (
      <RatingApplicationsListContainer>
        <Loader type="spinner" />
      </RatingApplicationsListContainer>
    );
  }

  return (
    <RatingApplicationsListContainer>
      <RatingApplicationsListContent>
        <RatingApplicationsListHeader>
          <TitleH1>Заявления на {graduateInfo[degree].text}</TitleH1>
          <DashedButton onClick={createNewApplication}>Новое заявление</DashedButton>
        </RatingApplicationsListHeader>
        <SelectPaymentGroup
          degree={degree}
          paymentGroups={paymentGroups}
          selectedGroup={selectedPaymentGroup}
          selectGroupCallback={setSelectedPaymentGroup}
        />
        {selectedPaymentGroup && (
          <>
            <StyledRatingApplicationsList $hasSortable={selectedPaymentGroup.name === "budget"}>
              {selectedPaymentGroup.name === "budget" ? (
                <>
                  <ReactSortable
                    handle=".sortableItem"
                    className="sortableList"
                    list={selectedPaymentGroup.applications}
                    setList={(newState): void => changeApplicationsPriority(newState)}
                  >
                    {getApplicationsCardsList()}
                  </ReactSortable>
                </>
              ) : (
                getApplicationsCardsList()
              )}
            </StyledRatingApplicationsList>
          </>
        )}
      </RatingApplicationsListContent>
    </RatingApplicationsListContainer>
  );
};

export default RatingApplicationsList;
