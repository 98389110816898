import React from "react";
import { useNavigate } from "react-router-dom";
import {
  InfoPage,
  InfoPageContent,
  InfoPageTextInfo,
} from "../../../../../../../shared/infoPage/infoPage.styles";
import { TitleH1, TitleH2 } from "../../../../../../../shared/native/typography.styles";
import { PrimaryButton } from "../../../../../../../shared/native/button.styles";
import { setHasCompletedApplications } from "../../../../../../../../store/core/account/accountSlice";
import { useAppDispatch } from "../../../../../../../../core/hooks/redux";

const CompletePostgraduateEducationForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(
      setHasCompletedApplications({
        degree: "postgraduate",
        value: true,
      })
    );
  }, []);

  return (
    <InfoPage>
      <InfoPageContent>
        <InfoPageTextInfo>
          <TitleH1>Поздравляем!</TitleH1>
          <TitleH2>Ваше заявление отправлено.</TitleH2>
          <p>Все статусы будут отображаться в разделе уведомлений.</p>
        </InfoPageTextInfo>
        <PrimaryButton onClick={(): void => navigate("/applications/postgraduate-education")}>
          Перейти
        </PrimaryButton>
      </InfoPageContent>
    </InfoPage>
  );
};

export default CompletePostgraduateEducationForm;
