import { AxiosResponse } from "axios";
import { $baseApi } from "../index";
import ResponseBody from "../models/ResponseBody";
import RequestBody from "../models/RequestBody";
import Dictionaries from "../../core/interfaces/dictionaries/Dictionaries";
import ProgramDictionary from "../../core/interfaces/dictionaries/programDictionaries/ProgramDictionary";
import AdditionalEducationDictionary from "../../core/interfaces/dictionaries/additionalEducationDictionaries/AdditionalEducationDictionary";

export const getDictionariesRequest = (): Promise<AxiosResponse<ResponseBody<Dictionaries>>> => {
  return $baseApi.post<ResponseBody<Dictionaries>>("", new RequestBody("profile.getdata1c", null));
};

export const getProgramDictionariesRequest = (): Promise<
  AxiosResponse<ResponseBody<ProgramDictionary[]>>
> => {
  return $baseApi
    .post<ResponseBody<ProgramDictionary[]>>("", new RequestBody("application.getprogramm1c", null))
    .then((res) => ({
      ...res,
      data: {
        ...res.data,
        payload: res.data.payload.map((dictionary) => ({
          ...dictionary,
          kvota: dictionary.kvota === "" ? "Нет" : dictionary.kvota,
        })),
      },
    }));
};

export const getAdditionalEducationDictionariesRequest = (): Promise<
  AxiosResponse<ResponseBody<AdditionalEducationDictionary>>
> => {
  return $baseApi.post<ResponseBody<AdditionalEducationDictionary>>(
    "",
    new RequestBody("application.getprogrammDpo1c", null)
  );
};

export const getPostgraduateEducationDictionariesRequest = (): Promise<
  AxiosResponse<ResponseBody<{ error: boolean; programs: ProgramDictionary[]; status: string }>>
> => {
  return $baseApi.post<
    ResponseBody<{ error: boolean; programs: ProgramDictionary[]; status: string }>
  >("", new RequestBody("application.getprogrammPo1c", null));
};
