import React from "react";
import { Control, FormState, useFieldArray, UseFormSetValue, useWatch } from "react-hook-form";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import {
  FieldDescription,
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import {
  FieldData,
  FormDataGrayToRedButton,
} from "../../../../../../../shared/form/fieldData.styles";
import Select from "../../../../../../../shared/select/Select";
import AddFileButton from "../../../../../../../shared/addFileButton/AddFileButton";
import { useAppSelector } from "../../../../../../../../core/hooks/redux";
import getSelectedProgram from "../../utils/getSelectedProgram";
import { SplitPostgraduateEducationForm } from "../../interfaces/postgraduateEducationForm";
import Loader from "../../../../../../../shared/loaders/Loader";
import ProgramDictionary from "../../../../../../../../core/interfaces/dictionaries/programDictionaries/ProgramDictionary";
import AchievementsFormInterface from "../../interfaces/achievementsForm";
import AddFileInfo from "../../../../../../../shared/addFileInfo/AddFileInfo";

const AchievementsForm: React.FC<{
  formState: FormState<AchievementsFormInterface>;
  control: Control<AchievementsFormInterface>;
  setValue: UseFormSetValue<SplitPostgraduateEducationForm>;
}> = ({ formState: { errors }, control, setValue }) => {
  const { dictionariesState } = useAppSelector((state) => state.coreState);
  const { postgraduateEducationState } = useAppSelector(
    (state) => state.modulesState.applicationsState
  );
  const { fields, append, update } = useFieldArray({
    control,
    name: "achievements",
  });
  const [selectedProgram, setSelectedProgram] = React.useState<ProgramDictionary>(null);
  const achievementsWatch = useWatch({ control, name: "achievements" });

  React.useEffect(() => {
    if (!dictionariesState.postgraduateEducationDictionaries) {
      return;
    }

    setSelectedProgram(
      getSelectedProgram(
        postgraduateEducationState.application.educationLevel,
        postgraduateEducationState.application.directionAndSpeciality,
        postgraduateEducationState.application.form,
        postgraduateEducationState.application.financing
      )
    );
  }, [dictionariesState.postgraduateEducationDictionaries, postgraduateEducationState]);

  React.useEffect(() => {
    if (fields.length) {
      return;
    }

    if (postgraduateEducationState.achievements.length) {
      postgraduateEducationState.achievements.forEach((achievement, index) => {
        update(index, achievement);
      });
      return;
    }

    append({
      achievement: "0",
      document: null,
    });
  }, [postgraduateEducationState.achievements]);

  if (!selectedProgram) {
    return <Loader type="spinner" />;
  }

  return (
    <FormContainer>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <FieldInfo>
            {index === 0 && (
              <>
                <FieldName>Индивидуальные достижения</FieldName>
                {errors.achievements?.[index].achievement && (
                  <FieldError>
                    {errors.achievements?.[index].achievement?.message as string}
                  </FieldError>
                )}
              </>
            )}
          </FieldInfo>
          <FieldData>
            <Select
              className="defaultSelect"
              placeholder="Выберите достижение"
              items={[
                { achievement_id: "0", achievement_name: "Отсутствует" },
                ...selectedProgram.achievements,
              ].map((discount: any) => ({
                label: discount.achievement_name,
                value: discount.achievement_id,
              }))}
              control={control}
              name={`achievements.${index}.achievement`}
            />
          </FieldData>
          <FieldInfo>
            {index === 0 && (
              <>
                <FieldName>Загрузка документа</FieldName>
                <FieldDescription>Поле для загрузки достижений</FieldDescription>
                {errors.achievements?.[index].document && (
                  <FieldError>
                    {errors.achievements?.[index].document?.message as string}
                  </FieldError>
                )}
              </>
            )}
          </FieldInfo>
          <FieldData>
            <AddFileButton
              name={`achievements.${index}.document`}
              options={{
                required: {
                  value:
                    !!achievementsWatch[index]?.achievement &&
                    achievementsWatch[index]?.achievement !== "0",
                  message: "Обязательное поле",
                },
              }}
              control={control}
              setValue={setValue}
              accept={"image/*"}
              multiple
            >
              Подтверждение достижений
            </AddFileButton>
          </FieldData>
        </React.Fragment>
      ))}
      <FieldInfo />
      <FieldData>
        <FormDataGrayToRedButton
          type="button"
          onClick={(): void =>
            append({
              achievement: "",
              document: null,
            })
          }
        >
          Добавить еще достижения
        </FormDataGrayToRedButton>
      </FieldData>
      <FieldInfo />
      <FieldData>
        <AddFileInfo />
      </FieldData>
    </FormContainer>
  );
};

export default AchievementsForm;
