import React from "react";
import { FormDescription } from "../../signatureForm.styles";

const RequestCode: React.FC = () => {
  return (
    <>
      <FormDescription>
        Для получения электронной подписи, подтвердите личность через смс с кодом. Нажмите кнопку
        «Получить код», и введите его в соответствующее поле.
        <hr />
        <span>!</span>Если номер вашего телефона не принадлежит Российской Федерации, то после
        нажатия на кнопку «Получить код», проверьте вашу электронную почту, код автоматически был
        отправлен на нее.
      </FormDescription>
    </>
  );
};

export default RequestCode;
