import React from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { ColumnFormContainer } from "../../../../../../../shared/form/form.styles";
import {
  ColumnFieldInfo,
  FieldDescription,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import { FieldData } from "../../../../../../../shared/form/fieldData.styles";
import AddFileButton from "../../../../../../../shared/addFileButton/AddFileButton";
import { SplitPostgraduateEducationForm } from "../../interfaces/postgraduateEducationForm";
import AddFileInfo from "../../../../../../../shared/addFileInfo/AddFileInfo";

const LetterForm: React.FC<{
  control: Control;
  setValue: UseFormSetValue<SplitPostgraduateEducationForm>;
}> = ({ control, setValue }) => {
  return (
    <ColumnFormContainer>
      <ColumnFieldInfo>
        <FieldName leftContent>Загрузка мотивационного письма</FieldName>
        <FieldDescription leftContent>
          В свободной форме опишите задачи (исследовательские, профессиональные, личностного
          развития и т.д.), которые вы планируете решить в рамках обучения в аспирантуре, а также
          обозначьте сферу ваших научных интересов и предполагаемую тему диссертации; рекомендуемый
          объем – 2-3 страницы формата А-4).
        </FieldDescription>
      </ColumnFieldInfo>
      <FieldData style={{ marginTop: 16 }}>
        <AddFileButton
          name="letter"
          control={control}
          setValue={setValue}
          accept={"image/*"}
          multiple
          description="(Все страницы)"
        >
          Приложение к документу
        </AddFileButton>
      </FieldData>
      <FieldInfo />
      <FieldData>
        <AddFileInfo />
      </FieldData>
    </ColumnFormContainer>
  );
};

export default LetterForm;
