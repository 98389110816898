import styled from "styled-components";
import { InputHTMLAttributes } from "react";
import colors from "../../../core/styles/colors";
import side from "../../../core/types/side";

export const NumberInput = styled.input.attrs<
  {
    hasErrors?: boolean;
    arrow?: {
      $show: boolean;
      position: side;
    };
  } & InputHTMLAttributes<HTMLInputElement>
>({
  type: "number",
})`
  position: relative;
  width: calc(100% - 30px);
  height: 44px;
  padding: 0 13px;
  border: 2px solid
    ${(props: { hasErrors?: boolean }): string =>
      props.hasErrors ? colors.red.general : colors.white.hoverWhite3};
  background: ${colors.white.hoverWhite3};
  border-radius: 10px;
  font-family: "Gilroy", serif;
  font-size: 18px;
  font-weight: 300;
  color: ${colors.black.general};
  outline: none;
  transition: ease-in-out 0.3s;
  &:focus {
    border: 2px solid
      ${(props: { hasErrors?: boolean }): string =>
        props.hasErrors ? colors.red.general : colors.gray.gray200};
  }
  &::placeholder {
    color: ${colors.gray.gray300};
  }
  &:disabled {
    cursor: not-allowed;
    border-color: ${colors.white.disabled};
    background: ${colors.white.disabled};
    &::placeholder {
      color: ${colors.white.disabledText};
    }
  }
`;
