import React from "react";
import { useNavigate } from "react-router-dom";
import {
  InfoPage,
  InfoPageContent,
  InfoPageTextInfo,
} from "../../../../../../../shared/infoPage/infoPage.styles";
import { TitleH1, TitleH3 } from "../../../../../../../shared/native/typography.styles";
import { PrimaryButton } from "../../../../../../../shared/native/button.styles";
import { Ul } from "../../../../../../../shared/native/ul.styles";
import { Li } from "../../../../../../../shared/native/li.styles";

const WhatNeedInfo: React.FC = () => {
  const navigate = useNavigate();

  return (
    <InfoPage>
      <InfoPageContent>
        <InfoPageTextInfo>
          <TitleH1>Что нужно для заполнения:</TitleH1>
          <Ul>
            <Li>Документ о полученном образовании</Li>
            <Li>
              Документ, подтверждающий скидку или льготу при поступлении
              <br />
              (при наличии)
            </Li>
          </Ul>
          <TitleH3 style={{ marginTop: 16 }}>
            При переводе из другого вуза необходимы дополнительно:
          </TitleH3>
          <Ul>
            <Li>Справка об обучении</Li>
            <Li>Справка о статусе студента</Li>
            <Li>Лицензия вуза</Li>
          </Ul>
        </InfoPageTextInfo>
        <PrimaryButton onClick={(): void => navigate(`/applications/additional-high-data/form`)}>
          Продолжить
        </PrimaryButton>
      </InfoPageContent>
    </InfoPage>
  );
};

export default WhatNeedInfo;
