import React from "react";
import { Control, FieldValues, FormState, UseFormSetValue, useWatch } from "react-hook-form";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import SelectItem from "../../../../../../../shared/select/SelectItem";
import { useAppSelector } from "../../../../../../../../core/hooks/redux";
import { SplitAdditionalEducationForm } from "../../interfaces/additionalEducationForm";
import {
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import { FieldData } from "../../../../../../../shared/form/fieldData.styles";
import Select from "../../../../../../../shared/select/Select";
import Loader from "../../../../../../../shared/loaders/Loader";

const ApplicationForm: React.FC<{
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<SplitAdditionalEducationForm>;
}> = ({ control, formState: { errors }, setValue }) => {
  const { dictionariesState } = useAppSelector((state) => state.coreState);

  // STATES
  const [modifiedFormByUser, setModifiedFormByUser] = React.useState(false);

  // WATCH
  const levelWatch = useWatch({ control, name: "level" });
  const programWatch = useWatch({ control, name: "program" });

  const getSelectItems = (dictionary: "level" | "program" | "form"): SelectItem[] => {
    if (!dictionariesState.additionalEducationDictionaries?.levels) {
      return [];
    }

    const levels = dictionariesState.additionalEducationDictionaries?.levels;
    if (dictionary === "level") {
      return levels.map((level) => ({
        label: level.level,
        value: level.level_id,
      }));
    }

    if (!levelWatch) {
      return [];
    }

    const programs = levels?.find((level) => level.level_id === levelWatch)?.programs;
    if (!programs) {
      return [];
    }

    if (dictionary === "program") {
      return programs.map(
        (program) =>
          ({
            label: program.name,
            value: program.id,
          } as SelectItem)
      );
    }

    if (!programWatch) {
      return [];
    }

    const types = programs?.find((program) => program.id === programWatch)?.types;
    if (!types) {
      return [];
    }

    return types.map(
      (type) =>
        ({
          label: type.forma,
          value: type.forma_id,
        } as SelectItem)
    );
  };

  const setModifiedFormByUserHandler = (): void => {
    setModifiedFormByUser(true);
  };

  React.useEffect(() => {
    if (!modifiedFormByUser) {
      return;
    }

    setValue("program", "");
  }, [levelWatch]);

  React.useEffect(() => {
    if (!modifiedFormByUser) {
      return;
    }

    setValue("form", "");
  }, [levelWatch, programWatch]);

  if (dictionariesState.additionalEducationLoadingStatus === "LOADING") {
    return (
      <FormContainer>
        <Loader type="spinner" />
      </FormContainer>
    );
  }

  return (
    <FormContainer>
      <FieldInfo>
        <FieldName>Выберите вид программы</FieldName>
        {errors.level && <FieldError>{errors.level?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите вариант ответа"
          items={getSelectItems("level")}
          control={control}
          options={{
            required: "Обязательное поле",
          }}
          name="level"
          onClick={setModifiedFormByUserHandler}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Выберите программу</FieldName>
        {errors.program && <FieldError>{errors.program?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите вариант ответа"
          items={getSelectItems("program")}
          control={control}
          options={{
            required: "Обязательное поле",
          }}
          name="program"
          onClick={setModifiedFormByUserHandler}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Выберите форму обучения</FieldName>
        {errors.form && <FieldError>{errors.form?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите вариант ответа"
          items={getSelectItems("form")}
          control={control}
          options={{
            required: "Обязательное поле",
          }}
          name="form"
          disabled={!programWatch}
          onClick={setModifiedFormByUserHandler}
        />
      </FieldData>
    </FormContainer>
  );
};

export default ApplicationForm;
