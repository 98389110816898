import React from "react";
import {
  Control,
  FieldValues,
  FormState,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import {
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../../../shared/form/fieldInfo.styles";
import { FieldData } from "../../../../../../../../../shared/form/fieldData.styles";
import { Input } from "../../../../../../../../../shared/native/input.styles";
import { TitleH2 } from "../../../../../../../../../shared/native/typography.styles";
import Checkbox from "../../../../../../../../../shared/checkbox/Checkbox";
import Select from "../../../../../../../../../shared/select/Select";
import { useAppSelector } from "../../../../../../../../../../core/hooks/redux";

const PostForm: React.FC<{
  register: UseFormRegister<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<FieldValues>;
}> = ({ register, formState: { errors }, control, setValue }) => {
  const { dictionariesState } = useAppSelector((state) => state.coreState);

  const hasNoMiddleNameOfAuthorizedPersonWatch = useWatch({
    control,
    name: "hasNoMiddleNameOfAuthorizedPerson",
  });

  React.useEffect(() => {
    if (hasNoMiddleNameOfAuthorizedPersonWatch) {
      setValue("middleNameOfAuthorizedPerson", "");
    }
  }, [hasNoMiddleNameOfAuthorizedPersonWatch]);

  return (
    <>
      <FieldInfo>
        <TitleH2 style={{ textAlign: "left" }}>Полномочия</TitleH2>
      </FieldInfo>
      <FieldData />

      <FieldInfo leftContent>
        <FieldName leftContent>Должность</FieldName>
        {errors.post && <FieldError leftContent>{errors.post?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.post}
          {...register("post", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>

      <FieldInfo leftContent>
        <FieldName leftContent>Основание полномочий</FieldName>
        {errors.basisOfAuthority && (
          <FieldError leftContent>{errors.basisOfAuthority?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        {dictionariesState?.dictionaries && (
          <Select
            className="defaultSelect"
            placeholder="Выберите основание полномочий"
            items={dictionariesState.dictionaries.positionsDPO.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            control={control}
            options={{
              required: "Обязательное поле",
            }}
            name="basisOfAuthority"
          />
        )}
      </FieldData>

      <FieldInfo leftContent>
        <FieldName leftContent>Фамилия уполномоченного лица</FieldName>
        {errors.lastnameOfAuthorizedPerson && (
          <FieldError leftContent>
            {errors.lastnameOfAuthorizedPerson?.message as string}
          </FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.lastnameOfAuthorizedPerson}
          {...register("lastnameOfAuthorizedPerson", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>

      <FieldInfo leftContent>
        <FieldName leftContent>Имя уполномоченного лица</FieldName>
        {errors.firstnameOfAuthorizedPerson && (
          <FieldError leftContent>
            {errors.firstnameOfAuthorizedPerson?.message as string}
          </FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.firstnameOfAuthorizedPerson}
          {...register("firstnameOfAuthorizedPerson", {
            required: "Обязательное поле",
          })}
        />
      </FieldData>

      <FieldInfo leftContent>
        <FieldName leftContent>Отчество уполномоченного лица</FieldName>
        {errors.middleNameOfAuthorizedPerson && (
          <FieldError leftContent>
            {errors.middleNameOfAuthorizedPerson?.message as string}
          </FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Input
          className="defaultInput"
          placeholder="Начните вводить"
          hasErrors={!!errors.middleNameOfAuthorizedPerson}
          disabled={hasNoMiddleNameOfAuthorizedPersonWatch}
          {...register("middleNameOfAuthorizedPerson", {
            required: "Обязательное поле",
          })}
        />
        <Checkbox
          style={{ marginLeft: 8 }}
          label="Отсутствует"
          control={control}
          name="hasNoMiddleNameOfAuthorizedPerson"
        />
      </FieldData>
    </>
  );
};

export default PostForm;
