import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, FormActions, FormContent } from "../../../../../shared/form/form.styles";
import { useAppDispatch, useAppSelector } from "../../../../../../core/hooks/redux";
import UseAlert from "../../../../../../core/hooks/useAlert";
import getCookie from "../../../../../../core/utils/getCookie";
import { setViewedStep } from "../../../../../../store/modules/applications/additionalAEData/additionalAEDataSlice";
import {
  AdditionalAEDataForm as AdditionalAEDataFormInterface,
  SplitAdditionalAeDataForm,
} from "./interfaces/additionalAEDataForm";
import Stepper from "../../../../../shared/stepper/Stepper";
import { OutlinedButton, PrimaryButton } from "../../../../../shared/native/button.styles";
import { ReactComponent as LineArrowIcon } from "../../../../../../core/static/icons/line-arrow.svg";
import steps from "./constants/steps";
import convertSplitAdditionalAEForm from "./utils/convertSplitAdditionalAEForm";
import ResponseBody from "../../../../../../http/models/ResponseBody";
import loading from "../../../../../../core/types/loading";
import { fetchGetAdditionalAEData } from "../../../../../../store/modules/applications/additionalAEData/additionalAEDataThunk";
import { saveAdditionalAEDataFormRequest } from "../../../../../../http/requests/applications/additionalAEData.http";

const AdditionalDataForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const alertHandler = UseAlert();
  const navigate = useNavigate();
  const [submitLoadingStatus, setSubmitLoadingStatus] = React.useState<loading>(null);
  const { additionalAEDataState } = useAppSelector((state) => state.modulesState.applicationsState);
  const { register, control, formState, handleSubmit, reset, setValue } =
    useForm<SplitAdditionalAeDataForm>({
      values: {
        ...additionalAEDataState.education,
        ...additionalAEDataState.employment,
      },
      mode: "onBlur",
    });

  React.useEffect(() => {
    const cookie = getCookie();
    if (cookie?.lastModifiedAdditionalAEDataStep) {
      dispatch(setViewedStep(Number(cookie.lastModifiedAdditionalAEDataStep)));
    }
  }, []);

  const setLastModifiedStepCookie = (step: number): void => {
    document.cookie = `lastModifiedAdditionalAEDataStep=${step}; path=/`;
  };

  const getCurrentFormComponent = (): ((props?: any) => React.ReactNode) => {
    return steps.find((step, key) => additionalAEDataState.viewedStep === key)?.element;
  };

  const goToPreviousStep = (): void => {
    const previousStepIndex = additionalAEDataState.viewedStep - 1;
    dispatch(setViewedStep(previousStepIndex));
    setLastModifiedStepCookie(previousStepIndex);
  };

  const submitForm = (stepId: number, formData: SplitAdditionalAeDataForm): void => {
    setSubmitLoadingStatus("LOADING");

    saveAdditionalAEDataFormRequest(convertSplitAdditionalAEForm(formData)).then((saveRes) => {
      if (saveRes.data.error) {
        setSubmitLoadingStatus("ERROR");
        alertHandler({ message: saveRes.data.text, type: "error" });
        return;
      }
      setSubmitLoadingStatus("COMPLETE");

      alertHandler({ message: "Информация сохранена", type: "success" });

      dispatch(fetchGetAdditionalAEData()).then((action) => {
        const getRes = action.payload as ResponseBody<AdditionalAEDataFormInterface>;
        if (getRes.error) {
          alertHandler({ message: getRes.text, type: "error" });
        }

        if (stepId === steps.length - 1) {
          navigate("/applications/additional-ae-data/check");
          return;
        }
        dispatch(setViewedStep(stepId + 1));
        setLastModifiedStepCookie(stepId + 1);
      });
    });
  };

  return (
    <Form onSubmit={handleSubmit((data) => submitForm(additionalAEDataState.viewedStep, data))}>
      <Stepper items={steps} currentStep={additionalAEDataState.viewedStep} className="stepper" />
      <FormContent>
        {getCurrentFormComponent()({
          register,
          control,
          formState,
          reset,
          setValue,
        })}
      </FormContent>
      <FormActions lastStep={additionalAEDataState.viewedStep === steps.length - 1}>
        {additionalAEDataState.viewedStep !== 0 && (
          <OutlinedButton
            disabled={additionalAEDataState.dataLoadingStatus === "LOADING"}
            className="leftButton"
            type="button"
            onClick={goToPreviousStep}
          >
            <LineArrowIcon />
          </OutlinedButton>
        )}
        <PrimaryButton
          disabled={
            additionalAEDataState.dataLoadingStatus === "LOADING" ||
            submitLoadingStatus === "LOADING"
          }
          className="rightButton"
          type="submit"
        >
          {additionalAEDataState.viewedStep !== steps.length - 1 ? <LineArrowIcon /> : "Сохранить"}
        </PrimaryButton>
      </FormActions>
    </Form>
  );
};

export default AdditionalDataForm;
