import React from "react";
import { LinkCard as StyledLinkCard } from "./linkCard.styles";
import statusColors from "../../../../core/constants/statusColors";

const LinkCard: React.FC<
  {
    $dashed?: boolean;
    children?: React.ReactNode;
    notificationsCount?: number;
    ref?: React.RefObject<HTMLDivElement>;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ $dashed = false, children, notificationsCount, ref, ...attrs }) => {
  return (
    <StyledLinkCard
      {...attrs}
      $dashed={$dashed}
      color={statusColors[notificationsCount ? "active" : "complete"]}
      ref={ref}
    >
      {children}
    </StyledLinkCard>
  );
};

export default LinkCard;
