import styled from "styled-components";
import colors from "../../../../core/styles/colors";
import sizes from "../../../../core/styles/sizes";

export const Container = styled.div`
  width: 100%;
  min-height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  @media all and (${sizes.mobile}) {
    height: 100%;
  }
`;

export const Form = styled.div`
  flex-grow: 1;
  max-width: 350px;
  .fieldRow {
    width: 100%;
    margin-top: 16px;
    .fieldHeader {
      width: calc(100% - 32px);
      padding: 0 16px;
      margin-bottom: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        margin: 0;
        font-family: Gilroy, serif;
        font-size: 16px;
        font-weight: 300;
      }
    }
    input {
      width: calc(100% - 30px);
      margin-bottom: 16px;
    }
  }
  .actions {
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: 100%;
    }
  }

  @media all and (${sizes.mobile}) {
    width: calc(100% - 32px);
    margin: 0 16px;
    padding: 16px;
    border-radius: 10px;
    background: ${colors.white.general};
  }
`;
