import { combineReducers } from "@reduxjs/toolkit";
import accountSlice from "./account/accountSlice";
import alertSlice from "./alert/alertSlice";
import dictionariesSlice from "./dictionaries/dictionariesSlice";

export default combineReducers({
  accountState: accountSlice,
  alertState: alertSlice,
  dictionariesState: dictionariesSlice,
});
