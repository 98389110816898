import React from "react";
import {
  NotificationCard as StyledNotificationCard,
  NotificationCardActions,
  NotificationCardContent,
  NotificationCardHeader,
} from "./notificationCard.styles";
import { PrimaryButton } from "../../../../../../shared/native/button.styles";
import { TitleH3 } from "../../../../../../shared/native/typography.styles";

const NotificationCard: React.FC<{
  children?: React.ReactNode;
  title?: string;
  comment?: string;
  responseButtonText?: string;
  disableResponseButton?: boolean;
  hideResponseButton?: boolean;
  handleSubmit: () => void;
}> = ({
  children,
  title,
  comment,
  responseButtonText,
  disableResponseButton,
  hideResponseButton,
  handleSubmit,
}) => {
  return (
    <StyledNotificationCard onSubmit={handleSubmit}>
      <NotificationCardHeader>
        {!!title && <TitleH3 style={{ textAlign: "left" }}>{title}</TitleH3>}
        {!!comment && (
          <p style={{ marginTop: title ? 4 : 0 }}>
            <span>!</span>
            {comment}
          </p>
        )}
      </NotificationCardHeader>
      {!!children && <NotificationCardContent>{children}</NotificationCardContent>}
      <NotificationCardActions>
        {!hideResponseButton && (
          <PrimaryButton type="submit" disabled={disableResponseButton || hideResponseButton}>
            {responseButtonText ?? "Ответить"}
          </PrimaryButton>
        )}
      </NotificationCardActions>
    </StyledNotificationCard>
  );
};

export default NotificationCard;
