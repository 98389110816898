import styled from "styled-components";
import colors from "../../../core/styles/colors";

export const AddFileInfoStyles = styled.div`
  width: fit-content;
  background-color: ${colors.white.card};
  border: 1px solid ${colors.white.hoverWhite3};
  padding: 4px 12px;
  border-radius: 10px;

  b {
    font-size: 16px;
    font-weight: 400;
  }

  p {
    margin: 4px 0 0 0;
    font-size: 14px;
    font-weight: 300;
    code {
      color: ${colors.red.general};
    }
  }
`;
