import React from "react";
import { ModalContainer, ModalContent, ModalHeader, ModalWindow } from "./modal.styles";
import { ReactComponent as CloseIcon } from "../../../core/static/icons/close.svg";

const Modal: React.FC<{
  children: React.ReactNode;
  closeModal: () => void;
}> = ({ children, closeModal }) => {
  const closeModalHandler = (e: KeyboardEvent): void => {
    if (e.key === "Escape") {
      closeModal();
    }
  };

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", closeModalHandler);

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", closeModalHandler);
    };
  }, []);

  return (
    <ModalContainer>
      <ModalWindow>
        <ModalHeader>
          <CloseIcon onClick={closeModal} />
        </ModalHeader>
        <ModalContent>{children}</ModalContent>
      </ModalWindow>
    </ModalContainer>
  );
};

export default Modal;
