import React from "react";
import {
  Control,
  FieldValues,
  UseFieldArrayUpdate,
  UseFormRegister,
  useWatch,
} from "react-hook-form";
import { ExamItem as StyledExamItem } from "./examItem.styles";
import Select from "../../../../../../../../shared/select/Select";
import SelectItem from "../../../../../../../../shared/select/SelectItem";
import Checkbox from "../../../../../../../../shared/checkbox/Checkbox";
import { NumberInput } from "../../../../../../../../shared/native/numberInput.styles";
import ProgramDictionary from "../../../../../../../../../core/interfaces/dictionaries/programDictionaries/ProgramDictionary";
import Exam from "../../../interfaces/Exam";

const ExamItem: React.FC<{
  name: string;
  register: UseFormRegister<FieldValues>;
  control: Control;
  fieldIndex: number;
  program: ProgramDictionary;
  updateFunc: UseFieldArrayUpdate<FieldValues, "exams">;
}> = ({ name, register, control, fieldIndex, program, updateFunc }) => {
  const examItemWatch = useWatch({ control, name: `${name}.${fieldIndex}` }) as Exam;
  const [disableHasUSE, setDisableHasUSE] = React.useState(false);
  const yearsList = Array.from({ length: 5 }, (_, index) => new Date().getFullYear() - index).map(
    (year) =>
      ({
        label: year.toString(),
        value: year.toString(),
      } as SelectItem)
  );

  React.useEffect(() => {
    if (!program) {
      return;
    }

    const examTypes = program.vi[fieldIndex]?.find(
      (selectedExam) => selectedExam.dicsipline_id === examItemWatch.exam
    )?.types;

    if (!examTypes) {
      if (!examItemWatch.hasUSE) {
        return;
      }
      updateFunc(fieldIndex, {
        exam: examItemWatch.exam,
        hasUSE: false,
        scores: null,
        year: "",
      } as Exam);
      return;
    }

    const isUSE = examTypes.some((type) => type.type_vi_id === "000000001");
    const isExam = examTypes.some((type) => type.type_vi_id === "000000002");

    if (isUSE && isExam) {
      setDisableHasUSE(false);
      return;
    }

    if (!isUSE || !isExam) {
      setDisableHasUSE(true);
    }

    if (examItemWatch.hasUSE === isUSE) {
      return;
    }

    updateFunc(fieldIndex, {
      exam: examItemWatch.exam,
      hasUSE: isUSE,
      scores: null,
      year: "",
    } as Exam);
  }, [examItemWatch]);

  return (
    <StyledExamItem>
      <Select
        placeholder="Тип экзамена"
        items={
          program.vi[fieldIndex]?.map((discipline) => ({
            label: discipline.dicsipline_name,
            value: discipline.dicsipline_id,
          })) ?? []
        }
        control={control}
        options={{
          required: "Обязательное поле",
        }}
        name={`${name}.${fieldIndex}.exam`}
        readOnly={program.vi[fieldIndex]?.length === 1}
        hideArrow={program.vi[fieldIndex]?.length === 1}
        className="selectExam"
      />
      <Checkbox
        label="есть ЕГЭ"
        name={`${name}.${fieldIndex}.hasUSE`}
        control={control}
        style={{ marginRight: 8, width: 100 }}
        disabled={disableHasUSE}
      />
      <NumberInput
        type="number"
        placeholder="Баллы"
        min={0}
        max={100}
        disabled={!examItemWatch.hasUSE}
        {...register(`${name}.${fieldIndex}.scores`, {
          required: {
            value: examItemWatch.hasUSE,
            message: "Обязательное поле",
          },
          min: {
            value: 0,
            message: "Кол-во баллов не может быть меньше 0",
          },
          max: {
            value: 100,
            message: "Кол-во баллов не может быть больше 100",
          },
        })}
        style={{ marginRight: 8, width: 70 }}
      />
      <Select
        placeholder="Год"
        items={yearsList}
        control={control}
        options={{
          required: {
            value: examItemWatch.hasUSE,
            message: "Обязательное поле",
          },
        }}
        name={`${name}.${fieldIndex}.year`}
        disabled={!examItemWatch.hasUSE}
        className="selectYear"
      />
    </StyledExamItem>
  );
};

export default ExamItem;
