import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ApplicationCardContent,
  ApplicationsList as StyledApplicationsList,
  ApplicationsListContainer,
  ApplicationsListContent,
  CardBody,
  CardFooter,
  CardHeader,
} from "./applicationsList.styles";
import { TitleH1, TitleH2 } from "../../../../shared/native/typography.styles";
import LinkCard from "../../../../shared/cards/linkCard/LinkCard";
import { useAppDispatch, useAppSelector } from "../../../../../core/hooks/redux";
import { resetHighEducationState } from "../../../../../store/modules/applications/highEducation/highEducationSlice";
import { fetchGetNotificationsInfo } from "../../../../../store/modules/notifications/notificationsThunk";
import formatDate from "../../../../../core/utils/formatDate";
import degreeType from "../../../../../core/types/degreeType";

const ApplicationsList: React.FC<{ degree: degreeType }> = ({ degree }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { notificationsState } = useAppSelector((state) => state.modulesState);

  React.useEffect(() => {
    dispatch(fetchGetNotificationsInfo());
  }, []);

  const applicationClickHandler = (isNew: boolean, applicationId?: string): void => {
    if (isNew) {
      dispatch(resetHighEducationState());
      navigate(`/notifications/${degree}-education/general`);
      return;
    }
    navigate(`/notifications/${degree}-education/${applicationId}`);
  };

  return (
    <ApplicationsListContainer>
      <ApplicationsListContent>
        <TitleH1>Уведомления</TitleH1>
        {degree === "high" && <TitleH2>{notificationsState.info?.vpo?.status}</TitleH2>}
        <StyledApplicationsList>
          <LinkCard
            notificationsCount={
              notificationsState.info?.[degree === "high" ? "vpo" : "dpo"]?.common
            }
            onClick={(): void => applicationClickHandler(true)}
          >
            <ApplicationCardContent isNew>
              {degree === "high" && !!notificationsState.info?.vpo?.common && (
                <span className="chip">{notificationsState.info?.vpo?.common}</span>
              )}
              {degree === "additional" && !!notificationsState.info?.dpo?.common && (
                <span className="chip">{notificationsState.info?.dpo?.common}</span>
              )}
              <p>Общие документы</p>
            </ApplicationCardContent>
          </LinkCard>
          {!!notificationsState.info?.[degree === "high" ? "vpo" : "dpo"]?.applications &&
            notificationsState.info[degree === "high" ? "vpo" : "dpo"].applications.map(
              (application) => (
                <LinkCard
                  key={application.id}
                  notificationsCount={application.count}
                  onClick={(): void => applicationClickHandler(false, application.id)}
                >
                  {!!application.count && <span className="chip">{application.count}</span>}
                  <ApplicationCardContent>
                    <CardHeader>
                      <b>{degree === "high" ? application.level : ""}</b>
                      <p>{formatDate(application.datetime.split(" ")?.[0])}</p>
                    </CardHeader>
                    <CardBody>
                      <b>{application.program}</b>
                      <p>{application.form}</p>
                      <p>{degree === "high" ? application.financing : ""}</p>
                    </CardBody>
                    <CardFooter>
                      <p>{application.status}</p>
                    </CardFooter>
                  </ApplicationCardContent>
                </LinkCard>
              )
            )}
        </StyledApplicationsList>
      </ApplicationsListContent>
    </ApplicationsListContainer>
  );
};

export default ApplicationsList;
