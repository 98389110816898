import React from "react";
import { Route, Routes } from "react-router-dom";
import BaseApplicationsList from "../../../../ui/modules/applications/components/applicationsList/baseApplicationsList/BaseApplicationsList";
import PreviewPostgraduateEducationForm from "../../../../ui/modules/applications/components/postgraduateEducation/postgraduateEducationForm/components/previewPostgraduateEducationForm/PreviewPostgraduateEducationForm";
import SubmissionInfo from "../../../../ui/modules/applications/components/postgraduateEducation/postgraduateEducationForm/components/submissionInfo/SubmissionInfo";
import PostgraduateEducationForm from "../../../../ui/modules/applications/components/postgraduateEducation/postgraduateEducationForm/PostgraduateEducationForm";
import CompletePostgraduateEducationForm from "../../../../ui/modules/applications/components/postgraduateEducation/postgraduateEducationForm/components/completePostgraduateEducationForm/CompletePostgraduateEducationForm";

const PostgraduateEducationRoutes: React.FC = () => {
  return (
    <Routes>
      {/* PREVIEW */}
      <Route path="" element={<BaseApplicationsList degree="postgraduate" />} />
      <Route path=":id" element={<PreviewPostgraduateEducationForm mode="view" />} />
      {/* FORM */}
      <Route path="submission-info" element={<SubmissionInfo />} />
      <Route path="form" element={<PostgraduateEducationForm />} />
      <Route path="check" element={<PreviewPostgraduateEducationForm mode="finallyCheck" />} />
      <Route path="complete" element={<CompletePostgraduateEducationForm />} />
    </Routes>
  );
};

export default PostgraduateEducationRoutes;
