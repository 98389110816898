import React from "react";
import { Control, FormState, useFieldArray, UseFormSetValue, useWatch } from "react-hook-form";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import {
  FieldDescription,
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import {
  FieldData,
  FormDataGrayToRedButton,
} from "../../../../../../../shared/form/fieldData.styles";
import Select from "../../../../../../../shared/select/Select";
import AddFileButton from "../../../../../../../shared/addFileButton/AddFileButton";
import { SplitHighEducationForm } from "../../interfaces/highEducationForm";
import { useAppSelector } from "../../../../../../../../core/hooks/redux";
import ProgramDictionary from "../../../../../../../../core/interfaces/dictionaries/programDictionaries/ProgramDictionary";
import Loader from "../../../../../../../shared/loaders/Loader";
import getSelectedProgram from "../../utils/getSelectedProgram";
import DiscountsAndBenefitsFormInterface from "../../interfaces/discountsAndBenefitsForm";
import AddFileInfo from "../../../../../../../shared/addFileInfo/AddFileInfo";

const DiscountsAndBenefitsForm: React.FC<{
  formState: FormState<DiscountsAndBenefitsFormInterface>;
  control: Control<DiscountsAndBenefitsFormInterface>;
  setValue: UseFormSetValue<SplitHighEducationForm>;
}> = ({ formState: { errors }, control, setValue }) => {
  const { dictionariesState } = useAppSelector((state) => state.coreState);
  const { highEducationState } = useAppSelector((state) => state.modulesState.applicationsState);
  const [selectedProgram, setSelectedProgram] = React.useState<ProgramDictionary>(null);
  const discountsAndBenefitsWatch = useWatch({ control, name: "discountsAndBenefits" });
  const achievementsWatch = useWatch({ control, name: "achievements" });
  const { fields, append, update } = useFieldArray({ control, name: "achievements" });

  React.useEffect(() => {
    if (!dictionariesState.programDictionaries) {
      return;
    }

    setSelectedProgram(
      getSelectedProgram(
        highEducationState.application.educationLevel,
        highEducationState.application.directionAndSpeciality,
        highEducationState.application.profile,
        highEducationState.application.form,
        highEducationState.application.financing,
        highEducationState.application.kvota
      )
    );
  }, [dictionariesState.programDictionaries, highEducationState]);

  React.useEffect(() => {
    if (fields.length) {
      return;
    }

    if (highEducationState.discountsAndBenefits.achievements.length) {
      highEducationState.discountsAndBenefits.achievements.forEach((achievement, index) => {
        update(index, achievement);
      });
      return;
    }

    append({
      achievement: "0",
      document: null,
    });
  }, [highEducationState.discountsAndBenefits]);

  if (!selectedProgram) {
    return <Loader type="spinner" />;
  }

  return (
    <FormContainer>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <FieldInfo>
            {index === 0 && (
              <>
                <FieldName>Индивидуальные достижения</FieldName>
                {errors.achievements?.[index].achievement && (
                  <FieldError>
                    {errors.achievements?.[index].achievement?.message as string}
                  </FieldError>
                )}
              </>
            )}
          </FieldInfo>
          <FieldData>
            <Select
              className="defaultSelect"
              placeholder="Выберите достижение"
              items={[
                { achievement_id: "0", achievement_name: "Отсутствует" },
                ...selectedProgram.achievements,
              ].map((discount) => ({
                label: discount.achievement_name,
                value: discount.achievement_id,
              }))}
              control={control}
              name={`achievements.${index}.achievement`}
            />
          </FieldData>
          <FieldInfo>
            {index === 0 && (
              <>
                <FieldName>Загрузка документа</FieldName>
                <FieldDescription>Поле для загрузки достижений</FieldDescription>
                {errors.achievements?.[index].document && (
                  <FieldError>
                    {errors.achievements?.[index].document?.message as string}
                  </FieldError>
                )}
              </>
            )}
          </FieldInfo>
          <FieldData>
            <AddFileButton
              name={`achievements.${index}.document`}
              options={{
                required: {
                  value:
                    !!achievementsWatch[index]?.achievement &&
                    achievementsWatch[index]?.achievement !== "0",
                  message: "Обязательное поле",
                },
              }}
              control={control}
              setValue={setValue}
              accept={"image/*"}
              multiple
            >
              Подтверждение достижений
            </AddFileButton>
          </FieldData>
        </React.Fragment>
      ))}
      <FieldInfo />
      <FieldData>
        <FormDataGrayToRedButton
          type="button"
          onClick={(): void =>
            append({
              achievement: "",
              document: null,
            })
          }
        >
          Добавить еще достижения
        </FormDataGrayToRedButton>
      </FieldData>
      <FieldInfo>
        <FieldName>Скидки и льготы</FieldName>
        {errors.discountsAndBenefits && (
          <FieldError>{errors.discountsAndBenefits?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите льготу"
          items={[{ name: "Нет", id: "0" }, ...dictionariesState.dictionaries.discounts].map(
            (discount) => ({
              label: discount.name,
              value: discount.id,
            })
          )}
          control={control}
          name="discountsAndBenefits"
          options={{
            required: "Обязательное поле",
          }}
        />
      </FieldData>
      {!!discountsAndBenefitsWatch && discountsAndBenefitsWatch !== "0" && (
        <>
          <FieldInfo>
            <FieldName>Загрузка документа</FieldName>
            <FieldDescription>без документа скидка не будет применена</FieldDescription>
            {errors.discountsConfirm && (
              <FieldError>{errors.discountsConfirm?.message as string}</FieldError>
            )}
          </FieldInfo>
          <FieldData>
            <AddFileButton
              name="discountsConfirm"
              options={{
                required: "Обязательное поле",
              }}
              control={control}
              setValue={setValue}
              accept={"image/*"}
              multiple
            >
              Подтверждение скидки
            </AddFileButton>
          </FieldData>
          <FieldInfo />
          <FieldData>
            <AddFileInfo />
          </FieldData>
        </>
      )}
    </FormContainer>
  );
};

export default DiscountsAndBenefitsForm;
