import { AxiosResponse } from "axios/index";
import ResponseBody from "../../models/ResponseBody";
import { $baseApi } from "../../index";
import RequestBody from "../../models/RequestBody";
import { AdditionalAEDataForm } from "../../../ui/modules/applications/components/additionalEducation/additionalAEDataForm/interfaces/additionalAEDataForm";

export const getAdditionalAEDataFormRequest = (): Promise<
  AxiosResponse<ResponseBody<AdditionalAEDataForm>>
> => {
  return $baseApi.post<ResponseBody<AdditionalAEDataForm>>(
    "",
    new RequestBody("draft.read", {
      type: "additionalDPO",
    })
  );
};

export const saveAdditionalAEDataFormRequest = (
  data: AdditionalAEDataForm
): Promise<AxiosResponse<ResponseBody<null>>> => {
  const resultBody = {
    type: "additionalDPO",
    data: {
      ...data,
    },
  };
  return $baseApi.post<ResponseBody<null>>("", new RequestBody("draft.save", resultBody));
};

export const submitAdditionalAEDataFormRequest = (): Promise<AxiosResponse<ResponseBody<null>>> => {
  return $baseApi.post<ResponseBody<null>>("", new RequestBody("profile.save", null));
};
