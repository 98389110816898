import React from "react";
import {
  Control,
  FieldErrors,
  FieldValues,
  FormState,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import {
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../../../shared/form/fieldInfo.styles";
import { FieldData } from "../../../../../../../../../shared/form/fieldData.styles";
import Address from "../../../../../../../../profile/forms/residingPlaceForm/components/address/Address";
import Checkbox from "../../../../../../../../../shared/checkbox/Checkbox";
import { TitleH2 } from "../../../../../../../../../shared/native/typography.styles";

const ResidingPlaceForm: React.FC<{
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<FieldValues>;
}> = ({ formState: { errors }, control, setValue }) => {
  const residingCoincidesRegistrationWatch = useWatch({
    control,
    name: "residingCoincidesRegistration",
  });

  React.useEffect(() => {
    if (residingCoincidesRegistrationWatch) {
      setValue("residingAddress", {
        country: "",
        region: "",
        city: "",
        street: "",
        house: "",
        flat: "",
        index: "",
      });
    }
  }, [residingCoincidesRegistrationWatch]);

  return (
    <>
      <FieldInfo>
        <TitleH2 style={{ textAlign: "left" }}>Адреса</TitleH2>
      </FieldInfo>
      <FieldData />
      <FieldInfo byRowStart leftContent>
        <FieldName leftContent>Адрес регистрации</FieldName>
        {errors.registerAddress &&
          Object.keys(errors.registerAddress as FieldErrors).some(
            (field) => (errors.registerAddress as FieldErrors)[field].type === "required"
          ) && <FieldError leftContent>Обязательные поля</FieldError>}
      </FieldInfo>
      <FieldData>
        <Address
          control={control}
          name="registerAddress"
          options={{
            required: "Обязательное поле",
          }}
          setValue={setValue}
        />
      </FieldData>
      <FieldInfo byRowStart leftContent>
        <FieldName leftContent>Адрес проживания</FieldName>
        <Checkbox
          label="Совпадает с регистрацией"
          control={control}
          name="residingCoincidesRegistration"
        />
        {errors.residingAddress &&
          Object.keys(errors.residingAddress as FieldErrors).some(
            (field) => (errors.residingAddress as FieldErrors)[field].type === "required"
          ) && <FieldError leftContent>Обязательные поля</FieldError>}
      </FieldInfo>
      <FieldData>
        <Address
          control={control}
          name="residingAddress"
          options={{
            required: !residingCoincidesRegistrationWatch ? "Обязательное поле" : false,
          }}
          disabled={residingCoincidesRegistrationWatch}
          setValue={setValue}
        />
      </FieldData>
    </>
  );
};

export default ResidingPlaceForm;
