import React from "react";
import {
  Control,
  FieldValues,
  FormState,
  useFieldArray,
  UseFormRegister,
  useWatch,
} from "react-hook-form";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import ExamItem from "./components/examItem";
import { useAppSelector } from "../../../../../../../../core/hooks/redux";
import {
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import { FieldData } from "../../../../../../../shared/form/fieldData.styles";
import { SplitHighEducationForm } from "../../interfaces/highEducationForm";
import Loader from "../../../../../../../shared/loaders/Loader";
import ProgramDictionary from "../../../../../../../../core/interfaces/dictionaries/programDictionaries/ProgramDictionary";
import getSelectedProgram from "../../utils/getSelectedProgram";
import Exam from "../../interfaces/Exam";

const EntranceExamsForm: React.FC<{
  register: UseFormRegister<FieldValues>;
  formState: FormState<SplitHighEducationForm>;
  control: Control;
}> = ({ register, formState: { errors }, control }) => {
  // CONSTANTS
  const examsFieldsName = "exams";

  // STORE
  const { highEducationState } = useAppSelector((state) => state.modulesState.applicationsState);
  const { dictionariesState } = useAppSelector((state) => state.coreState);
  const [selectedProgram, setSelectedProgram] = React.useState<ProgramDictionary>(null);

  // CONTROLS
  const examsWatch = useWatch({ control, name: examsFieldsName }) as Exam[];
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: examsFieldsName,
  });

  // FUNCTIONS
  const equalsStateAndProgramExams = (): boolean => {
    return selectedProgram.vi.every((examRow, index) =>
      examRow.some((examItem) => examItem.dicsipline_id === examsWatch[index]?.exam)
    );
  };

  // EFFECTS
  React.useEffect(() => {
    if (!dictionariesState.programDictionaries) {
      return;
    }

    setSelectedProgram(
      getSelectedProgram(
        highEducationState.application.educationLevel,
        highEducationState.application.directionAndSpeciality,
        highEducationState.application.profile,
        highEducationState.application.form,
        highEducationState.application.financing,
        highEducationState.application.kvota
      )
    );
  }, [dictionariesState.programDictionaries, highEducationState]);

  React.useEffect(() => {
    if (!selectedProgram) {
      return;
    }

    if (equalsStateAndProgramExams()) {
      return;
    }

    remove(Array.from(Array(fields.length).keys()));

    selectedProgram.vi.forEach((examRow) => {
      append({
        exam: examRow.length === 1 ? examRow[0].dicsipline_id : "",
        hasUSE: false,
        scores: null,
        year: "",
      } as Exam);
    });
  }, [selectedProgram]);

  if (!selectedProgram) {
    return <Loader type="spinner" />;
  }

  return (
    <FormContainer>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <FieldInfo>
            <FieldName>Данные об экзамене</FieldName>
            <FieldError>
              {errors.exams?.[index]?.exam?.message ??
                errors.exams?.[index]?.scores?.message ??
                errors.exams?.[index]?.year?.message}
            </FieldError>
          </FieldInfo>
          <FieldData>
            <ExamItem
              name={examsFieldsName}
              register={register}
              control={control}
              updateFunc={update}
              program={selectedProgram}
              fieldIndex={index}
            />
          </FieldData>
        </React.Fragment>
      ))}
    </FormContainer>
  );
};

export default EntranceExamsForm;
