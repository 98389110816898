import styled from "styled-components";
import colors from "../../../core/styles/colors";
import sizes from "../../../core/styles/sizes";
import zIndex from "../../../core/styles/z-index";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  .globalBackground {
    position: fixed;
    z-index: ${zIndex.background};
    width: 100%;
    height: 110vh;
    img {
      position: absolute;
      height: 105vh;
      &:nth-child(1) {
        transform: rotate(175deg);
        top: 0;
        right: -450px;
      }
      &:nth-child(2) {
        transform: rotate(-200deg);
        top: 0;
        left: 0;
      }
    }
  }
  @media all and (${sizes.mobile}) {
    .globalBackground {
      img {
        &:nth-child(1) {
          transform: rotate(90deg);
          top: 0;
          right: -190px;
        }
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
`;

export const Content = styled.div`
  position: absolute;
  z-index: ${zIndex.default};
  top: calc((100% - 70vh) / 2);
  left: 0;
  width: calc(100% - 400px);
  min-height: 65vh;
  margin: 0 80px 0 290px;
  padding: 25px;
  background: ${colors.white.general};
  border-radius: 25px;

  @media all and (${sizes.mobile}) {
    position: static;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background: none;
    border-radius: 0;
  }
`;
