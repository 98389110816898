import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SignatureForm from "../../ui/modules/signature/SignatureForm";
import { useAppSelector } from "../hooks/redux";
import SignatureInfo from "../../ui/modules/signature/components/signatureInfo/SignatureInfo";

const SignatureRoutes: React.FC = () => {
  const { accountState } = useAppSelector((state) => state.coreState);

  return (
    <Routes>
      <Route
        path=""
        element={
          accountState?.user?.hasEcp ? <Navigate to="/profile/signature/info" /> : <SignatureForm />
        }
      />
      <Route path="info" element={<SignatureInfo />} />
    </Routes>
  );
};

export default SignatureRoutes;
