import React from "react";
import ReactCalendar from "react-calendar";
import { Value } from "react-calendar/dist/cjs/shared/types";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Control, RegisterOptions, useController } from "react-hook-form";
import InputMask from "react-input-mask";
import { CalendarPopup, Container } from "./calendar.styles";
import UseOutsideClick from "../../../core/hooks/useOutsideClick";
import { Input } from "../native/input.styles";
import dateFormat from "../../../core/constants/dateFormat";
import isDate from "../../../core/validators/isDate";
import isMinMax from "../../../core/validators/isMinMax";

dayjs.extend(customParseFormat);

const Calendar: React.FC<
  {
    placeholder?: string;
    control: Control;
    name: string;
    options?: RegisterOptions;
    minDate?: Date;
    maxDate?: Date;
    defaultActiveStartDate?: Date;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  placeholder,
  control,
  name,
  options = {},
  minDate,
  maxDate,
  defaultActiveStartDate,
  ...attrs
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const inputElementBoundingRect = inputRef?.current?.getBoundingClientRect();
  const controller = useController({
    control,
    name,
    rules: {
      ...options,
      validate: (value) => isMinMax(value, minDate, maxDate),
    },
  });
  const [showCalendarPopup, setShowCalendarPopup] = React.useState(false);
  const [calendarValue, setCalendarValue] = React.useState<Date>(
    controller.field.value ? dayjs(controller.field.value, dateFormat).toDate() : null
  );

  UseOutsideClick(inputRef, () => setShowCalendarPopup(false), showCalendarPopup);

  const setCalendarComponentValue = (value: Value): void => {
    setCalendarValue(value as Date);
    const stringValue = dayjs(value as Date).format(dateFormat);
    controller.field.onChange(stringValue);
  };

  const setInputValueHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    controller.field.onChange(e.target.value);
    if (isDate(e.target.value)) {
      setCalendarValue(dayjs(e.target.value, dateFormat).toDate());
    }
  };

  return (
    <Container ref={inputRef} {...attrs}>
      <InputMask
        mask="99.99.9999"
        value={controller.field.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setInputValueHandler(e)}
        onClick={(): void => setShowCalendarPopup(!showCalendarPopup)}
      >
        <Input placeholder={placeholder} hasErrors={!!controller.fieldState.error} />
      </InputMask>
      {showCalendarPopup && (
        <CalendarPopup
          style={{
            left: inputElementBoundingRect.left,
            top: inputElementBoundingRect.top + inputElementBoundingRect.height,
          }}
        >
          <ReactCalendar
            defaultValue={calendarValue}
            locale="Ru-ru"
            minDetail="month"
            maxDetail="month"
            minDate={minDate}
            maxDate={maxDate}
            defaultActiveStartDate={defaultActiveStartDate}
            onChange={(value): void => setCalendarComponentValue(value)}
          />
        </CalendarPopup>
      )}
    </Container>
  );
};

export default Calendar;
