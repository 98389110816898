import SelectItem from "../../../../../../shared/select/SelectItem";

const specialConditionsSelectItems: SelectItem[] = [
  {
    label: "Да",
    value: "1",
  },
  {
    label: "Нет",
    value: "2",
  },
];

export default specialConditionsSelectItems;
