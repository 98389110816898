import React from "react";

const UseOutsideClick = (
  elementRef: React.RefObject<any>,
  handler: () => void,
  attached = true
): void => {
  React.useEffect(() => {
    if (!attached) return;

    const handleClick = (e: MouseEvent): void => {
      if (!elementRef.current) return;
      if (!elementRef.current.contains(e.target)) {
        handler();
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [elementRef, handler, attached]);
};

export default UseOutsideClick;
