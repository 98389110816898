import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdditionalDataForm } from "../../../../ui/modules/applications/components/highEducation/additionalDataForm/interfaces/additionalDataForm";
import { fetchGetAdditionalData } from "./additionalDataThunk";
import convertFromSavedData from "../../../../ui/modules/applications/components/highEducation/additionalDataForm/utils/convertFromSavedData";
import loading from "../../../../core/types/loading";

interface AdditionalDataState extends AdditionalDataForm {
  viewedStep: number;
  dataLoadingStatus: loading;
}

const initialState: AdditionalDataState = {
  viewedStep: 0,
  educationInfo: {
    educationLevel: "",
    educationRegion: "",
    educationDocument: null,
    educationDocumentAppendix: null,
    transferredFromAnotherUniversity: false,
  },
  educationDocument: {
    educationDocumentType: "",
    serial: "",
    number: "",
    issuedByWhom: "",
    dateOfIssue: "",
    ownerCode: "",
  },
  trainingCertificate: {
    trainingCertificate: null,
    nameOfUniversity: "",
    certificateRegistrationNumber: "",
    certificateDateIssue: "",
    certificateAboutOfStudentStatus: null,
    certificateAboutOfUniversityLicense: null,
  },
  healthLimitations: {
    hasOvz: "1",
    disabilityType: "0",
    disabilityDocument: null,
    specialConditions: "2",
    nosology: "1",
  },
  dataLoadingStatus: null,
};

const additionalEducationAdapter = createEntityAdapter<AdditionalDataState>();

const AdditionalDataSlice = createSlice({
  name: "additionalData",
  initialState: additionalEducationAdapter.getInitialState(initialState),
  reducers: {
    setViewedStep: (state, action: PayloadAction<number>) => {
      state.viewedStep = action.payload;
    },
    resetAdditionalDataState: (state) => {
      state.viewedStep = 0;
      state.educationInfo = {
        educationLevel: "",
        educationRegion: "",
        educationDocument: null,
        educationDocumentAppendix: null,
        transferredFromAnotherUniversity: false,
      };
      state.educationDocument = {
        educationDocumentType: "",
        serial: "",
        number: "",
        issuedByWhom: "",
        dateOfIssue: "",
        ownerCode: "",
      };
      state.trainingCertificate = {
        trainingCertificate: null,
        nameOfUniversity: "",
        certificateRegistrationNumber: "",
        certificateDateIssue: "",
        certificateAboutOfStudentStatus: null,
        certificateAboutOfUniversityLicense: null,
      };
      state.healthLimitations = {
        hasOvz: "1",
        disabilityType: "0",
        disabilityDocument: null,
        specialConditions: "2",
        nosology: "1",
      };
      state.dataLoadingStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetAdditionalData.pending, (state) => {
      state.dataLoadingStatus = "LOADING";
    });
    builder.addCase(fetchGetAdditionalData.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.dataLoadingStatus = "ERROR";
        return;
      }
      state.dataLoadingStatus = "COMPLETE";
      if (!action.payload.payload) {
        return;
      }

      const convertedData = convertFromSavedData(action.payload.payload);
      if (convertedData.educationInfo) {
        state.educationInfo = convertedData.educationInfo;
      }
      if (convertedData.educationDocument) {
        state.educationDocument = convertedData.educationDocument;
      }
      if (convertedData.trainingCertificate) {
        state.trainingCertificate = convertedData.trainingCertificate;
      }
      if (convertedData.healthLimitations) {
        state.healthLimitations = convertedData.healthLimitations;
      }
    });
  },
});

export const { setViewedStep, resetAdditionalDataState } = AdditionalDataSlice.actions;
export default AdditionalDataSlice.reducer;
