import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {
  SignatureForm as StyledSignatureForm,
  SignatureFormContent,
  SignatureFormFile,
  SignatureFormInfo,
  SignatureFormActions,
} from "./signatureForm.styles";
import { useAppDispatch, useAppSelector } from "../../../core/hooks/redux";
import steps from "./constants/steps";
import Stepper from "../../shared/stepper/Stepper";
import { PrimaryButton } from "../../shared/native/button.styles";
import { setViewedStep } from "../../../store/modules/signature/signatureSlice";
import getCookie from "../../../core/utils/getCookie";
import {
  sendVerificationCodeRequest,
  verifyVerificationCodeRequest,
} from "../../../http/requests/signature.http";
import UseAlert from "../../../core/hooks/useAlert";
import useScrollPosition from "../../../core/hooks/useScrollPosition";
import { fetchGetAccount } from "../../../store/core/account/accountThunk";
import PreviewDocument from "./components/previewDocument/PreviewDocument";

const Familiarization: React.FC = () => {
  const alertHandler = UseAlert();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { signatureState } = useAppSelector((state) => state.modulesState);
  const { control, formState, handleSubmit } = useForm<FieldValues>();
  const [fileScrollPosition, setFileScrollPosition] = React.useState(0);
  const [disableNextButton, setDisableNextButton] = React.useState(signatureState.viewedStep === 0);
  const [fileRef, setFileRef] = React.useState<React.RefObject<HTMLDivElement>>(null);

  useScrollPosition(fileRef, setFileScrollPosition);

  React.useEffect(() => {
    if (fileScrollPosition >= 95) {
      setDisableNextButton(false);
    }
  }, [fileScrollPosition]);

  React.useEffect(() => {
    const cookie = getCookie();
    if (cookie?.lastModifiedSignatureStep) {
      dispatch(setViewedStep(Number(cookie.lastModifiedSignatureStep)));
    }
  }, []);

  const submitForm = (data: FieldValues): void => {
    const newStepIndex = signatureState.viewedStep + 1;

    if (signatureState.viewedStep === 0) {
      dispatch(setViewedStep(newStepIndex));
      document.cookie = `lastModifiedSignatureStep=${newStepIndex}; path=/`;
      return;
    }

    if (signatureState.viewedStep === 1) {
      sendVerificationCodeRequest("sms").then((res) => {
        if (res.data.error) {
          alertHandler({ message: res.data.text, type: "error" });
          return;
        }

        dispatch(setViewedStep(newStepIndex));
        document.cookie = `lastModifiedSignatureStep=${newStepIndex}; path=/`;
      });
      return;
    }

    if (signatureState.viewedStep === 2) {
      verifyVerificationCodeRequest(data.code).then((res) => {
        if (res.data.error) {
          alertHandler({ message: res.data.text, type: "error" });
          return;
        }

        dispatch(fetchGetAccount())
          .then(() => {
            navigate("/profile/signature/info");
          })
          .catch((err) => {
            console.error(err);
            navigate("/profile/signature/info");
          });
      });
    }
  };

  const getComponentByStep = (step: number): ((props: any) => React.ReactNode) => {
    return steps[step].element;
  };

  const getButtonTextByStep = (step: number): string => {
    return steps[step].submitButtonText;
  };

  return (
    <StyledSignatureForm>
      <Stepper items={steps} currentStep={signatureState.viewedStep} className="stepper" />
      <SignatureFormContent
        onSubmit={handleSubmit(submitForm)}
        stepIndex={signatureState.viewedStep}
      >
        <SignatureFormFile stepIndex={signatureState.viewedStep}>
          <PreviewDocument
            currentStep={signatureState.viewedStep}
            setDisableNextButton={setDisableNextButton}
            fileRefCallback={(ref): void => setFileRef(ref)}
          />
        </SignatureFormFile>
        <SignatureFormInfo>
          {getComponentByStep(signatureState.viewedStep)({
            control,
            formState,
          })}
        </SignatureFormInfo>
        <SignatureFormActions>
          <PrimaryButton type="submit" disabled={disableNextButton}>
            {getButtonTextByStep(signatureState.viewedStep)}
          </PrimaryButton>
        </SignatureFormActions>
      </SignatureFormContent>
    </StyledSignatureForm>
  );
};

export default Familiarization;
