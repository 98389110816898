import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ApplicationsList from "../../ui/modules/notifications/components/applicationsList/ApplicationsList";
import ApplicationInfo from "../../ui/modules/notifications/components/applicationInfo/ApplicationInfo";
import { useAppSelector } from "../hooks/redux";

const NotificationsRoutes: React.FC = () => {
  const { accountState } = useAppSelector((state) => state.coreState);

  const autoRedirectFromRoot = (): React.ReactNode => (
    <Navigate
      to={`/notifications/${
        accountState.hasCompletedHighEducationApplications || accountState.ss === "1"
          ? "high"
          : "additional"
      }-education`}
    />
  );

  return (
    <Routes>
      <Route path="" element={autoRedirectFromRoot()} />

      <Route path="high-education">
        <Route path="" element={<ApplicationsList degree="high" />} />
        <Route path="general" element={<ApplicationInfo degree="high" isGeneral />} />
        <Route path=":id" element={<ApplicationInfo degree="high" />} />
      </Route>

      <Route path="additional-education">
        <Route path="" element={<ApplicationsList degree="additional" />} />
        <Route path="general" element={<ApplicationInfo degree="additional" isGeneral />} />
        <Route path=":id" element={<ApplicationInfo degree="additional" />} />
      </Route>
    </Routes>
  );
};

export default NotificationsRoutes;
