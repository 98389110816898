import React from "react";
import { useNavigate } from "react-router-dom";
import {
  InfoPage,
  InfoPageContent,
  InfoPageTextInfo,
} from "../../../../../../../shared/infoPage/infoPage.styles";
import { TitleH1 } from "../../../../../../../shared/native/typography.styles";
import { PrimaryButton } from "../../../../../../../shared/native/button.styles";
import { useAppDispatch, useAppSelector } from "../../../../../../../../core/hooks/redux";
import { setViewedStep } from "../../../../../../../../store/modules/applications/additionalData/additionalDataSlice";
import { Li } from "../../../../../../../shared/native/li.styles";
import { Ul } from "../../../../../../../shared/native/ul.styles";

const AddingDataInfo: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { additionalPostgraduateDataState } = useAppSelector(
    (state) => state.modulesState.applicationsState
  );

  const setStepAndNavigate = (stepId: number): void => {
    dispatch(setViewedStep(stepId));
    navigate("/applications/additional-postgraduate-data/form");
  };

  React.useEffect(() => {
    if (additionalPostgraduateDataState.educationInfo.graduateOfMIP) {
      setStepAndNavigate(0);
      return;
    }

    if (additionalPostgraduateDataState.educationDocument.dateOfIssue) {
      dispatch(setViewedStep(2));
      navigate("/applications/additional-postgraduate-data/form");
      return;
    }
    if (additionalPostgraduateDataState.educationInfo.educationLevel) {
      dispatch(setViewedStep(1));
      navigate("/applications/additional-postgraduate-data/form");
    }
  }, []);

  return (
    <InfoPage>
      <InfoPageContent>
        <InfoPageTextInfo>
          <TitleH1>Внесение дополнительных сведений</TitleH1>
          <p>
            Для подачи заявления в аспирантуру, пожалуйста, внесите данные о предыдущем образовании
            и специальных условиях
            <br />
            (при необходимости).
          </p>
          <Ul>
            <Li>Документ о ранее полученном образовании</Li>
          </Ul>
        </InfoPageTextInfo>
        <PrimaryButton
          onClick={(): void => navigate(`/applications/additional-postgraduate-data/form`)}
        >
          Продолжить
        </PrimaryButton>
      </InfoPageContent>
    </InfoPage>
  );
};

export default AddingDataInfo;
