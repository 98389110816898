import React from "react";
import { useNavigate } from "react-router-dom";
import {
  InfoPage,
  InfoPageContent,
  InfoPageTextInfo,
} from "../../../../../../../shared/infoPage/infoPage.styles";
import { TitleH1, TitleH2 } from "../../../../../../../shared/native/typography.styles";
import { PrimaryButton } from "../../../../../../../shared/native/button.styles";

const CompleteAdditionalPostgraduateDataForm: React.FC = () => {
  const navigate = useNavigate();

  return (
    <InfoPage>
      <InfoPageContent>
        <InfoPageTextInfo>
          <TitleH1>Поздравляем!</TitleH1>
          <TitleH2>Все данные заполнены.</TitleH2>
          <p>Теперь вы можете подавать заявления на обучение.</p>
        </InfoPageTextInfo>
        <PrimaryButton onClick={(): void => navigate("/applications/postgraduate-education")}>
          Перейти
        </PrimaryButton>
      </InfoPageContent>
    </InfoPage>
  );
};

export default CompleteAdditionalPostgraduateDataForm;
