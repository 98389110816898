import styled from "styled-components";
import { OutlinedButton } from "../../../../../../../shared/native/button.styles";
import colors from "../../../../../../../../core/styles/colors";
import sizes from "../../../../../../../../core/styles/sizes";

export const SelectPaymentGroupContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media all and (${sizes.mobile}) {
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 8px;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }
`;

export const PaymentGroupsButton = styled(OutlinedButton)<{ $active?: boolean }>`
  min-width: calc(120px - 60px);
  padding: 0 30px;
  color: ${colors.black.lightBlack};
  text-transform: none;
  margin-right: 24px;
  border: 2px solid
    ${(props): string => (props.$active ? colors.red.general : colors.black.lightBlack)};
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    border: 2px solid ${colors.red.general};
  }
  &:disabled {
    border: 2px solid ${colors.white.hoverWhite2};
    color: ${colors.gray.gray200};
    cursor: auto;
    &:hover {
      border: 2px solid ${colors.white.hoverWhite2};
      background: none;
    }
  }

  @media all and (${sizes.mobile}) {
    min-width: auto;
    margin: 0;
  }
`;
