import { createGlobalStyle } from "styled-components";
import colors from "../core/styles/colors";

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    background: ${colors.white.hoverWhite3};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: Gilroy, serif;

    *::-webkit-scrollbar {
      width: 4px;
      background: ${colors.white.hoverWhite3};
      border-radius: 5px;
    }
    *::-webkit-scrollbar-thumb {
      background: ${colors.gray.gray100};
      border-radius: 5px;
    }
    scrollbar-track-color: ${colors.gray.gray100};
  }
`;

export default GlobalStyles;
