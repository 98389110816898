import { Link } from "../interfaces/link";
import store from "../../../../store/store";

const getLinks = (): Link[] => {
  const state = store.getState();

  return [
    {
      path: "/profile",
      text: "Профиль",
      hide: !state.coreState.accountState.token,
      subgroups: [
        {
          links: [
            {
              path: state.coreState.accountState.user?.hasProfile ? "/profile/preview" : "/profile",
              text: "Личные данные",
              hide: state.coreState.accountState.ss === "1",
            },
            {
              path: "/profile/signature",
              text: "Электронная подпись",
              hide: !state.coreState.accountState.hasCompletedHighEducationApplications,
            },
          ],
        },
      ],
    },
    {
      path: "/applications",
      text: "Заявления",
      isDisabled: !state.coreState.accountState?.user?.hasProfile,
      hide: !state.coreState.accountState.token || state.coreState.accountState.ss === "1",
      subgroups: [
        {
          title: "Высшее образование",
          links: [
            {
              path: "/applications/additional-high-data",
              text: "Дополнительные сведения",
            },
            {
              path: "/applications/high-education",
              text: "Заявления на поступление",
              isDisabled: !state.coreState.accountState?.user?.hasVO,
              hide: state.modulesState.applicationsState.additionalDataState.educationInfo
                .transferredFromAnotherUniversity,
            },
          ],
        },
        {
          title: "Дополнительное образование",
          links: [
            {
              path: "/applications/additional-ae-data",
              text: "Дополнительные сведения",
            },
            {
              path: "/applications/additional-education",
              text: "Заявления на поступление",
              isDisabled: !state.coreState.accountState?.user?.hasDPO,
            },
          ],
        },
        {
          title: "Аспирантура",
          links: [
            {
              path: "/applications/additional-postgraduate-data",
              text: "Дополнительные сведения",
            },
            {
              path: "/applications/postgraduate-education",
              text: "Заявления на поступление",
              isDisabled: !state.coreState.accountState?.user?.hasASP,
            },
          ],
        },
      ],
    },
    {
      path: "/notifications",
      text: "Уведомления",
      isDisabled:
        !state.coreState.accountState.hasCompletedHighEducationApplications &&
        !state.coreState.accountState.hasCompletedAdditionalEducationApplications,
      // !state.coreState.accountState.hasCompletedPostgraduateEducationApplications,
      isText: true,
      hide: !state.coreState.accountState.token,
      displaySubgroupsCondition: () =>
        state.coreState.accountState.hasCompletedHighEducationApplications ||
        state.coreState.accountState.hasCompletedAdditionalEducationApplications,
      // state.coreState.accountState.hasCompletedPostgraduateEducationApplications,
      subgroups: [
        {
          links: [
            {
              path: "/notifications/high-education",
              text: "Высшее образование",
              additionalText: state.modulesState.notificationsState.info?.vpo?.count,
              isDisabled: !state.coreState.accountState.hasCompletedHighEducationApplications,
            },
            {
              hide: state.coreState.accountState.ss === "1",
              path: "/notifications/additional-education",
              text: "Дополнительное образование",
              additionalText: state.modulesState.notificationsState.info?.dpo?.count,
              isDisabled: !state.coreState.accountState.hasCompletedAdditionalEducationApplications,
            },
            // {
            //   path: "/notifications/postgraduate-education",
            //   text: "Аспирантура",
            //   isDisabled:
            //     !state.coreState.accountState.hasCompletedPostgraduateEducationApplications,
            // },
          ],
        },
      ],
    },
  ];
};

export default getLinks;
