import { AdditionalPostgraduateDataForm } from "../interfaces/additionalPostgraduateDataForm";
import convertFile from "../../../../../../../core/utils/convertFile";

const convertFromSavedData = (
  data: AdditionalPostgraduateDataForm
): AdditionalPostgraduateDataForm => ({
  educationInfo: data?.educationInfo
    ? {
        ...data.educationInfo,
        educationDocument: convertFile("from", data.educationInfo.educationDocument),
        educationDocumentAppendix: convertFile(
          "from",
          data.educationInfo.educationDocumentAppendix
        ),
      }
    : null,
  educationDocument: data?.educationDocument ?? null,
  healthLimitations: data?.healthLimitations
    ? {
        ...data.healthLimitations,
        disabilityDocument: convertFile("from", data.healthLimitations.disabilityDocument),
      }
    : null,
});

export default convertFromSavedData;
