import NotificationSubmitForm from "../../../../../../interfaces/NotificationSubmitForm";
import convertAddressObject from "../../../../../../../profile/utils/convertAddressObject";
import convertFile from "../../../../../../../../../core/utils/convertFile";

const convertFormData = (
  data: NotificationSubmitForm & { answer: number }
): NotificationSubmitForm => {
  const formData = data;

  formData.citizenship = formData.documentType === "000000047" ? "643" : formData.citizenship;

  if (formData.residingCoincidesRegistration) {
    formData.residingAddress = formData.registerAddress;
  }
  if (formData.answer === 2 || formData.answer === 3) {
    formData.registerAddress = convertAddressObject("to", formData.registerAddress);
    formData.residingAddress = convertAddressObject("to", formData.residingAddress);
  }
  if (formData.answer === 4) {
    formData.identityDocument = convertFile("to", formData.identityDocument);
    formData.maternityCertificate = convertFile("to", formData.maternityCertificate);
    formData.pfrCertificate = convertFile("to", formData.pfrCertificate);
  }

  delete formData.answer;

  return formData;
};

export default convertFormData;
