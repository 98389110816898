import styled from "styled-components";
import colors from "../../../../../core/styles/colors";

export const ApplicationCardContent = styled.div<{ isnew?: boolean }>`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: ${(props): string => (props.isnew ? "center" : "flex-start")};
  justify-content: ${(props): string => (props.isnew ? "center" : "flex-start")};
  ${(props): string =>
    props.isnew &&
    `
    p {
    width: calc(100% - 8px);
    padding: 4px 0;
    margin: 0;
    font-family: Gilroy, serif;
    font-weight: 300;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    color: ${colors.gray.gray300};
  }
  `}
`;

export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  b {
    font-family: Gilroy, serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
  }
  p {
    margin: 0;
    font-family: Gilroy, serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.gray.gray300};
  }
`;

export const CardPriority = styled.div`
  width: 100%;
  border: 1px solid ${colors.red.general};
  color: ${colors.red.general};
  font-size: 14px;
  padding: 0 4px;
  margin-left: 4px;
  text-align: center;
  border-radius: 10px;
  span {
    font-weight: 600;
  }
`;

export const CardBody = styled.div`
  width: 100%;
  flex-grow: 1;
  b {
    font-family: Gilroy, serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
  }
  p {
    margin: 0;
    font-family: Gilroy, serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.gray.gray300};
  }
  .date {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const CardFooter = styled.div`
  width: 100%;
  p {
    margin: 8px 0 0 0;
    text-transform: uppercase;
    font-family: Gilroy, serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.red.general};
  }
`;
