import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../shared/native/button.styles";
import { TitleH1 } from "../../shared/native/typography.styles";
import { ServicePage as StyledServicePage, ServicePageContent } from "./servicePage.styles";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  return (
    <StyledServicePage>
      <ServicePageContent>
        <TitleH1>Страница не найдена</TitleH1>
        <PrimaryButton onClick={(): void => navigate(-1)} style={{ width: "100%" }}>
          Назад
        </PrimaryButton>
      </ServicePageContent>
    </StyledServicePage>
  );
};

export default NotFoundPage;
