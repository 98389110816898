import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAppSelector } from "../../../hooks/redux";
import AdditionalPostgraduateDataForm from "../../../../ui/modules/applications/components/postgraduateEducation/additionalPostgraduateDataForm/AdditionalPostgraduateDataForm";
import AddingDataInfo from "../../../../ui/modules/applications/components/postgraduateEducation/additionalPostgraduateDataForm/components/addingDataInfo/AddingDataInfo";
import PreviewAdditionalPostgraduateDataForm from "../../../../ui/modules/applications/components/postgraduateEducation/additionalPostgraduateDataForm/components/previewAdditionalPostgraduateDataForm/PreviewAdditionalPostgraduateDataForm";
import CompleteAdditionalPostgraduateDataForm from "../../../../ui/modules/applications/components/postgraduateEducation/additionalPostgraduateDataForm/components/completeAdditionalPostgraduateDataForm/CompleteAdditionalPostgraduateDataForm";

const AdditionalPostgraduateDataRoutes: React.FC = () => {
  const { accountState } = useAppSelector((state) => state.coreState);

  if (accountState?.user?.hasASP) {
    return (
      <Routes>
        <Route path="" element={<PreviewAdditionalPostgraduateDataForm mode="view" />} />
        <Route path="complete" element={<CompleteAdditionalPostgraduateDataForm />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="" element={<AddingDataInfo />} />
      <Route path="form" element={<AdditionalPostgraduateDataForm />} />
      <Route path="check" element={<PreviewAdditionalPostgraduateDataForm mode="finallyCheck" />} />
    </Routes>
  );
};

export default AdditionalPostgraduateDataRoutes;
