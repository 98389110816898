import styled from "styled-components";
import colors from "../../../core/styles/colors";
import zIndex from "../../../core/styles/z-index";

export const Container = styled.div`
  position: relative;
`;

export const CalendarPopup = styled.div`
  position: fixed;
  z-index: ${zIndex.special};
  width: 330px;
  height: 275px;
  bottom: 60px;
  padding: 10px;
  background: ${colors.white.general};
  box-shadow: 0 0 15px ${colors.white.white700};
  border-radius: 10px;
  .react-calendar {
    width: 100%;
    height: 100%;
    &__navigation {
      width: 100%;
      display: grid;
      grid-auto-flow: column;
      grid-gap: 16px;
      button {
        outline: none;
        height: 30px;
        border: none;
        border-radius: 8px;
        background: none;
        color: ${colors.black.lightBlack};
        font-family: Gilroy, serif;
        font-size: 18px;
        cursor: pointer;
        transition: ease-in-out 0.3s;
        &:hover {
          background: ${colors.white.hoverWhite3};
        }
        &:disabled {
          color: ${colors.gray.gray300};
          background: ${colors.white.hoverWhite};
          &:hover {
            color: ${colors.gray.gray300};
            background: ${colors.white.hoverWhite};
          }
        }
      }
    }
    &__viewContainer {
      width: 100%;
      margin-top: 16px;
      .react-calendar__month-view__weekdays {
        width: 100%;
        margin-bottom: 5px;
        text-align: center;
        abbr {
          color: ${colors.red.general};
          font-size: 18px;
          font-weight: 600;
          text-decoration: none;
        }
      }
      .react-calendar__tile--active {
        background: ${colors.red.general};
        color: ${colors.white.general};
      }
      button {
        outline: none;
        border: none;
        height: 30px;
        border-radius: 8px;
        background: none;
        color: ${colors.black.lightBlack};
        font-family: Gilroy, serif;
        font-size: 18px;
        cursor: pointer;
        transition: ease-in-out 0.3s;
        &:hover:not(.react-calendar__tile--active) {
          background: ${colors.red.light};
          color: ${colors.white.general};
        }
        &:disabled {
          color: ${colors.gray.gray300};
          background: ${colors.white.hoverWhite};
          &:hover {
            color: ${colors.gray.gray300};
            background: ${colors.white.hoverWhite};
          }
        }
      }
    }
  }
`;
