import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import User from "../../../core/interfaces/User";
import { fetchGetAccount } from "./accountThunk";
import loading from "../../../core/types/loading";
import degreeType from "../../../core/types/degreeType";

interface AccountState {
  token?: string;
  ss?: "1" | "0";
  user?: User;
  hasCompletedHighEducationApplications: boolean;
  hasCompletedAdditionalEducationApplications: boolean;
  hasCompletedPostgraduateEducationApplications: boolean;
  userLoadingStatus: loading;
}

const initialState: AccountState = {
  token: null,
  ss: (localStorage.getItem("ss") as "0" | "1" | null) ?? "0",
  user: {
    email: null,
    name: null,
    hasProfile: null,
    hasVO: null,
    hasDPO: null,
    hasASP: null,
    hasEcp: null,
  },
  hasCompletedHighEducationApplications: false,
  hasCompletedAdditionalEducationApplications: false,
  hasCompletedPostgraduateEducationApplications: false,
  userLoadingStatus: null,
};

const accountAdapter = createEntityAdapter<AccountState>();

const profileFormSlice = createSlice({
  name: "account",
  initialState: accountAdapter.getInitialState(initialState),
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setSS: (state, action: PayloadAction<"0" | "1">) => {
      state.ss = action.payload;
    },
    setHasCompletedApplications: (
      state,
      action: PayloadAction<{
        degree: degreeType;
        value: boolean;
      }>
    ) => {
      switch (action.payload.degree) {
        case "high": {
          state.hasCompletedHighEducationApplications = action.payload.value;
          break;
        }
        case "additional": {
          state.hasCompletedAdditionalEducationApplications = action.payload.value;
          break;
        }
        case "postgraduate": {
          state.hasCompletedPostgraduateEducationApplications = action.payload.value;
          break;
        }
        default: {
          break;
        }
      }
    },
    logout: (state) => {
      localStorage.clear();
      // PROFILE COOKIE
      document.cookie = "lastModifiedProfileStep=0; path=/";
      // ADDITIONAL DATA COOKIE
      document.cookie = "lastModifiedAdditionalDataStep=0; path=/";
      // ADDITIONAL AE DATA COOKIE
      document.cookie = "lastModifiedAdditionalAEDataStep=0; path=/";
      // ADDITIONAL POSTGRADUATE DATA COOKIE
      document.cookie = "lastModifiedAdditionalPostgraduateDataStep=0; path=/";
      // HIGH EDUCATION COOKIE
      document.cookie = "lastModifiedHighEducationStep=0; path=/";
      document.cookie = "lastModifiedHighEducationApplicationId=null; path=/";
      // ADDITIONAL EDUCATION COOKIE
      document.cookie = "lastModifiedAdditionalEducationStep=0; path=/";
      document.cookie = "lastModifiedAdditionalEducationApplicationId=null; path=/";
      // POSTGRADUATE EDUCATION COOKIE
      document.cookie = "lastModifiedPostgraduateEducationStep=0; path=/";
      document.cookie = "lastModifiedPostgraduateEducationApplicationId=null; path=/";
      // SIGNATURE COOKIE
      document.cookie = "lastModifiedSignatureStep=0; path=/";
      // STATE
      state.token = null;
      state.user = null;
      state.hasCompletedHighEducationApplications = false;
      state.hasCompletedAdditionalEducationApplications = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetAccount.pending, (state) => {
      state.userLoadingStatus = "LOADING";
    });
    builder.addCase(fetchGetAccount.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.userLoadingStatus = "ERROR";
      }
      state.userLoadingStatus = "COMPLETE";

      state.user = action.payload.payload;
    });
  },
});

export const { setToken, setSS, setHasCompletedApplications, logout } = profileFormSlice.actions;
export default profileFormSlice.reducer;
