import styled from "styled-components";
import colors from "../../../../core/styles/colors";
import zIndex from "../../../../core/styles/z-index";

export const DadataInput = styled.div<{ width: number; hasErrors?: boolean }>`
  width: 100%;
  position: relative;
  height: fit-content;
  .react-dadata {
    position: relative;
    &__input {
      position: relative;
      width: calc(100% - 26px);
      height: 44px;
      padding: 0 13px;
      border: 2px solid
        ${(props: { hasErrors?: boolean }): string =>
          props.hasErrors ? colors.red.general : colors.white.hoverWhite3};
      background: ${colors.white.hoverWhite3};
      border-radius: 10px;
      font-family: "Gilroy", serif;
      font-size: 18px;
      font-weight: 300;
      color: ${colors.black.general};
      outline: none;
      transition: ease-in-out 0.3s;
      &:focus {
        border: 2px solid
          ${(props: { hasErrors?: boolean }): string =>
            props.hasErrors ? colors.red.general : colors.gray.gray200};
        border-radius: 10px 10px 0 0;
        border-bottom: 2px solid ${colors.white.hoverWhite3};
      }
      &::placeholder {
        color: ${colors.gray.gray300};
      }
      &:disabled {
        cursor: not-allowed;
        border-color: ${colors.white.disabled};
        background: ${colors.white.disabled};
        &::placeholder {
          color: ${colors.white.disabledText};
        }
      }
    }
    &__suggestions {
      position: absolute;
      z-index: ${zIndex.popup};
      width: ${(props): string => (props.width ? `${props.width - 24}px` : "auto")};
      max-height: 300px;
      overflow-y: auto;
      top: calc(100% - 24px);
      left: 0;
      padding: 10px;
      background: ${colors.white.hoverWhite3};
      border-radius: 0 0 10px 10px;
      border: 2px solid ${colors.gray.gray200};
      border-top: 1px solid ${colors.white.white700};
      list-style: none;
    }
    &__suggestion {
      background: none;
      border: none;
      width: 100%;
      padding: 8px;
      border-radius: 5px;
      font-family: Gilroy, serif;
      font-size: 16px;
      font-weight: 300;
      text-align: left;
      cursor: pointer;
      transition: ease-in-out 0.3s;
      &:hover {
        background: ${colors.white.hoverWhite};
      }
      &-subtitle {
        color: ${colors.gray.gray100};
        margin-top: 4px;
      }
      &--current {
        background: ${colors.red.general};
        color: ${colors.white.general};
      }
      &--highlighted {
        background: ${colors.red.general};
      }
    }
  }
`;
