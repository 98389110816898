const isSnils = (snils: string): string | boolean => {
  const errorMessage = "Некорректный СНИЛС";
  snils = snils.toString().replace(/\D/g, "");

  if (snils.length !== 11) return errorMessage;

  if (/(\d)\1\1/.test(snils.toString())) return errorMessage;

  const controlCode = Number(snils.substr(-2));
  let hashCode = snils
    .substr(0, 9)
    .split("")
    .map((N, idx) => Number(N) * (9 - idx))
    .reduce((sum, N) => sum + N);

  if (hashCode === 100) hashCode = 0;

  return controlCode === hashCode % 101 || errorMessage;
};

export default isSnils;
