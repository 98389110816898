import styled from "styled-components";
import colors from "../../../core/styles/colors";
import sizes from "../../../core/styles/sizes";

export const InfoPage = styled.div`
  width: calc(100% - 40px);
  margin: 0 20px;
  min-height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  @media all and (${sizes.mobile}) {
    height: 100%;
  }
`;

export const InfoPageContent = styled.div`
  min-width: 100px;
  max-width: 476px;
  width: 100%;
  h1 {
    text-align: left;
  }
  h2 {
    text-align: left;
  }
  h3 {
    text-align: left;
  }
  p {
    margin: 16px 0 24px 0;
    font-family: "Proxima Nova Lt", serif;
    font-size: 14px;
  }
  span {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.red.general};
  }
  ul {
    margin-top: 8px;
  }
  button {
    width: 100%;
    margin-top: 56px;
  }

  @media all and (${sizes.mobile}) {
    position: relative;
    min-width: auto;
    max-width: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    button {
      position: absolute;
      bottom: 25px;
      margin-top: 0;
    }
  }
`;

export const InfoPageTextInfo = styled.div`
  @media all and (${sizes.mobile}) {
    width: calc(100% - 32px);
    max-width: 100%;
    height: fit-content;
    padding: 25px 16px;
    border-radius: 10px;
    background: ${colors.white.general};
  }
`;
