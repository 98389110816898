import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Subgroup as StyledSubgroup, SubgroupLink, SubgroupTitle } from "./subgroup.styles";
import { Subgroup as SubgroupInterface } from "../../interfaces/subgroup";

const Subgroup: React.FC<
  { subgroup: SubgroupInterface } & React.HTMLAttributes<HTMLDivElement>
> = ({ subgroup, ...attrs }) => {
  const location = useLocation();

  return (
    <StyledSubgroup {...attrs}>
      {subgroup?.title && <SubgroupTitle>{subgroup.title}</SubgroupTitle>}
      {subgroup?.links?.length &&
        subgroup.links.map(
          (link) =>
            !link.hide && (
              <SubgroupLink
                key={link.path}
                $active={location.pathname.includes(link.path)}
                disabled={link.isDisabled}
              >
                {link?.isDisabled ? (
                  <span>{link.text}</span>
                ) : (
                  <Link to={link.path}>{link.text}</Link>
                )}
                {!!link?.additionalText && <p>({link.additionalText})</p>}
              </SubgroupLink>
            )
        )}
    </StyledSubgroup>
  );
};

export default Subgroup;
