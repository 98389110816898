import RequirementsInfoItem from "../interfaces/RequirementsInfoItem";
import { ReactComponent as Requirement1 } from "../../../../../../../core/static/img/requirements/1.svg";
import { ReactComponent as Requirement2 } from "../../../../../../../core/static/img/requirements/2.svg";
import { ReactComponent as Requirement3 } from "../../../../../../../core/static/img/requirements/3.svg";
import { ReactComponent as Requirement4 } from "../../../../../../../core/static/img/requirements/4.svg";
import { ReactComponent as Requirement5 } from "../../../../../../../core/static/img/requirements/5.svg";
import { ReactComponent as Requirement6 } from "../../../../../../../core/static/img/requirements/6.svg";

const requirementsInfo: RequirementsInfoItem[] = [
  {
    img: <Requirement1 />,
    check: true,
    label: "Взгляд прямо",
  },
  {
    img: <Requirement2 />,
    check: false,
    label: "Не видны глаза",
  },
  {
    img: <Requirement3 />,
    check: false,
    label: "Блики",
  },
  {
    img: <Requirement4 />,
    check: true,
    label: "Светлый фон",
  },
  {
    img: <Requirement5 />,
    check: false,
    label: "Посторонние предметы",
  },
  {
    img: <Requirement6 />,
    check: false,
    label: "Тень",
  },
];

export default requirementsInfo;
