import styled from "styled-components";
import colors from "../../../../../../core/styles/colors";
import sizes from "../../../../../../core/styles/sizes";

export const Container = styled.div`
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      width: 100%;
      margin: 0;
      text-align: center;
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: ${colors.red.general};
    }
    p {
      width: 315px;
      margin: 8px 0 0 0;
      text-align: center;
      font-size: 14px;
      font-weight: 300;
      color: ${colors.red.general};
    }
  }
  .content {
    width: calc(100% - 48px);
    height: 100%;
    padding: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px 29px;
    justify-content: center;
    &__item {
      .image {
        position: relative;
        height: 169px;
        background: ${colors.white.general};
        border-radius: 12px;
        svg {
          width: 100%;
          position: absolute;
          bottom: 0;
          border-radius: 12px;
        }
      }
      .info {
        margin-top: 7px;
        display: flex;
        align-items: center;
        p {
          margin: 0 0 0 4px;
          font-size: 14px;
          line-height: 17px;
          font-weight: 300;
        }
      }
    }
  }

  @media all and (${sizes.mobile}) {
    .content {
      grid-template-columns: repeat(2, 1fr);
      max-height: 60vh;
      overflow-y: auto;
    }
  }
`;

export const Flag = styled.div<{ checked: boolean }>`
  position: relative;
  height: 14px;
  min-width: 14px;
  border: 1px solid ${(props): string => (props.checked ? colors.red.general : colors.gray.xMark)};
  border-radius: 3px;
  svg {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    path {
      fill: ${(props): string => (props.checked ? colors.red.general : colors.gray.xMark)};
    }
  }
`;
