import File from "../../../../../../../../../core/interfaces/File";
import NotificationSubmitForm from "../../../../../../interfaces/NotificationSubmitForm";

const defaultFormValues = {
  answer: null, // Тип плательщика
  // PERSONAL
  firstname: "", // Имя
  middlename: "", // Отчество
  lastname: "", // Фамилия
  dateOfBirth: "", // Дата рождения
  phone: "", // Номер телефона
  snils: "", // СНИЛС
  hasNoSnils: "", // СНИСЛ отсутствует
  // PASSPORT
  documentType: "", // Тип документа
  citizenship: "", // Гражданство
  serial: "", // Серия
  number: "", // Номер
  serialAndNumber: "", // Серия и номер
  dateOfIssue: "", // Дата выдачи
  issuedByWhom: "", // Кем выдан
  divisionCode: "", // Код подразделения
  // ADDRESS
  residingCoincidesRegistration: false,
  actualCoincidesLegal: false,
  registerAddress: {
    // Адрес регистрации
    country: "",
    region: "",
    city: "",
    street: "",
    house: "",
    flat: "",
    index: "",
  },
  residingAddress: {
    // Адрес проживания
    country: "",
    region: "",
    city: "",
    street: "",
    house: "",
    flat: "",
    index: "",
  },
  // ORGANIZATION INFO
  organizationName: "", // Наименование организации
  inn: "", // ИНН
  kpp: "", // КПП
  ogrn: "", // ОГРН
  bankName: "", // Наименование банка
  paymentAccount: "", // Рассчетный счет
  bik: "", // БИК
  correspondentAccount: "", // Корреспондентский счет
  organizationPhone: "", // Телефон организации
  organizationEmail: "", // Email организации
  // CERTIFICATE INFO
  certificateSerial: "", // Серия
  certificateNumber: "", // Номер
  certificateDateOfIssue: "", // Дата выдачи
  identityDocument: [] as File[], // Скан документа удостоверяющего личность законного представителя
  maternityCertificate: [] as File[], // Скан сертификата на материнский капитал
  pfrCertificate: [] as File[], // Справка из ПФР о размере денежных средств на сертификате о материнском капитале
} as NotificationSubmitForm & { answer: number };

export default defaultFormValues;
