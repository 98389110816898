import React from "react";
import { FormDescription } from "../../signatureForm.styles";

const Familiarization: React.FC = () => {
  return (
    <FormDescription>
      <span>!</span>Ознакомьтесь с Соглашением об электронном взаимодействии, пролистав документ до
      конца и нажмите кнопку «Ознакомлен(-а)».
    </FormDescription>
  );
};

export default Familiarization;
