import React from "react";
import { Container, AccountPopupContainer, ShowAccountPopupButton } from "./accountPopup.styles";
import { ReactComponent as ArrowBottomIcon } from "../../../core/static/icons/arrow-bottom.svg";
import { useAppDispatch, useAppSelector } from "../../../core/hooks/redux";
import { GrayToBlackButton } from "../native/button.styles";
import { logout } from "../../../store/core/account/accountSlice";
import { logoutRequest } from "../../../http/requests/account.http";
import { resetProfileState } from "../../../store/modules/profile/profileForm/profileFormSlice";
import { resetAdditionalDataState } from "../../../store/modules/applications/additionalData/additionalDataSlice";
import { resetHighEducationState } from "../../../store/modules/applications/highEducation/highEducationSlice";
import { resetAdditionalEducationState } from "../../../store/modules/applications/additionalEducation/additionalEducationSlice";
import { resetAdditionalAeDataState } from "../../../store/modules/applications/additionalAEData/additionalAEDataSlice";
import { resetAdditionalPostgraduateDataState } from "../../../store/modules/applications/additionalPostgraduateData/additionalPostgraduateDataSlice";
import { resetPostgraduateEducationState } from "../../../store/modules/applications/postgraduateEducation/postgraduateEducationSlice";

const AccountPopup: React.FC = () => {
  const dispatch = useAppDispatch();
  const { accountState } = useAppSelector((state) => state.coreState);
  const [showProfilePopup, setShowProfilePopup] = React.useState(false);

  const logoutHandler = (): void => {
    logoutRequest().then((res) => {
      if (res.data.error) {
        return;
      }
      dispatch(logout());
      dispatch(resetProfileState());
      dispatch(resetAdditionalDataState());
      dispatch(resetAdditionalAeDataState());
      dispatch(resetAdditionalPostgraduateDataState());
      dispatch(resetHighEducationState());
      dispatch(resetAdditionalEducationState());
      dispatch(resetPostgraduateEducationState());
    });
  };

  if (!accountState.token) {
    return null;
  }

  return (
    <Container>
      {showProfilePopup ? (
        <AccountPopupContainer onClick={(): void => setShowProfilePopup(false)}>
          <div className="header">
            {accountState?.user?.name ? (
              <b>{accountState?.user?.name}</b>
            ) : (
              <b>{accountState?.user?.email}</b>
            )}
            <p>{accountState?.user?.role}</p>
            <ArrowBottomIcon />
          </div>
          <GrayToBlackButton onClick={logoutHandler}>Выйти</GrayToBlackButton>
        </AccountPopupContainer>
      ) : (
        <ShowAccountPopupButton onClick={(): void => setShowProfilePopup(true)}>
          {accountState?.user?.name ? (
            <p>{accountState?.user?.name}</p>
          ) : (
            <p>{accountState?.user?.email}</p>
          )}
          <ArrowBottomIcon />
        </ShowAccountPopupButton>
      )}
    </Container>
  );
};

export default AccountPopup;
