import styled from "styled-components";
import colors from "../../../core/styles/colors";
import sizes from "../../../core/styles/sizes";

export const FieldInfo = styled.div<{ byRowStart?: boolean; leftContent?: boolean }>`
  height: 100%;
  margin: ${(props): string => (props.byRowStart ? "24px 0 0 0" : "0 0 8px 0")};
  display: flex;
  flex-direction: column;
  justify-content: ${(props): string => (props.byRowStart ? "flex-start" : "center")};
  align-items: ${(props): string => (props.leftContent ? "flex-start" : "flex-end")};
  button {
    text-align: ${(props): string => (props.leftContent ? "left" : "right")};
  }

  @media all and (${sizes.mobile}) {
    height: auto;
    margin: 0 0 8px 0;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FieldName = styled.p<{ leftContent?: boolean }>`
  width: 100%;
  margin: 0;
  font-size: 18px;
  font-weight: 300;
  text-align: ${(props): string => (props.leftContent ? "left" : "right")};

  @media all and (${sizes.mobile}) {
    width: fit-content;
    margin: 0 16px;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
  }
`;

export const FieldDescription = styled.p<{ leftContent?: boolean }>`
  width: 70%;
  margin: 0;
  font-size: 12px;
  font-weight: 300;
  text-align: ${(props): string => (props.leftContent ? "left" : "right")};
  color: ${colors.gray.gray300};

  @media all and (${sizes.mobile}) {
    width: fit-content;
    margin: 0 16px;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
  }
`;

export const FieldError = styled.p<{ leftContent?: boolean }>`
  width: calc(100% - 2px);
  margin: 2px 0 0 0;
  padding: 0;
  font-size: 12px;
  font-weight: 300;
  text-align: ${(props): string => (props.leftContent ? "left" : "right")};
  color: ${colors.red.general};

  @media all and (${sizes.mobile}) {
    width: fit-content;
  }
`;

export const FieldLink = styled.button<{ leftContent?: boolean }>`
  background: none;
  border: none;
  width: fit-content;
  height: fit-content;
  margin: 2px 0 0 0;
  padding: 0;
  font-family: "Gilroy", serif;
  font-weight: 300;
  font-size: 12px;
  color: ${colors.red.light};
  text-decoration: underline 1px ${colors.red.light};
  cursor: pointer;
  transition: ease-in-out 0.3s;
  &:hover {
    color: ${colors.red.general};
    text-decoration: underline 1px ${colors.red.general};
  }
`;

// COLUMN FORM

export const ColumnFieldInfo = styled.div<{ byRowStart?: boolean; leftContent?: boolean }>`
  height: 100%;
  margin: 24px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  button {
    text-align: left;
  }

  @media all and (${sizes.mobile}) {
    height: auto;
    p {
      margin: 0;
    }
  }
`;
