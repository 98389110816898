import { useAppDispatch, useAppSelector } from "./redux";
import { addAlert, removeAlertById } from "../../store/core/alert/alertSlice";
import AlertProps from "../../ui/shared/alertService/interfaces/alertProps";

const UseAlert = (): ((alertData: AlertProps) => void) => {
  const { alertState } = useAppSelector((state) => state.coreState);
  const dispatch = useAppDispatch();

  return (alertData: AlertProps) => {
    dispatch(addAlert(alertData));
    const alertIndex = alertState.alertsIndex;

    setTimeout(() => {
      dispatch(removeAlertById(alertIndex));
    }, alertData.autoClose ?? 3000);
  };
};

export default UseAlert;
