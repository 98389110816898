import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, FormActions, FormContent } from "../../../../../shared/form/form.styles";
import { useAppDispatch, useAppSelector } from "../../../../../../core/hooks/redux";
import UseAlert from "../../../../../../core/hooks/useAlert";
import getCookie from "../../../../../../core/utils/getCookie";
import Stepper from "../../../../../shared/stepper/Stepper";
import { OutlinedButton, PrimaryButton } from "../../../../../shared/native/button.styles";
import { ReactComponent as LineArrowIcon } from "../../../../../../core/static/icons/line-arrow.svg";
import steps from "./constants/steps";
import {
  PostgraduateEducationForm as PostgraduateEducationFormInterface,
  SplitPostgraduateEducationForm,
} from "./interfaces/postgraduateEducationForm";
import convertSplitPostgraduateEducationForm from "./utils/convertSplitPostgraduateEducationForm";
import { setViewedStep } from "../../../../../../store/modules/applications/postgraduateEducation/postgraduateEducationSlice";
import { savePostgraduateEducationFormRequest } from "../../../../../../http/requests/applications/postgraduateEducation.http";
import Loader from "../../../../../shared/loaders/Loader";
import { fetchGetPostgraduateEducation } from "../../../../../../store/modules/applications/postgraduateEducation/postgraduateEducationThunk";
import ResponseBody from "../../../../../../http/models/ResponseBody";
import loading from "../../../../../../core/types/loading";

const PostgraduateEducationForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const alertHandler = UseAlert();
  const navigate = useNavigate();
  const [submitLoadingStatus, setSubmitLoadingStatus] = React.useState<loading>(null);
  const { postgraduateEducationState } = useAppSelector(
    (state) => state.modulesState.applicationsState
  );
  const { dictionariesState } = useAppSelector((state) => state.coreState);
  const { register, control, formState, handleSubmit, reset, setValue } =
    useForm<SplitPostgraduateEducationForm>({
      values: {
        ...postgraduateEducationState.application,
        achievements: postgraduateEducationState.achievements,
        letter: postgraduateEducationState.letter,
        ...postgraduateEducationState.discountsAndBenefits,
      },
      mode: "onBlur",
    });

  React.useEffect(() => {
    const cookie = getCookie();
    if (cookie?.lastModifiedPostgraduateEducationStep) {
      dispatch(setViewedStep(Number(cookie.lastModifiedPostgraduateEducationStep)));
    }
    if (cookie?.lastModifiedPostgraduateEducationApplicationId) {
      dispatch(
        fetchGetPostgraduateEducation(Number(cookie.lastModifiedPostgraduateEducationApplicationId))
      );
    }
  }, []);

  const setLastModifiedStepCookie = (step: number): void => {
    document.cookie = `lastModifiedPostgraduateEducationStep=${step}; path=/`;
  };

  const setLastModifiedApplicationCookie = (applicationId: number): void => {
    document.cookie = `lastModifiedPostgraduateEducationApplicationId=${applicationId}; path=/`;
  };

  const getCurrentFormComponent = (): ((props?: any) => React.ReactNode) => {
    return steps.find((step, key) => postgraduateEducationState.viewedStep === key)?.element;
  };

  const goToPreviousStep = (): void => {
    const previousStepIndex = postgraduateEducationState.viewedStep - 1;
    dispatch(setViewedStep(previousStepIndex));
    setLastModifiedStepCookie(previousStepIndex);
  };

  const submitForm = (stepId: number, formData: SplitPostgraduateEducationForm): void => {
    if (stepId !== 0 && !postgraduateEducationState.id) {
      return;
    }

    setSubmitLoadingStatus("LOADING");
    savePostgraduateEducationFormRequest(
      postgraduateEducationState.id,
      convertSplitPostgraduateEducationForm(formData)
    ).then((saveRes) => {
      if (saveRes.data.error) {
        setSubmitLoadingStatus("ERROR");
        alertHandler({ message: saveRes.data.text, type: "error" });
        return;
      }
      setSubmitLoadingStatus("COMPLETE");

      setLastModifiedApplicationCookie(saveRes.data.payload?.id);
      alertHandler({ message: "Информация сохранена", type: "success" });

      dispatch(fetchGetPostgraduateEducation(saveRes.data.payload.id)).then((action) => {
        const getRes = action.payload as ResponseBody<PostgraduateEducationFormInterface>;
        if (getRes.error) {
          alertHandler({ message: getRes.text, type: "error" });
        }

        if (stepId === steps.length - 1) {
          navigate("/applications/postgraduate-education/check");
          return;
        }
        dispatch(setViewedStep(stepId + 1));
        setLastModifiedStepCookie(stepId + 1);
      });
    });
  };

  if (postgraduateEducationState.dataLoadingStatus === "LOADING") {
    return (
      <Form>
        <Loader type="blank" />
      </Form>
    );
  }

  return (
    <Form
      onSubmit={handleSubmit((data) => submitForm(postgraduateEducationState.viewedStep, data))}
    >
      <Stepper
        items={steps}
        currentStep={postgraduateEducationState.viewedStep}
        className="stepper"
      />
      {dictionariesState.programDictionariesLoadingStatus === "LOADING" ? (
        <FormContent>
          <Loader type="blank" />
        </FormContent>
      ) : (
        <FormContent>
          {getCurrentFormComponent() &&
            getCurrentFormComponent()({
              register,
              control,
              formState,
              reset,
              setValue,
            })}
        </FormContent>
      )}
      <FormActions lastStep={postgraduateEducationState.viewedStep === steps.length - 1}>
        {postgraduateEducationState.viewedStep !== 0 && (
          <OutlinedButton className="leftButton" type="button" onClick={goToPreviousStep}>
            <LineArrowIcon />
          </OutlinedButton>
        )}
        <PrimaryButton
          disabled={submitLoadingStatus === "LOADING"}
          className="rightButton"
          type="submit"
        >
          {postgraduateEducationState.viewedStep !== steps.length - 1 ? (
            <LineArrowIcon />
          ) : (
            "Сохранить"
          )}
        </PrimaryButton>
      </FormActions>
    </Form>
  );
};

export default PostgraduateEducationForm;
