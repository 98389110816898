import styled from "styled-components";
import colors from "../../../../../../core/styles/colors";
import sizes from "../../../../../../core/styles/sizes";
import { PrimaryButton } from "../../../../../shared/native/button.styles";

export const BaseApplicationsListContainer = styled.div`
  width: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media all and (${sizes.mobile}) {
    height: calc(100% - 200px);
    padding: 160px 0 40px 0;
    justify-content: space-between;
  }
`;

export const BaseApplicationsListContent = styled.div`
  width: 100%;
  h1 {
    width: calc(100% - 64px);
    padding: 0 32px;
    margin: 0;
  }

  @media all and (${sizes.mobile}) {
    width: calc(100% - 96px);
    background: ${colors.white.general};
    border-radius: 10px;
    padding: 25px 32px;
  }
`;

export const BaseApplicationsList = styled.div`
  width: 100%;
  max-height: calc(55vh - 24px);
  padding: 24px 0;
  overflow-y: auto;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 250px);
  grid-gap: 16px;

  @media all and (${sizes.mobile}) {
    height: fit-content;
    max-height: 50vh;
  }

  @media all and (max-width: 570px) {
    grid-template-columns: 1fr;
  }
`;

export const AddNewBaseApplicationButton = styled(PrimaryButton)`
  width: calc(100% - 28px);
  padding: 14px;
`;
