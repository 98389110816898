import React from "react";
import { Control, FieldValues, FormState } from "react-hook-form";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import {
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import { FieldData } from "../../../../../../../shared/form/fieldData.styles";
import Select from "../../../../../../../shared/select/Select";
import { useAppSelector } from "../../../../../../../../core/hooks/redux";

const WorkForm: React.FC<{
  formState: FormState<FieldValues>;
  control: Control;
}> = ({ formState: { errors }, control }) => {
  const { dictionariesState } = useAppSelector((state) => state.coreState);

  return (
    <FormContainer>
      <FieldInfo>
        <FieldName>Тип организации</FieldName>
        {errors.organizationType && (
          <FieldError>{errors.organizationType?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        {dictionariesState?.dictionaries && (
          <Select
            className="defaultSelect"
            placeholder="Выберите тип организации"
            items={dictionariesState.dictionaries.type_organization.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            control={control}
            options={{
              required: "Обязательное поле",
            }}
            name="organizationType"
          />
        )}
      </FieldData>
      <FieldInfo>
        <FieldName>Должность</FieldName>
      </FieldInfo>
      <FieldData>
        {dictionariesState?.dictionaries && (
          <Select
            className="defaultSelect"
            placeholder="Выберите должность"
            items={dictionariesState.dictionaries.positionsDPO.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            control={control}
            options={{
              required: "Обязательное поле",
            }}
            name="position"
          />
        )}
      </FieldData>
    </FormContainer>
  );
};

export default WorkForm;
