import { AxiosResponse } from "axios";
import ResponseBody from "../models/ResponseBody";
import { $baseApi } from "../index";
import RequestBody from "../models/RequestBody";
import NotificationsInfo from "../../ui/modules/notifications/interfaces/NotificationsInfo";
import Notification from "../../ui/modules/notifications/interfaces/Notification";
import NotificationSubmit from "../../ui/modules/notifications/interfaces/NotificationSubmit";
import store from "../../store/store";
import { fetchGetNotificationsInfo } from "../../store/modules/notifications/notificationsThunk";

export const getNotificationsInfoRequest = (): Promise<
  AxiosResponse<ResponseBody<NotificationsInfo>>
> => {
  return $baseApi.post<ResponseBody<NotificationsInfo>>("", new RequestBody("notify.count", null));
};

export const getNotificationsListRequest = (
  type: "vpo" | "dpo" | "asp",
  applicationId?: string
): Promise<AxiosResponse<ResponseBody<{ status: boolean; notification: Notification[] }>>> => {
  return $baseApi.post<ResponseBody<{ status: boolean; notification: Notification[] }>>(
    "",
    new RequestBody(`notify.${type}`, {
      applicationId,
    })
  );
};

export const getDetailedNotificationRequest = (
  notificationId: string
): Promise<AxiosResponse<ResponseBody<Notification>>> => {
  return $baseApi.post<ResponseBody<Notification>>(
    "",
    new RequestBody("notify.read", {
      notify_id: notificationId,
    })
  );
};

export const submitNotificationRequest = async (
  payload: NotificationSubmit
): Promise<AxiosResponse<ResponseBody<Notification>>> => {
  const res = await $baseApi.post<ResponseBody<Notification>>(
    "",
    new RequestBody("notify.answer", payload)
  );
  if (!res.data.error) {
    store.dispatch(fetchGetNotificationsInfo());
  }
  return res;
};
