import React from "react";
import { useNavigate } from "react-router-dom";
import { FieldData, FieldPhoto, FieldText } from "../../../../shared/form/fieldData.styles";
import { FieldDescription, FieldInfo, FieldName } from "../../../../shared/form/fieldInfo.styles";
import { FormContainer } from "../../../../shared/form/form.styles";
import { OutlinedButton, PrimaryButton } from "../../../../shared/native/button.styles";
import { TitleH2 } from "../../../../shared/native/typography.styles";
import RadioButtons from "../../../../shared/radioButtons/RadioButtons";
import { useAppDispatch, useAppSelector } from "../../../../../core/hooks/redux";
import File from "../../../../../core/interfaces/File";
import { submitProfileFormRequest } from "../../../../../http/requests/profile.http";
import UseAlert from "../../../../../core/hooks/useAlert";
import { setViewedStep } from "../../../../../store/modules/profile/profileForm/profileFormSlice";
import { fetchGetAccount } from "../../../../../store/core/account/accountThunk";
import { FormPreview, Header, Actions } from "../../../../shared/form/formPreview.styles";
import getPreviewProfileFields from "./utils/getPreviewProfileFields";
import useScrollPosition from "../../../../../core/hooks/useScrollPosition";
import loading from "../../../../../core/types/loading";

const PreviewProfileForm: React.FC<{ mode: "finallyCheck" | "view" }> = ({ mode }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const alertHandler = UseAlert();
  const previewRef = React.useRef(null);
  const [previewScrollPosition, setPreviewScrollPosition] = React.useState(0);
  const [disableNextButton, setDisableNextButton] = React.useState(true);
  const [previewFields, setPreviewFields] = React.useState([]);
  const [submitLoadingStatus, setSubmitLoadingStatus] = React.useState<loading>(null);
  const { profileFormState } = useAppSelector((state) => state.modulesState);
  const { dictionariesState } = useAppSelector((state) => state.coreState);

  useScrollPosition(previewRef, setPreviewScrollPosition);

  React.useEffect(() => {
    if (previewScrollPosition >= 95) {
      setDisableNextButton(false);
    }
  }, [previewScrollPosition]);

  React.useEffect(() => {
    if (
      !dictionariesState.dictionaries?.country ||
      !profileFormState.residingPlaceData?.placeOfBirth
    ) {
      return;
    }

    setPreviewFields(
      getPreviewProfileFields({
        ...profileFormState.personalData,
        ...profileFormState.contactsData,
        ...profileFormState.passportData,
        ...profileFormState.residingPlaceData,
      })
    );
  }, [profileFormState, dictionariesState]);

  const backToEdit = (): void => {
    dispatch(setViewedStep(0));
    document.cookie = `lastModifiedProfileStep=0; path=/`;
    navigate("/profile/profile-form");
  };

  const submitData = (): void => {
    setSubmitLoadingStatus("LOADING");
    submitProfileFormRequest().then((res) => {
      if (res.data.error) {
        setSubmitLoadingStatus("ERROR");
        alertHandler({ message: res.data.text, type: "error" });
        return;
      }
      setSubmitLoadingStatus("COMPLETE");
      alertHandler({ message: "Информация сохранена", type: "success" });
      dispatch(fetchGetAccount());
      navigate("/profile/complete-profile-form");
    });
  };

  return (
    <FormPreview mode={mode}>
      <Header>
        <TitleH2>
          {mode === "finallyCheck" ? "Проверка внесенных данных" : "Данные профиля"}
        </TitleH2>
        {mode === "finallyCheck" && (
          <p>Если вы нашли ошибку в данных, то внесите необходимые правки.</p>
        )}
      </Header>
      <FormContainer ref={previewRef} className="form">
        {previewFields.map(
          (field) =>
            !field.hideField && (
              <React.Fragment key={field.name}>
                <FieldInfo>
                  <FieldName>{field.name}</FieldName>
                  {!!field?.description && <FieldDescription>{field.description}</FieldDescription>}
                </FieldInfo>
                <FieldData>
                  {field.type === "text" && (
                    <FieldText $gray={!(field.value as string)?.length}>
                      {(field.value as string)?.length ? (field.value as string) : "Отсутствует"}
                    </FieldText>
                  )}
                  {field.type === "radio" && (
                    <RadioButtons
                      style={{ marginLeft: 6 }}
                      items={field.items}
                      value={field.value as string}
                    />
                  )}
                  {field.type === "files" &&
                    ((field.value as string[])?.every((item) => !item) ? (
                      <FieldText $gray>Отсутствует</FieldText>
                    ) : (
                      (field.value as File[])?.map(
                        (file, index) =>
                          file?.info?.name && (
                            <FieldPhoto
                              key={file.info.name + index.toString()}
                              src={file.file as string}
                              alt={file.info.name}
                            />
                          )
                      )
                    ))}
                </FieldData>
              </React.Fragment>
            )
        )}
      </FormContainer>
      {mode === "finallyCheck" && (
        <Actions>
          <OutlinedButton onClick={backToEdit}>Редактировать</OutlinedButton>
          <PrimaryButton
            disabled={disableNextButton || submitLoadingStatus === "LOADING"}
            onClick={submitData}
          >
            Подтвердить
          </PrimaryButton>
        </Actions>
      )}
    </FormPreview>
  );
};

export default PreviewProfileForm;
