import React from "react";
import { useNavigate } from "react-router-dom";
import { TitleH1, TitleH3 } from "../../../../../../../shared/native/typography.styles";
import { PrimaryButton } from "../../../../../../../shared/native/button.styles";
import { Ul } from "../../../../../../../shared/native/ul.styles";
import { Li } from "../../../../../../../shared/native/li.styles";
import {
  InfoPage,
  InfoPageContent,
  InfoPageTextInfo,
} from "../../../../../../../shared/infoPage/infoPage.styles";
import { useAppDispatch } from "../../../../../../../../core/hooks/redux";
import getCookie from "../../../../../../../../core/utils/getCookie";
import ResponseBody from "../../../../../../../../http/models/ResponseBody";
import { setViewedStep } from "../../../../../../../../store/modules/applications/highEducation/highEducationSlice";
import { fetchGetAdditionalEducation } from "../../../../../../../../store/modules/applications/additionalEducation/additionalEducationThunk";
import { AdditionalEducationForm } from "../../interfaces/additionalEducationForm";

const SubmissionInfo: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const cookie = getCookie();
    if (cookie?.lastModifiedAdditionalEducationApplicationId) {
      dispatch(
        fetchGetAdditionalEducation(Number(cookie.lastModifiedAdditionalEducationApplicationId))
      ).then((res) => {
        const data = res.payload as ResponseBody<AdditionalEducationForm>;

        if (data.payload?.application?.form) {
          dispatch(setViewedStep(1));
          navigate("/applications/additional-education/form");
        }
      });
    }
  }, []);

  return (
    <InfoPage>
      <InfoPageContent>
        <InfoPageTextInfo>
          <TitleH1>Подача заявления на программу дополнительного образования</TitleH1>
          <p>Подайте заявление на интересующую вас программу и форму обучения.</p>
          <TitleH3>Что нужно для заполнения:</TitleH3>
          <Ul>
            <Li>Документ, подтверждающий право на скидку (при наличии)</Li>
          </Ul>
        </InfoPageTextInfo>
        <PrimaryButton onClick={(): void => navigate("/applications/additional-education/form")}>
          Начать
        </PrimaryButton>
      </InfoPageContent>
    </InfoPage>
  );
};

export default SubmissionInfo;
