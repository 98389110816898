import React from "react";
import { useNavigate } from "react-router-dom";
import { TitleH1, TitleH3 } from "../../../../../../../shared/native/typography.styles";
import { PrimaryButton } from "../../../../../../../shared/native/button.styles";
import { Ul } from "../../../../../../../shared/native/ul.styles";
import { Li } from "../../../../../../../shared/native/li.styles";
import {
  InfoPage,
  InfoPageContent,
  InfoPageTextInfo,
} from "../../../../../../../shared/infoPage/infoPage.styles";
import { fetchGetHighEducation } from "../../../../../../../../store/modules/applications/highEducation/highEducationThunk";
import getCookie from "../../../../../../../../core/utils/getCookie";
import { useAppDispatch } from "../../../../../../../../core/hooks/redux";
import ResponseBody from "../../../../../../../../http/models/ResponseBody";
import { PostgraduateEducationForm } from "../../interfaces/postgraduateEducationForm";
import { setViewedStep } from "../../../../../../../../store/modules/applications/highEducation/highEducationSlice";

const SubmissionInfo: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const setStepAndNavigate = (stepId: number): void => {
    dispatch(setViewedStep(stepId));
    navigate("/applications/postgraduate-education/form");
  };

  React.useEffect(() => {
    const cookie = getCookie();
    if (cookie?.lastModifiedHighEducationApplicationId) {
      dispatch(fetchGetHighEducation(Number(cookie.lastModifiedHighEducationApplicationId))).then(
        (res) => {
          const data = res.payload as ResponseBody<PostgraduateEducationForm>;

          if (data.payload?.discountsAndBenefits?.discountsConfirm) {
            navigate("/applications/postgraduate-education/check");
          }

          if (data.payload?.letter) {
            setStepAndNavigate(3);
            return;
          }

          if (data.payload?.achievements) {
            setStepAndNavigate(2);
            return;
          }

          if (data.payload?.application?.financing) {
            setStepAndNavigate(1);
          }
        }
      );
    }
  }, []);

  return (
    <InfoPage>
      <InfoPageContent>
        <InfoPageTextInfo>
          <TitleH1>Подача заявления на поступление</TitleH1>
          <p>
            Используя ранее созданный профиль физического лица, подайте заявление на интересующую
            вас программу и форму обучения.
          </p>
          <TitleH3>Что нужно для заполнения:</TitleH3>
          <Ul>
            <Li>
              Документы, подтверждающие индивидуальные достижения
              <br />
              (по усмотрению поступающего)
            </Li>
            <Li>
              Документы, подтверждающие скидку
              <br />
              (при наличии)
            </Li>
          </Ul>
        </InfoPageTextInfo>
        <PrimaryButton onClick={(): void => navigate("/applications/postgraduate-education/form")}>
          Продолжить
        </PrimaryButton>
      </InfoPageContent>
    </InfoPage>
  );
};

export default SubmissionInfo;
