import React from "react";
import { Control, Controller, RegisterOptions, UseFormSetValue } from "react-hook-form";
import { DaDataAddress, DaDataSuggestion } from "react-dadata/dist/types";
import { AddressContainer } from "./address.styles";
import environments from "../../../../../../../env/environments";
import AddressSuggestions from "../../../../../../shared/dadata/addressSuggestions/AddressSuggestions";
import addressFields from "./constants/addressFields";
import { Input } from "../../../../../../shared/native/input.styles";

const Address: React.FC<{
  control: Control;
  name: string;
  options?: RegisterOptions;
  disabled?: boolean;
  setValue: UseFormSetValue<any>;
}> = ({ control, name, options = {}, disabled = false, setValue }) => {
  const [locations, setLocations] = React.useState<{
    [key: string]: string;
  }>({});

  const onChangeHandler = (
    e: DaDataSuggestion<DaDataAddress>,
    onChange: (...events: any[]) => void,
    dataKey: "country" | "region" | "city" | "street" | "house" | "flat"
  ): void => {
    onChange(e);

    const locationKey = addressFields.find((field) => field.name === dataKey).fiasIdKey;
    if (locationKey) {
      setLocations({
        ...locations,
        [locationKey]: e.data[locationKey],
      });
    }

    if (dataKey === "house") {
      setValue(`${name}.index`, e.data.postal_code);
    }
  };

  const resetFields = (fields: string[]): void => {
    fields.forEach((field) => {
      const fiasIdKey = addressFields.find(
        (addressField) => addressField.name === field
      )?.fiasIdKey;
      setValue(`${name}.${field}`, "");
      if (fiasIdKey && Object.keys(locations).includes(fiasIdKey)) {
        const newLocations = locations;
        delete newLocations[fiasIdKey];
        setLocations(newLocations);
      }
    });
  };

  const onInputHandler = (
    e: React.FormEvent<HTMLInputElement>,
    dataKey: "region" | "city" | "street" | "house" | "flat"
  ): void => {
    const fields = ["region", "city", "street", "house", "flat", "index"];
    resetFields(fields.slice(fields.indexOf(dataKey) + 1));
    setValue(`${name}.${dataKey}`, {
      value: (e.target as HTMLInputElement).value,
      unrestricted_value: (e.target as HTMLInputElement).value,
      data: null,
    } as DaDataSuggestion<DaDataAddress>);
  };

  return (
    <AddressContainer>
      <Controller
        control={control}
        name={`${name}.country`}
        rules={options}
        render={({ field }): React.ReactElement => (
          <Input
            placeholder="Страна"
            disabled={disabled}
            style={{ marginBottom: 8 }}
            autoComplete="null"
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      <Controller
        control={control}
        name={`${name}.region`}
        rules={options}
        render={({ field }): React.ReactElement => (
          <AddressSuggestions
            inputProps={{
              type: "text",
              placeholder: "Регион",
              disabled,
              style: { marginBottom: 8 },
              autoComplete: "null",
              onInput: (e) => onInputHandler(e, "region"),
            }}
            delay={500}
            token={environments.DADATA_TOKEN}
            value={field.value}
            onChange={(e: DaDataSuggestion<DaDataAddress>): void =>
              onChangeHandler(e, field.onChange, "region")
            }
            dadataAttrs={{
              filterFromBound: "region",
              filterToBound: "region",
            }}
          />
        )}
      />
      <Controller
        control={control}
        name={`${name}.city`}
        rules={options}
        render={({ field }): React.ReactElement => (
          <AddressSuggestions
            inputProps={{
              type: "text",
              placeholder: "Город",
              disabled,
              style: { marginBottom: 8 },
              autoComplete: "null",
              onInput: (e) => onInputHandler(e, "city"),
            }}
            delay={500}
            token={environments.DADATA_TOKEN}
            value={field.value}
            onChange={(e: DaDataSuggestion<DaDataAddress>): void =>
              onChangeHandler(e, field.onChange, "city")
            }
            dadataAttrs={{
              filterFromBound: "city",
              filterToBound: "city",
              filterLocations: [locations],
              filterRestrictValue: true,
            }}
          />
        )}
      />
      <Controller
        control={control}
        name={`${name}.street`}
        rules={options}
        render={({ field }): React.ReactElement => (
          <AddressSuggestions
            inputProps={{
              type: "text",
              placeholder: "Улица",
              disabled,
              style: { marginBottom: 8 },
              autoComplete: "null",
              onInput: (e) => onInputHandler(e, "street"),
            }}
            delay={500}
            token={environments.DADATA_TOKEN}
            value={field.value}
            onChange={(e: DaDataSuggestion<DaDataAddress>): void =>
              onChangeHandler(e, field.onChange, "street")
            }
            dadataAttrs={{
              filterFromBound: "street",
              filterToBound: "street",
              filterLocations: [locations],
              filterRestrictValue: true,
            }}
          />
        )}
      />
      <Controller
        control={control}
        name={`${name}.house`}
        rules={options}
        render={({ field }): React.ReactElement => (
          <AddressSuggestions
            inputProps={{
              type: "text",
              placeholder: "Дом/Корпус/Строение",
              disabled,
              style: { marginBottom: 8 },
              autoComplete: "null",
              onInput: (e) => onInputHandler(e, "house"),
            }}
            delay={500}
            token={environments.DADATA_TOKEN}
            value={field.value}
            onChange={(e: DaDataSuggestion<DaDataAddress>): void =>
              onChangeHandler(e, field.onChange, "house")
            }
            dadataAttrs={{
              filterFromBound: "house",
              filterToBound: "house",
              filterLocations: [locations],
              filterRestrictValue: true,
            }}
          />
        )}
      />
      <div className="flatAndIndex">
        <Controller
          control={control}
          name={`${name}.flat`}
          rules={{
            ...options,
            required: false,
          }}
          render={({ field }): React.ReactElement => (
            <Input
              placeholder="Кв."
              disabled={disabled}
              style={{ marginBottom: 8 }}
              autoComplete="null"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name={`${name}.index`}
          rules={options}
          render={({ field }): React.ReactElement => (
            <Input
              placeholder="Индекс"
              disabled={disabled}
              style={{ marginBottom: 8 }}
              autoComplete="null"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </div>
    </AddressContainer>
  );
};

export default Address;
