import convertFile from "../../../../../../../core/utils/convertFile";
import { PostgraduateEducationForm } from "../interfaces/postgraduateEducationForm";

const convertPostgraduateEducationFromSavedData = (
  data: PostgraduateEducationForm
): PostgraduateEducationForm => ({
  id: data.id,
  application: data?.application ?? null,
  achievements: data?.achievements.map((fields) => ({
    achievement: fields.achievement,
    document: convertFile("from", fields.document),
  })),
  letter: convertFile("from", data.letter),
  discountsAndBenefits: data?.discountsAndBenefits
    ? {
        discountsAndBenefits: data.discountsAndBenefits.discountsAndBenefits,
        discountsPercentage: data.discountsAndBenefits.discountsPercentage,
        discountsConfirm: convertFile("from", data.discountsAndBenefits.discountsConfirm),
      }
    : null,
});

export default convertPostgraduateEducationFromSavedData;
